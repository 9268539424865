import * as cookies from 'js-cookie';

class BaseApiService {
  public _apiBase = '';

  public reportingSessionId;

  getApiBase = (localPort: number, remoteEndpointPrefix: string) => {
    const domain = window.location.host;
    const proto = window.location.protocol;
    if (
      domain === '127.0.0.1:3000' ||
      domain === 'localhost:3000' ||
      domain === 'recon-stage.exante.eu'
    ) {
      return `https://recon9.test.zorg.sh/${remoteEndpointPrefix}/api/v1/`;
    }

    if (window.runUIhistoryContainer) {
      console.log('Using recon test inside run ui'); // eslint-disable-line
      if (domain === 'run.exante.eu') {
        return `https://recon.exante.eu/${remoteEndpointPrefix}/api/v1/`;
      }
      return `https://recon9.test.zorg.sh/${remoteEndpointPrefix}/api/v1/`;
    }

    const apiBase = `${proto}//${domain}/${remoteEndpointPrefix}/api/v1/`;
    return apiBase;
  };

  constructor() {
    this._apiBase = '';

    this.reportingSessionId = cookies.get('run-ui_reporting_session_id');
  }

  async getResource<R>(url: string): Promise<R> {
    const options = this.reportingSessionId
      ? {
          method: 'GET',
          headers: {
            'x-auth-sessionid': this.reportingSessionId,
          },
        }
      : { method: 'GET' };
    const res = await fetch(`${this._apiBase}${url}`, options);
    if (!res.ok) {
      throw new Error(`Can't fetch from ${url}. Got: ${res.status}`);
    }

    return res.json();
  }

  async postResource<T, R>(url: string, payload?: T): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res.json();
  }

  async patch<T>(url: string, payload: T) {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res.json();
  }

  async delete(url: string) {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res;
  }
}

export default BaseApiService;
