import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

function TableRow({ entry, isBO, reload }) {
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(undefined);

  useEffect(() => {
    setModified({ ...entry });
  }, [entry]);

  const onInputChange = (e) => {
    const name = e.target.name;
    const newVal = { ...entry, ...modified };
    newVal[name] = e.target.value;
    setModified(newVal);
  };

  const onChangeSubmit = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    if (isBO) {
      api
        .patchFtpPathBoDrive(modified.id, modified)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      api
        .patchFtpPath(modified.id, modified)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onDeleteSubmit = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    if (isBO) {
      api
        .deleteFtpPathBoDrive(modified.id)
        .then(() => {
          setLoading(false);
          reload(isBO, true);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      api
        .deleteFtpPath(modified.id)
        .then(() => {
          setLoading(false);
          reload(isBO, true);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  if (modified === undefined) {
    return null;
  }

  return (
    <tr key={entry.field_id}>
      <td>{entry.cp}</td>
      <td>
        <input
          value={modified.path}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="path"
        />
      </td>
      <td>
        <input
          value={modified.first_part_path}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="first_part_path"
        />
      </td>
      <td>
        <input
          value={modified.second_part_path}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="second_part_path"
        />
      </td>
      <td>
        <input
          value={modified.third_part_path}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="third_part_path"
        />
      </td>
      <td>
        <input
          value={modified.end_with}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="end_with"
        />
      </td>
      <td>
        <input
          value={modified.contains}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="contains"
        />
      </td>
      <td>
        <input
          value={modified.type}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="type"
        />
      </td>
      <td>
        {loading ? (
          <Spin />
        ) : (
          <button
            className="btn btn-sm btn-outline-success"
            onClick={onChangeSubmit}
          >
            Update
          </button>
        )}
      </td>
      <td>
        {loading ? (
          <Spin />
        ) : (
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={onDeleteSubmit}
          >
            Delete
          </button>
        )}
      </td>
    </tr>
  );
}

export function FTPPathTable({ entries, isBO, reload }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      entries.map((e) => <TableRow entry={e} isBO={isBO} reload={reload} />),
    );
  }, [entries, isBO]);

  return (
    <div className="container-fluid mt-3">
      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-light">
          <tr>
            <th>Counterparty</th>
            <th>Path</th>
            <th>First Path</th>
            <th>Second Path</th>
            <th>Third Path</th>
            <th>Ends With</th>
            <th>Contains</th>
            <th>Type</th>
            <th>#</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
