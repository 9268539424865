import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import ManualReconcileSearchForm, { BOvsBO, BOvsCP, CPvsCP } from './form';
import { ReconcileSideBySideView } from './side-by-side';

export default class ManualReconcilePage extends Component {
  // eslint-disable-next-line
  state = {
    searchResults: [],
    loading: false,
    formPayload: undefined,
    kind: undefined,
  };

  onSearchSubmit = (formPayload, kind) => {
    this.setState({ formPayload, kind });
    this.loadSearchResults(formPayload, kind);
  };

  loadSearchResults = (formPayload, kind) => {
    this.setState({ loading: true });
    const api = new ReconTablesApiService();
    if (kind === BOvsBO) {
      api
        .searchReconcileBoVsBo(formPayload)
        .then((results) => {
          this.setState({
            loading: false,
            searchResults: results,
          });
        })
        .catch((e) => {
          alert(`Failed to search: ${e}`);
          this.setState({ loading: false });
        });
    }

    if (kind === BOvsCP) {
      api
        .searchReconcileBoVsCp(formPayload)
        .then((results) => {
          this.setState({
            loading: false,
            searchResults: results,
          });
        })
        .catch((e) => {
          alert(`Failed to search: ${e}`);
          this.setState({ loading: false });
        });
    }

    if (kind === CPvsCP) {
      api
        .searchReconcileCpVsCp(formPayload)
        .then((results) => {
          this.setState({
            loading: false,
            searchResults: results,
          });
        })
        .catch((e) => {
          alert(`Failed to search: ${e}`);
          this.setState({ loading: false });
        });
    }
  };

  onReconcileSuccess = (groupdID) => {
    alert(groupdID);
    const { formPayload, kind } = this.state;
    this.loadSearchResults(formPayload, kind);
  };

  render() {
    let sideBySideView =
      this.state.searchResults.length === 0 ? (
        <h3>Nothing found</h3>
      ) : (
        <ReconcileSideBySideView
          searchResults={this.state.searchResults}
          kind={this.state.kind}
          onReconcileSuccess={this.onReconcileSuccess}
        />
      );

    sideBySideView = this.state.loading ? <Spinner /> : sideBySideView;

    return (
      <>
        <ManualReconcileSearchForm onSearchSubmit={this.onSearchSubmit} />
        {sideBySideView}
      </>
    );
  }
}
