import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export default function ManualMappingForm({ onMappingChanged }) {
  const counterParties = useSelector((state) => state.counterParties);
  const [cpOptionsList, setCpOptionsList] = useState([]);

  useEffect(() => {
    setCpOptionsList(
      counterParties.map((id) => (
        <option key={id} value={id}>
          {id.toUpperCase()}
        </option>
      )),
    );
  }, [counterParties]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target);
    const { cp, cp_symbol: cpSymbol, exante_symbol: exanteSymbol } = payload;

    if (cp === undefined || cp === '') {
      alert('Counterparty is not specified');
      return;
    }

    if (cpSymbol === undefined || cpSymbol === '') {
      alert('Counterparty symbol is not specified');
      return;
    }

    if (exanteSymbol === undefined || exanteSymbol === '') {
      alert('Exante symbol is not specified');
      return;
    }
    const split = exanteSymbol.split('.');
    if (split.length < 2) {
      alert('Looks like Exante symbol is in wrong format');
      return;
    }

    const startDate = new Date(Date.now());
    payload.account = payload.cp; // TODO looks like issue on backend side
    payload.start_date = startDate.toISOString();
    const apiService = new ReconTablesApiService();
    apiService
      .postSymbolsMapping(payload)
      .then(() => {
        onMappingChanged();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="container-fluid">
      <div className="card bg-secondary">
        <div className="card-body">
          <h4 className="card-title">Manual Mapping</h4>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-2">
              <div className="col">
                <select className="custom-select mr-sm-2" name="cp">
                  <option key="emptySelect" value="" selected />
                  {cpOptionsList}
                </select>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP</div>
                  </div>
                  <input
                    type="text"
                    name="cp_symbol"
                    className="form-control text-capitalize"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Exante Symbol</div>
                  </div>
                  <input
                    type="text"
                    name="exante_symbol"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">MtyPrice</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="price_multiplier"
                    step="0.01"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="value_multiplier"
                    step="0.1"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">MtyQty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="qty_multiplier"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CCY</div>
                  </div>
                  <input type="text" className="form-control" name="ccy" />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP Type</div>
                  </div>
                  <input type="text" className="form-control" name="cp_type" />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">ISIN</div>
                  </div>
                  <input type="text" className="form-control" name="isin" />
                </div>
              </div>
              <button className="btn btn-info">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
