import './bank_style.css';
import { useEffect, useState } from 'react';

import ScriptRunNotifications from '../run-script-page/notifications';

import { BOTableRow } from './bo-table-row';
import { ReconciledRow } from './reconciled-row';

export function BankReconcileTable(props) {
  const {
    results,
    bankReconcileRequest,
    reUploadResults,
    changeCommentRequest,
  } = props;
  // eslint-disable-next-line
  const [scriptStatus, _] = useState();
  const [data, setData] = useState(results);
  const [comment, setComment] = useState();

  const [selectedValueDelta, setSelectedValueDelta] = useState(0);
  const [leftSelectAll, setLeftSelectAll] = useState(false);
  const [rightSelectAll, setRightSelectAll] = useState(false);
  const [leftSelected, updateLeftSelected] = useState({});
  const [rightSelected, updateRightSelected] = useState({});

  const [leftSortedRows, setLeftSortedRows] = useState([]);
  const [prevLeftSortKey, setLeftPrevSortKey] = useState();
  const [reverseLeftSort, setLeftReverseSort] = useState(false);

  const [rightSortedRows, setRightSortedRows] = useState([]);
  const [prevRightSortKey, setRightPrevSortKey] = useState();
  const [reverseRightSort, setRightReverseSort] = useState(false);

  useEffect(() => {
    setData(results);
    if (results !== undefined) {
      setLeftSortedRows(results.results.left_side);
      setRightSortedRows(results.results.right_side);
    }
  }, [results]);

  if (data === undefined || data.length === 0) {
    return <h3>No data</h3>;
  }

  const deltaNotifier =
    selectedValueDelta !== 0 ? (
      <div className="alert alert-danger mt-2 mb-2" role="alert">
        Value delta between selected left_side and right_side is{' '}
        <b>{Math.abs(selectedValueDelta)}</b>
      </div>
    ) : undefined;

  const onReconcile = () => {
    const leftSelectedArray = [];
    const rightSelectedArray = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(leftSelected)) {
      if (value.status === true) {
        leftSelectedArray.push(key);
      }
    }
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(rightSelected)) {
      if (value.status === true) {
        rightSelectedArray.push(key);
      }
    }
    if (Math.abs(selectedValueDelta) > 0.2) {
      if (
        window.confirm(
          `Wrong sum transactions: ${selectedValueDelta}\nDo you want to reconcile them?`,
        )
      ) {
        bankReconcileRequest(leftSelectedArray, rightSelectedArray);
        alert('Status: ok');
        reUploadResults();
      }
    } else {
      bankReconcileRequest(leftSelectedArray, rightSelectedArray);
      alert('Status: ok');
      reUploadResults();
    }
  };

  const onSelectAll = (value) => {
    setLeftSelectAll(value);
    setRightSelectAll(value);
  };

  const onLeftSelectAll = () => {
    setLeftSelectAll(!leftSelectAll);
  };

  const onRightSelectAll = () => {
    setRightSelectAll(!rightSelectAll);
  };

  const setLeftSelected = (id, status, value, xle_account) => {
    const selected = {
      ...leftSelected,
      [id]: { status, value, xle_account },
    };
    let leftSums = 0;
    let rightSums = 0;

    // eslint-disable-next-line
    for (const [_, value] of Object.entries(rightSelected)) {
      if (value.status === true) {
        rightSums += value.value;
      }
    }
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(selected)) {
      if (value.status === true) {
        if (
          value.xle_account === true &&
          rightSums !== 0 &&
          value.value === rightSums
        ) {
          // eslint-disable-next-line
          console.log(`Skipping XLE account:  ${key}`);
        } else {
          leftSums += value.value;
        }
      }
    }

    setSelectedValueDelta(leftSums + rightSums);
    updateLeftSelected((tempSelected) => ({
      ...tempSelected,
      [id]: { status, value, xle_account },
    }));
  };

  const setRightSelected = (id, status, value, xle_account) => {
    const selected = {
      ...rightSelected,
      [id]: { status, value, xle_account },
    };
    let rightSums = 0;
    let leftSums = 0;
    // eslint-disable-next-line
    for (const [_, value] of Object.entries(selected)) {
      if (value.status === true) {
        rightSums += value.value;
      }
    }
    // eslint-disable-next-line
    for (const [key, tempValue] of Object.entries(leftSelected)) {
      if (tempValue.status === true) {
        if (
          tempValue.xle_account === true &&
          rightSums !== 0 &&
          tempValue.value === rightSums
        ) {
          // eslint-disable-next-line
          console.log(`Skipping XLE account:  ${key}`);
        } else {
          leftSums += tempValue.value;
        }
      }
    }

    setSelectedValueDelta(leftSums + rightSums);
    updateRightSelected((tempSelected) => ({
      ...tempSelected,
      [id]: { status, value, xle_account },
    }));
  };

  const onLeftSort = (event, sortKey) => {
    event.preventDefault();
    const newData = [...leftSortedRows];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevLeftSortKey) {
      reverse = !reverseLeftSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setLeftPrevSortKey(sortKey);
    setLeftSortedRows(newData);
    setLeftReverseSort(reverse);

    document
      .getElementById(`column-${prevLeftSortKey}`)
      .classList.remove('text-primary');
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');
  };

  const getLeftSide = () => {
    const leftRows = leftSortedRows.map((entry) => (
      <BOTableRow
        entry={entry}
        selectAll={leftSelectAll}
        setSelectedRows={setLeftSelected}
        reUploadResults={reUploadResults}
      />
    ));

    return (
      <div className="col-6">
        <h3>Left Side</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onLeftSelectAll}
                    checked={leftSelectAll}
                  />
                </th>
                <th>Tx_id</th>
                <th>Account Id</th>
                <th>Date Time</th>
                <th>Operation Type</th>
                <th>Asset</th>
                <th
                  onClick={(e) => onLeftSort(e, 'sum')}
                  id="column-sum"
                  key="sum"
                >
                  Sum
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>BO Comment</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>{leftRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const onRightSort = (event, sortKey) => {
    event.preventDefault();
    const newData = [...rightSortedRows];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevRightSortKey) {
      reverse = !reverseRightSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setRightPrevSortKey(sortKey);
    setRightSortedRows(newData);
    setRightReverseSort(reverse);

    document
      .getElementById(`column-${prevRightSortKey}`)
      .classList.remove('text-primary');
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');
  };

  const getRightSide = () => {
    const rightRows = rightSortedRows.map((entry) => (
      <BOTableRow
        entry={entry}
        selectAll={rightSelectAll}
        setSelectedRows={setRightSelected}
        reUploadResults={reUploadResults}
      />
    ));

    return (
      <div className="col-6">
        <h3>Right Side</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onRightSelectAll}
                    checked={rightSelectAll}
                  />
                </th>
                <th>Tx_id</th>
                <th>Account Id</th>
                <th>Date Time</th>
                <th>Operation Type</th>
                <th>Asset</th>
                <th
                  onClick={(e) => onRightSort(e, 'sum')}
                  id="column-sum"
                  key="sum"
                >
                  Sum
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>BO Comment</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>{rightRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const getReconciled = () => {
    const rows = data.results.reconciled.map((entry) => (
      <ReconciledRow entry={entry} reUploadResults={reUploadResults} />
    ));

    return (
      <div className="col">
        <h3>Reconciled operations</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>Tx Id</th>
                <th>Account Id</th>
                <th>Date Time</th>
                <th>Sum</th>
                <th>Asset</th>
                <th>Operation Type</th>
                <th>BO Comment</th>
                <th>Comment</th>
                <th>Recon Comment</th>
                <th>Group Id</th>
                <th>Recon Type</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const onChangeCommentSubmit = () => {
    const leftSelectedArray = [];
    const rightSelectedArray = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(leftSelected)) {
      if (value === true) {
        leftSelectedArray.push(key);
      }
    }
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(rightSelected)) {
      if (value === true) {
        rightSelectedArray.push(key);
      }
    }
    const sumArrays = [...leftSelectedArray, ...rightSelectedArray];
    const newComment = comment === undefined ? null : comment;
    changeCommentRequest(sumArrays, newComment);
    alert('Status: ok');
    reUploadResults();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn btn-primary"
              onClick={() => onSelectAll(false)}
            >
              Clear selected
            </button>
            <button
              className="btn btn-danger"
              onClick={() => onSelectAll(true)}
            >
              Select all
            </button>
            <button className="btn btn-warning" onClick={onReconcile}>
              Reconcile
            </button>
            <div className="input-group mx-4">
              <span className="input-group-text">Comment: </span>
              <input
                onChange={onChangeComment}
                type="text"
                className="form-control"
              />
              <div className="input-group-prepend">
                <button
                  className="btn btn-sm btn-warning"
                  type="submit"
                  onClick={onChangeCommentSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScriptRunNotifications runResults={scriptStatus} />
      {deltaNotifier}

      <div className="row">
        {getLeftSide()}
        {getRightSide()}
      </div>
      <div className="row mt-4">{getReconciled()}</div>
    </div>
  );
}
