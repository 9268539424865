import { useEffect, useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';

import ReconTablesApiService from '../../services/ReconTablesApiService';

function XleTableRow(props) {
  const { entry } = props;
  const columns = [];
  const [userComment, setUserComment] = useState();
  const [commentUpdating, setCommentUpdating] = useState(false);

  useEffect(() => {
    setUserComment(entry.comment);
  }, [entry]);

  const onCommentChangeClick = (e) => {
    setCommentUpdating(true);
    e.preventDefault();

    const payload = {
      table: entry.table,
      report_date: entry.report_date,
      symbol: entry.symbol,
      ccy: entry.ccy,
      comment: userComment === undefined ? '' : userComment,
    };

    const service = new ReconTablesApiService();
    if (entry.comment_id) {
      service
        .patchXleReportComment(entry.comment_id, payload)
        .then(() => {
          setCommentUpdating(false);
        })
        .catch(() => {
          setCommentUpdating(false);
        });
    } else {
      service
        .postXleReportComment(payload)
        .then(() => {
          setCommentUpdating(false);
        })
        .catch(() => {
          setCommentUpdating(false);
        });
    }
  };

  const onInputChange = (e) => {
    setUserComment(e.target.value);
  };

  Object.keys(entry).forEach((k) => {
    if (k === 'table' || k === 'comment_id') {
      return;
    }
    if (k.toLowerCase() === 'comment') {
      columns.push(
        <td key={`${k}-${entry.symbol}`}>
          {commentUpdating ? (
            <Spinner />
          ) : (
            <form onSubmit={onCommentChangeClick}>
              <textarea
                className="form-control text-small"
                id={`commentEditXle${entry.symbol}${entry.ccy}`}
                onChange={onInputChange}
                rows="2"
                defaultValue={entry.comment}
                value={userComment}
              />
              <button type="submit" className="btn btn-sm btn-link">
                Update
              </button>
            </form>
          )}
        </td>,
      );
      return;
    }
    columns.push(<td key={`${k}-${entry.symbol}`}>{entry[k]}</td>);
  });

  return <tr key={`${entry.symbol}-${entry.ccy}`}>{columns}</tr>;
}

export function XleReportTable(props) {
  const { data } = props;
  const l = data === undefined ? 0 : data.length;

  const [prevSortKey, setPrevSortKey] = useState();
  const [reverseSort, setReverseSort] = useState(false);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(data);
  }, [l]);

  const onSort = (event, sortKey) => {
    event.preventDefault();

    if (data === undefined || data.length === 0) {
      return;
    }
    const newData = [...sortedData];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevSortKey) {
      reverse = !reverseSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setPrevSortKey(sortKey);
    setSortedData(newData);
    setReverseSort(reverse);

    try {
      document
        .getElementById(`column-${prevSortKey}`)
        .classList.remove('text-primary');
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
    try {
      document
        .getElementById(`column-${sortKey}`)
        .classList.add('text-primary');
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  if (data === undefined || data.length === 0) {
    return <h3>No data</h3>;
  }

  const newCols = [];
  const filterOptions = [];
  Object.keys(data[0]).forEach((k) => {
    if (k === 'table' || k === 'comment_id') {
      return;
    }
    newCols.push(
      <th onClick={(e) => onSort(e, k)} id={`column-${k}`} key={k}>
        {k}
        <i className="fa fa-fw fa-sort" />
      </th>,
    );

    filterOptions.push(
      <option key={k} value={k}>
        {k}
      </option>,
    );
  });
  const rows = sortedData.map((e) => <XleTableRow entry={e} key={e.symbol} />);
  return (
    <>
      <div className="table-responsive mt-3">
        <table className="table table-bordered table-hover table-sm">
          <thead>
            <tr className="thead-light">{newCols}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </>
  );
}
