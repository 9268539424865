import * as cookies from 'js-cookie';

import { BASE_URL } from './constants';

const sessionId = cookies.get('run-ui_workflow_session_id');

export const getRequest = (url: string) => {
  const options = sessionId
    ? {
        method: 'GET',
        headers: {
          'x-auth-sessionid': sessionId,
        },
      }
    : { method: 'GET' };

  return fetch(`${BASE_URL}${url}`, options);
};

export const postRequest = ({ url, payload }: any) => {
  const options: any = sessionId
    ? {
        method: 'POST',
        headers: {
          'x-auth-sessionid': sessionId,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    : {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };

  return fetch(`${BASE_URL}${url}`, options);
};
