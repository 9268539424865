import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const COLUMNS = [
  'date',
  'account',
  'cp_account',
  'symbol',
  'qty',
  'price',
  'value',
  'cp_name',
  'symbol_type',
  'ccy',
  'isin',
];

function TableRow({ trade, reconcile }) {
  const columns = [];
  const leNames = useSelector((state) => state.legalEntities);
  // eslint-disable-next-line
  for (const key of COLUMNS) {
    if (key === 'entitylegalmalta') {
      columns.push(<td key={key}>{leNames[trade[key]]}</td>);
    } else {
      columns.push(<td key={key}>{trade[key]}</td>);
    }
  }

  const rec = () => {
    reconcile(trade);
  };

  columns.push(
    <td key="loading">
      <button className="btn btn-primary" onClick={rec}>
        Reconcile
      </button>
    </td>,
  );
  return <tr key={trade.tradenumber}>{columns}</tr>;
}

export function MatchedResults({ ctrades, cptrade, reconcile }) {
  const [names, setNames] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newNames = [];
    // eslint-disable-next-line
    for (const key of COLUMNS) {
      newNames.push(<th key={key}>{key}</th>);
    }
    newNames.push(<th key="en">Action</th>);
    setNames(newNames);
  }, []);

  useEffect(() => {
    if (ctrades === undefined || ctrades.length === 0) {
      setRows([]);
      return;
    }

    const newRows = [];
    // eslint-disable-next-line
    for (const t of ctrades) {
      newRows.push(<TableRow trade={t} reconcile={reconcile} />);
    }
    setRows(newRows);
  }, [ctrades, cptrade]);
  if (cptrade === undefined) {
    return null;
  }
  if (ctrades === undefined || ctrades.length === 0) {
    return <h2>No matches</h2>;
  }

  return (
    <div className="table-responsive mt-3">
      <h3>Broker Trade</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>reportdate</th>
            <th>brokerid</th>
            <th>account</th>
            <th>symbol</th>
            <th>qty</th>
            <th>price</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{cptrade.report_date}</td>
            <td>{cptrade.broker_id}</td>
            <td>{cptrade.account}</td>
            <td>{cptrade.symbol}</td>
            <td>{cptrade.qty}</td>
            <td>{cptrade.price}</td>
            <td>{cptrade.value}</td>
          </tr>
        </tbody>
      </table>
      <h3>Our Trades</h3>
      <table className="table table-bordered table-hover table-sm">
        <thead>
          <tr className="thead-light">{names}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
