import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';
import { commonSlice } from 'store/reducers/commonReducer';

const PositionDiffForm = () => {
  const dispatch = useDispatch();
  const cpNamesList = useAppSelector((state) => state.counterParties);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    if (payload.report_date === undefined || payload.cp === undefined) {
      alert('Date and CP fields are required');
      return;
    }

    dispatch(commonSlice.actions.setPositionDiffResultsLoading());
    dispatch(commonSlice.actions.setPositionDiffResultsCP(payload.cp));

    const api = new ReconTablesApiService();
    if (payload.groupByISIN) {
      api.getListPositionsDiffsGroupedByISIN(payload).then((res) => {
        const results = res.results === undefined ? res : res.results;
        dispatch(commonSlice.actions.setPositionDiffResults(results));
      });
    } else {
      api.getListPositionsDiffsWithPrevious(payload).then((res) => {
        const results = res.results === undefined ? res : res.results;
        dispatch(commonSlice.actions.setPositionDiffResults(results));
      });
    }
  };

  const cpOptionsList = cpNamesList.map((id) => (
    <option key={id} value={id}>
      {id.toUpperCase()}
    </option>
  ));

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchFormReconcile">
        <div className="form-row mb-2">
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp"
                placeholder="Counterparty"
              >
                {cpOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">BO Symbol</div>
              </div>
              <input name="symbol" type="text" className="form-control" />
            </div>
          </div>

          <div className="col">
            <div className="mx-auto">
              <button className="btn btn-outline-success" type="submit">
                Load
              </button>
              <div className="form-check">
                <input
                  name="groupByISIN"
                  type="checkbox"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="groupByIsin">
                  <b>Group by ISIN</b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default PositionDiffForm;
