import { Component } from 'react';

import { FilterForm } from 'components/FilterForm/FilterForm';
import ReconRunnerApiService from 'services/ReconRunnerApiService';

import ScriptRunInfoTable from './run-info-table/table';

class RunInfoPage extends Component {
  // eslint-disable-next-line
  state = {
    runInfos: [],
    selectedRunInfos: [],
  };

  componentDidMount() {
    this.getInfos();
  }

  getInfos = () => {
    const apiService = new ReconRunnerApiService();

    apiService
      .getScriptsExecutionsInfo()
      .then((json) => {
        this.setState({
          runInfos: json,
          selectedRunInfos: json,
        });
      })
      .catch(() => {});
  };

  getFilteredRunInfos = (val) => {
    if (val === undefined) {
      // eslint-disable-next-line
      this.setState({ selectedRunInfos: this.state.runInfos });
      return;
    }

    // eslint-disable-next-line
    const found = this.state.runInfos.filter(
      (script) =>
        script.script_name.toLowerCase().includes(val) ||
        script.app.toLowerCase().includes(val),
    );
    this.setState({ selectedRunInfos: found });
  };

  render() {
    const container = (
      <ScriptRunInfoTable runStatuses={this.state.selectedRunInfos} />
    );
    return (
      <>
        <FilterForm
          header="Run info"
          searchChanged={this.getFilteredRunInfos}
        />
        {container}
      </>
    );
  }
}

export default RunInfoPage;
