import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import { EodReportSearchFrom } from './form';
import { EODReportTable } from './table/table';

export function EodReportPage() {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [activePayload, setActivePayload] = useState();

  const queryReport = (payload) => {
    setLoading(true);
    setActivePayload(payload);
    const service = new ReconTablesApiService();
    service
      .getReconBySymbolReport(payload)
      .then((e) => {
        setResults(e);
        setLoading(false);
      })
      .catch((e) => {
        setResults([]);
        setLoading(false);
        alert(e);
      });
  };

  return (
    <>
      <EodReportSearchFrom queryReport={queryReport} />
      {loading ? (
        <Spinner />
      ) : (
        <EODReportTable data={results} payload={activePayload} />
      )}
    </>
  );
}
