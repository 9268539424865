import {
  TCommonParams,
  IOnFetchArguments,
  Notification,
} from 'react-ui-kit-exante';

import { SortingTableObject } from 'types';

import BaseApiService from './baseApiService';
import { getUrlFromPayload } from './utils';

export interface ResponseWithPagination<T> {
  content: T[];
  pagination: { limit: number; page: number; total: number };
}

export interface Trade {
  uid: string;
  account: string;
  trade_date: string;
  trade_time: string;
  operation_type: string;
  value_date: string;
  side: string;
  symbol: string;
  symbol_type: string;
  isin: string | null;
  qty: string;
  price: string;
  value: string;
  ccy: string;
  cp_account: string;
  execution_cp: string;
  settlement_cp: string;
  entity: string;
  order_id: string;
  position_id: number;
  sent_date: string | null;
  status: string | null;
  status_date: string | null;
  timestamp: string;
  error: string;
  internal_status: string;
  bo_trade_detailed: string | null;
  chain_id: string;
}

export interface TradesTable {
  trades: Trade[];
  pagination: { total: number };
}

export interface Position {
  account: string;
  ccy: string;
  cp?: string;
  created_date: string;
  description: string;
  error?: string;
  exchange: string;
  expiration_date: string;
  id: number;
  internal_status: string;
  isin?: string;
  operation_type: string;
  pnl: string;
  price: string;
  qty: string;
  realized_pnl?: string;
  sent_date?: string;
  status?: string;
  status_date?: string;
  symbol: string;
  value: string;
}

export interface PositionsTable {
  positions: Position[];
  pagination: { total: number };
}

export interface DetailInfoItem {
  name: string;
  items: Record<string, string>;
}

export enum FilterValueType {
  STRING = 'string',
  FLOAT = 'float',
  DATE = 'date',
  BOOLEAN = 'bool',
}

export interface ScriptItem {
  name: string;
  params: Record<string, FilterValueType>;
}

export enum STATUSES {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}

export interface Task {
  end_time: string | null;
  id: number;
  name: string;
  output: string;
  start_time: string;
  report_date: string;
  status: STATUSES;
}

export interface TasksTable {
  tasks: Task[];
  pagination: { total: number };
}

export type Metrics = Record<string, Record<string, number>>;

export const getSortingParams = (sorting: TCommonParams['sorting']) =>
  (sorting as SortingTableObject[]).reduce((acc, item) => {
    const noEmpty = !!Object.values(item).length;
    return noEmpty
      ? `${acc}order=${item.desc ? 'desc' : 'asc'}&order_by=${item.id}&`
      : acc;
  }, '');

enum Urls {
  TRADES = 'trades?',
  POSITIONS = 'positions?',
  TASKS = 'tasks?',
}

export class ReconEmirApiService extends BaseApiService {
  _apiBase = `https://${
    window?.RECON_UI?.EMIR ?? `emir-reports-stage.exante.eu`
  }/api/v1/`;

  async getTrades({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Trade>>(
        getUrlFromPayload(Urls.TRADES, filterParams) +
          getSortingParams(params.sorting),
      );
      return {
        trades: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error(`Load trades error: ${error}`);
    }
    return {
      trades: [],
      pagination: {},
    };
  }

  async getCurrentTrade(tradeId: string) {
    return this.getResource<Record<string, DetailInfoItem[]>>(
      `trades/entries?trade=${tradeId}`,
    );
  }

  async getPositions({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Position>>(
        getUrlFromPayload(Urls.POSITIONS, filterParams) +
          getSortingParams(sorting),
      );
      return {
        positions: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error(`Load positions error: ${error}`);
    }
    return {
      positions: [],
      pagination: {},
    };
  }

  async getCurrentPosition(positionId: string) {
    return this.getResource<Record<string, DetailInfoItem[]>>(
      `positions/entries?position=${positionId}`,
    );
  }

  async getScripts() {
    return this.getResource<{ scripts: ScriptItem[] }>(`get_run_scripts`);
  }

  async runScript(payload: Record<string, boolean | string>) {
    return this.postResource(`run`, payload);
  }

  async getTasks({ params }: IOnFetchArguments) {
    try {
      const { sorting, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Task>>(
        getUrlFromPayload(Urls.TASKS, filterParams) + getSortingParams(sorting),
      );
      return {
        tasks: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error(`Load tasks error: ${error}`);
    }
    return {
      tasks: [],
      pagination: {},
    };
  }

  async getDashboardInfo(reportDate?: string[]) {
    return this.getResource<{
      metrics: Metrics;
      report_date: string[];
    }>(`metrics${reportDate ? `?report_date=${reportDate}` : ''}`);
  }
}
