import { ChangeEvent, Component } from 'react';

import { FilterFormProps, FilterFormState } from './types';

export class FilterForm extends Component<FilterFormProps, FilterFormState> {
  // eslint-disable-next-line
  state: FilterFormState = {
    searchText: undefined,
  };

  onInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { searchChanged } = this.props;
    this.setState({
      searchText: event.target.value,
    });

    searchChanged(event.target.value);
  };

  render() {
    const { header } = this.props;

    const { searchText } = this.state;
    return (
      <div className="container text-center mb-3">
        <h2>{header}</h2>
        <div className="input-group">
          <input
            className="form-control"
            id="search"
            placeholder="Search script..."
            name="s"
            autoComplete="off"
            onChange={this.onInputChanged}
            value={searchText}
          />
        </div>
      </div>
    );
  }
}
