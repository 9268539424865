import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from 'services/utils';

export function EodReportSearchFrom(props) {
  const { queryReport } = props;
  const legalEntities = useSelector((state) => state.legalEntities);
  const counterParties = useSelector((state) => state.counterParties);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    queryReport(payload);
  };

  const cpOptions = counterParties.map((cp) => (
    <option key={cp} value={cp}>
      {cp.toUpperCase()}
    </option>
  ));

  const leOptions = Object.entries(legalEntities).map(([key, value]) => (
    <option key={key} value={value}>
      {value.toUpperCase()}
    </option>
  ));

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchEODReport">
        <div className="form-row mb-2">
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp">
                <option key="any" value="">
                  Any
                </option>
                {cpOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select mr-sm-2" name="legal_entity">
                <option key="any" value="">
                  Any
                </option>
                {leOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Status</div>
              </div>
              <select className="custom-select mr-sm-2" name="rec_status">
                <option key="any" value="">
                  Any
                </option>
                <option key="ok" value="OK">
                  OK
                </option>
                <option key="not ok" value="Not OK">
                  NOT OK
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="mx-auto">
            <button className="btn btn-outline-success" type="submit">
              Load
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
