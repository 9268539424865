import { useEffect } from 'react';

import ReconTablesApiService from '../services/ReconTablesApiService';

export const useSortedRows = (
  formPayload,
  pageNumberDirection,
  direction,
  necessaryRows,
  setNecessaryRows,
  mode,
  isFilled,
) => {
  useEffect(() => {
    if (formPayload === undefined || !isFilled) {
      return;
    }
    const api = new ReconTablesApiService();
    formPayload.page_number = pageNumberDirection;
    if (
      (direction === 'right' && mode !== 'cp-vs-cp') ||
      (mode === 'bo-vs-bo' && direction === 'left')
    ) {
      api.getBoRecords(formPayload).then((resultsData) => {
        setNecessaryRows(resultsData.results.right_side);
        necessaryRows.push(resultsData);
      });
    }
    if (
      (direction === 'left' && mode !== 'bo-vs-bo') ||
      (mode === 'cp-vs-cp' && direction === 'right')
    ) {
      api.getCpRecords(formPayload).then((resultsData) => {
        setNecessaryRows(resultsData.results.left_side);
        necessaryRows.push(resultsData);
      });
    }
  }, [pageNumberDirection]);
};
