import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import ReconTablesApiService from '../../../services/ReconTablesApiService';
import { formatDate } from '../../../services/date-funcs';
import './style.css';

export class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cssClass: '', // class for row. changes when we modify row and after server side updates
      cp_report_date: this.props.entry.cp_report_date, // used to change value on server
      cp_name: this.props.entry.cp_name, // used to change value on server
    };
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === this.props) {
      return;
    }

    if (prevState === this.state) {
      return;
    }

    this.state = {
      cp_report_date: this.props.entry.cp_report_date,
      cp_name: this.props.entry.cp_name,
    };
  }

  dateChangeHandler = (date) => {
    this.setState({
      cp_report_date: formatDate(date),
      cssClass: 'bg-info',
    });
  };

  cpChangeHandler = (event) => {
    this.setState({
      cssClass: 'bg-info',
      cp_name: event.target.value,
    });
  };

  updateData = (event) => {
    // update single row when form submitted
    event.preventDefault();
    const { entry } = this.props;
    if (
      this.state.cp_name === entry.cp_name &&
      this.state.cp_report_date === entry.cp_report_date
    ) {
      return;
    }
    const payload = {
      ...entry,
      cp_report_date: this.state.cp_report_date,
      cp_name: this.state.cp_name,
    };

    const api = new ReconTablesApiService();
    api
      .patchCTrade(entry.trade_number, payload)
      .then(() => {
        this.setState({ cssClass: 'bg-success' });
      })
      .catch((e) => {
        this.setState({ cssClass: 'bg-danger' });
        alert(e);
      });
  };

  onChecked = (e) => {
    // when checked we need to callback to notify table about changed rows
    const { entry, onCheck } = this.props;
    const currentCheckState = e.target.checked;
    onCheck(entry, currentCheckState);
  };

  cpOptions = () => {
    // get cp options for select
    const { entry, cpNameList } = this.props;
    if (!cpNameList) {
      return (
        <option selected value={entry.cp_name}>
          {entry.cp_name}
        </option>
      );
    }
    let hasEntry = false;

    const options = cpNameList.map((name) => {
      const selected = name === entry.cp_name;
      if (selected) {
        hasEntry = true;
      }
      return (
        <option key={name} value={name} selected={selected}>
          {name.toUpperCase()}
        </option>
      );
    });
    if (!hasEntry) {
      options.push(
        <option selected value={entry.cp_name}>
          {entry.cp_name}
        </option>,
      );
    }
    return options;
  };

  render() {
    const { entry, legalEntities } = this.props;
    return (
      <tr
        className={this.state.cssClass}
        name={entry.trade_number}
        key={entry.trade_number}
      >
        <td>{entry.rec_status ? 'OK' : 'NOT OK'}</td>
        <td>{entry.account}</td>
        <td>{entry.symbol}</td>
        <td>{entry.order_id}</td>
        <td>{entry.order_pos}</td>
        <td>{entry.qty}</td>
        <td>{entry.price}</td>
        <td>{legalEntities[entry.entity_legal_malta_id]}</td>
        <td>{entry.bo_trade_timestamp}</td>
        <td className="table-select-td">
          <DayPickerInput
            inputProps={{
              className: 'custom-select mr-sm-2',
              autoComplete: 'off',
            }}
            value={this.state.cp_report_date}
            onDayChange={this.dateChangeHandler}
          />
        </td>
        <td className="table-select-td">
          <select
            defaultValue={entry.cp_name}
            id="inputState"
            className="form-control"
            onChange={this.cpChangeHandler}
          >
            {this.cpOptions()}
          </select>
        </td>
        <td>
          <button className="btn btn-outline-dark" onClick={this.updateData}>
            Update
          </button>
        </td>
        <td>
          <input
            name="check"
            type="checkbox"
            checked={this.props.checked}
            onChange={this.onChecked}
          />
        </td>
      </tr>
    );
  }
}
