import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from '../../services/utils';

export function CtradeSearchForm({ searchTrades }) {
  const legalEntities = useSelector((state) => state.legalEntities);
  const brokerIDs = useSelector((state) => state.counterParties);

  const [leOptions, setLeOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);

  useEffect(() => {
    if (!Object.values(legalEntities).length) {
      return;
    }
    const entityOptionsList = [];
    // eslint-disable-next-line
    for (const key of Object.keys(legalEntities)) {
      entityOptionsList.push(
        <option key={key} value={key}>
          {legalEntities[key].toUpperCase()}
        </option>,
      );
    }
    setLeOptions(entityOptionsList);
  }, [legalEntities]);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }
    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    payload.rec_status = false;
    payload.type_of_trade = 'Trade';
    searchTrades(payload);
  };

  return (
    <div className="container-fluid mb-3 mt-3">
      <form onSubmit={onSubmit} name="CtradeSearchForm">
        <div className="form-row mb-2">
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <select
                className="custom-select mr-sm-2"
                id="entity"
                name="broker_id"
              >
                <option value="">BrokerID</option>
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <select
              className="custom-select mr-sm-2"
              id="entity"
              name="legal_entity"
            >
              <option value="">All Entities</option>
              {leOptions}
            </select>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Price</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="price"
                name="price"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Symbol</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="symbol"
                name="symbol"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Qty</div>
              </div>
              <input type="text" className="form-control" id="qty" name="qty" />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="mx-auto">
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
