import { Component } from 'react';

class ScriptRunNotifications extends Component {
  // eslint-disable-next-line
  state = { runResults: [] };

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.runResults !== this.props.runResults) {
      // eslint-disable-next-line
      this.setState({ runResults: this.props.runResults });
    }
  }

  render() {
    const items = this.state.runResults.map((res) => {
      const cls =
        res.status === 'error'
          ? 'list-group-item list-group-item-danger'
          : 'list-group-item list-group-item-success';
      return res.scripts.map((name) => (
        <li className={cls}>
          <b>{name}</b> {res.message}
        </li>
      ));
    });

    const itemsReversed = items.map((_, idx, arr) => arr[arr.length - 1 - idx]);

    return (
      <div className="container-fluid">
        <ul key="notifier" className="list-group mt-2">
          {itemsReversed}
        </ul>
      </div>
    );
  }
}

export default ScriptRunNotifications;
