import dayjs from 'dayjs';
import { FC, useMemo, Dispatch, SetStateAction } from 'react';
import { DatePicker, Autocomplete, Input, Button } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import { CategoryComment } from 'services/ReconTablesApiService';

import { DatesIsValid } from '../../UnionTransactionsReconcile/types';

interface Props {
  datesIsValid: DatesIsValid;
  setDatesIsValid: Dispatch<SetStateAction<DatesIsValid>>;
  setFromFilter: Dispatch<SetStateAction<string | undefined>>;
  fromFilter?: string;
  setToFilter: Dispatch<SetStateAction<string | undefined>>;
  toFilter?: string;
  setEntityFilter: Dispatch<SetStateAction<string>>;
  setCpFilter: Dispatch<SetStateAction<string>>;
  setOurSymbolFilter: Dispatch<SetStateAction<string>>;
  ourSymbolFilter: string;
  setBrokerSymbolFilter: Dispatch<SetStateAction<string>>;
  brokerSymbolFilter: string;
  breakCategories: CategoryComment[];
  setBreakCategoryFilter: Dispatch<SetStateAction<string>>;
  handleSubmit: (firstPage: boolean) => void;
}

export const SearchForm: FC<Props> = ({
  setFromFilter,
  fromFilter,
  setToFilter,
  toFilter,
  datesIsValid,
  setDatesIsValid,
  setEntityFilter,
  setCpFilter,
  setOurSymbolFilter,
  ourSymbolFilter,
  setBrokerSymbolFilter,
  brokerSymbolFilter,
  setBreakCategoryFilter,
  handleSubmit,
  breakCategories,
}) => {
  const brokerIDs = useAppSelector((state) => state.counterParties);
  const entities = useAppSelector((state) => state.legalEntities);

  const entityOptions = useMemo(
    () =>
      Object.keys(entities).map((i) => ({
        label: entities[i],
        value: i,
      })),
    [entities],
  );
  const cpOptions = useMemo(
    () =>
      brokerIDs.map((i) => ({
        label: i.toUpperCase(),
        value: i,
      })),
    [brokerIDs],
  );
  const breakCategoryOptions = useMemo(
    () => [
      { label: 'empty', value: 'empty' },
      { label: 'not_empty', value: 'not_empty' },
      ...breakCategories.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ],
    [breakCategories],
  );

  const handleSetDate = (date: unknown, type: 'from' | 'to') => {
    if (!date) {
      setDatesIsValid({ ...datesIsValid, [type]: false });
      return;
    }
    setDatesIsValid({ ...datesIsValid, [type]: true });
    const formattedDate = dayjs(date as string).format(DATE_FORMAT);
    if (type === 'from') {
      setFromFilter(formattedDate);
    }
    if (type === 'to') {
      setToFilter(formattedDate);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3 col-md-4 mt-2">
        <DatePicker
          label="From"
          controlProps={{ fullWidth: true }}
          value={fromFilter}
          onChange={(date) => handleSetDate(date, 'from')}
        />
      </div>
      <div className="col-lg-3 col-md-4 mt-2">
        <DatePicker
          label="To"
          controlProps={{ fullWidth: true }}
          value={toFilter}
          onChange={(date) => handleSetDate(date, 'to')}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Entity"
          className="w-100"
          options={entityOptions}
          onChange={(event, newValue: any) => {
            setEntityFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="CP"
          className="w-100"
          options={cpOptions}
          onChange={(event, newValue: any) => {
            setCpFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Input
          label="Our Symbol"
          value={ourSymbolFilter}
          fullWidth
          onChange={(event) => setOurSymbolFilter(event.target.value)}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Input
          label="Their Symbol"
          value={brokerSymbolFilter}
          fullWidth
          onChange={(event) => setBrokerSymbolFilter(event.target.value)}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Break Category"
          className="w-100"
          options={breakCategoryOptions}
          onChange={(event, newValue: any) => {
            setBreakCategoryFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Button fullWidth onClick={() => handleSubmit(true)}>
          Load Data
        </Button>
      </div>
    </div>
  );
};
