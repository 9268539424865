import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { dynamicSort } from 'utils';

function ReportTableRow(prop) {
  const report = prop.report;

  const onClone = (cp) => {
    const api = new ReconTablesApiService();
    api
      .postCloneReportWithFields(cp)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        alert(e);
      });
  };

  const { fields } = report;
  let rowSpan = fields.length;
  if (rowSpan === 0) {
    rowSpan = 1;
  }
  const extraRows = [];

  if (fields.length > 1) {
    for (let i = 1; i < fields.length; i += 1) {
      extraRows.push(
        <tr key={`${report.id}_${i}`}>
          <td>{`${fields[i].cp_field} to ${fields[i].mapping_field} by ${fields[i].mapping_type}`}</td>
        </tr>,
      );
    }
  }

  if (
    (report.state === 'Closed' ||
      report.state === 'Setup' ||
      report.state === 'Stopped') &&
    prop.active === false
  ) {
    return null;
  }
  if (report.type !== 'unpivot') {
    return null;
  }

  if (report.isUsed === false) {
    return (
      <>
        <tr key={report.id}>
          <td rowSpan={rowSpan}>
            <Link to={`/mapping/unpivot_reports/${report.id}/`}>
              {report.id}
            </Link>
          </td>
          <td rowSpan={rowSpan}>{report.cp_id}</td>
          <td rowSpan={rowSpan}>{report.name}</td>
          <td rowSpan={rowSpan}>{report.type}</td>
          <td rowSpan={rowSpan}>{report.path}</td>
          <td rowSpan={rowSpan}>{report.contains}</td>
          <td rowSpan={rowSpan}>{report.output}</td>
          <td rowSpan={rowSpan}>{report.state.toString()}</td>
          {fields === undefined || fields.length === 0 ? (
            <td rowSpan={rowSpan}>No conditions</td>
          ) : (
            <td>{`${fields[0].cp_field} to ${fields[0].mapping_field} by ${fields[0].mapping_type}`}</td>
          )}

          <td rowSpan={rowSpan}>
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => onClone(report.id)}
            >
              Clone
            </button>
          </td>
        </tr>
        {extraRows}
      </>
    );
  }
  return (
    <>
      <tr key={report.id}>
        <td rowSpan={rowSpan}>
          <Link to={`/mapping/unpivot_reports/${report.id}/`}>{report.id}</Link>
        </td>
        <td rowSpan={rowSpan}>{report.cp_id}</td>
        <td rowSpan={rowSpan}>{report.name}</td>
        <td rowSpan={rowSpan}>{report.type}</td>
        <td rowSpan={rowSpan}>{report.path}</td>
        <td rowSpan={rowSpan}>{report.contains}</td>
        <td rowSpan={rowSpan}>{report.output}</td>
        <td rowSpan={rowSpan}>{report.state.toString()}</td>
        {fields === undefined || fields.length === 0 ? (
          <td rowSpan={rowSpan}>No conditions</td>
        ) : (
          <td>{`${fields[0].cp_field} to ${fields[0].mapping_field} by ${fields[0].mapping_type}`}</td>
        )}

        <td rowSpan={rowSpan}>
          <button
            type="button"
            className="btn btn-sm btn-outline-success"
            onClick={() => onClone(report.id)}
          >
            Clone
          </button>
        </td>
      </tr>
      {extraRows}
    </>
  );
}

function ReportListView(prop) {
  const reports = prop.reports;
  const temp = prop.active;
  const data = reports;
  const l = reports === undefined ? 0 : reports.length;
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [l]);

  const onSort = (event, sortKey) => {
    setSortedData([]);
    setSortedData([...sortedData].sort(dynamicSort(sortKey)));
  };

  const rows = sortedData.map((report) => (
    <ReportTableRow report={report} active={temp} key={report.id} />
  ));
  return (
    <div className="container-fluid mt-3">
      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th onClick={(e) => onSort(e, 'id')}>ID</th>
            <th onClick={(e) => onSort(e, 'cp_id')}>Counterparty</th>
            <th onClick={(e) => onSort(e, 'name')}> Name</th>
            <th onClick={(e) => onSort(e, 'type')}> Type</th>
            <th onClick={(e) => onSort(e, 'path')}>Path</th>
            <th onClick={(e) => onSort(e, 'contains')}> Contains</th>
            <th onClick={(e) => onSort(e, 'file_type')}>Output File</th>
            <th onClick={(e) => onSort(e, 'state')}>State</th>
            <th>Field Mappings</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default function ReportsListPage() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListMappingReportWithFields()
      .then((res) => {
        setLoading(false);
        setReports(res);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const onSelected = (e) => {
    setActive(e.target.checked);
  };
  return (
    <>
      <h2>Unpivot Reports List</h2>
      Show not active elements{' '}
      <input type="checkbox" onChange={onSelected} checked={active} />
      {loading ? (
        <div className="mt-5">
          <Spin />
        </div>
      ) : (
        <ReportListView reports={reports} active={active} />
      )}
    </>
  );
}
