import { FC, useCallback, useMemo, useState } from 'react';
import {
  Panel,
  IOnFetchArguments,
  useTableData,
  ISortBy,
  Table,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import ReconTablesApiService, {
  RawDataReportSetup,
  ResponseForTable,
} from 'services/ReconTablesApiService';
import { calculateCountOfPages } from 'utils';

import { NewRawData } from './NewRawData/NewRawData';
import { getRawDataTableColumns } from './RawDataUtils';

const api = new ReconTablesApiService();

export const RawDataPage: FC = () => {
  const [showedIds, setShowedIds] = useState<number[]>([]);

  const handleShowRow = useCallback(
    (id: number) => {
      setShowedIds([...showedIds, id]);
    },
    [showedIds],
  );
  const handleHideRow = useCallback(
    (id: number) => {
      setShowedIds(showedIds.filter((i) => i !== id));
    },
    [showedIds],
  );

  const getReportConfigs = useCallback(
    (props: IOnFetchArguments) => api.getRawDataReportConfigs(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getRawDataTableColumns({
        showedIds,
        showRow: handleShowRow,
        hideRow: handleHideRow,
      }).map((column) => column.accessor);
      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof RawDataReportSetup)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReportConfigs },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getReportConfigs],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<ResponseForTable<RawDataReportSetup>>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const rawDataTableColumns = useMemo(
    () =>
      getRawDataTableColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        showedIds,
        showRow: handleShowRow,
        hideRow: handleHideRow,
      }),
    [setFilter, removeFilter, showedIds],
  );
  return (
    <div className="container-fluid">
      <div className="row mb-2">
        <Panel className="w-100" title="Raw Data" />
      </div>
      <div className="row">
        <div className="col-8">
          <Table
            tableId="rawDataTable"
            data={data?.data ?? []}
            columns={rawDataTableColumns}
            filteringProps={filteringProps}
            isLoading={isLoading}
            hasFilters
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            defaultSortBy={defaultOrdering}
            isFlexLayout
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
        <div className="col-4">
          <NewRawData />
        </div>
      </div>
    </div>
  );
};
