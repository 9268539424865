import { FC } from 'react';
import { Input, Autocomplete, Checkbox } from 'react-ui-kit-exante';

import { ParsingModuleParam } from 'services/ReconTablesApiService';

export interface ModuleValue {
  param_name: string;
  param_value: string | boolean;
}

interface Props {
  item: ParsingModuleParam;
  values: ModuleValue[];
  onChangeValue: (value: ModuleValue) => void;
}

export const ModuleItem: FC<Props> = ({ item, onChangeValue, values }) => {
  const {
    param_name: paramName,
    description,
    param_type: paramType,
    options,
  } = item;

  const value = values.find((i) => i.param_name === paramName);
  if (!value) {
    return null;
  }

  if (paramType === 'string' || paramType === 'integer') {
    return (
      <Input
        fullWidth
        label={paramName}
        placeholder={description}
        value={value.param_value}
        onChange={(e) =>
          onChangeValue({ param_name: paramName, param_value: e.target.value })
        }
      />
    );
  }

  if (paramType === 'select') {
    const selectOptions = options
      ? options.map((i) => ({ label: i, value: i }))
      : [];
    const selectBlock = (
      <Autocomplete
        placeholder={paramName}
        value={{ value: value.param_value, label: value.param_value }}
        className="w-100"
        options={selectOptions}
        onChange={(event, newValue: any) => {
          onChangeValue({
            param_name: paramName,
            param_value: newValue?.value,
          });
        }}
      />
    );
    return selectBlock;
  }

  if (paramType === 'bool') {
    return (
      <Checkbox
        label={paramName}
        value={Boolean(value.param_value)}
        onChange={(event) => {
          onChangeValue({
            param_name: paramName,
            param_value: event.target.checked,
          });
        }}
      />
    );
  }
  return null;
};
