export function dynamicSort(property) {
  let sortOrder = 1;
  let tempProperty = property;
  if (property[0] === '-') {
    sortOrder = -1;
    tempProperty = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = 0;
    if (a[tempProperty] < b[tempProperty]) {
      result = -1;
    } else if (a[tempProperty] > b[tempProperty]) {
      result = 1;
    }
    return result * sortOrder;
  };
}
