import { useEffect, useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { CtradeSearchForm } from './form';
import { MatchedResults } from './matched-results';
import { SearchResultTable } from './search-result-table';

export default function AutoReconcilePage() {
  const [trades, setTrades] = useState([]);
  const [mappedBrokerSymbols, setMappedBrokerSymbols] = useState([]);

  const [loading, setLoading] = useState(false);
  const [reconciling, setReconciling] = useState(false);
  const [matchedResults, setMatchedResults] = useState([]);
  const [activeCPTrade, setActiveCPTrade] = useState(undefined);

  useEffect(() => {
    const newTrades = [...trades];
    // eslint-disable-next-line
    for (const t of newTrades) {
      const idx = mappedBrokerSymbols.indexOf(t.symbol);
      t.hasMapping = idx > -1 ? mappedBrokerSymbols[idx] : undefined;
    }
    // eslint-disable-next-line
    newTrades.sort((a, b) => (a.hasMapping === undefined ? 1 : 0));
    setTrades(newTrades);
  }, [mappedBrokerSymbols]);

  const clearMatches = () => {
    setMatchedResults([]);
    setActiveCPTrade(undefined);
  };

  const searchTrades = (payload) => {
    setLoading(true);
    clearMatches();

    const api = new ReconTablesApiService();
    api
      .getListCPTrades(payload)
      .then((res) => {
        const brokerSymbolsSet = new Set(res.results.map((x) => x.symbol));
        const brokerSymbols = Array.from(brokerSymbolsSet);
        if (brokerSymbols.length > 0) {
          const searchPayload = { cp_symbols: brokerSymbols.slice(0, 40) };
          api
            .getListSymbolsMapping(searchPayload)
            .then((tempRes) => {
              setMappedBrokerSymbols(tempRes.results.map((x) => x.cp_symbol));
            })
            .catch((e) => alert(e));
        } else {
          setMappedBrokerSymbols([]);
        }
        setLoading(false);
        setTrades(res.results);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  const setMatchResults = (results, cptrade) => {
    setMatchedResults(results);
    setActiveCPTrade(cptrade);
  };

  const excludeTradeFromResults = (trade) => {
    const newTrades = [];
    // eslint-disable-next-line
    for (const t of trades) {
      if (t.full_id !== trade.full_id) {
        newTrades.push(t);
      }
    }
    setTrades(newTrades);
  };

  const reconcile = (ctrade) => {
    setReconciling(true);
    const api = new ReconTablesApiService();
    const payload = {
      left_trades: [ctrade.trade_number],
      right_trades: [activeCPTrade.full_id],
      add_mapping: true,
      source: 'auto-reconcile',
    };

    api
      .reconcileTradesBoVsCp(payload)
      .then(() => {
        alert('Reconciled');
        excludeTradeFromResults(activeCPTrade);
        clearMatches();
        setReconciling(false);
      })
      .catch((e) => {
        alert(e);
        setReconciling(false);
      });
  };

  return (
    <>
      <CtradeSearchForm searchTrades={searchTrades} />
      {loading ? (
        <Spinner />
      ) : (
        <SearchResultTable trades={trades} setMatchResults={setMatchResults} />
      )}
      <hr />
      {reconciling ? (
        <Spinner />
      ) : (
        <MatchedResults
          ctrades={matchedResults}
          cptrade={activeCPTrade}
          reconcile={reconcile}
        />
      )}
    </>
  );
}
