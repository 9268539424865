import { Component } from 'react';
import { connect } from 'react-redux';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { PosDiffTableRow } from './table-row';
import './style.css';

function OurTraderRow({ entry }) {
  const recStatus = entry.rec_status ? 'Ok' : 'Not OK';
  return (
    <tr>
      <td>{entry.symbol}</td>
      <td>{entry.date}</td>
      <td>{entry.cp_report_date}</td>
      <td>{entry.qty}</td>
      <td>{entry.value}</td>
      <td>{recStatus}</td>
    </tr>
  );
}

function CPTraderRow({ entry }) {
  const recStatus =
    entry.bo_trade_number && entry.bo_trade_number !== '' ? 'Ok' : 'Not OK';
  return (
    <tr>
      <td>{entry.symbol}</td>
      <td>{entry.trade_date}</td>
      <td>{entry.report_date}</td>
      <td>{entry.qty}</td>
      <td>{entry.value}</td>
      <td>{recStatus}</td>
    </tr>
  );
}

class LinkedTables extends Component {
  // eslint-disable-next-line
  state = {
    brokerTrades: [],
    ourTrades: [],
    loadingTrades: false,
  };

  onPosDiffRowClick = (event) => {
    this.setState({ loadingTrades: true, brokerTrades: [], ourTrades: [] });

    const api = new ReconTablesApiService();
    const cpTradesPayload = {
      broker_id: this.props.positionDiffResultsCP,
      symbol: event.cp_symbol,
      min_days_from_report_date: `${event.report_date}:2`,
    };

    const cTradesPayload = {
      cp: this.props.positionDiffResultsCP,
      symbol: event.symbol,
      min_days_from_report_date: `${event.report_date}:2`,
    };

    api
      .getListCPTrades(cpTradesPayload)
      .then((res) => {
        this.setState({ brokerTrades: res.results });
        return api.getListCTrades(cTradesPayload);
      })
      .then((res) => {
        this.setState({ ourTrades: res.results, loadingTrades: false });
      })
      .catch(() => {
        this.setState({ loadingTrades: false });
      });
  };

  render() {
    if (
      this.props.positionDiffResults === undefined ||
      this.props.positionDiffResults.length === 0
    ) {
      if (this.props.loadingDiffResults) {
        return <Spinner />;
      }
      return <h3>No data</h3>;
    }

    const rowsPositionDiffs = this.props.positionDiffResults.map((e) => (
      <PosDiffTableRow onClick={this.onPosDiffRowClick} entry={e} />
    ));
    const rowsOurTrades = this.state.ourTrades.map((e) => (
      <OurTraderRow entry={e} />
    ));
    const rowsCPTrades = this.state.brokerTrades.map((e) => (
      <CPTraderRow entry={e} />
    ));

    const tradeView = (
      <>
        <div className="col">
          <h4>Our Trades</h4>
          <div className="table-wrapper">
            <table className="table table-striped table-sm table-bordered text-small">
              <thead className="thead-light">
                <tr>
                  <th>Symbol</th>
                  <th>Time</th>
                  <th>CP Report Date</th>
                  <th>Qty</th>
                  <th>Value</th>
                  <th>Rec Status</th>
                </tr>
              </thead>
              <tbody>{rowsOurTrades}</tbody>
            </table>
          </div>
        </div>
        <div className="col">
          <h4>CP Trades</h4>
          <div className="table-wrapper">
            <table className="table table-striped table-sm table-bordered text-small">
              <thead className="thead-light">
                <tr>
                  <th>Symbol</th>
                  <th>Time</th>
                  <th>Report Date</th>
                  <th>Qty</th>
                  <th>Value</th>
                  <th>Rec Status</th>
                </tr>
              </thead>
              <tbody>{rowsCPTrades}</tbody>
            </table>
          </div>
        </div>
      </>
    );

    const displayTrades = this.state.loadingTrades ? (
      <div className="col">
        <Spinner />
      </div>
    ) : (
      tradeView
    );

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="table-wrapper">
              <table className="table table-striped table-sm table-bordered text-small tableFixHead table-hover">
                <thead className="thead-light">
                  <tr>
                    <th>CP Account</th>
                    <th>BO Symbol</th>
                    <th>CP Symbol</th>
                    <th>ISIN</th>
                    <th>BO Qty</th>
                    <th>CP Qty</th>
                    <th>Prev Qty</th>
                    <th>Diffs</th>
                    <th>Diffs Sum</th>
                    <th>Correction Sum</th>
                    <th>Transactions Sum</th>
                  </tr>
                </thead>
                <tbody>{rowsPositionDiffs}</tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">{displayTrades}</div>
      </div>
    );
  }
}

const mapStateToProps = ({
  positionDiffResults,
  loadingDiffResults,
  positionDiffResultsCP,
}) => ({ positionDiffResults, loadingDiffResults, positionDiffResultsCP });

export default connect(mapStateToProps)(LinkedTables);
