import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import {
  getSortingParams,
  ResponseWithPagination,
} from './ReconEmirApiService';
import BaseApiService from './baseApiService';
import { FetchArrayResponse, FetchArrayResponseWithoutNext } from './types';
import { getUrlFromPayload } from './utils';

export interface CpMapping {
  id: number;
  cp: string;
  bo_settlement_cp: string;
  bo_cp: string;
  map_cp: string;
  null_value: boolean;
  settlement_execution_ccp: string;
  ignore_ccy: boolean;
  all_days: boolean;
  active: boolean;
  direct_recon: boolean;
  only_by_value: boolean;
  cp_type: string;
  one_to_one: boolean;
  malta_entity: number;
}

interface BoEntityLegalName {
  id: number;
  name: string;
  id_in_ctrades: number;
  legal_entity_short_name: string;
  env: string;
  key: string;
}

interface OperationType {
  id: number;
  name: string;
}

export interface TransactionOperationType {
  id: number;
  name: string;
  for_page: string;
}

interface Currency {
  id: number;
  name: string;
}

interface FetchСtradePayload {
  from_date?: string;
  to_date?: string;
  cp?: string;
  qty?: number;
  price?: number;
  cp_name?: string;
  rec_status?: string;
  symbol?: string;
  order_id?: string;
  account?: string;
  entity_legal_malta_id?: string;
  date_date?: string;
  from_cp_report_date?: string;
  to_cp_report_date?: string;
  min_days_from_report_date?: string;
  page?: number;
}
interface Ctrade {
  trade_number: number;
  date: string;
  account: string;
  symbol: string;
  qty: number;
  price: number;
  cp: string;
  value_date: string;
  rec_status: boolean;
  value: number;
  exchange_order_id: string;
  fees: number;
  ccy: string;
  timestamp: string;
  valid: number;
  username: string;
  order_id: string;
  bo_trade_timestamp: string;
  trade_type: string;
  delivery_date: string;
  settlement_cp: string;
  mty: string;
  order_pos: number;
  comment: string;
  type_of_recon: string;
  type_of_issue: string;
  request_link: string;
  cp_account: string;
  internal_match: string;
  cp_report_date: string;
  cp_name: string;
  internal_rec_status: boolean;
  internal_tags: string;
  is_manual: boolean;
  chain_id: string;
  internal_comment: string;
  pnl: number;
  entity_legal_malta_id: number;
  recon_group: number;
}
interface FetchRowBalancePayload {
  cp?: string;
  report_date?: string;
  type?: string;
  ccy?: string;
  value?: number;
  comment?: string;
  timestamp?: string;
  exchange?: string;
  account?: string;
  source_file?: string;
  uid?: string;
  asset?: string;
  price?: number;
  order_id?: string;
  category?: string;
  rate?: number;
  isin?: string;
  qty?: number;
  base_ccy?: string;
  base_amount?: number;
  settlement_date?: string;
  amount?: number;
  bo_symbol?: string;
  legal_entity?: string;
  report?: string;
  option_type?: string;
  underlying?: string;
  strike?: number;
  raw?: string;
  recon_status?: string;
  parsing_tag?: string;
  parsing_uniq_hash?: string;
  value_rules_applied?: string;
  recon_group?: string;
  compare_fields?: string;
  page?: number;
}
interface RowBalance {
  full_id: number;
  cp: string;
  report_date: string;
  type: string;
  ccy: string;
  value: number;
  comment: string;
  timestamp: string;
  exchange: string;
  account: string;
  source_file: string;
  uid: string;
  asset: string;
  price: number;
  order_id: string;
  category: string;
  rate: number;
  isin: string;
  qty: number;
  base_ccy: string;
  base_amount: number;
  settlement_date: string;
  amount: number;
  bo_symbol: string;
  option_type: string;
  underlying: string;
  strike: number;
  recon_status: boolean;
  parsing_tag: string;
  parsing_uniq_hash: string;
  value_rules_applied: string;
  compare_fields: string;
  legal_entity: number;
  report: number;
  raw: number;
  recon_group: number;
}
interface FetchCpTransactionPayload {
  cp?: string;
  report_date?: string;
  type?: string;
  account?: string;
  source_file?: string;
  page?: number;
}
interface CpTransaction {
  full_id: number;
  cp: string;
  report_date: string;
  account: string;
  source_file: string;
  uid: string;
  entity_id: number;
  recon_status: boolean;
  timestamp: string;
  type: string;
  operation_type: string;
  asset: string;
  sum: number;
  price: number;
  comment: string;
  symbol: string;
  symbol_type: string;
  value_date: string;
  category: string;
  category_orginal: string;
  parsing_tag: string;
  parsing_uniq_hash: string;
  value_rules_applied: string;
  compare_fields: string;
  type_of_recon: string;
  rule_id: string;
  isin: string;
  option_type: string;
  report: number;
  raw: number;
  recon_group: number;
}
export interface BOFinancialTransaction {
  account?: string;
  bo_timestamp?: string;
  operation_type?: string;
  asset?: string;
  sum?: number;
  who?: string;
  gateway?: string;
  trade_number?: string;
  comment?: string;
  symbol?: string;
  value_date?: string;
  order_id?: string;
  order_pos?: string;
  price?: number;
  client_type?: string;
  execution_cp?: string;
  timestamp?: string;
  user?: string;
  category?: string;
  type?: string;
  internal_comment?: string;
  symbol_type?: string;
  base_ccy?: string;
  settlement_ccy?: string;
  settlement_ccy_movement?: number;
  exchange_commission?: number;
  settlement_cp?: number;
  transfer_id?: number;
  client_cp?: number;
  exante_cp?: number;
  uuid?: number;
  parent_uuid?: number;
  legal_entity?: number;
  converted_sum?: number;
  exchange?: string;
  cross_rate_to_eur?: number;
  entity_legal_malta?: number;
  report_date?: string;
  id?: number;
  converted_usd_sum?: number;
  cp?: string;
  cp_report_date?: string;
  recon_status?: boolean;
  from_le_id?: number;
  to_le_id?: number;
  from_acc?: string;
  to_acc?: string;
  isin?: string;
  recon_group?: number;
}
// interface FetchTransactionsPayload {
//   from_date: string;
//   to_date: string;
//   cp?: string;
//   legal_entity: number;
//   operation_types: string;
//   mode?: string;
//   limit: number;
//   page_number: number;
//   break_report_category?: string;
//   break_report_filter?: boolean;
// }
export interface Transaction {
  id: number;
  account: string;
  client_account_id: string;
  amount: number;
  break_comment?: string;
  break_report_category?: string;
  ccy: string;
  comment: string;
  category: string;
  cp: string;
  isin?: string;
  operationtype: string;
  reportdate: string;
  side: string;
  symbol?: string;
  symboltype?: string;
}
export interface ReconciledTransaction {
  id: number;
  report_date: string;
  group_id: string;
  cp_id: string;
  cp_ccy: string;
  cp_value: string;
  cp_symbol: string;
  bo_id: string;
  bo_symbol: string;
  bo_ccy: string;
  bo_amount: string;
  symbol_type: string;
  isin: string;
}
interface FetchCpTradesPayload {
  start_date?: string;
  end_date?: string;
  bo_cp?: string;
  broker_id?: string;
  cp_id?: number;
  price?: number;
  qty?: number;
  legal_entity?: number;
  type_of_trade?: string;
  full_id?: number;
  symbol?: string;
  account?: string;
  bo_symbol?: string;
  bo_trade_number?: string;
  report_date?: string;
  rec_status?: string;
  min_days_from_report_date?: string;
  page?: number;
}
export interface CpTrade {
  full_id: number;
  report_date: string;
  trade_date: string;
  broker_id: string;
  symbol: string;
  type: string;
  qty: number;
  price: number;
  value: number;
  value_date: string;
  bo_symbol: string;
  bo_trade_number: string;
  cp_id: number;
  exchange_fees: number;
  fee: number;
  id: string;
  timestamp: string;
  valid: number;
  username: string;
  bo_cp: string;
  exchange_order_id: string;
  type_of_trade: string;
  comment: string;
  exchange_fee_currency: string;
  clearing_fee_ccy: string;
  ccy: string;
  recon_account: string;
  fee_2: number;
  fee_3: number;
  interest: number;
  account: string;
  trade_id: string;
  exchange: string;
  rec_status: boolean;
  type_of_recon: string;
  type_of_issue: string;
  request_link: string;
  uid: string;
  source_file: string;
  isin: string;
  legal_entity: number;
  order_pos: number;
  bbg_code: string;
  cp_price: number;
  cp_qty: number;
  cusip: string;
  sedol: string;
  rule_id: string;
  strike: number;
  underlying: string;
  value_rules_applied: string;
  option_type: string;
  parsing_tag: string;
  parsing_uniq_hash: string;
  compare_fields: string;
  report: number;
  raw: number;
  recon_group: number;
}
export enum SIDE {
  OUR = 'our',
  THEIR = 'their',
}
export interface FetchCpAndCTradesPayload {
  from_date?: string;
  to_date?: string;
  cp?: string;
  break_report_category?: number;
  break_report_filter?: boolean;
  legal_entity: number | null;
  broker_symbol?: string;
  our_symbol?: string;
  order?: string;
}
export interface CpAndCTrades {
  symbol: string;
  qty: number;
  price: number;
  value: number;
  value_date: string;
  account: string;
  side: SIDE;
  ccy: string;
  date: string;
  type: string;
  id: number;
  legal_entity: number;
  cp: string;
  isin: string | null;
  symbol_type: string | null;
  break_report_category: string | null;
  break_comment: string | null;
}
export interface CpAndCTradesWithGroupId extends CpAndCTrades {
  group_id: number;
}
interface FetchSymbolsMappingPayload {
  cp?: string;
  cp_symbol?: string;
  exante_symbol?: string;
  ccy?: string;
  isin?: string;
  cp_type?: string;
  cp_symbols?: string;
  page?: number;
}
interface SymbolsMapping {
  id: number;
  exante_symbol: string;
  cp_symbol: string;
  cp: string;
  account: string;
  price_multiplier: 1; // cmty
  qty_multiplier: 3; // mty_qty
  value_multiplier: 1; // mty
  is_manual: boolean;
  timestamp: string;
  cp_type: string;
  active: boolean;
  start_date: string;
  end_date: string;
  isin: string;
  ccy: string;
  cusip: string;
}
interface ExpectedSymbolMapping {
  cp: string;
  cp_symbol: string;
  exante_symbol: string;
  mty: number;
  cmty: number;
  mty_qty: number;
  exact: boolean;
  ccy: string;
  cp_type: string;
}
interface FetchShortMappingPayload {
  cp?: string;
  cp_symbol?: string;
  exante_symbol?: string;
  page?: number;
}
interface ShortMapping {
  id: number;
  cp: string;
  cp_symbol: string;
  exante_symbol: string;
  plus_month: number;
  strike_mty: number;
  mty_qty: number;
  mty_price: number;
  mty_value: number;
  ccy_1_for_forts: string;
  ccy_2_for_forts: string;
}
interface FetchUnreconSearchResultPayload {
  order_id?: string;
  trade_number?: string;
  exchange_order_id?: string;
  full_id?: string;
  cp?: string;
  report_date?: string;
  symbol?: string;
  cp_symbol?: string;
}
interface UnreconCtrade {
  report_date: string;
  order_id: string;
  trade_number: number;
  symbol: string;
  cp: string;
  qty: number;
  price: number;
  value: number;
  timestamp: string;
  rec_status: boolean;
  exchange_order_id: string;
}
interface UnreconCPTrade {
  symbol: string;
  qty: number;
  price: number;
  value: number;
  timestamp: string;
  full_id: string;
  bo_trade_number: string;
  exchange_order_id: string;
}
export interface UnreconSearchItem {
  group_id: number;
  ctrades: UnreconCtrade[];
  cptrades: UnreconCPTrade[];
}
interface FetchReconcileSearchResultPayload {
  from_report_date?: string;
  to_report_date?: string;
  cp?: string;
  legal_entity_malta?: number;
  symbol?: string;
  trade_type?: string;
}
interface ReconcileSearchBoVsBoResult {
  left_trades: Ctrade[];
  right_trades: Ctrade[];
}
interface ReconcileSearchBoVsCpResult {
  left_trades: Ctrade[];
  right_trades: CpTrade[];
}
interface ReconcileSearchCpVsCpResult {
  left_trades: CpTrade[];
  right_trades: CpTrade[];
}
export interface CtradeExtended extends Ctrade {
  isin: string;
  symbol_type: string;
}
interface FetchReconBySymbolWithCommentPayload {
  cp?: string;
  report_date?: string;
  legal_entity?: string;
  rec_status?: boolean;
}
interface ReconBySymbolWithComment {
  id: number;
  legal_entity: string;
  report_date: string;
  cp: string;
  bo_symbol: string;
  cp_symbol: string;
  bo_qty: number;
  cp_qty: number;
  bo_value: number;
  cp_value: number;
  bo_ccy: string;
  cp_ccy: string;
  diff_value: number;
  recon_status: string;
  timestamp: string;
  cp_row: string;
  comment: string;
}
interface FetchReconBySymbolTradesDetailsPayload {
  symbol?: string;
  cp_symbol?: string;
  cp?: string;
  report_date?: string;
}
interface ReconBySymbolTradesDetails {
  ctrades: Ctrade[];
  cptrades: CpTrade[];
}
interface FetchReconStatusPayload {
  id?: number;
  report_date?: string;
  cp?: string;
  legal_entity?: string;
  total_bo_trades?: number;
  bo_matched?: number;
  bo_not_matched?: number;
  total_cp_trades?: number;
  cp_matched?: number;
  cp_not_matched?: number;
  is_manual?: string;
  status_bo_status_cp?: string;
  page?: number;
}
interface ReconStatus {
  id: number;
  report_date: string;
  cp: string;
  legal_entity: string;
  total_bo_trades: number;
  bo_matched: number;
  bo_not_matched: number;
  total_cp_trades: number;
  cp_matched: number;
  cp_not_matched: number;
  is_manual: boolean;
  status_bo_status_cp: string;
}
interface FetchReconPositionDiffWithPrevValuePayload {
  report_date?: string;
  cp?: string;
  symbol?: string;
  account?: string;
}
interface ReconPositionDiffWithPrevValue {
  id: number;
  report_date: string;
  symbol: string;
  difference: string;
  account: string;
  reported: string;
  current: string;
  previously_reported: string;
  cp: string;
  isin: string;
  cp_symbol: string;
  diffs_sum: string;
  correction_sum: string;
  transactions_sum: string;
}
interface FetchReconPositionDiffGroupedPayload {
  report_date: string;
  cp?: string;
  symbol?: string;
  account?: string;
}
interface ReconPositionDiffGrouped {
  id: number;
  report_date: string;
  symbol: string;
  isin: string;
  cp_symbol: string;
  difference: string;
  account: string;
  reported: string;
  current: string;
  diffs_sum: string;
  correction_sum: string;
  transactions_sum: string;
  previously_reported: string;
  cp: string;
}
interface PositionReconciliationFieldTypes {
  name: string;
  type: string;
  required: boolean;
}
interface PositionReconciliation {
  id: number;
  report_date: string;
  cp: string;
  cp_symbol: string;
  bo_symbol: string;
  qty: number;
  timestamp: string;
  account: string;
  price: number;
  isin: string;
  ccy: string;
  type: string;
  qty_long: number;
  qty_short: number;
  price_long: number;
  price_short: number;
  price_open: number;
  price_long_open: number;
  price_short_open: number;
  uid: string;
  value: number;
  contract_size: number;
  source_file: string;
  exchange: string;
  settlement_price: number;
  value_date: string;
  rate: number;
  base_amount: number;
  base_ccy: string;
  margin_rate: number;
  margin_amount: number;
  comment: string;
  cp_qty: number;
  option_type: string;
  underlying: string;
  strike: number;
  ruleid: number;
  cusip: string;
  sedol: string;
  typerow: string;
  symboltype: string;
  parsing_tag: string;
  parsing_uniq_hash: string;
  value_rules_applied: string;
  compare_fields: string;
  report: number;
  raw: number;
}
interface FetchPositionReconciliationPayload
  extends Omit<PositionReconciliation, 'id'> {
  page?: number;
}
interface FetchPositionReconUnmappedPayload {
  report_date?: string;
  cp?: string;
  legal_entity_malta?: number;
}
interface PositionMappingCandidate {
  symbol: string;
  ccy: string;
  isin: string;
  symbol_type: string;
  bo_qty: number;
  diff: string;
  diff_value: string;
}
interface PositionMappingSearchResults {
  position_reconciliation: PositionReconciliation;
  condidates: PositionMappingCandidate[];
}
interface CpCutOffTime {
  id: number;
  cp: string;
  time: string;
  hours: number;
  hour_subtract: number;
  all_days: boolean;
  minutes_subtract: number;
}
interface CutOffTimeBySymbol {
  id: number;
  symbol: string;
  cp: string;
  hour_subtract: string;
  time: string;
}
interface FtpPath {
  id: number;
  cp: string;
  first_part_path: string;
  second_part_path: string;
  end_with: string;
  contains: string;
  type: string;
  third_part_path: string;
  path: string;
  numbers: number;
}
interface FtpPathBoDrive {
  cp: string;
  first_part_path: string;
  second_part_path: string;
  end_with: string;
  contains: string;
  type: string;
  third_part_path: string;
  path: string;
  numbers: number;
}
interface XleFields {
  hkrecon: string[];
  xntrecon: string[];
  ium_recon: string[];
}
interface FetchReconWithCommentsPayload {
  report_date: string;
}
interface HKReconWithComments {
  report_date: string;
  symbol: string;
  ccy: string;
  symbol_type: string;
  ext: number;
  xnt: number;
  bo_qty: number;
  cowen: number;
  aarna: number;
  orient: number;
  edf: number;
  diff: number;
  diff_value: number;
  table: string;
  comment: string;
  comment_id: number;
}
interface IUMReconWithComments {
  report_date: string;
  symbol: string;
  ccy: string;
  symbol_type: string;
  bo_qty: number;
  xnt: number;
  xhk: number;
  lmax: number;
  bcs: number;
  diff: number;
  diff_value: number;
  table: string;
  comment: string;
  comment_id: number;
}
interface XNTReconWithComments {
  report_date: string;
  symbol: string;
  ccy: string;
  symbol_type: string;
  bo_qty: number;
  ext: number;
  cowen: number;
  ititrade: number;
  gpp: number;
  cibmmt: number;
  lek: number;
  straits: number;
  mse: number;
  macte: number;
  nissan: number;
  oyak: number;
  dolfin: number;
  diff: number;
  diff_value: number;
  table: string;
  comment: string;
  comment_id: number;
}
interface FetchReportPayload {
  page: string;
}
interface Report {
  id: number;
  cp_id: string;
  contains: string;
  path: string;
  file_type: string;
  state: string;
  isUsed: boolean;
  delimitter: string;
  quotechar: string;
  name: string;
  output: string;
  fields: string;
  counts: number;
  is_heading: boolean;
  type: string;
}
interface InstrumentMappingField {
  field: string;
}
enum MappingReplaceType {
  REGEX = 'regex',
  REPLACREGEX_FINDE = 'replace',
  SET_VALUE = 'set_value',
  MULTIPLIER = 'multiplier',
  REGEX_FIND = '',
}
enum StandardValueType {
  STRING = 'string',
  INT = 'int',
  NUMERIC = 'numeric',
}
enum ComparisonType {
  EXACT = 'exact',
  CONTAINS = 'contains',
  ICONTAINS = 'icontains',
  REGEX = 'regex',
  NOTCONTAINS = 'notcontains',
  NOTICONTAINS = 'noticontains',
  ISEMPTY = 'isempty',
  ISNOTEMPTY = 'isnotempty',
}

interface ReportFieldCondition {
  comparison_type: string;
  condition_field: string;
  condition_value: string;
  id: number;
  rule: number;
}
interface ReportField {
  id: number;
  action: boolean;
  conditions: ReportFieldCondition[];
  cp_field: string;
  end_date?: string;
  mapping_field: string;
  mapping_type: string;
  report: number;
  start_date?: string;
}
export interface ReportWithFields {
  id: number;
  contains: string;
  counts: string;
  cp_id: string;
  delimitter: string;
  fields: ReportField[];
  file_type: string;
  isUsed: boolean;
  is_heading: boolean;
  name: string;
  output?: string;
  path: string;
  quotechar: string;
  state: string;
  type: string;
  unique_fields: any[];
}
interface FetchGetTransactionRecordsPayload {
  from_date: string;
  to_date: string;
  cp?: string;
  legal_entity: number;
  transaction_kind: string;
  limit: number;
  page_number: number;
}
interface ReconcilePayload {
  left_trades: number[];
  right_trades: number[];
  source: string;
}
interface AutoMappingRuleField {
  required: boolean;
  instrument_mapping_field: string;
}
interface AutoMappingRuleWithFields {
  name: string;
  cp_id: string;
  report: number;
  instrument_type: string;
  start_date: string;
  end_date: string;
  fields: AutoMappingRuleField[];
}
interface ValueMappingCondition {
  condition: string;
  condition_value: string;
}
interface ValueMappingRuleWithConditions {
  name: string;
  cp_id: string;
  report: number;
  cp_field: string;
  instrument_mapping_field: string;
  replace_type: string;
  cp_value: string;
  standard_value: string;
  start_date: string;
  end_date: string;
  conditions: ValueMappingCondition;
}
export interface CategoryComment {
  id: number;
  name: string;
  priority: string;
  severity: string;
}
interface FetchCategoryCommentPayload {
  name?: string;
  priority?: string;
  severity?: string;
  page?: number;
}
export enum BreakReportCommentPriorityAndSeverity {
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
}
export enum TablesWithBreakReport {
  CPTRANSACTION = 'cptransaction',
  FTBO = 'ftbo',
  CPTRADES = 'cptrades',
  CTRADES = 'ctrades',
}
export interface RawDataModuleConfigRawDataModuleConfig {
  param_name: string;
  param_value: string;
  id?: number;
}
export interface RawDataReportSetup {
  id?: number;
  module_configs: RawDataModuleConfigRawDataModuleConfig[];
  cp: string;
  name: string;
  module: string;
}
export type CreateRawDataReportPayload = Omit<RawDataReportSetup, 'id'>;

interface BreakReportManyComment {
  category: number;
  priority?: BreakReportCommentPriorityAndSeverity;
  severity?: BreakReportCommentPriorityAndSeverity;
  comment: string;
  record_types_mapping: Record<string, number[]>;
}
export interface ParsingModuleParam {
  id: number;
  default_value: string | null | boolean;
  description: string;
  module: string;
  options: string[] | null;
  param_name: string;
  param_type: 'string' | 'select' | 'bool' | 'integer';
}
export interface ResponseForTable<T> {
  data: T[];
  pagination: any;
}

export default class ReconTablesApiService extends BaseApiService {
  _apiBase = this.getApiBase(8000, 'tables');

  async getRawDataReportConfigs({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<
        ResponseWithPagination<RawDataReportSetup>
      >(
        getUrlFromPayload('tables/raw-data-report-config/?', filterParams) +
          getSortingParams(sorting),
      );
      return {
        data: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error(`Load raw data error: ${error}`);
    }
    return {
      data: [],
      pagination: {},
    };
  }

  async createRawDataReportConfig(payload: CreateRawDataReportPayload) {
    const url = 'tables/raw-data-report-config/';
    try {
      await this.postResource<CreateRawDataReportPayload, RawDataReportSetup>(
        url,
        payload,
      );
      Notification.success('New raw data report created');
    } catch (e) {
      Notification.error(`Create raw data report error`);
    }
  }

  async getParsingModuleOptions() {
    const url = 'tables/parsing-module/';
    return this.getResource<FetchArrayResponse<{ name: string }>>(url);
  }

  async getParsingModuleParams(moduleName: string) {
    const url = `tables/parsing-module-param/?module=${moduleName}`;
    return this.getResource<FetchArrayResponse<ParsingModuleParam>>(url);
  }

  exportCtradesURL(query: string) {
    return `${this._apiBase}trade-recon/export/ctrades/?${query}`;
  }

  exportEODResultsUrl(query: string) {
    return `${this._apiBase}trade-recon/export/recon-by-symbol/?${query}`;
  }

  async getListCPMapping() {
    const url = 'tables/cp-mapping/';
    return this.getResource<FetchArrayResponse<CpMapping>>(url);
  }

  async getCcyList() {
    const url = 'mapping/currency-info/';
    return this.getResource<{ results: string[]; status: boolean }>(url);
  }

  async getListBOEntityLegalName() {
    const url = 'tables/bo-entity-legal-name/';
    return this.getResource<FetchArrayResponse<BoEntityLegalName>>(url);
  }

  async getListOperationType() {
    const url = 'tables/operation-type/';
    return this.getResource<FetchArrayResponse<OperationType>>(url);
  }

  async getListTransactionOperationType() {
    const url = 'tables/transaction-operation-types/';
    return this.getResource<FetchArrayResponse<TransactionOperationType>>(url);
  }

  async getTransactionOperationTypeListForPage(page: string) {
    const url = `tables/transaction-operation-types/?for_page=${page}`;
    return this.getResource<FetchArrayResponse<TransactionOperationType>>(url);
  }

  async getListCurrency() {
    const url = 'tables/currency/';
    return this.getResource<FetchArrayResponse<Currency>>(url);
  }

  async getListCTrades(payload: FetchСtradePayload) {
    return this.getResource<FetchArrayResponse<Ctrade>>(
      getUrlFromPayload('tables/ctrades/?', payload),
    );
  }

  async getListRowBalance(payload: FetchRowBalancePayload) {
    return this.getResource<FetchArrayResponse<RowBalance>>(
      getUrlFromPayload('tables/row-balance/?', payload),
    );
  }

  async getListCpTransaction(payload: FetchCpTransactionPayload) {
    return this.getResource<FetchArrayResponse<CpTransaction>>(
      getUrlFromPayload('tables/cp-transaction/?', payload),
    );
  }

  async getCpBoTransactions(payload: string, pageNumber = 0, limit = 10) {
    return this.getResource<FetchArrayResponseWithoutNext<Transaction>>(
      `transactions/get-cp-bo-transactions/?page_number=${pageNumber}&limit=${limit}${payload}`,
    );
  }

  async getReconciledTransactions(payload: string) {
    return this.getResource<
      FetchArrayResponseWithoutNext<ReconciledTransaction>
    >(`transactions/get-reconciled-records/?${payload}`);
  }

  async getCommentCategories(payload: FetchCategoryCommentPayload) {
    return this.getResource<FetchArrayResponse<CategoryComment>>(
      getUrlFromPayload(`tables/comment-category/?`, payload),
    );
  }

  async createBreakReportComment(payload: BreakReportManyComment) {
    return this.postResource<BreakReportManyComment, BreakReportManyComment>(
      'tables/break-report-comment/',
      payload,
    );
  }

  async getListCTradesRawQueryParams(strParams: string) {
    const url = `tables/ctrades/?${strParams}`;
    return this.getResource<FetchArrayResponse<Ctrade>>(url);
  }

  async getCpAndCTrades(
    payload: FetchCpAndCTradesPayload,
    pageNumber = 1,
    limit = 10,
  ) {
    return this.getResource<{
      results: CpAndCTrades[];
      status: string;
      total: number;
    }>(
      getUrlFromPayload(
        `trade-recon/get-cptrades-ctrades/?page_number=${pageNumber}&limit=${limit}&`,
        payload,
      ),
    );
  }

  async getReconciledTrades(payload: FetchCpAndCTradesPayload) {
    return this.getResource<{
      results: CpAndCTradesWithGroupId[];
      status: string;
    }>(getUrlFromPayload('trade-recon/get-reconciled-trades/?', payload));
  }

  async getListCPTrades(payload: FetchCpTradesPayload) {
    return this.getResource<FetchArrayResponse<CpTrade>>(
      getUrlFromPayload('tables/cp-trades/?', payload),
    );
  }

  async getListSymbolsMapping(filterPayload: FetchSymbolsMappingPayload) {
    return this.getResource<FetchArrayResponse<SymbolsMapping>>(
      getUrlFromPayload('tables/symbols-mapping/?', filterPayload),
    );
  }

  async searchOneToOneMapping(date: string, counterparty: string) {
    const url = `trade-recon/search-one-to-one-mapping/?date=${date}&cp=${counterparty}`;
    return this.getResource<ExpectedSymbolMapping>(url);
  }

  async getListShortMapping(payload: FetchShortMappingPayload) {
    return this.getResource<FetchArrayResponse<ShortMapping>>(
      getUrlFromPayload('tables/short-mapping/?', payload),
    );
  }

  async searchTradesUnreconcile(payload: FetchUnreconSearchResultPayload) {
    return this.getResource<UnreconSearchItem[]>(
      getUrlFromPayload('trade-recon/search-unreconcile/?', payload),
    );
  }

  async searchReconcileBoVsBo(payload: FetchReconcileSearchResultPayload) {
    return this.getResource<ReconcileSearchBoVsBoResult>(
      getUrlFromPayload('trade-recon/search-reconcile/bo-vs-bo/?', payload),
    );
  }

  async searchReconcileBoVsCp(payload: FetchReconcileSearchResultPayload) {
    return this.getResource<ReconcileSearchBoVsCpResult>(
      getUrlFromPayload('trade-recon/search-reconcile/bo-vs-cp/?', payload),
    );
  }

  async searchReconcileCpVsCp(payload: FetchReconcileSearchResultPayload) {
    return this.getResource<ReconcileSearchCpVsCpResult>(
      getUrlFromPayload('trade-recon/search-reconcile/cp-vs-cp/?', payload),
    );
  }

  // TODO: Types
  async searchBankTransactions(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/search-transactions/?', payload),
    );
  }

  // TODO: Types
  async searchBankTransfers(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/search-transfers/?', payload),
    );
  }

  // TODO: Types
  async bankReconcile(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/bank-reconcile/?', payload),
    );
  }

  // TODO: Types
  async bankUnReconcile(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/bank-unreconcile/?', payload),
    );
  }

  // TODO: Types
  async bankChangeReconComment(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/change-recon-comment/?', payload),
    );
  }

  // TODO: Types
  async changeBankTXComment(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/change-transactions-comment/?', payload),
    );
  }

  // TODO: Types
  async bankChangeAccountId(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/change-account-id/?', payload),
    );
  }

  // TODO: Types
  async bankChangeOperationType(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('bank-recon/change-operation-type/?', payload),
    );
  }

  async suggestCTradesForCpTrade(cptrade_fullid: number) {
    const url = `trade-recon/suggest-ctrades-for-cptrade/?cp_trade_id=${cptrade_fullid}`;
    return this.getResource<CtradeExtended[]>(url);
  }

  async getReconBySymbolReport(
    filterPayload: FetchReconBySymbolWithCommentPayload,
  ) {
    return this.getResource<ReconBySymbolWithComment[]>(
      getUrlFromPayload('trade-recon/recon-by-symbol/search/?', filterPayload),
    );
  }

  async getReconBySymbolTradesDetails(
    filterPayload: FetchReconBySymbolTradesDetailsPayload,
  ) {
    return this.getResource<ReconBySymbolTradesDetails>(
      getUrlFromPayload(
        'trade-recon/recon-by-symbol/trades-details/?',
        filterPayload,
      ),
    );
  }

  async getListReconStatus(payload: FetchReconStatusPayload) {
    return this.getResource<FetchArrayResponse<ReconStatus>>(
      getUrlFromPayload('tables/recon-status/?', payload),
    );
  }

  async getListPositionsDiffsWithPrevious(
    payload: FetchReconPositionDiffWithPrevValuePayload,
  ) {
    return this.getResource<ReconPositionDiffWithPrevValue[]>(
      getUrlFromPayload(
        'position-recon/position-diff/with-previous/?',
        payload,
      ),
    );
  }

  async getListPositionsDiffsGroupedByISIN(
    payload: FetchReconPositionDiffGroupedPayload,
  ) {
    return this.getResource<ReconPositionDiffGrouped[]>(
      getUrlFromPayload(
        'position-recon/position-diff/grouped-by-isin/?',
        payload,
      ),
    );
  }

  async getListPositionRecFields() {
    return this.getResource<PositionReconciliationFieldTypes[]>(
      'position-recon/action/get-position-reconciliation-fields/',
    );
  }

  async getListPositionReconciliation(
    payload: FetchPositionReconciliationPayload,
  ) {
    return this.getResource<FetchArrayResponse<PositionReconciliation>>(
      getUrlFromPayload('tables/position-reconciliation/?', payload),
    );
  }

  async searchUnmappedPositions(payload: FetchPositionReconUnmappedPayload) {
    return this.getResource<PositionReconciliation[]>(
      getUrlFromPayload('position-recon/action/search-unmapped/?', payload),
    );
  }

  // TODO: Add types
  async findMappingForPositionReconciliation(
    id: string,
    mty: string,
    qty: string,
    cp: string,
    report_date: string,
    ccy: string,
  ) {
    const url = `position-recon/action/search-mapping-for-position-reconciliation/?id=${id}&mty=${mty}&qty=${qty}&cp=${cp}&report_date=${report_date}&ccy=${ccy}`;
    return this.getResource<PositionMappingSearchResults>(url);
  }

  async getListCpCutOffTime() {
    return this.getResource<FetchArrayResponse<CpCutOffTime>>(
      'tables/cp-cut-off-time/',
    );
  }

  async getListSymbolCutOffTime() {
    return this.getResource<FetchArrayResponse<CutOffTimeBySymbol>>(
      'tables/cut-off-time-by-symbol/',
    );
  }

  async getListFtpPath() {
    return this.getResource<FetchArrayResponse<FtpPath>>('tables/ftp-path/');
  }

  async getListFtpPathBoDrive() {
    return this.getResource<FetchArrayResponse<FtpPath>>(
      'tables/ftp-path-bodrive/',
    );
  }

  // TODO: Add types
  async getOrderStatus(payload: any) {
    return this.getResource<any>(getUrlFromPayload('order-status/?', payload));
  }

  // TODO: Add types
  async getMonitoringRegulatoryStatuses(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('monitoring/regulatory-statuses/?', payload),
    );
  }

  // TODO: Add types
  async getMonitoringCpStatuses(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload('monitoring/cp-statuses/?', payload),
    );
  }

  async getXleFiltersByLE() {
    return this.getResource<XleFields>('position-recon/filter-fields-by-le/');
  }

  async getListXleReportHK(payload: FetchReconWithCommentsPayload) {
    return this.getResource<HKReconWithComments[]>(
      getUrlFromPayload(
        'position-recon/xle-report-with-comments/hk/?',
        payload,
      ),
    );
  }

  async getListXleReportEXT(payload: FetchReconWithCommentsPayload) {
    return this.getResource<IUMReconWithComments[]>(
      getUrlFromPayload(
        'position-recon/xle-report-with-comments/ext/?',
        payload,
      ),
    );
  }

  async getListXleReportXNT(payload: FetchReconWithCommentsPayload) {
    return this.getResource<XNTReconWithComments[]>(
      getUrlFromPayload(
        'position-recon/xle-report-with-comments/xnt/?',
        payload,
      ),
    );
  }

  async getListMappingReports(payload: FetchReportPayload) {
    return this.getResource<FetchArrayResponse<Report>>(
      getUrlFromPayload('mapping/report/?', payload),
    );
  }

  async getListInstrumentMappingField() {
    const url = 'mapping/instrument-mapping-field/';
    return this.getResource<FetchArrayResponse<InstrumentMappingField>>(url);
  }

  // TODO: Add types
  async getListAutoMappingRuleWithFields(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload(
        'mapping/auto-mapping-rule-with-fields/list/?',
        payload,
      ),
    );
  }

  async getListMappingReplaceType() {
    const url = 'mapping/mapping-replace-type/';
    return this.getResource<FetchArrayResponse<{ type: MappingReplaceType }>>(
      url,
    );
  }

  async getListStandardValueType() {
    const url = 'mapping/standard-value-type/';
    return this.getResource<FetchArrayResponse<{ type: StandardValueType }>>(
      url,
    );
  }

  // TODO: Add type
  async getListCpWithEntity() {
    const url = 'mapping/cp-with-fields/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListEntityDistinct() {
    const url = 'mapping/entitylegalname_distinct/';
    return this.getResource<any>(url);
  }

  async getListComparisonType() {
    const url = 'mapping/comparison-type/';
    return this.getResource<FetchArrayResponse<{ type: ComparisonType }>>(url);
  }

  // TODO: Add type
  async getListReportState() {
    const url = 'mapping/report-state/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListMappingCpTradesFields() {
    const url = 'mapping/cptrades-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListMappingRowBalanceFields() {
    const url = 'mapping/row-balance-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListCpTransactionFields() {
    const url = 'mapping/cp-transaction-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getCouponFields() {
    const url = 'mapping/coupon-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getDividendFields() {
    const url = 'dvd-records-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListMappingPositionReconciliationFields() {
    const url = 'mapping/position-reconciliation-fields/list/';
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListValueMappingRuleWithConditions(payload: any) {
    return this.getResource<any>(
      getUrlFromPayload(
        'mapping/value-mapping-rule-with-conditions/list/?',
        payload,
      ),
    );
  }

  async getListMappingReportType() {
    const url = 'mapping/report-type/';
    return this.getResource<FetchArrayResponse<{ type: string }>>(url);
  }

  async getSdbFields() {
    const url = 'mapping/sdb-fields/';
    return this.getResource(url);
  }

  // TODO: Add type
  async getMappingReportWithFields(reportID: string) {
    const url = `mapping/report-with-fields/${reportID}/`;
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getExcelMappingReportWithFields(reportID: string) {
    const url = `mapping/report-excel-with-fields/${reportID}/`;
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListMappingReportWithFields() {
    const url = `mapping/report-with-fields/`;
    return this.getResource<ReportWithFields[]>(url);
  }

  // TODO: Add type
  async getListMappingExcelReportWithFields() {
    const url = `mapping/report-excel-with-fields/`;
    return this.getResource<any>(url);
  }

  // TODO: Add type
  async getListCpType() {
    const url = `mapping/list-cp-type/`;
    return this.getResource<any>(url);
  }

  async getReconcileTransactions(payload: any, mode = 'bo-vs-cp') {
    let url = 'transactions/get-cp-bo-transactions-two-tables/?';
    /* eslint-disable */
    for (const [key, value] of Object.entries(payload)) {
      if (value === '' || value === undefined) {
        continue;
      }
      url += `${key}=${value}&`;
    }
    /* eslint-enable */
    url += `mode=${mode}&`;
    return this.getResource(url);
  }

  // TODO: Add type
  async getBoRecords(payload: FetchGetTransactionRecordsPayload) {
    return this.getResource<any>(
      getUrlFromPayload('transactions/get-bo-records/?', payload),
    );
  }

  // TODO: Add type
  async getCpRecords(payload: FetchGetTransactionRecordsPayload) {
    return this.getResource<any>(
      getUrlFromPayload('transactions/get-cp-records/?', payload),
    );
  }

  // POST requests
  async postSymbolsMapping(payload: FetchSymbolsMappingPayload) {
    const url = 'tables/symbols-mapping/';
    return this.postResource<
      FetchSymbolsMappingPayload,
      FetchSymbolsMappingPayload
    >(url, payload);
  }

  async patchCTrade(full_id: string, payload: Ctrade) {
    const url = `tables/ctrades/${full_id}/`;
    return this.patch<Ctrade>(url, payload);
  }

  async patchCPTrade(full_id: string, payload: CpTrade) {
    const url = `tables/cp-trades/${full_id}/`;
    return this.patch<CpTrade>(url, payload);
  }

  async patchRowBalance(full_id: string, payload: RowBalance) {
    const url = `tables/row-balance/${full_id}/`;
    return this.patch<RowBalance>(url, payload);
  }

  async patchCpTransaction(full_id: number, payload: Record<string, string>) {
    const url = `tables/cp-transaction/${full_id}/`;
    return this.patch<Record<string, string>>(url, payload);
  }

  async patchBoFinancialTransaction(
    full_id: number,
    payload: Record<string, string>,
  ) {
    const url = `tables/bo-financial-transactions/${full_id}/`;
    return this.patch<Record<string, string>>(url, payload);
  }

  async patchSymbolsMapping(id: string, payload: SymbolsMapping) {
    const url = `tables/symbols-mapping/${id}/`;
    return this.patch<SymbolsMapping>(url, payload);
  }

  async deleteSymbolsMapping(id: number) {
    return this.delete(`tables/symbols-mapping/${id}/`);
  }

  async deleteShortMapping(id: number) {
    return this.delete(`tables/short-mapping/${id}/`);
  }

  async postShortMapping(payload: ShortMapping) {
    const url = 'tables/short-mapping/';
    return this.postResource<ShortMapping, ShortMapping>(url, payload);
  }

  async unreconcileGroup(group_id: number) {
    const payload = { group_id };
    return this.postResource('trade-recon/unreconcile-group/', payload);
  }

  async reconcileTrades(payload: any) {
    return this.postResource<
      { ctrade_ids: number[]; cptrade_ids: number[] },
      { ctrade_ids: number[]; cptrade_ids: number[] }
    >('trade-recon/reconcile-trades/', payload);
  }

  async unReconcileTrades(payload: any) {
    return this.postResource<{ group_id: string }, { message: string }>(
      'trade-recon/unreconcile-group/',
      payload,
    );
  }

  async reconcileTradesBoVsBo(payload: ReconcilePayload) {
    return this.postResource<ReconcilePayload, { group_id: number }>(
      'trade-recon/reconcile-trades/bo-vs-bo/',
      payload,
    );
  }

  async reconcileTradesBoVsCp(payload: ReconcilePayload) {
    return this.postResource<ReconcilePayload, { group_id: number }>(
      'trade-recon/reconcile-trades/bo-vs-cp/',
      payload,
    );
  }

  async reconcileTradesCpVsCp(payload: ReconcilePayload) {
    return this.postResource<ReconcilePayload, { group_id: number }>(
      'trade-recon/reconcile-trades/cp-vs-cp/',
      payload,
    );
  }

  async upsertReconBySymbolComment(payload: { id: number; comment: string }) {
    const url = `trade-recon/recon-by-symbol/upsert-comment/`;
    return this.postResource<
      { id: number; comment: string },
      { message: string }
    >(url, payload);
  }

  async patchPositionReconciliation(
    id: number,
    payload: PositionReconciliation,
  ) {
    const url = `tables/position-reconciliation/${id}/`;
    return this.patch<PositionReconciliation>(url, payload);
  }

  async postPositionReconciliation(payload: PositionReconciliation) {
    const url = 'tables/position-reconciliation/';
    return this.postResource<PositionReconciliation, PositionReconciliation>(
      url,
      payload,
    );
  }

  async updatePositionReconciliationFromMapping(mappingID: number) {
    const url =
      'position-recon/action/update-position-reconciliation-from-mapping/';
    const payload = { id: mappingID };
    return this.postResource<{ id: number }, { message: string }>(url, payload);
  }

  async patchReport(id: number, payload: any) {
    return this.patch(`mapping/report/${id}/`, payload);
  }

  async patchCpCutOffTime(id: number, payload: CpCutOffTime) {
    return this.patch<CpCutOffTime>(`tables/cp-cut-off-time/${id}/`, payload);
  }

  async deleteSymbolCutOffTime(id: number) {
    return this.delete(`tables/cut-off-time-by-symbol/${id}/`);
  }

  async patchSymbolCutOffTime(id: number, payload: CutOffTimeBySymbol) {
    return this.patch<CutOffTimeBySymbol>(
      `tables/cut-off-time-by-symbol/${id}/`,
      payload,
    );
  }

  async postSymbolCutOffTime(payload: CutOffTimeBySymbol) {
    return this.postResource<CutOffTimeBySymbol, CutOffTimeBySymbol>(
      'tables/cut-off-time-by-symbol/',
      payload,
    );
  }

  async patchFtpPathBoDrive(id: number, payload: FtpPathBoDrive) {
    return this.patch<FtpPathBoDrive>(
      `tables/ftp-path-bodrive/${id}/`,
      payload,
    );
  }

  async patchFtpPath(id: number, payload: FtpPath) {
    return this.patch<FtpPath>(`tables/ftp-path/${id}/`, payload);
  }

  async deleteFtpPathBoDrive(id: number) {
    return this.delete(`tables/ftp-path-bodrive/${id}/`);
  }

  async deleteFtpPath(id: number) {
    return this.delete(`tables/ftp-path/${id}/`);
  }

  async postFtpPathBoDrive(payload: FtpPathBoDrive) {
    return this.postResource<FtpPathBoDrive, FtpPathBoDrive>(
      'tables/ftp-path-bodrive/',
      payload,
    );
  }

  async postFtpPath(payload: FtpPath) {
    return this.postResource<FtpPath, FtpPath>('tables/ftp-path/', payload);
  }

  async postRowBalance(payload: RowBalance) {
    return this.postResource<RowBalance, RowBalance>(
      'tables/row-balance/',
      payload,
    );
  }

  async deleteRowBalance(id: number) {
    return this.delete(`tables/row-balance/${id}/`);
  }

  async postCpTransaction(payload: CpTransaction) {
    return this.postResource<CpTransaction, CpTransaction>(
      'tables/cp-transaction/',
      payload,
    );
  }

  async deleteCpTransaction(id: number) {
    return this.delete(`tables/cp-transaction/${id}/`);
  }

  async postXleReportComment(payload: HKReconWithComments) {
    const url = `tables/hk-ium-recon-comments/`;
    return this.postResource<HKReconWithComments, HKReconWithComments>(
      url,
      payload,
    );
  }

  async patchXleReportComment(id: number, payload: HKReconWithComments) {
    const url = `tables/hk-ium-recon-comments/${id}/`;
    return this.patch<HKReconWithComments>(url, payload);
  }

  async postAutoMappingRuleWithFields(payload: AutoMappingRuleWithFields) {
    const url = 'mapping/auto-mapping-rule-with-fields/add/';
    return this.postResource<AutoMappingRuleWithFields, { message: string }>(
      url,
      payload,
    );
  }

  async postValueMappingRuleWithConditions(
    payload: ValueMappingRuleWithConditions,
  ) {
    const url = 'mapping/value-mapping-rule-with-conditions/add/';
    return this.postResource<
      ValueMappingRuleWithConditions,
      { message: string }
    >(url, payload);
  }

  // TODO: Fix type
  async postValueMappingUpdate(payload: any) {
    const url = 'mapping/value-mapping-rule-with-conditions/update/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postMappingReportWithFields(payload: any) {
    const url = 'mapping/create-report-with-fields/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postMappingFields(payload: any) {
    const url = 'mapping/create-fields/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postCpMapping(payload: any) {
    const url = 'mapping/create-cp-fields/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postMappingExcelReportWithFields(payload: any) {
    const url = 'mapping/create-excel-report-with-fields/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postMappingExcelStopString(payload: any) {
    const url = 'mapping/create-excel-stop-string/';
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postMappingUpdateReport(payload: any) {
    const url = `mapping/update-report/`;
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postCpUpdate(payload: any) {
    const url = `mapping/update-cp/`;
    return this.postResource<any, any>(url, payload);
  }

  // TODO: Fix type
  async postDeleteMappingReportWithFields(reportID: string) {
    const url = `mapping/delete-report-with-fields/${reportID}/`;
    return this.postResource<any, any>(url);
  }

  // TODO: Fix type
  async postCloneReportWithFields(reportID: number) {
    const url = `mapping/clone-report-with-fields/${reportID}`;
    return this.postResource<any, any>(url);
  }

  // TODO: Fix type
  async postDeleteMappingField(fieldID: number) {
    const url = `mapping/delete-field/${fieldID}/`;
    return this.postResource<any, any>(url);
  }

  // TODO: Fix type
  async postDeleteExcelStopString(stringID: string) {
    const url = `mapping/delete-table-string/${stringID}/`;
    return this.postResource<any, any>(url);
  }

  async postReconcileTransactions(payload: {
    cp_ids: number[];
    bo_ids: number[];
  }) {
    const url = 'transactions/reconcile/';
    return this.postResource<
      { cp_ids: number[]; bo_ids: number[] },
      { cp_ids: number[]; bo_ids: number[] }
    >(url, payload);
  }

  async deleteReconcileTransactions(payload: { group_id: number }) {
    const url = 'transactions/unreconcile/';
    return this.postResource<{ group_id: number }, { group_id: number }>(
      url,
      payload,
    );
  }
}
