import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

export function ReconciledRow(props) {
  const { entry, reUploadResults } = props;
  const [reconComment, setReconComment] = useState(entry.recon_comment);
  const api = new ReconTablesApiService();

  useEffect(() => {
    setReconComment(entry.recon_comment);
  }, [entry]);

  const onUnGroup = (tempEntry) => {
    // eslint-disable-next-line
    console.log(`Group_id: ${tempEntry.group_id}`);
    api
      .bankUnReconcile({ group_id: tempEntry.group_id })
      .then((results) => {
        alert(`Status: ${results.status}`);
        reUploadResults();
      })
      .catch((e) => {
        alert(`Failed to reconcile: ${e}`);
      });
  };

  const onChangeReconComment = (e) => {
    setReconComment(e.target.value);
  };

  const onChangeReconCommentSubmit = () => {
    api
      .bankChangeReconComment({
        group_id: entry.group_id,
        recon_comment: reconComment,
      })
      .then((results) => {
        alert(`Status: ${results.status}`);
        reUploadResults();
      })
      .catch((e) => {
        alert(`Failed to reconcile: ${e}`);
      });
  };

  return (
    <tr key={entry.bo_id}>
      <td>{entry.bo_id}</td>
      <td>{entry.account_id}</td>
      <td>{entry.botimestamp}</td>
      <td>{entry.sum}</td>
      <td>{entry.currency}</td>
      <td>{entry.operation_type}</td>
      <td>{entry.bo_comment}</td>
      <td>{entry.comment}</td>
      <td>
        <input
          type="text"
          value={reconComment || ''}
          onChange={onChangeReconComment}
          className="form-control"
          name="recon_comment_text"
        />
        <button
          className="btn btn-link"
          type="submit"
          onClick={onChangeReconCommentSubmit}
        >
          Change
        </button>
      </td>
      <td>
        <b>{entry.group_id}</b>
        <br />
        <button className="btn-sm btn-danger" onClick={() => onUnGroup(entry)}>
          unrecon
        </button>
      </td>
      <td>{entry.recon_type}</td>
    </tr>
  );
}
