import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import AddSymbolCOTForm from './form';
import SymbolCOTTable from './table';

class SymbolCOTPage extends Component {
  // eslint-disable-next-line
  state = {
    loading: false,
    entries: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true, entries: [] });
    const api = new ReconTablesApiService();
    api
      .getListSymbolCutOffTime()
      .then((response) => {
        this.setState({ loading: false, entries: response.results });
      })
      .catch((e) => {
        this.setState({ loading: false });
        alert(e);
      });
  };

  render() {
    return (
      <>
        <AddSymbolCOTForm onAdded={this.fetchData} />
        {this.state.loading ? (
          <Spinner />
        ) : (
          <SymbolCOTTable
            entries={this.state.entries}
            fetchData={this.fetchData}
          />
        )}
      </>
    );
  }
}

export default SymbolCOTPage;
