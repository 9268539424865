import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

function ReportTableRow(prop) {
  const { report } = prop;

  const onSelected = (e) => {
    const payload = {};
    const api = new ReconTablesApiService();

    payload.cp_id = report.id;
    payload.active = e.target.checked;
    api
      .postCpUpdate(payload)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {});
  };
  if (report.name !== prop.entity && prop.entity !== 'All') {
    return null;
  }
  let rowSpan = report.length;
  if (rowSpan === 0) {
    rowSpan = 1;
  }

  return (
    <>
      <tr key={report.id}>
        <td rowSpan={rowSpan}>{report.cp}</td>
        <td rowSpan={rowSpan}>{report.bo_settlement_cp}</td>
        <td rowSpan={rowSpan}>{report.name}</td>
        <td rowSpan={rowSpan}>{report.bo_cp}</td>
        <td rowSpan={rowSpan}>{report.settlement_execution_ccp}</td>
        <td rowSpan={rowSpan}>{report.cp_type}</td>
        <td rowSpan={rowSpan}>
          <input
            type="checkbox"
            onChange={onSelected}
            checked={report.active}
          />
        </td>
      </tr>
    </>
  );
}

function dynamicSort(property) {
  let sortOrder = 1;
  let tempProperty = property;
  if (property[0] === '-') {
    sortOrder = -1;
    tempProperty = property.substr(1);
  }
  function sortFunc(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = 0;
    if (a[tempProperty] < b[tempProperty]) {
      result = -1;
    } else if (a[tempProperty] > b[tempProperty]) {
      result = 1;
    }
    return result * sortOrder;
  }
  return sortFunc;
}

function ReportListView(prop) {
  const { reports, entity, active: temp } = prop;
  const data = reports;
  const l = reports === undefined ? 0 : reports.length;
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [l]);

  const onSort = (event, sortKey) => {
    const tempData = [...sortedData].sort(dynamicSort(sortKey));
    setSortedData([]);
    setSortedData(tempData);
  };
  const rows = sortedData.map((report) => (
    <ReportTableRow
      report={report}
      active={temp}
      key={report.id}
      entity={entity}
    />
  ));

  return (
    <div className="container-fluid mt-3">
      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th onClick={(e) => onSort(e, 'cp')}>Counterparty</th>
            <th onClick={(e) => onSort(e, 'bo_settlement_cp')}>Bosettcp</th>
            <th onClick={(e) => onSort(e, 'malta_entity')}>MaltaEntity</th>
            <th onClick={(e) => onSort(e, 'bo_cp')}>bo_cpid</th>
            <th onClick={(e) => onSort(e, 'settlement_execution_ccp')}>
              Settlexeccp
            </th>
            <th onClick={(e) => onSort(e, 'cp_type')}>CpType</th>
            <th onClick={(e) => onSort(e, 'active')}>Active</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default function ReportsListPage() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState('All');
  const active = false;

  useEffect(() => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListCpWithEntity()
      .then((res) => {
        setLoading(false);
        setReports(res.results);
      })
      .catch((e) => {
        setLoading(false);
        // eslint-disable-next-line
        alert(e);
      });

    //  const api = new ReconTablesApiService();
    api
      .getListEntityDistinct()
      .then((res) => {
        const list = res.results;

        list.push('All');
        const options = res.results.map((r) => {
          if (r === 'All') {
            return (
              <option key={r} value={r} selected={r}>
                {r}
              </option>
            );
          }
          return (
            <option key={r} value={r}>
              {r}
            </option>
          );
        });
        setFilters(options);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(e);
      });
  }, []);
  const onChange = (e) => {
    const cp = e.target.value;
    setFilterData(cp);
  };

  return (
    <>
      <h2>Cp List</h2>
      Filters{' '}
      <select
        className="custom-select mr-sm-2"
        name="filters"
        onChange={onChange}
      >
        {filters}
      </select>
      {loading ? (
        <div className="mt-5">
          <Spin />
        </div>
      ) : (
        <ReportListView reports={reports} active={active} entity={filterData} />
      )}
    </>
  );
}
