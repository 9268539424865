import { Spin } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { commonSlice } from 'store/reducers/commonReducer';

import { SearchForm } from './search-form';
import { TransactionReconcileTable } from './table';

export function TransactionsManualReconcilePage() {
  const api = new ReconTablesApiService();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [formPayload, setFormPayload] = useState();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.mode);

  const loadSearchResults = (form_payload) => {
    setLoading(true);
    dispatch(commonSlice.actions.setSearchPayload(form_payload));
    setFormPayload(form_payload);
    api
      .getReconcileTransactions(form_payload, mode)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const reUploadResults = () => {
    setLoading(true);
    api
      .getReconcileTransactions(formPayload, mode)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const txReconcileRequest = (left_ids, right_ids) => {
    api
      .postReconcileTransactions({ cp_ids: left_ids, bo_ids: right_ids })
      .then((res) => {
        // eslint-disable-next-line
        console.log(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to reconcile: ${e}`);
      });
  };

  return (
    <>
      <h2>Transactions Reconciliation</h2>
      <SearchForm search={loadSearchResults} />
      {loading ? (
        <Spin />
      ) : (
        <TransactionReconcileTable
          results={results}
          txReconcileRequest={txReconcileRequest}
          reUploadResults={reUploadResults}
        />
      )}
    </>
  );
}
