import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { CPMonitorTable } from './table';

export function CPMonitorPage() {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [entityFilter, setEntityFilter] = useState('All');
  const [cpTypeFilter, setCpTypeFilter] = useState('All');
  const [entityTypeFilter, setEntityTypeFilter] = useState('Regular');
  // eslint-disable-next-line
  const getData = (
    payload,
    currentEntityFilter,
    currentCpTypeFilter,
    currentEntityTypeFilter,
  ) => {
    setLoading(true);
    setEntityFilter(currentEntityFilter);
    setCpTypeFilter(currentCpTypeFilter);
    setEntityTypeFilter(currentEntityTypeFilter);
    const reconTablesApi = new ReconTablesApiService();
    reconTablesApi
      .getMonitoringCpStatuses(payload)
      .then((e) => {
        setResults(e);
        setLoading(false);
      })
      .catch((e) => {
        setResults([]);
        setLoading(false);
        alert(e);
      });
  };

  return loading ? (
    <Spinner />
  ) : (
    <CPMonitorTable
      data={results}
      getData={getData}
      entityFilter={entityFilter}
      cpTypeFilter={cpTypeFilter}
      entityTypeFilter={entityTypeFilter}
    />
  );
}
