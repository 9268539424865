import { IOnFetchArguments } from 'react-ui-kit-exante';

import { getSortingParams } from './ReconEmirApiService';
import BaseApiService from './baseApiService';
import { getUrlFromPayload } from './utils';

interface ResponseWithPagination<T> {
  content: T[];
  pagination: { limit: number; page: number; total: number };
}

export interface Report {
  id: number;
  accepted_records: string | null;
  date: string;
  error: string | null;
  last_response: null | string;
  legal_entity_id: string;
  rejected_records: null | string;
  status_id: string;
  total_records: null | string;
  uid: string;
}

export interface ReportsTable {
  reports: Report[];
  pagination: { total: number };
}

export interface XmlEntryItem {
  value: string;
  name: string;
}
export interface Entry {
  id: number;
  bo_symbol: string;
  bo_trade: string;
  error: string;
  report_id: null | string;
  report_uid: string;
  status_id: string;
  uid: string;
  xml_entry_str: {
    buyer: XmlEntryItem[];
    common: XmlEntryItem[];
    seller: XmlEntryItem[];
  };
}

export interface EntriesTable {
  entries: Entry[];
  pagination: { total: number };
}

enum Urls {
  REPORTS = 'mifid/reports',
  ENTRY = 'mifid/entry',
}

export class MifidApiService extends BaseApiService {
  _apiBase = `https://${
    window?.RECON_UI?.MIFIR ?? `mifir-reports-stage.exante.eu`
  }/api/v1/`;

  async getReports({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Report>>(
        getUrlFromPayload(`${Urls.REPORTS}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        reports: response.content,
        pagination: response.pagination as any,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getReportEntries({ params }: IOnFetchArguments, id: string) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Entry>>(
        getUrlFromPayload(`${Urls.REPORTS}/${id}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        entries: response.content,
        pagination: response.pagination as any,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getEntry(id: string) {
    try {
      return await this.getResource<{ entry: Entry; report: Report }>(
        `${Urls.ENTRY}/${id}?`,
      );
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
