import { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import NavBar from 'components/navbar';

import './jsonView.css';
import Approvals from './approvals-prоject/pages/Approvals/Approvals';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import MetaDataFetcher from './components/metadata-fetcher';
import { UnionManualReconcilePage } from './pages/ManualReconcileUnion/ManualReconcileUnion';
import { RawDataPage } from './pages/RawData/RawData';
import { UnionTransactionsReconcilePage } from './pages/UnionTransactionsReconcile/UnionTransactionsReconcile';
import AutoMappingRulesPage from './pages/auto-mapping-rules/page';
import AutoReconcilePage from './pages/auto-reconcile/page';
import { BankCashTransferReconcilePage } from './pages/bank-cash-transfer-reconcile/page';
import { BankReconcilePage } from './pages/bank-reconcile/page';
import { CPCutOffTimePage } from './pages/cot-cp/page';
import SymbolCOTPage from './pages/cot-symbol/page';
import { CPMonitorPage } from './pages/cp-monitor/page';
import CpTransactionPage from './pages/cp-transaction';
import CpListPage from './pages/cp/list/page';
import NewCpPage from './pages/cp/new/page';
import CPTradesTablePage from './pages/cptrades-table/page';
import CTradesTablePage from './pages/ctrades-table';
import { DetailPage } from './pages/emir/detail-page';
import { EmirPositionsPage } from './pages/emir/positions-page';
import { EmirScriptsPage } from './pages/emir/scripts-page/page';
import { EmirTradesPage } from './pages/emir/trades-page';
import { EodReportPage } from './pages/eod-report/page';
import ReportExcelDetailsPage from './pages/excel-report/details/page';
import ReportsExcelListPage from './pages/excel-report/list/page';
import NewReportExcelPage from './pages/excel-report/new/page';
import FTPPathPage from './pages/ftp-path/page';
import ManualReconcilePage from './pages/manual-reconcile';
import ManualInreconcilePage from './pages/manual-unreconcile';
import MappingPage from './pages/mapping-page/page';
import LiquidityMonitorPage from './pages/metabase/liquidityMonitor';
import { MifidEntryPage } from './pages/mifid/entries/detail-page';
import { MifidEntriesPage } from './pages/mifid/entries/page';
import { MifidReportsPage } from './pages/mifid/reports/page';
import { OrderStatusPage } from './pages/order-status/page';
import PositionMappingPage from './pages/position-mapping';
import { PositionrecPage } from './pages/positionrec/page';
import PositionDiffPage from './pages/positions-diff/page';
import { RegulatoryMonitorPage } from './pages/regulatory-monitor/page';
import ReportDetailsPage from './pages/report/details/page';
import { ReportsListPage } from './pages/report/list/page';
import NewReportPage from './pages/report/new/page';
import RowBalancePage from './pages/row-balance';
import RunInfoPage from './pages/run-info/page';
import RunScriptPage from './pages/run-script-page';
import ScriptsStatusesPage from './pages/scripts-statuses';
import SeriesMappingPage from './pages/series-mapping';
import TradeReconDashboardPage from './pages/trade-recon-dashboard/page';
import { DividendsCouponReconcile } from './pages/transactions-reconcile/dividends-coupon-reconcile';
import { TransactionsManualReconcilePage } from './pages/transactions-reconcile/page';
import UnpivotReportDetailsPage from './pages/unpivot/details/page';
import UnpivotListPage from './pages/unpivot/list/page';
import NewUnpivotPage from './pages/unpivot/new/page';
import ValueMappingRulesPage from './pages/value-mapping-rules/page';
import { XleTotalReportPage } from './pages/xle-total-reports/page';
import { setupStore } from './store/store';
import { getBaseName } from './utils';

const store = setupStore();
// eslint-disable-next-line
class App extends Component {
  render() {
    const isInsideRun = !!window.runUIhistoryContainer;
    const urlPrefix = isInsideRun ? '/recon' : '';
    const baseName = getBaseName();
    const pathname = window.location.pathname;
    const isReport = pathname.includes('emir') || pathname.includes('mifir');

    const InnerRouterComponent = (
      <div className="App">
        {!isInsideRun && (
          <>
            <NavBar />
            <div className="mb-2" />
          </>
        )}
        <Switch>
          <Route path={`${urlPrefix}/run-info`}>
            <RunInfoPage />
          </Route>

          <Route path={`${urlPrefix}/bank-transaction-reconcile`}>
            <BankReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/bank-cash-transfer-reconcile`}>
            <BankCashTransferReconcilePage />
          </Route>

          <Route path={`${urlPrefix}/edit/tables`}>
            <CTradesTablePage />
          </Route>
          <Route path={`${urlPrefix}/edit/cptrades`}>
            <CPTradesTablePage />
          </Route>
          <Route path={`${urlPrefix}/auto-reconcile`}>
            <AutoReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/edit/mapping`}>
            <MappingPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/new-unpivot`}>
            <NewUnpivotPage />
          </Route>
          <Route path={`${urlPrefix}/edit/positionrec`}>
            <PositionrecPage />
          </Route>
          <Route path={`${urlPrefix}/edit/series-mapping`}>
            <SeriesMappingPage />
          </Route>
          <Route path={`${urlPrefix}/run/trade-recon`}>
            <RunScriptPage group="trade-recon" isTradeRecon hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/crypto-position-recon`}>
            <RunScriptPage group="position-recon" hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/crypto-balance-recon`}>
            <RunScriptPage group="balance-recon" hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/crypto-transactions`}>
            <RunScriptPage group="transactions" hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/large-trades`}>
            <RunScriptPage group="large-trades" />
          </Route>
          <Route path={`${urlPrefix}/run/vm`}>
            <RunScriptPage group="vm" />
          </Route>
          <Route path={`${urlPrefix}/run/views`}>
            <RunScriptPage group="views" hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/misc`}>
            <RunScriptPage group="misc" />
          </Route>
          <Route path={`${urlPrefix}/run/load-trades`}>
            <RunScriptPage group="load-trades" hasDate />
          </Route>
          <Route path={`${urlPrefix}/run/reporting`}>
            <RunScriptPage group="reporting" />
          </Route>
          <Route path={`${urlPrefix}/manual-unreconcile`}>
            <ManualInreconcilePage />
          </Route>
          <Route path={`${urlPrefix}/manual-reconcile`}>
            <ManualReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/manual-reconcile-union`}>
            <UnionManualReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/eod-statement`}>
            <EodReportPage />
          </Route>
          <Route path={`${urlPrefix}/positions-diff`}>
            <PositionDiffPage />
          </Route>
          <Route path={`${urlPrefix}/symbol-cot`}>
            <SymbolCOTPage />
          </Route>
          <Route path={`${urlPrefix}/xle-total`}>
            <XleTotalReportPage />
          </Route>
          <Route path={`${urlPrefix}/order-status`}>
            <OrderStatusPage />
          </Route>
          <Route path={`${urlPrefix}/cp-monitor`}>
            <CPMonitorPage />
          </Route>
          <Route path={`${urlPrefix}/regulatory-monitor`}>
            <RegulatoryMonitorPage />
          </Route>
          <Route path={`${urlPrefix}/position-mapping`}>
            <PositionMappingPage />
          </Route>
          <Route path={`${urlPrefix}/trade-recon-dashboard`}>
            <TradeReconDashboardPage />
          </Route>
          <Route path={`${urlPrefix}/cp-cot`}>
            <CPCutOffTimePage />
          </Route>
          <Route path={`${urlPrefix}/ftp-path`}>
            <FTPPathPage />
          </Route>
          <Route path={`${urlPrefix}/cp-cot`}>
            <CPCutOffTimePage />
          </Route>
          <Route path="/metabase">
            <LiquidityMonitorPage />
          </Route>
          <Route path={`${urlPrefix}/row-balance/add`}>
            <RowBalancePage />
          </Route>
          <Route path={`${urlPrefix}/cp-transaction/add`}>
            <CpTransactionPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/auto-mapping-rules`}>
            <AutoMappingRulesPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/value-mapping-rules`}>
            <ValueMappingRulesPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/new-report`}>
            <NewReportPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/new-report-excel`}>
            <NewReportExcelPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/new-cp`}>
            <NewCpPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/cps`}>
            <CpListPage />
          </Route>

          <Route path={`${urlPrefix}/mapping/reports/:reportId/`}>
            <ReportDetailsPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/excel-reports/:reportId/`}>
            <ReportExcelDetailsPage />
          </Route>
          <Route path="/mapping/unpivot_reports/:reportId/">
            <UnpivotReportDetailsPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/reports`}>
            <ReportsListPage />
          </Route>
          <Route path="/mapping/unpivot">
            <UnpivotListPage />
          </Route>
          <Route path={`${urlPrefix}/mapping/excel-report`}>
            <ReportsExcelListPage />
          </Route>
          <Route path={`${urlPrefix}/transactions-reconcile`}>
            <TransactionsManualReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/transactions-reconcile-union`}>
            <UnionTransactionsReconcilePage />
          </Route>
          <Route path={`${urlPrefix}/dividends-coupons-reconcile`}>
            <DividendsCouponReconcile />
          </Route>
          <Route exact path={isInsideRun ? '/recon/status' : '/'}>
            <ScriptsStatusesPage />
          </Route>
          <Route exact path={`${urlPrefix}/emir/trades`}>
            <EmirTradesPage />
          </Route>
          <Route exact path={`${urlPrefix}/emir/scripts`}>
            <EmirScriptsPage />
          </Route>
          <Route path={`${urlPrefix}/emir/trade/:tradeId`}>
            <DetailPage />
          </Route>
          <Route path={`${urlPrefix}/emir/position/:positionId`}>
            <DetailPage />
          </Route>

          <Route path={`${urlPrefix}/raw-data`}>
            <RawDataPage />
          </Route>

          <Route exact path={`${urlPrefix}/emir/positions`}>
            <EmirPositionsPage />
          </Route>
          <Route exact path={`${urlPrefix}/mifid/reports`}>
            <MifidReportsPage />
          </Route>
          <Route exact path={`${urlPrefix}/mifid/reports/:reportId`}>
            <MifidEntriesPage />
          </Route>
          <Route exact path={`${urlPrefix}/mifid/entry/:entryId`}>
            <MifidEntryPage />
          </Route>
          <Route exact path={`${urlPrefix}/wfe/approvals`}>
            <Approvals />
          </Route>
        </Switch>
      </div>
    );

    const routes = isInsideRun ? (
      <Router history={window.runUIhistoryContainer}>
        {InnerRouterComponent}
      </Router>
    ) : (
      <BrowserRouter basename={baseName}>{InnerRouterComponent}</BrowserRouter>
    );

    return (
      <ErrorBoundary>
        <Provider store={store}>
          <NotificationProvider />
          {isReport && isInsideRun ? (
            routes
          ) : (
            <MetaDataFetcher>{routes}</MetaDataFetcher>
          )}
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
