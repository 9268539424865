import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function NewReportForm({ onNewReportCreated }) {
  const [loading, setLoading] = useState(false);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const brokerIDs = useSelector((state) => state.counterParties);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);
  const [stopStrings, setStopStrings] = useState([]);

  useEffect(() => {
    const apiService = new ReconTablesApiService();

    apiService.getListMappingReportType().then((res) => {
      const options = res.results.map((r) => (
        <option value={r.type} key={r.type}>
          {r.type}
        </option>
      ));
      setReportTypeOptions(options);
    });

    apiService.getListComparisonType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setComparisonOptions(options);
    });

    apiService.getListMappingCpTradesFields().then(() => {});
  }, []);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }

    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);

  const onAddFieldMapping = () => {
    const newFieldMapping = {
      conditions: [],
    };
    const newFieldMappings = [...fieldMappings];
    newFieldMappings.push(newFieldMapping);
    setFieldMappings(newFieldMappings);
  };

  const onAddStopString = () => {
    const newStopStrings = [...stopStrings];
    newStopStrings.push(newStopStrings);
    setStopStrings(newStopStrings);
  };

  const deleteCondition = (fieldMappingIdx, condIdx) => {
    const newFieldMappings = [...fieldMappings];
    const fmToModify = newFieldMappings[fieldMappingIdx];
    fmToModify.conditions = fmToModify.conditions.filter(
      (_, idx) => idx !== condIdx,
    );
    newFieldMappings[fieldMappingIdx] = fmToModify;
    setFieldMappings(newFieldMappings);
  };

  const deleteFieldMapping = (fieldMappingIdx) => {
    const newFieldMappings = fieldMappings.filter(
      (_, idx) => idx !== fieldMappingIdx,
    );
    setFieldMappings(newFieldMappings);
  };

  const fieldMappingEl = () => {
    const elements = [];
    let idx = -1;
    // eslint-disable-next-line
    for (const fm of fieldMappings) {
      idx += 1;
      const tempIdx = idx;
      const conditionsElements = [];
      let idxCond = -1;
      // eslint-disable-next-line
      for (const cond of fm.conditions) {
        idxCond += 1;
        const condEl = (
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Field</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_field#${idxCond}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Value</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_value#${idxCond}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Comparison Type</div>
                </div>
                <select
                  className="custom-select mr-sm-2"
                  name={`conditions#${idx}:comparison_type#${idxCond}`}
                >
                  {comparisonOptions}
                </select>
              </div>
            </div>
            <div className="col-1">
              <div className="input-group">
                <button
                  className="btn btn-danger"
                  onClick={
                    // eslint-disable-next-line
                    () => deleteCondition(idx, idxCond)
                  }
                  type="button"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
        conditionsElements.push(condEl);
      }
      const el = (
        <div className="card bg-light mt-3">
          <div className="card-body">
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Start string</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`start_string#${idx}`}
                  />
                </div>
              </div>

              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Field miss</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`field_miss#${idx}`}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Start miss</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`start_miss#${idx}`}
                  />
                </div>
              </div>

              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Book</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`book#${idx}`}
                  />
                </div>
              </div>

              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">File postfix</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`file_postfix#${idx}`}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Raw miss</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`row_miss#${idx}`}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Stop string</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`stop_string#${idx}`}
                  />
                </div>
              </div>
              <button
                className="btn btn-success ml-2"
                type="button"
                onClick={onAddStopString}
              >
                Add Stop String
              </button>
            </div>
            {conditionsElements}
            <div className="form-row mt-2">
              <div className="mx-auto">
                {loading ? (
                  <Spin />
                ) : (
                  <div>
                    <button
                      className="btn btn-danger ml-2"
                      onClick={() => deleteFieldMapping(tempIdx)}
                      type="button"
                    >
                      Delete Table
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      elements.push(el);
    }
    let idxs = -1;
    // eslint-disable-next-line
    for (const fm of stopStrings) {
      idxs += 1;
      const el = (
        <div className="card bg-light mt-3">
          <div className="card-body">
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="col">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Stop string</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name={`stop_string#${idxs}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      elements.push(el);
    }

    return elements;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const rawPayload = getFormPayload(event.target);
    const payload = {};
    // eslint-disable-next-line
    for (const key of Object.keys(rawPayload)) {
      if (key.indexOf('#') > -1) {
        continue;
      }
      payload[key] = rawPayload[key];
    }
    payload.file_type = 'xlsx';

    const strings = [];
    for (let i = 0; i < stopStrings.length; i += 1) {
      strings.push(rawPayload[`stop_string#${i}`]);
    }

    const fm = [];
    for (let i = 0; i < fieldMappings.length; i += 1) {
      fm.push({
        start_string: rawPayload[`start_string#${i}`],
        stop_string: strings,
        fields_miss: rawPayload[`field_miss#${i}`],
        start_miss: rawPayload[`start_miss#${i}`],
        book: rawPayload[`book#${i}`],
        file_postfix: rawPayload[`file_postfix#${i}`],
        row_miss: rawPayload[`row_miss#${i}`],
        name: rawPayload[`name#${i}`],
      });
    }

    payload.tables = fm;
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .postMappingExcelReportWithFields(payload)
      .then((res) => {
        setLoading(false);
        onNewReportCreated(res.report_id);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_id">
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <select className="custom-select mr-sm-2" name="type">
                {reportTypeOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Name</div>
              </div>
              <input type="text" className="form-control" name="name" />
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path</div>
              </div>
              <input type="text" className="form-control" name="path" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Contains</div>
              </div>
              <input type="text" className="form-control" name="contains" />
            </div>
          </div>
        </div>
        {fieldMappingEl()}
        <div className="form-row mt-3">
          <div className="mx-auto">
            {loading ? (
              <Spin />
            ) : (
              <div>
                <button className="btn btn-warning" type="submit">
                  Create
                </button>
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={onAddFieldMapping}
                >
                  Add Table
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
