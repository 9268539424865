import { NotificationProvider } from 'react-ui-kit-exante';

import ApprovalsTable from '../../components/ApprovalsTable/ApprovalsTable';

import { ApprovalsDataProvider } from './ApprovalsProvider';

const Approvals = () => (
  <ApprovalsDataProvider>
    <ApprovalsTable />
    <NotificationProvider />
  </ApprovalsDataProvider>
);

export default Approvals;
