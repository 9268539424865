import { uniq } from 'ramda';
import { useMemo, useState } from 'react';

import { CpAndCTradesWithGroupId, SIDE } from 'services/ReconTablesApiService';

import { getTrades } from './ManualReconcileUnionUtils';

export function useReconciledTable() {
  const [isLoadingReconciledTrades, setIsLoadingReconciledTrades] =
    useState(false);
  const [reconciledTrades, setReconciledTrades] = useState<
    CpAndCTradesWithGroupId[]
  >([]);

  const groupedReconciledTrades = useMemo(
    () =>
      uniq(reconciledTrades.map((i) => i.group_id))
        .sort((a, b) => b - a)
        .reduce((acc: any, groupId) => {
          const filteredByGroupId = reconciledTrades.filter(
            (i) => i.group_id === groupId,
          );
          const theirTrades = getTrades(SIDE.THEIR, 'cp', filteredByGroupId);
          const ourTrades = getTrades(SIDE.OUR, 'bo', filteredByGroupId);

          let moreTrades = theirTrades;
          let lessTrades = ourTrades;
          if (ourTrades.length >= theirTrades.length) {
            moreTrades = ourTrades;
            lessTrades = theirTrades;
          }

          const mappedTrades = moreTrades
            .map((item, index) =>
              lessTrades[index] ? { ...item, ...lessTrades[index] } : item,
            )
            .map((item, index) =>
              index === 0 ? item : { ...item, group_id: null },
            );
          return [...acc, ...mappedTrades];
        }, [])
        .map((item: any, index: number) => ({ ...item, id: index })),
    [reconciledTrades],
  );

  return {
    isLoadingReconciledTrades,
    setIsLoadingReconciledTrades,
    reconciledTrades,
    setReconciledTrades,
    groupedReconciledTrades,
  };
}
