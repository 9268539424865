import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { RegulatoryMonitorTable } from './table';

export function RegulatoryMonitorPage() {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);

  const getData = (payload) => {
    setLoading(true);
    const reconTablesApi = new ReconTablesApiService();
    reconTablesApi
      .getMonitoringRegulatoryStatuses(payload)
      .then((e) => {
        setResults(e);
        setLoading(false);
      })
      .catch((e) => {
        setResults([]);
        setLoading(false);
        alert(e);
      });
  };

  return loading ? (
    <Spinner />
  ) : (
    <RegulatoryMonitorTable data={results} getData={getData} />
  );
}
