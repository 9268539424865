import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

function TableRow({ entry }) {
  // type_of_trade is the only field we can change in cptrades
  const [typeOfTrade, setTypeOfTrade] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTypeOfTrade(entry.type_of_trade);
  }, [entry]);

  const onTypeChange = (e) => {
    setTypeOfTrade(e.target.value);
  };

  const update = () => {
    // update type_of_trade on server
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .patchCPTrade(entry.full_id, { ...entry, type_of_trade: typeOfTrade })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <tr key={entry.full_id}>
      <td>{entry.broker_id.toUpperCase()}</td>
      <td>{entry.symbol}</td>
      <td>{entry.bo_symbol}</td>
      <td>{entry.qty}</td>
      <td>{entry.price}</td>
      <td style={{ maxWidth: '80px' }}>
        {loading ? (
          <Spin />
        ) : (
          <div className="input-group">
            <div className="input-group-prepend">
              <button className="btn btn-sm btn-primary" onClick={update}>
                Update
              </button>
            </div>
            <input
              value={typeOfTrade}
              onChange={onTypeChange}
              type="text"
              className="form-control"
            />
          </div>
        )}
      </td>
      <td>{entry.ccy}</td>
      <td>{entry.report_date}</td>
    </tr>
  );
}

export default function CPTradesTable({ entries }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(entries.map((e) => <TableRow entry={e} />));
  }, [entries]);

  if (entries === undefined || entries.length === 0) {
    return <h2>No data</h2>;
  }

  return (
    <table className="table table-striped table-sm table-bordered">
      <thead>
        <tr>
          <th>BrokerID</th>
          <th>Symbol</th>
          <th>Our Symbol</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Type of Trade</th>
          <th>CCY</th>
          <th>Report Date</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
