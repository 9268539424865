import { Spin } from 'antd';
import { useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { CPTradesForm } from './form';
import CPTradesTable from './table';

export default function CPTradesTablePage() {
  // page to display filtered CPTrades values with ability to change type_of_trade for each trade
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);

  const onSearchSubmit = (payload) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListCPTrades(payload)
      .then((resp) => {
        setLoading(false);
        setEntries(resp.results);
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  };
  return (
    <>
      <br />
      <CPTradesForm onSubmit={onSearchSubmit} />
      <hr />
      {loading ? <Spin /> : <CPTradesTable entries={entries} />}
    </>
  );
}
