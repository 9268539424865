import { Component } from 'react';

class SeriesMappingFormSearch extends Component {
  // eslint-disable-next-line
  state = {
    cpSeries: undefined,
    exanteSeries: undefined,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { updateSearchResults } = this.props;
    updateSearchResults(
      undefined,
      this.state.cpSeries,
      this.state.exanteSeries,
    );
  };

  onInputChanged = (e) => {
    const fieldName = e.target.id.split('-')[2];
    const value = e.target.value;
    this.setState({ [fieldName]: value });
  };

  render() {
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP Series</div>
                </div>
                <input
                  type="text"
                  className="form-control text-capitalize"
                  value={this.state.cpSeries}
                  id="input-search-cpSeries"
                  onChange={this.onInputChanged}
                  placeholder=""
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Exante Series</div>
                </div>
                <input
                  type="text"
                  value={this.state.exanteSeries}
                  className="form-control"
                  id="input-search-exanteSeries"
                  onChange={this.onInputChanged}
                  placeholder=""
                />
              </div>
            </div>
            <button className="btn btn-info" type="submit">
              Filter
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default SeriesMappingFormSearch;
