import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export class OurSideTableRow extends Component {
  // eslint-disable-next-line
  state = {
    updating: false,
    cpName: '',
  };

  componentDidMount() {
    const { trade } = this.props;
    this.setState({ cpName: trade.cp_name });
  }

  onCheckboxClick = (e) => {
    if (e.target.checked) {
      this.props.addTrade(this.props.trade.trade_number);
      return;
    }
    this.props.removeTrade(this.props.trade.trade_number);
  };

  onCPNameChangeSubmit = (e) => {
    e.preventDefault();

    const { trade } = this.props;
    const formData = getFormPayload(e.target);
    this.setState({ updating: true, cpName: formData.cp_name });

    const payload = { ...trade, cp_name: formData.cp_name };

    const api = new ReconTablesApiService();
    api
      .patchCTrade(trade.trade_number, payload)
      .then(() => {
        this.setState({ updating: false });
      })
      .catch(() => {
        this.setState({ updating: false });
      });
  };

  cpNameChangeHandler = (e) => {
    const { newVal } = e.target.value;
    if (newVal === this.state.cpName) {
      return;
    }
    this.setState({ cpName: newVal });
  };

  render() {
    const { trade, selected } = this.props;
    const date = new Date(trade.cp_report_date);
    const dateF = date.toLocaleDateString();
    let cls = trade.trade_type === 'EXERCISE' ? 'bg-warning' : null;
    if (typeof cls === 'string') {
      // eslint-disable-next-line
      cls =
        trade.trade_type.toLowerCase() === 'redemption' ? 'by-secondary' : cls;
    }

    return (
      <tr key={trade.trade_number} className={cls}>
        <td>
          <input
            type="checkbox"
            onChange={this.onCheckboxClick}
            checked={selected}
          />
        </td>
        <td>{trade.symbol}</td>
        <td>{trade.qty}</td>
        <td>{trade.price}</td>
        <td>{trade.ccy}</td>
        <td>{trade.value}</td>
        <td>{trade.trade_number}</td>
        <td>{dateF}</td>
        <td>
          {this.state.updating ? (
            <Spinner />
          ) : (
            <form onSubmit={this.onCPNameChangeSubmit}>
              <input
                type="text"
                value={this.state.cpName}
                onChange={this.cpNameChangeHandler}
                className="form-control"
                name="cp_name"
              />
              <button className="btn btn-link" type="submit">
                Change
              </button>
            </form>
          )}
        </td>
      </tr>
    );
  }
}
