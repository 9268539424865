import { useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { EodModalDetails } from '../modal-detail';

import { FilterForm } from './filter-form';
import { EODTableRow } from './table-row';

export function EODReportTable(props) {
  const { data, payload } = props;
  const [prevSortKey, setPrevSortKey] = useState();
  const [reverseSort, setReverseSort] = useState(false);
  const [sortedData, setSortedData] = useState(
    data !== undefined ? data : undefined,
  );
  const [eodModalDetailsData, setEodModalDetailsData] = useState();

  if (data === undefined || data.length === 0) {
    return <h3>No data</h3>;
  }

  const onSort = (event, sortKey) => {
    event.preventDefault();
    if (data === undefined || data.length === 0) {
      return;
    }
    const newData = data;

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevSortKey) {
      reverse = !reverseSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setPrevSortKey(sortKey);
    setSortedData(newData);
    setReverseSort(reverse);

    try {
      document
        .getElementById(`column-${prevSortKey}`)
        .classList.remove('text-primary');
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
    try {
      document
        .getElementById(`column-${sortKey}`)
        .classList.add('text-primary');
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const onFilter = (filterConditions) => {
    const newData = [];
    /* eslint-disable */
    for (const d of data) {
      let allOk = true;
      for (const cond of filterConditions) {
        const val = d[cond.field];
        let compareVal = cond.value;
        if (typeof val === 'number') {
          compareVal = parseFloat(compareVal);
        }

        switch (cond.condition) {
          case '=':
            if (val !== compareVal) {
              allOk = false;
            }
            continue;
          case '!=':
            if (val === compareVal) {
              allOk = false;
            }
            continue;
          case '>':
            if (val <= compareVal) {
              allOk = false;
            }
            continue;
          case '<':
            if (val >= compareVal) {
              allOk = false;
            }
            continue;
          case 'like':
            if (
              !val
                .toString()
                .toLowerCase()
                .includes(compareVal.toString().toLowerCase())
            ) {
              allOk = false;
            }
        }
      }

      if (allOk) {
        newData.push(d);
      }
    }
    /* eslint-enable */
    setSortedData(newData);
  };

  const onFilterReset = () => {
    setSortedData(data);
  };

  const getExportURL = () => {
    let uri = '';
    /* eslint-disable */
    for (const [key, value] of Object.entries(payload)) {
      uri += `${key}=${value}&`;
    }
    /* eslint-enable */
    const api = new ReconTablesApiService();
    return api.exportEODResultsUrl(uri);
  };

  if (sortedData === undefined) {
    return null;
  }
  const rows = sortedData.map((entry) => (
    <EODTableRow
      entry={entry}
      setEodModalDetailsData={setEodModalDetailsData}
    />
  ));

  const filterOptions = [];
  Object.keys(data[0]).forEach((k) => {
    if (
      [
        'timestamp',
        'id',
        'comment',
        'details',
        'report_date',
        'rec_status',
      ].includes(k)
    ) {
      return;
    }
    filterOptions.push(
      <option key={k} value={k}>
        {k}
      </option>,
    );
  });

  return (
    <>
      <EodModalDetails data={eodModalDetailsData} />
      <FilterForm
        filterOptions={filterOptions}
        onFilter={onFilter}
        onFilterReset={onFilterReset}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mx-auto">
            <a
              className="btn btn-link"
              href={getExportURL()}
              target="_blank"
              rel="noreferrer"
            >
              CSV Export
            </a>
          </div>
        </div>
        <table className="table table-striped table-sm table-bordered text-small">
          <thead className="thead-light">
            <tr>
              <th
                onClick={(e) => onSort(e, 'legal_entity')}
                id="column-legal_entity"
                key="legal_entity"
              >
                Entity
                <i className="fa fa-fw fa-sort" />
              </th>
              <th>Report Date</th>
              <th onClick={(e) => onSort(e, 'cp')} id="column-cp" key="cp">
                Counterparty
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'bo_symbol')}
                id="column-bo_symbol"
                key="bo_symbol"
              >
                BO Symbol
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'cp_symbol')}
                id="column-cp_symbol"
                key="cp_symbol"
              >
                CP Symbol
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'bo_value')}
                id="column-bo_value"
                key="bo_value"
              >
                BO Value
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'cp_value')}
                id="column-cp_value"
                key="cp_value"
              >
                CP Value
                <i className="fa fa-fw fa-sort" />
              </th>
              <th>BO CCY</th>
              <th>CP CCY</th>
              <th
                onClick={(e) => onSort(e, 'bo_qty')}
                id="column-bo_qty"
                key="bo_qty"
              >
                BO Qty
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'cp_qty')}
                id="column-cp_qty"
                key="cp_qty"
              >
                CP Qty
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={(e) => onSort(e, 'diff_value')}
                id="column-diff_value"
                key="diff_value"
              >
                Difference
                <i className="fa fa-fw fa-sort" />
              </th>
              <th>Recon Status</th>
              <th>Details</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </>
  );
}
