import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from '../../services/ReconTablesApiService';
import { getFormPayload } from '../../services/utils';

export function FTPPathAddForm({ isBO, updateTable }) {
  const [loading, setLoading] = useState(false);
  const brokerIDs = useSelector((state) => state.counterParties);
  const [brokerOptions, setBrokerOptions] = useState([]);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }
    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key]}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);

  const onSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = getFormPayload(event.target, true);
    const api = new ReconTablesApiService();
    if (isBO) {
      api
        .postFtpPathBoDrive(payload)
        .then(() => {
          setLoading(false);
          updateTable(isBO, true);
        })
        .catch((e) => {
          setLoading(false);
          alert(e);
        });
    } else {
      api
        .postFtpPath(payload)
        .then(() => {
          setLoading(false);
          updateTable(isBO, true);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  if (loading) {
    return (
      <div className="container-fluid mb-3 mt-3">
        <Spin />
      </div>
    );
  }

  return (
    <div className="container-fluid mb-3 mt-3">
      <form onSubmit={onSubmit} name="FTPPathAddForm">
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <select className="custom-select mr-sm-2" name="cp">
                <option value="">Counterparty</option>
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Contains</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="contains"
                name="contains"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Ends With</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="end_with"
                name="end_with"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="type"
                name="type"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="path"
                name="path"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path #1</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="first_part_path"
                name="first_part_path"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path #2</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="second_part_path"
                name="second_part_path"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path #3</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="third_part_path"
                name="third_part_path"
              />
            </div>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="mx-auto">
            <button className="btn btn-outline-success" type="submit">
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
