import DayPickerInput from 'react-day-picker/DayPickerInput';

import { formatDate } from 'services/date-funcs';
import { getFormPayload } from 'services/utils';

export function RegulatoryTableRow(props) {
  const { entry } = props;

  const getStatusStyle = (value) => {
    let badge = <span>{value}</span>;
    if (value === 'Sent') {
      badge = <span className="badge badge-success ml-1">{value}</span>;
    } else {
      badge = <span className="badge badge-danger ml-1">{value} </span>;
    }
    return badge;
  };

  return (
    <tr key={entry.entity}>
      <td className="align-middle">{entry.report_date}</td>
      <td className="align-middle">{entry.entity}</td>
      <td className="align-middle">{getStatusStyle(entry.CFTC)}</td>
      <td className="align-middle">{getStatusStyle(entry.CFTC)}</td>
      <td className="align-middle">{getStatusStyle(entry.OCC)}</td>
      <td className="align-middle">{getStatusStyle(entry.MIFID)}</td>
    </tr>
  );
}

export function RegulatoryMonitorTable(props) {
  const { data, getData } = props;
  let rows = [];
  if (data === undefined || data.length === 0 || data.results === undefined) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    getData({ report_date: formatDate(currentDate) });
  }
  if (data !== undefined && data.length !== 0 && data.results !== undefined) {
    rows = data.results.map((e) => <RegulatoryTableRow entry={e} />);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    getData(payload);
  };

  return (
    <div className="container mb-3 mt-3">
      <div className="col mb-3 mt-3">
        <form onSubmit={onSubmit} name="searchFormReconcile">
          <div className="form-row justify-content-md-center">
            <div className="col-sm-auto">
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'report_date',
                }}
                value={data !== undefined ? data.report_date : null}
              />
            </div>
            <div className="col-sm-auto">
              <div className="mx-auto">
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="form-row justify-content-md-center">
        <div className="col-10">
          <table className="table table-striped table-sm table-bordered text-small">
            <thead>
              <tr key="thead">
                <th>Date</th>
                <th>Entity</th>
                <th>CFTC</th>
                <th>CME</th>
                <th>OCC</th>
                <th>MIFID</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="col-2">
          <table className="table table-striped table-sm table-bordered text-small">
            <thead>
              <tr key="thead">
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Empty</td>
                <td>Didn’t run for this date</td>
              </tr>
              <tr>
                <td>GS</td>
                <td>GS is uploaded to DB</td>
              </tr>
              <tr>
                <td>Limits</td>
                <td>Limits updated</td>
              </tr>
              <tr>
                <td>Generated</td>
                <td>Report generated</td>
              </tr>
              <tr>
                <td>Sent</td>
                <td>Files are sent</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
