import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from 'services/utils';

export function CPTradesForm({ onSubmit }) {
  const legalEntities = useSelector((state) => state.legalEntities);
  const counterParties = useSelector((state) => state.counterParties);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    onSubmit(payload);
  };

  const cpOptions = counterParties.map((cp) => (
    <option key={cp} value={cp}>
      {cp.toUpperCase()}
    </option>
  ));

  const leOptions = Object.entries(legalEntities).map(([key, value]) => (
    <option key={key} value={key}>
      {value.toUpperCase()}
    </option>
  ));

  return (
    <div className="container-fluid">
      <form onSubmit={onSearchSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="broker_id">
                <option key="any" value="">
                  Any
                </option>
                {cpOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select mr-sm-2" name="legal_entity">
                <option key="any" value="">
                  Any
                </option>
                {leOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Rec Status</div>
              </div>
              <select className="custom-select mr-sm-2" name="rec_status">
                <option key="any" value="">
                  Any
                </option>
                <option key="any" value="true">
                  True
                </option>
                <option key="any" value="false">
                  False
                </option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type of Trade</div>
              </div>
              <input
                type="text"
                className="form-control"
                name="type_of_trade"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="mx-auto">
            <button className="btn btn-outline-success" type="submit">
              Load
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
