import { Spin } from 'antd';
import { useState } from 'react';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import { PositionMappingForm } from './form';
import { PositionMappingTable } from './table';

export default function PositionMappingPage() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const searchUnMapped = (payload) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .searchUnmappedPositions(payload)
      .then((resp) => {
        setLoading(false);
        setSearchResults(resp);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <>
      <PositionMappingForm search={searchUnMapped} />
      {loading ? <Spin /> : <PositionMappingTable entries={searchResults} />}
    </>
  );
}
