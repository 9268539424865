import { FC } from 'react';
import { Button, Select, Input } from 'react-ui-kit-exante';

import { CategoryComment } from 'services/ReconTablesApiService';

interface Props {
  onCreateBreakCategoryComment: () => void;
  setComment: (val: string) => void;
  comment: string;
  setCategory: (val: number) => void;
  breakCategories: CategoryComment[];
}

export const BreakCategoryForm: FC<Props> = ({
  onCreateBreakCategoryComment,
  setComment,
  comment,
  setCategory,
  breakCategories,
}) => (
  <div className="row">
    <div className="col-lg-3 col-md-4">
      <Select
        label="Break Category"
        fullWidth
        onChange={(event) => setCategory(Number(event.target.value))}
        options={breakCategories.map((i) => ({ label: i.name, value: i.id }))}
      />
    </div>
    <div className="col-lg-3 col-md-4">
      <Input
        label="Comment"
        fullWidth
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
    </div>
    <div className="col-lg-3 col-md-4">
      <Button fullWidth onClick={onCreateBreakCategoryComment}>
        Update Selected
      </Button>
    </div>
  </div>
);
