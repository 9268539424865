import dayjs from 'dayjs';
import { ChangeEvent, FC, useMemo } from 'react';
import { DatePicker, Input, Checkbox } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { FilterValueType } from 'services/ReconEmirApiService';

interface Props {
  type: FilterValueType;
  fieldName: string;
  values: Record<string, string | boolean>;
  setValues: (values: Record<any, any>) => void;
}

export const EmirScriptItem: FC<Props> = ({
  type,
  fieldName,
  values,
  setValues,
}) => {
  const handleChangeDate = (date: unknown) => {
    const dateObject = dayjs(date as string);
    if (dateObject.isValid()) {
      setValues({ ...values, [fieldName]: dateObject.format(DATE_FORMAT) });
    }
  };
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };
  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [fieldName]: event.target.checked });
  };
  const value = useMemo(() => values[fieldName], [fieldName, values]);

  if (type === FilterValueType.DATE) {
    return (
      <DatePicker
        controlProps={{ fullWidth: true }}
        onChange={handleChangeDate}
        value={value as string}
        label={fieldName}
      />
    );
  }
  if (type === FilterValueType.STRING || type === FilterValueType.FLOAT) {
    return (
      <Input
        fullWidth
        onChange={handleChangeText}
        label={fieldName}
        value={value}
      />
    );
  }
  if (type === FilterValueType.BOOLEAN) {
    return <Checkbox label={fieldName} onChange={handleChangeCheckbox} />;
  }
  return null;
};
