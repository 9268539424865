import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from '../../services/utils';

export function PositionRecSearchForm({ searchFunc }) {
  const brokerIDs = useSelector((state) => state.counterParties);
  const [cpOptionsList, setCpOptionsList] = useState([]);

  useEffect(() => {
    const options = brokerIDs.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));
    setCpOptionsList(options);
  }, [brokerIDs]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    searchFunc(payload);
  };

  return (
    <div className="container-fluid mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchPositionRec">
        <div className="form-row mb-2">
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp"
                placeholder="Counterparty"
              >
                <option key="any" value="">
                  Any
                </option>
                {cpOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">BO Symbol</div>
              </div>
              <input
                name="bo_symbol"
                type="text"
                className="form-control"
                placeholder=""
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">CP Symbol</div>
              </div>
              <input
                name="cp_symbol"
                type="text"
                className="form-control"
                placeholder=""
              />
            </div>
          </div>
          <div className="col">
            <div className="mx-auto">
              <button className="btn btn-outline-success" type="submit">
                Load
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
