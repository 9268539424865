import { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from '../../services/utils';

export function BankReconcileForm(props) {
  const { onSearchSubmit } = props;
  const legalEntities = useSelector((state) => state.legalEntities);
  const currencies = useSelector((state) => state.currencies);
  const operationTypes = useSelector((state) => state.operationTypes);
  const [operationType, setOperationType] = useState('Cash Transfer');

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);

    if (
      payload.from_report_date === undefined ||
      payload.to_report_date === undefined
    ) {
      alert('From and To dates are required field for search');
      return;
    }
    onSearchSubmit(payload);
  };

  const onChangeOperationType = (e) => {
    setOperationType(e.target.value);
  };

  const leOptions = Object.entries(legalEntities).map(([key, value]) => (
    <option key={key} value={value}>
      {value.toUpperCase()}
    </option>
  ));

  const currenciesOptions = currencies.map((entry) => (
    <option key={entry} value={entry}>
      {entry}
    </option>
  ));

  const operationTypesOptions = operationTypes.map((entry) => (
    <option key={entry} value={entry}>
      {entry}
    </option>
  ));

  return (
    <div className="container mt-3 col-12">
      <form onSubmit={onSubmit} name="searchFormBankReconcile">
        <div className="form-row">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">From</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select',
                    name: 'from_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">To</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select',
                    name: 'to_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select" name="legal_entity">
                <option key="all" value="all">
                  ALL
                </option>
                {leOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Currency</div>
              </div>
              <select className="custom-select" name="ccy">
                <option key="all" value="all">
                  ALL
                </option>
                {currenciesOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">OperationType</div>
              </div>
              <select
                className="custom-select"
                name="operation_type"
                onChange={onChangeOperationType}
                value={operationType}
              >
                {operationTypesOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Account ID</div>
              </div>
              <input type="text" className="form-control" name="account_id" />
            </div>
          </div>

          <div className="mx-auto">
            <button className="btn btn-success" type="submit">
              Load Data
            </button>
          </div>
        </div>
      </form>
      <hr />
    </div>
  );
}
