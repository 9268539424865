import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';

import { getDayBack } from 'services/date-funcs';

export default class DayRangeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: getDayBack(140),
      to: new Date(),
    };
  }

  render() {
    const { from, to } = this.state;
    const { fromDate, toDate } = this.props;
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          inputProps={{
            className: 'custom-select mr-sm-2',
            autoComplete: 'off',
            name: fromDate,
          }}
        />{' '}
        -{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={(el) => {
              this.to = el;
            }}
            value={to}
            placeholder="To"
            format="LL"
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            inputProps={{
              className: 'custom-select mr-sm-2',
              autoComplete: 'off',
              name: toDate,
            }}
          />
        </span>
        <Helmet>
          <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}
