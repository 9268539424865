import { Spin } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { SearchForm } from './search-form';
import { TransactionReconcileTable } from './table';

export function DividendsCouponReconcile() {
  const api = new ReconTablesApiService();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [formPayload, setFormPayload] = useState();
  const mode = useSelector((state) => state.mode);

  const LoadSearchResults = (form_payload) => {
    setLoading(true);
    setFormPayload(form_payload);
    form_payload.mode = mode;
    api
      .getReconcileTransactions(form_payload, mode)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const ReUploadResults = () => {
    setLoading(true);
    formPayload.mode = mode;
    api
      .getReconcileTransactions(formPayload, mode)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const txReconcileRequest = (left_ids, right_ids) => {
    api
      .postReconcileTransactions({ cp_ids: left_ids, bo_ids: right_ids })
      .then((res) => {
        // eslint-disable-next-line
        console.log(res);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to reconcile: ${e}`);
      });
  };

  return (
    <>
      <h2>Dividends and coupons reconciliation</h2>
      <SearchForm search={LoadSearchResults} />
      {loading ? (
        <Spin />
      ) : (
        <TransactionReconcileTable
          results={results}
          txReconcileRequest={txReconcileRequest}
          reUploadResults={ReUploadResults}
        />
      )}
    </>
  );
}
