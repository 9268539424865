import { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import ReconRunnerApiService from 'services/ReconRunnerApiService';
import { formatDate } from 'services/date-funcs';
import { getFormPayload } from 'services/utils';

import ScriptRunNotifications from '../run-script-page/notifications';

export function CPStatusTableRow(props) {
  const { entry, reportDate, setScriptStatus } = props;
  const [actionType, setActionType] = useState();

  const onSelectActionType = (e) => {
    setActionType(e.target.value.toLowerCase());
  };

  const onReRunClick = (cp) => {
    const reconRunnerApi = new ReconRunnerApiService();
    reconRunnerApi
      .runByCPAndType(cp, actionType, reportDate)
      .then((json) => {
        setScriptStatus([json]);
      })
      .catch((e) => {
        alert(e);
        setScriptStatus([
          { status: 'error', message: "doesn't run", scripts: [cp] },
        ]);
      });
  };

  const getStatusStyle = (value) => {
    let badge = <span>{value}</span>;
    if (value === 'Done') {
      badge = <span className="badge badge-success ml-1">{value}</span>;
    } else {
      badge = <span className="badge badge-danger ml-1">{value} </span>;
    }
    return badge;
  };

  return (
    <tr key={entry.cp}>
      <td className="align-middle">{reportDate}</td>
      <td className="align-middle">{entry.cp}</td>
      <td className="align-middle">{entry.entity}</td>
      <td className="align-middle">{getStatusStyle(entry.trades)}</td>
      <td className="align-middle">{getStatusStyle(entry.cash)}</td>
      <td className="align-middle">{getStatusStyle(entry.positions)}</td>
      <td className="align-middle">{getStatusStyle(entry.interest)}</td>
      <td className="align-middle">
        <div className="row justify-content-md-center">
          <div className="col-mx-auto">
            <select
              id="inputState"
              className="form-control"
              onChange={onSelectActionType}
            >
              <option key="empty" />
              <option key="all">All</option>
              <option key="trades">Trades</option>
              <option key="cash">Cash</option>
              <option key="positions">Positions</option>
              <option key="recon">Recon</option>
              <option key="transaction-recon">Transaction Recon</option>
              <option key="transactions">Transactions</option>
            </select>
          </div>
          <div className="col-mx-auto ml-2 mt-1">
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => onReRunClick(entry.cp)}
            >
              rerun
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}

export function CPMonitorTable(props) {
  const { data, getData, entityFilter, cpTypeFilter, entityTypeFilter } = props;
  const [scriptStatus, setScriptStatus] = useState();
  const [filteredData, setFilteredData] = useState(
    data !== undefined ? data.results : undefined,
  );
  const [selectedEntityFilter, setSelectedEntityFilter] =
    useState(entityFilter);
  const [selectedCpTypeFilter, setSelectedCpTypeFilter] =
    useState(cpTypeFilter);

  const [selectedTypeFilter, setSelectedTypeFilter] =
    useState(entityTypeFilter);
  let entities = [];
  let cpType = [];
  let rows = [];
  let types = [];

  if (data === undefined || data.length === 0 || data.results === undefined) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    getData(
      { report_date: formatDate(currentDate) },
      selectedEntityFilter,
      selectedCpTypeFilter,
      selectedTypeFilter,
    );
  }
  if (data !== undefined && data.length !== 0 && data.results !== undefined) {
    const leList = [];
    const cpTypeList = [];
    const typeList = [];
    rows = filteredData.map((e) => {
      typeList.push(e.type);
      if (selectedEntityFilter === 'All' && selectedCpTypeFilter === 'All') {
        leList.push(e.entity);
        cpTypeList.push(e.cp_type);
        return (
          <CPStatusTableRow
            entry={e}
            reportDate={data.report_date}
            setScriptStatus={setScriptStatus}
          />
        );
      }

      if (
        e.entity.toUpperCase() === selectedEntityFilter &&
        selectedCpTypeFilter === 'All'
      ) {
        leList.push(e.entity);
        cpTypeList.push(e.cp_type);
        return (
          <CPStatusTableRow
            entry={e}
            reportDate={data.report_date}
            setScriptStatus={setScriptStatus}
          />
        );
      }
      if (
        e.cp_type.toUpperCase() === selectedCpTypeFilter &&
        selectedEntityFilter === 'All'
      ) {
        leList.push(e.entity);
        cpTypeList.push(e.cp_type);
        return (
          <CPStatusTableRow
            entry={e}
            reportDate={data.report_date}
            setScriptStatus={setScriptStatus}
          />
        );
      }
      if (
        e.cp_type.toUpperCase() === selectedCpTypeFilter &&
        e.entity.toUpperCase() === selectedEntityFilter
      ) {
        leList.push(e.entity);
        cpTypeList.push(e.cp_type);
        return (
          <CPStatusTableRow
            entry={e}
            reportDate={data.report_date}
            setScriptStatus={setScriptStatus}
          />
        );
      }
      return null;
    });
    entities = [...new Set(leList)];
    cpType = [...new Set(cpTypeList)];
    types = [...new Set(typeList)];
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    getData(payload, selectedEntityFilter, selectedCpTypeFilter);
  };

  const onFilterEntity = (filterCondition) => {
    let filtered = [];
    filteredData.forEach((row) => {
      if (row.entity.toUpperCase() === filterCondition) {
        filtered.push(row);
      }
    });
    if (filterCondition === 'All' && selectedCpTypeFilter !== 'All') {
      filtered = [];
      data.results.forEach((row) => {
        if (row.cp_type.toUpperCase() === selectedCpTypeFilter) {
          filtered.push(row);
        }
      });
    }
    if (filterCondition === 'All' && selectedCpTypeFilter === 'All') {
      filtered = data.results;
    }
    setFilteredData(filtered);
    setSelectedEntityFilter(filterCondition);
  };

  const onFilterType = (filterCondition) => {
    const filtered = [];
    filteredData.forEach((row) => {
      if (row.type === filterCondition) {
        filtered.push(row);
      }
    });
    setFilteredData(filtered);
    setSelectedTypeFilter(filterCondition);
  };

  const onFilterCpType = (filterCondition) => {
    let filtered = [];
    filteredData.forEach((row) => {
      if (row.cp_type.toUpperCase() === filterCondition) {
        filtered.push(row);
      }
    });
    if (filterCondition === 'All' && selectedEntityFilter !== 'All') {
      filtered = [];
      data.results.forEach((row) => {
        if (row.entity.toUpperCase() === selectedEntityFilter) {
          filtered.push(row);
        }
      });
    }
    if (filterCondition === 'All' && selectedEntityFilter === 'All') {
      filtered = data.results;
    }
    setFilteredData(filtered);
    setSelectedCpTypeFilter(filterCondition);
  };

  const entityFilterButtons = entities.map((e) => (
    <div className="col-mx-auto ml-2 mt-1">
      <button
        type="button"
        className="btn btn-sm btn-outline-success"
        onClick={() => onFilterEntity(e)}
      >
        {e}
      </button>
    </div>
  ));

  const typeFilterButtons = types.map((e) => (
    <div className="col-mx-auto ml-2 mt-1">
      <button
        type="button"
        className="btn btn-sm btn-outline-success"
        onClick={() => onFilterType(e)}
      >
        {e}
      </button>
    </div>
  ));

  const cpTypeFilterButtons = cpType.map((e) => (
    <div className="col-mx-auto ml-2 mt-1">
      <button
        type="button"
        className="btn btn-sm btn-outline-success"
        onClick={() => onFilterCpType(e)}
      >
        {e}
      </button>
    </div>
  ));

  return (
    <div className="container mb-3 mt-3">
      <div className="col mb-3 mt-3">
        <form onSubmit={onSubmit} name="searchFormReconcile">
          <div className="form-row justify-content-md-center">
            <div className="col-sm-auto">
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'report_date',
                  autoComplete: 'off',
                }}
                value={data !== undefined ? data.report_date : null}
              />
            </div>
            <div className="col-sm-auto">
              <div className="mx-auto">
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ScriptRunNotifications runResults={scriptStatus} />

      <div className="col-sm-auto mb-2">
        <div className="form-row justify-content-md-center">
          <span className="input-group-text">CP Type: </span>
          {cpTypeFilterButtons}
          <div className="col-mx-auto ml-2 mt-1">
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={() => onFilterCpType('All')}
            >
              RESET
            </button>
          </div>
        </div>
      </div>

      <div className="col-sm-auto mb-2">
        <div className="form-row justify-content-md-center">
          <span className="input-group-text">Legal Entity: </span>
          {entityFilterButtons}
          <div className="col-mx-auto ml-2 mt-1">
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={() => onFilterEntity('All')}
            >
              RESET
            </button>
          </div>
        </div>
      </div>

      <div className="col-sm-auto mb-2">
        <div className="form-row justify-content-md-center">
          <span className="input-group-text">Type: </span>
          {typeFilterButtons}
          <div className="col-mx-auto ml-2 mt-1">
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={() => onFilterEntity('All')}
            >
              RESET
            </button>
          </div>
        </div>
      </div>

      <div className="form-row justify-content-md-center">
        <div className="col-10">
          <table className="table table-striped table-sm table-bordered text-small">
            <thead>
              <tr key="thead">
                <th>Date</th>
                <th>CP</th>
                <th>Entity</th>
                <th>Trades</th>
                <th>Cash</th>
                <th>Positions</th>
                <th>Interest</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="col-2">
          <table className="table table-striped table-sm table-bordered text-small">
            <thead>
              <tr key="thead">
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Empty</td>
                <td>
                  Didn’t run for this date or not exist script for this type
                </td>
              </tr>
              <tr>
                <td>Started</td>
                <td>Run script (time of running)</td>
              </tr>
              <tr>
                <td>No data</td>
                <td>No data for parsing</td>
              </tr>
              <tr>
                <td>Parsed</td>
                <td>Parsing completed without exception</td>
              </tr>
              <tr>
                <td>Uploaded</td>
                <td>Data uploaded to DB</td>
              </tr>
              <tr>
                <td>ReconStarted</td>
                <td>Started Recon process</td>
              </tr>
              <tr>
                <td>Unreconciled</td>
                <td>Trades are not fully reconciled</td>
              </tr>
              <tr>
                <td>Done</td>
                <td>All OK</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
