import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Notification, Loader, PanelGroup, Panel } from 'react-ui-kit-exante';

import {
  ReconEmirApiService,
  Metrics as IMetrics,
} from 'services/ReconEmirApiService';

const api = new ReconEmirApiService();
export const Metrics: FC<{
  reportDate: string[];
}> = ({ reportDate }) => {
  const [metrics, setMetrics] = useState<IMetrics>({});
  const [fetchingReportData, setFetchingReportData] = useState<string[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await api.getDashboardInfo(reportDate);
      setMetrics(response.metrics);
      setFetchingReportData(response.report_date);
    } catch (e) {
      Notification.error(`Dashboard not loaded ${e}`);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (reportDate.length) {
      loadData();
    }
  }, [reportDate]);

  const metricSections = useMemo(
    () =>
      Object.keys(metrics).map((key) => {
        const items = Object.keys(metrics[key]);
        return {
          title: key,
          items:
            items
              ?.filter((item) => item !== 'total')
              .map((item) => {
                const value = metrics[key][item];
                return {
                  title: `${item}: ${value}`,
                  link: value
                    ? `/emir/${key}?status=${item}&created_date=${fetchingReportData}`
                    : null,
                };
              }) ?? [],
        };
      }),
    [metrics, fetchingReportData],
  );

  return isLoading ? (
    <div className="row justify-content-center my-2">
      <Loader />
    </div>
  ) : (
    <div className="row text-left">
      <PanelGroup equalWidth>
        {metricSections.map((i) => (
          <Panel key={i.title} title={i.title}>
            {i.items.map(
              (item) =>
                item.link && (
                  <Link
                    key={item.link}
                    target="_blank"
                    to={item.link}
                    className="nav-link p-0"
                  >
                    {item.title}
                  </Link>
                ),
            )}
          </Panel>
        ))}
      </PanelGroup>
    </div>
  );
};
