import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function PositionRecAddForm() {
  const [expanded, setExpanded] = useState(false);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const brokerIDs = useSelector((state) => state.counterParties);
  const [cpOptionsList, setCpOptionsList] = useState([]);

  useEffect(() => {
    const options = brokerIDs.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));
    setCpOptionsList(options);
  }, [brokerIDs]);

  useEffect(() => {
    const api = new ReconTablesApiService();
    api
      .getListPositionRecFields()
      .then((e) => {
        setFields(e);
      })
      .catch(() => alert('Failed to get metadata for position rec'));
  }, []);

  const onDisplayClick = () => {
    setExpanded(!expanded);
  };

  const onAddSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = getFormPayload(event.target, true);
    const api = new ReconTablesApiService();
    api
      .postPositionReconciliation(payload)
      .then(() => {
        alert('Saved');
        setExpanded(false);
        setLoading(false);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  const getForm = () => {
    const rows = [];
    let currRow = [];
    // eslint-disable-next-line
    for (const field of fields) {
      if (currRow.length > 3) {
        rows.push(currRow);
        currRow = [];
      }

      let input = null;
      switch (field.type) {
        case 'date':
          input = (
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">{field.name}</div>
                </div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: field.name,
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          );
          break;
        case 'bool':
          input = (
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Counterparty</div>
                </div>
                <select className="custom-select mr-sm-2" name={field.name}>
                  <option key="any" value="">
                    Not Set
                  </option>
                  <option key="any" value="true">
                    yes
                  </option>
                  <option key="any" value="false">
                    no
                  </option>
                </select>
              </div>
            </div>
          );
          break;
        default:
          if (field.name === 'cp') {
            input = (
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">{field.name}</div>
                  </div>
                  <select className="custom-select mr-sm-2" name={field.name}>
                    {cpOptionsList}
                  </select>
                </div>
              </div>
            );
          } else {
            input = (
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">{field.name}</div>
                  </div>
                  <input
                    name={field.name}
                    type={field.type}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            );
          }
      }
      currRow.push(input);
    }
    rows.push(currRow);

    return (
      <div className="card mt-2 bg-secondary">
        <div className="card-body">
          <form onSubmit={onAddSubmit} name="AddPositionRecForm">
            {rows.map((r) => (
              <div className="form-row mb-2">{r}</div>
            ))}
            <div className="form-row">
              <div className="mx-auto">
                <button className="btn btn-success" type="submit">
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <button className="btn btn-warning" onClick={onDisplayClick}>
        {expanded ? 'Hide' : 'Add New'}
      </button>
      {expanded && loading ? <Spinner /> : null}
      {expanded && !loading ? getForm() : null}
    </div>
  );
}
