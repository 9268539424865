import { UseTableCellProps } from 'react-table';
import { TableProps } from 'react-ui-kit-exante';

import { minColumnWidth, editColumnWidth } from 'constants/tables';
import {
  SIDE,
  TablesWithBreakReport,
  Transaction,
} from 'services/ReconTablesApiService';

import {
  getGeneralCheckbox,
  getCellCheckbox,
  getBreakReportCategoryCell,
  getBreakCommentCell,
  getUngroupCell,
  getColumnHeader,
} from '../ManualReconcileUnion/ColumnComponents/ColumnComponents';

import {
  IUpdateAction,
  getCpEditCell,
  getReportDateEditCell,
  getOperationTypeEditCell,
  getInputEditCell,
} from './ColumnComponents/ColumnComponents';
import { ModifyReconciledTransaction } from './types';

export const editTableValueMapping: Record<string, string> = {
  operation_type: 'operationtype',
  report_date: 'reportdate',
  cp_symbol: 'symbol',
};

const minWidths = {
  id: 60,
  side: 70,
  ccy: 80,
};

const minWidth = minColumnWidth;
export function getCpBoMixedTableColumns<
  T extends { id: number },
  B extends { id: number; name: string },
>(
  transactions: T[],
  selectedTransactionsIds: number[],
  onCheckTransaction: (item: T) => void,
  commentColumnWidth: number,
  onCheckAllTransactionOnPage: () => void,
  onUnCheckAllTransactionOnPage: () => void,
  breakCategories: B[],
  onUpdateBreakCategoryById: (
    recordTypesMapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => void,
  operationTypes: { id: number; name: string }[],
  cpList: string[],
  onUpdateTheirTransaction: IUpdateAction,
  onUpdateOurTransaction: IUpdateAction,
) {
  const columns: TableProps<Transaction>['columns'] = [
    {
      accessor: 'id',
      width: 70,
      // @ts-ignore
      disableSortBy: true,
      minWidth: minWidths.id,
      Header: () =>
        getGeneralCheckbox(
          transactions,
          selectedTransactionsIds,
          onCheckAllTransactionOnPage,
          onUnCheckAllTransactionOnPage,
        ),
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getCellCheckbox(
          row.values.id,
          transactions,
          selectedTransactionsIds,
          onCheckTransaction,
        ),
      defaultCanSort: false,
    },
    {
      Header: getColumnHeader('Side'),
      accessor: 'side',
      width: 90,
      minWidth: minWidths.side,
    },
    {
      Header: getColumnHeader('Report date'),
      accessor: 'reportdate',
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getReportDateEditCell(
          row,
          row.values.side === SIDE.THEIR
            ? onUpdateTheirTransaction
            : onUpdateOurTransaction,
        ),
      width: editColumnWidth,
      minWidth,
    },
    {
      Header: getColumnHeader('CP'),
      accessor: 'cp',
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getCpEditCell(row, cpList, onUpdateOurTransaction),
      width: editColumnWidth,
      minWidth,
    },
    {
      Header: getColumnHeader('Operation type'),
      accessor: 'operationtype',
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getOperationTypeEditCell(
          row,
          operationTypes,
          row.values.side === SIDE.THEIR
            ? onUpdateTheirTransaction
            : onUpdateOurTransaction,
        ),
      width: editColumnWidth,
      minWidth,
    },
    {
      Header: getColumnHeader('Amount'),
      accessor: 'amount',
      minWidth,
    },
    {
      Header: getColumnHeader('CCY'),
      accessor: 'ccy',
      width: 90,
      minWidth: minWidths.ccy,
    },
    {
      Header: getColumnHeader('Сlient id'),
      accessor: 'client_account_id',
      width: 100,
      minWidth,
    },
    {
      Header: getColumnHeader('Category'),
      accessor: 'category',
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        row.values.side === SIDE.OUR
          ? row.values.category
          : getInputEditCell({
              row,
              editFieldName: 'category',
              fieldName: 'category',
              onChangeAction: onUpdateTheirTransaction,
            }),
      width: editColumnWidth,
      minWidth,
    },
    {
      Header: getColumnHeader('Symbol'),
      accessor: 'symbol',
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        row.values.side === SIDE.OUR
          ? row.values.symbol
          : getInputEditCell({
              row,
              editFieldName: 'cp_symbol',
              fieldName: 'symbol',
              onChangeAction: onUpdateTheirTransaction,
            }),
      width: editColumnWidth,
      minWidth,
    },
    {
      Header: getColumnHeader('Symbol type'),
      accessor: 'symboltype',
      minWidth,
    },

    {
      Header: getColumnHeader('ISIN'),
      accessor: 'isin',
      minWidth,
    },
    {
      Header: getColumnHeader('Account'),
      accessor: 'account',
      minWidth,
    },
    {
      Header: getColumnHeader('Break category'),
      accessor: 'break_report_category',
      minWidth,
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getBreakReportCategoryCell(
          row.values.id,
          row.values.break_report_category,
          row.values.side,
          row.values.break_comment,
          breakCategories,
          onUpdateBreakCategoryById,
          TablesWithBreakReport.CPTRANSACTION,
          TablesWithBreakReport.FTBO,
        ),
    },
    {
      Header: getColumnHeader('Break comment'),
      accessor: 'break_comment',
      minWidth,
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getBreakCommentCell(
          row.values.id,
          row.values.break_report_category,
          row.values.side,
          row.values.break_comment,
          breakCategories,
          onUpdateBreakCategoryById,
          TablesWithBreakReport.CPTRANSACTION,
          TablesWithBreakReport.FTBO,
        ),
    },
    {
      Header: getColumnHeader('Comment'),
      accessor: 'comment',
      minWidth,
      width: 500,
    },
  ];
  return columns;
}

export function getReconciledTableColumns(onUngroup: (item: number) => void) {
  const columns: TableProps<ModifyReconciledTransaction>['columns'] = [
    {
      Header: '',
      accessor: 'id',
      width: 0,
    },
    {
      Header: 'Group id',
      accessor: 'group_id',
      minWidth,
      width: 260,
      Cell: ({ row }: UseTableCellProps<Transaction>) =>
        getUngroupCell(row.values.group_id, onUngroup),
    },
    {
      Header: 'Cp id',
      accessor: 'cp_id',
      minWidth,
    },
    {
      Header: 'Cp report date',
      accessor: 'cp_report_date',
      minWidth,
    },
    {
      Header: 'Cp ccy',
      accessor: 'cp_ccy',
      minWidth,
    },
    {
      Header: 'Cp amount',
      accessor: 'cp_value',
      minWidth,
    },
    {
      Header: 'Cp symbol',
      accessor: 'cp_symbol',
      minWidth,
    },
    {
      Header: 'Bo id',
      accessor: 'bo_id',
      minWidth,
    },
    {
      Header: 'Bo report date',
      accessor: 'bo_report_date',
      minWidth,
    },
    {
      Header: 'Bo symbol',
      accessor: 'bo_symbol',
      minWidth,
    },
    {
      Header: 'Bo ccy',
      accessor: 'bo_ccy',
      minWidth,
    },
    {
      Header: 'Bo amount',
      accessor: 'bo_amount',
      minWidth,
    },
    {
      Header: 'Symbol type',
      accessor: 'symbol_type',
      minWidth,
    },
    {
      Header: 'Isin',
      accessor: 'isin',
      minWidth,
    },
  ];
  return columns;
}
