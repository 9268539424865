import { Spin } from 'antd';
import { Component } from 'react';

import ReconTablesApiService from '../../../../services/ReconTablesApiService';

class SeriesMappingTableRow extends Component {
  // eslint-disable-next-line
  state = {
    loading: false,
  };

  onDelete = () => {
    this.setState({ loading: true });
    const { entry } = this.props;
    const apiService = new ReconTablesApiService();
    apiService
      .deleteShortMapping(entry.id)
      .then(() => {
        this.setState({ loading: false });
        this.props.onMappingChanged();
      })
      .catch((e) => {
        this.setState({ loading: false });
        alert(e);
      });
  };

  render() {
    const { entry } = this.props;
    return (
      <tr>
        <td>{entry.cp}</td>
        <td>{entry.cp_symbol}</td>
        <td>{entry.exante_symbol}</td>
        <td>{entry.plus_month}</td>
        <td>{entry.strike_mty}</td>
        <td>{entry.mty_qty}</td>
        <td>{entry.mty_price}</td>
        <td>{entry.mty_value}</td>
        <td>
          {this.state.loading ? (
            <Spin />
          ) : (
            <button className="btn btn-outline-danger" onClick={this.onDelete}>
              Remove
            </button>
          )}
        </td>
      </tr>
    );
  }
}

export default SeriesMappingTableRow;
