import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

const types = [];

function AddForm({ onSuccess, ccyList, transactionKindsList }) {
  const counterParties = useSelector((state) => state.counterParties);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(null);
  const valueValid = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = getFormPayload(event.target);
    payload.comment = 'added via interface';
    payload.source_file = 'recon-ui';
    payload.category = payload.category === '' ? null : payload.category;

    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .postCpTransaction(payload)
      .then((resp) => {
        setCreated(resp.full_id);
        setLoading(false);
        onSuccess({});
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  };

  const cpOptionsList = counterParties.map((id) => (
    <option key={id} value={id}>
      {id.toUpperCase()}
    </option>
  ));
  const ccyOptionsList = ccyList.map((ccy) => (
    <option
      key={ccy.name}
      value={ccy.name}
      selected={ccy.name.toUpperCase() === 'USD'}
    >
      {ccy.name.toUpperCase()}
    </option>
  ));
  return (
    <div className="container">
      <div className="card bg-secondary mt-2">
        <div className="card-body">
          <h4 className="card-title">Add Cp Transaction Entry</h4>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="cp">
                    {cpOptionsList}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Report Date</div>
                  </div>
                  <DayPickerInput
                    inputProps={{
                      className: 'custom-select mr-sm-2',
                      name: 'report_date',
                      autoComplete: 'off',
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Type</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name="operation_type"
                  >
                    {transactionKindsList.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Value Date</div>
                  </div>
                  <DayPickerInput
                    inputProps={{
                      className: 'custom-select mr-sm-2',
                      name: 'value_date',
                      autoComplete: 'off',
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CCY</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="asset">
                    {ccyOptionsList}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Amount</div>
                  </div>
                  <input type="text" className="form-control" name="sum" />
                </div>
              </div>
            </div>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Symbol Type</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="symbol_type"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Symbol ID</div>
                  </div>
                  <input type="text" className="form-control" name="symbol" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="mx-auto">
                {valueValid ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!valueValid}
                  >
                    Add
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? (
        <div className="mt-2">
          <Spin />
        </div>
      ) : null}
      {created ? (
        <div className="mt-2">
          <div className="alert alert-success" role="alert">
            Created cp transaction with full id = {created}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function FilterForm({ onFilter }) {
  const counterParties = useSelector((state) => state.counterParties);
  const cpOptionsList = [
    <option key="any" value="">
      Any
    </option>,
  ];

  counterParties.forEach((id) => {
    cpOptionsList.push(
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>,
    );
  });

  const typeOptionsList = [
    <option key="any" value="">
      Any
    </option>,
  ];
  types.forEach((id) => {
    typeOptionsList.push(
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>,
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    onFilter(payload);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">CP</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp">
                {cpOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Report Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'report_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <select className="custom-select mr-sm-2" name="operation_type">
                {typeOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Account</div>
              </div>
              <input type="text" className="form-control" name="account" />
            </div>
          </div>
          <div className="col-1">
            <button className="btn btn-primary" type="submit">
              Filter
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function TableRow({ entry, ccyList, onRowDeleted }) {
  const [modified, setModified] = useState(entry);
  const [initial, setInitial] = useState(entry);
  const [loading, setLoading] = useState(false);

  const onUpdate = () => {
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .patchCpTransaction(modified.full_id, modified)
      .then(() => {
        setInitial(modified);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .deleteCpTransaction(modified.full_id)
      .then(() => {
        setLoading(false);
        onRowDeleted();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onValueChange = (e) => {
    const newM = { ...modified };
    newM[e.target.name] = e.target.value;
    setModified(newM);
  };

  const dateChangeHandler = (date) => {
    const strDate = date.toISOString().slice(0, 10);
    const newM = { ...modified };
    newM.report_date = strDate;
    setModified(newM);
  };

  const counterParties = useSelector((state) => state.counterParties);
  const cpOptionsList = counterParties.map((id) => (
    <option key={id} value={id} selected={id === entry.cp}>
      {id.toUpperCase()}
    </option>
  ));
  const typeOptionsList = types.map((id) => (
    <option key={id} value={id} selected={id === entry.operation_type}>
      {id.toUpperCase()}
    </option>
  ));
  // prettier-ignore
  const ccyOptionsList = ccyList.map((ccy) => (
       <option key={ccy.name} value={ccy.name} selected={entry.asset === ccy.name}>{ccy.name.toUpperCase()}</option>
  ));

  const isModified = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const p of Object.keys(entry)) {
      if (modified[p] !== initial[p]) {
        return true;
      }
    }
    return false;
  };

  const updateButton = (
    <button className="btn btn-primary" onClick={onUpdate}>
      Update
    </button>
  );

  return (
    <tr
      className={isModified() ? 'bg-success' : ''}
      key={`${entry.report_date}__${entry.account}__${entry.value}`}
    >
      <td>
        <select
          className="custom-select mr-sm-2"
          name="cp"
          onChange={onValueChange}
        >
          {cpOptionsList}
        </select>
      </td>
      <td>
        <DayPickerInput
          inputProps={{
            className: 'custom-select mr-sm-2',
            name: 'report_date',
            autoComplete: 'off',
          }}
          value={entry.report_date}
          onDayChange={dateChangeHandler}
        />
      </td>
      <td>
        <select
          className="custom-select mr-sm-2"
          name="operation_type"
          onChange={onValueChange}
        >
          {typeOptionsList}
        </select>
      </td>
      <td>
        <select
          className="custom-select mr-sm-2"
          name="asset"
          onChange={onValueChange}
        >
          {ccyOptionsList}
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          name="sum"
          defaultValue={entry.sum}
          onChange={onValueChange}
        />
      </td>
      <td>
        <input
          defaultValue={entry.symbol_type}
          className="form-control"
          name="symbol_type"
          onChange={onValueChange}
        />
      </td>
      <td>
        <input
          type="text"
          defaultValue={entry.symbol}
          className="form-control"
          name="symbol"
          onChange={onValueChange}
        />
      </td>
      <td>{loading ? <Spin /> : updateButton}</td>
      <td>
        {loading ? (
          <Spin />
        ) : (
          <button className="btn btn-danger" onClick={onDelete}>
            Delete
          </button>
        )}
      </td>
    </tr>
  );
}

function Table({ data, ccyList, refresh }) {
  if (data === undefined) {
    return null;
  }
  const rows = data.map((d) => (
    <TableRow
      key={d.full_id}
      entry={d}
      ccyList={ccyList}
      onRowDeleted={refresh}
    />
  ));

  return (
    <div className="container-fluid">
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>CP</th>
            <th>Report Date</th>
            <th>Type</th>
            <th>CCY</th>
            <th>Amount</th>
            <th>Symbol Type</th>
            <th>Symbol ID</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default function CpTransactionPage() {
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [ccyList, setCcyList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [transactionKindsList, setTransactionTypes] = useState([]);

  const loadResults = (filterPayload) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    filterPayload.source_file = 'recon-ui';
    api
      .getListCpTransaction(filterPayload)
      .then((response) => {
        setFilteredResults(response.results);
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilter = (filterPayload) => {
    setActiveFilter(filterPayload);
    loadResults(filterPayload);
  };

  const onUpdate = () => {
    loadResults(activeFilter);
  };

  useEffect(() => {
    const api = new ReconTablesApiService();

    api.getListCurrency().then((res) => {
      setCcyList(res.results);
    });
    api.getListBOEntityLegalName().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.name} value={r.id_in_ctrades}>
          {r.name}
        </option>
      ));
      setEntityList(options);
    });
    fetch(
      // eslint-disable-next-line no-underscore-dangle
      `${api._apiBase}tables/transaction-operation-types/?for_page=transactions-reconcile`,
    )
      .then((response) => response.json())
      .then((data) => {
        setTransactionTypes(data.results);
        for (let i = 0; i < data.results.length; i += 1) {
          types.push(data.results[i].name);
        }
      });
    onUpdate();
  }, []);

  const onAddSuccess = () => {
    loadResults(activeFilter);
  };

  return (
    <>
      <AddForm
        onSuccess={onAddSuccess}
        ccyList={ccyList}
        entityList={entityList}
        transactionKindsList={transactionKindsList}
      />
      <hr />
      <FilterForm onFilter={onFilter} />
      {loading ? (
        <Spin />
      ) : (
        <Table data={filteredResults} ccyList={ccyList} refresh={onUpdate} />
      )}
    </>
  );
}
