import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { ValueMappingAddForm } from './add-form';
import { ResultsTable } from './table';

export default function ValueMappingRulesPage() {
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [active, setActive] = useState(true);
  const [filterData, setFilterData] = useState('All');
  const [filters, setFilters] = useState([]);

  const loadRules = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListValueMappingRuleWithConditions({})
      .then((res) => {
        setLoading(false);
        const fil = [];
        /* eslint-disable */
        for (const variable in res) {
          fil.push(res[variable].cp_id);
        }
        /* eslint-enable */
        fil.push('All');
        const options = fil
          .filter((item, pos) => fil.indexOf(item) === pos)
          .map((r) => {
            if (r === 'All') {
              return (
                <option key={r} value={r} selected={r}>
                  {r}
                </option>
              );
            }
            return (
              <option key={r} value={r}>
                {r}
              </option>
            );
          });
        setFilters(options);
        setRules(res);
      })
      .catch(() => {
        setRules([]);
      });
  };

  useEffect(() => {
    loadRules();
  }, []);

  const onAddSubmit = () => {
    loadRules();
  };

  const onChange = (e) => {
    const cp = e.target.value;
    setFilterData(cp);
  };

  const onSelected = (e) => {
    setActive(e.target.checked);
  };

  return (
    <>
      <ValueMappingAddForm successCallback={onAddSubmit} />
      Show not active elements{' '}
      <input type="checkbox" onChange={onSelected} checked={active} />
      Filters{' '}
      <select
        className="custom-select mr-sm-2"
        name="filters"
        onChange={onChange}
      >
        {filters}
      </select>
      {loading ? (
        <Spin />
      ) : (
        <ResultsTable results={rules} active={active} entity={filterData} />
      )}
    </>
  );
}
