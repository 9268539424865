import { FC, useCallback, useMemo } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import {
  MifidApiService,
  ReportsTable,
  Report,
} from 'services/MifidApiService';
import { calculateCountOfPages } from 'utils';

import { getReportsTableColumns } from './utils';

const api = new MifidApiService();

export const MifidReportsPage: FC = () => {
  const getReports = useCallback(
    (props: IOnFetchArguments) => api.getReports(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getReportsTableColumns({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Report)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReports },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getReports],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<ReportsTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const columns = useMemo(
    () =>
      getReportsTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [setFilter, removeFilter],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );
  // TODO: Use for mifid mui styles
  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12">
          <Table
            tableId="mifidReportsTable"
            data={data?.reports ?? []}
            columns={columns}
            filteringProps={filteringProps}
            defaultSortBy={defaultOrdering}
            isLoading={isLoading}
            hasFilters
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            isFlexLayout
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
      </div>
    </div>
  );
};
