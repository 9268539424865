import './navbar.css';
import { Link } from 'react-router-dom';

const hostname = window.location.hostname;
const isStage =
  hostname === 'recon9.test.zorg.sh' ||
  hostname === 'localhost' ||
  hostname === 'recon-stage.exante.eu';

const NavBar = () => (
  <nav className="navbar navbar-expand-sm bg-dark navbar-dark bg-nva-black">
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/" className="nav-link">
          Scripts status
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/run-info" className="nav-link">
          Run info
        </Link>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Monitors
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/cp-monitor" className="dropdown-item">
            CP Monitor
          </Link>
          <Link to="/regulatory-monitor" className="dropdown-item">
            Regulatory Monitor
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Run script
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/run/trade-recon" className="dropdown-item">
            Trade Recon
          </Link>
          <Link to="/run/load-trades" className="dropdown-item">
            Load Trades
          </Link>
          <Link to="/run/crypto-position-recon" className="dropdown-item">
            Crypto Positions
          </Link>
          <Link to="/run/crypto-balance-recon" className="dropdown-item">
            Crypto Balance
          </Link>
          <Link to="/run/crypto-transactions" className="dropdown-item">
            Crypto Transactions
          </Link>
          <Link to="/run/large-trades" className="dropdown-item">
            Large Trades
          </Link>
          <Link to="/run/vm" className="dropdown-item">
            VM
          </Link>
          <Link to="/run/views" className="dropdown-item">
            SQL Scripts
          </Link>
          <Link to="/run/misc" className="dropdown-item">
            Misc
          </Link>
          <Link to="/run/reporting" className="dropdown-item">
            Reporting
          </Link>
          <Link to="/order-status" className="dropdown-item">
            Order Status
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Bank reconciliation
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/bank-transaction-reconcile" className="dropdown-item">
            Transaction reconcile
          </Link>
          <Link to="/bank-cash-transfer-reconcile" className="dropdown-item">
            Cash Transfer reconcile
          </Link>
        </div>
      </li>

      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Cash recon
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/transactions-reconcile" className="dropdown-item">
            Transactions recon
          </Link>
          <Link to="/transactions-reconcile-union" className="dropdown-item">
            Transactions recon (union view)
          </Link>
          <Link to="/dividends-coupons-reconcile" className="dropdown-item">
            Dividends and coupons reconciliation
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Trade reconciliation
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/edit/tables" className="dropdown-item">
            Edit table (ctrades)
          </Link>
          <Link to="/edit/cptrades" className="dropdown-item">
            Edit table (cptrades)
          </Link>
          <Link to="/edit/mapping" className="dropdown-item">
            Simple Mapping
          </Link>
          <Link to="/edit/series-mapping" className="dropdown-item">
            Series Mapping
          </Link>
          <Link to="/manual-unreconcile" className="dropdown-item">
            Manual Unreconcile
          </Link>
          <Link to="/manual-reconcile" className="dropdown-item">
            Manual Reconcile
          </Link>
          <Link to="/manual-reconcile-union" className="dropdown-item">
            Manual Reconcile (union view)
          </Link>
          <Link to="/auto-reconcile" className="dropdown-item">
            Auto Reconcile
          </Link>
          <Link to="/eod-statement" className="dropdown-item">
            Report
          </Link>
          <Link to="/trade-recon-dashboard" className="dropdown-item">
            Dashboard
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Position reconciliation
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/positions-diff" className="dropdown-item">
            Positions Diff
          </Link>
          <Link to="/edit/positionrec" className="dropdown-item">
            Edit PositionRec
          </Link>
          <Link to="/position-mapping" className="dropdown-item">
            Position Mapping
          </Link>
          <Link to="/xle-total" className="dropdown-item">
            XLE Total Report
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Metabase
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/metabase/liquidity-monitor" className="dropdown-item">
            Liquidity Monitor
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Manual Add
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/row-balance/add" className="dropdown-item">
            Row Balance
          </Link>
          <Link to="/cp-transaction/add" className="dropdown-item">
            CP Transaction
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Settings
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/cp-cot" className="dropdown-item">
            COT by CP
          </Link>
          <Link to="/symbol-cot" className="dropdown-item">
            COT by Symbol
          </Link>
          <Link to="/ftp-path" className="dropdown-item">
            FTP Path
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Mapping
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/mapping/auto-mapping-rules" className="dropdown-item">
            Auto Mapping Rules
          </Link>
          <Link to="/mapping/value-mapping-rules" className="dropdown-item">
            Value Mapping Rules
          </Link>
          <Link to="/mapping/new-report" className="dropdown-item">
            New Report
          </Link>
          <Link to="/mapping/reports" className="dropdown-item">
            Reports
          </Link>
          <Link to="/mapping/new-report-excel" className="dropdown-item">
            New Excel Report
          </Link>
          <Link to="/mapping/excel-report" className="dropdown-item">
            Excel Reports
          </Link>
          <Link to="/mapping/cps" className="dropdown-item">
            Counterparty
          </Link>
          <Link to="/mapping/new-cp" className="dropdown-item">
            New Counterparty
          </Link>
          <Link to="/mapping/unpivot" className="dropdown-item">
            Unpivot Reports
          </Link>
          <Link to="/mapping/new-unpivot" className="dropdown-item">
            New Unpivot Report
          </Link>
        </div>
      </li>
      <li className="nav-item">
        <Link to="/raw-data" className="nav-link">
          Raw data
        </Link>
      </li>
      {isStage && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Workflow Engine
          </a>
          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <Link to="/wfe/approvals" className="dropdown-item">
              Approvals
            </Link>
          </div>
        </li>
      )}
      {isStage && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Emir
          </a>
          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <Link to="/emir/trades" className="dropdown-item">
              Trades
            </Link>
            <Link to="/emir/positions" className="dropdown-item">
              Positions
            </Link>
            <Link to="/emir/scripts" className="dropdown-item">
              Scripts
            </Link>
          </div>
        </li>
      )}
      {isStage && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Mifid
          </a>
          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <Link to="/mifid/reports" className="dropdown-item">
              Reports
            </Link>
          </div>
        </li>
      )}
    </ul>
  </nav>
);
export default NavBar;
