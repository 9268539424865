import { Component } from 'react';
import { Loader, NotificationProvider } from 'react-ui-kit-exante';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import ManualUnreconcileForm from './form';
import ManualUnreconcileTable from './table';

export default class ManualInreconcilePage extends Component {
  // eslint-disable-next-line
  state = {
    entries: [],
    loading: false,
  };

  onSearchSubmit = (payload) => {
    if (!Object.keys(payload).length) {
      alert('At least one filter should be applied. Search rejected.');
      return;
    }
    this.setState({ loading: true, entries: [] });

    const api = new ReconTablesApiService();
    api
      .searchTradesUnreconcile(payload)
      .then((result) => {
        this.setState({
          loading: false,
          entries: result,
        });
      })
      .catch((e) => {
        alert(e);
        this.setState({ loading: false });
      });
  };

  render() {
    let displayResults = (
      <ManualUnreconcileTable entries={this.state.entries} />
    );
    if (this.state.entries.length === 0) {
      displayResults = <h2>Nothing was found...</h2>;
    }
    if (this.state.loading) {
      displayResults = <Loader />;
    }
    return (
      <>
        <NotificationProvider />
        <ManualUnreconcileForm onSubmit={this.onSearchSubmit} />
        {displayResults}
      </>
    );
  }
}
