import { FC } from 'react';

import './ErrorIndicator.css';
import icon from 'assets/i/death-star.png';

export const ErrorIndicator: FC = () => (
  <div className="container-fluid">
    <div className="error-indicator">
      <img src={icon} alt="error icon" />
      <span className="boom">BOOM!</span>
      <span>Something has gone terribly wrong</span>
      <span>Please reload a page and try again</span>
    </div>
  </div>
);
