import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';
import { Table } from 'react-ui-kit-exante';

import { useSortedRows } from 'hooks/useSortedRows';
import ReconRunnerApiService from 'services/ReconRunnerApiService';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { getReconciledTableColumns } from '../UnionTransactionsReconcile/UnionTransactionsReconcileUtils';
import ScriptRunNotifications from '../run-script-page/notifications';

import { BOTableRow } from './bo-table-row';
import { CpTableRow } from './cp-table-row';

export function TransactionReconcileTable(props) {
  const { results, txReconcileRequest, reUploadResults } = props;
  const [isinFilter, setIsinFilter] = useState();
  const [selectedValueDelta, setSelectedValueDelta] = useState(0);
  const [scriptStatus, setScriptStatus] = useState();
  const [reportDateScript, setReportDateScript] = useState();

  const [leftSelected, updateLeftSelected] = useState({});
  const [rightSelected, updateRightSelected] = useState({});
  const [leftSelectAll, setLeftSelectAll] = useState(false);
  const [rightSelectAll, setRightSelectAll] = useState(false);

  const [leftSortedRows, setLeftSortedRows] = useState([]);
  const [prevLeftSortKey, setLeftPrevSortKey] = useState();
  const [reverseLeftSort, setLeftReverseSort] = useState(false);

  const [rightSortedRows, setRightSortedRows] = useState([]);
  const [prevRightSortKey, setRightPrevSortKey] = useState();
  const [reverseRightSort, setRightReverseSort] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reconciledSortedRows, setReconciledSortedRows] = useState([]);
  // const [prevReconciledSortKey, setReconciledPrevSortKey] = useState();
  // const [reverseReconciledSort, setReconciledReverseSort] = useState(false);
  const [pageNumberRight, setPageNumberRight] = useState(0);
  const [pageNumberLeft, setPageNumberLeft] = useState(0);
  const formPayload = useSelector((state) => state.formPayload);
  const mode = useSelector((state) => state.mode);
  const [isFilled, setIsFilled] = useState(false);
  const [leftRowsTitle, setLeftRowsTitle] = useState('');
  const [rightRowsTitle, setRightRowsTitle] = useState('');

  useEffect(() => {
    if (results !== undefined) {
      if (results.results.left_side) {
        setLeftSortedRows(results.results.left_side);
      }
      if (results.results.right_side) {
        setRightSortedRows(results.results.right_side);
      }
      if (results.results.reconciled) {
        setReconciledSortedRows(results.results.reconciled);
      }
      setIsFilled(true);
    }
    if (mode === 'cp-vs-bo') {
      setRightRowsTitle('BO Transactions');
      setLeftRowsTitle('Counterparty');
    }
    if (mode === 'bo-vs-bo') {
      setRightRowsTitle('BO Transactions');
      setLeftRowsTitle('BO Transactions');
    }
    if (mode === 'cp-vs-cp') {
      setLeftRowsTitle('Counterparty');
      setRightRowsTitle('Counterparty');
    }
  }, [results]);

  let leftRows;
  let rightRows;

  useSortedRows(
    formPayload,
    pageNumberLeft,
    'left',
    leftSortedRows,
    setLeftSortedRows,
    mode,
    isFilled,
  );
  useSortedRows(
    formPayload,
    pageNumberRight,
    'right',
    rightSortedRows,
    setRightSortedRows,
    mode,
    isFilled,
  );

  const handleUnGroup = (group_id) => {
    const api = new ReconTablesApiService();
    api
      .deleteReconcileTransactions({ group_id })
      .then((res) => {
        // eslint-disable-next-line
        alert(`Status: ${res.status}`);
        reUploadResults();
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(`Failed to reconcile: ${e}`);
      });
  };

  const onReconcile = () => {
    const leftSelectedArray = [];
    const rightSelectedArray = [];

    /* eslint-disable */
    for (const [key, value] of Object.entries(leftSelected)) {
      if (value.status === true) {
        if (mode === 'bo-vs-bo') {
          leftSelectedArray.push(key);
        } else {
          rightSelectedArray.push(key);
        }
      }
    }

    for (const [key, value] of Object.entries(rightSelected)) {
      if (value.status === true) {
        if (mode === 'cp-vs-cp') {
          leftSelectedArray.push(key);
        } else {
          rightSelectedArray.push(key);
        }
      }
    }
    /* eslint-enable */

    const arraySum = [];
    leftSortedRows.forEach((entry) => {
      if (leftSelectedArray.includes(entry.id.toString())) {
        arraySum.push(entry.amount);
      }
    });
    rightSortedRows.forEach((entry) => {
      if (rightSelectedArray.includes(entry.id.toString())) {
        arraySum.push(entry.amount);
      }
    });
    const sumTxs = arraySum.reduce((a, b) => Math.abs(a) - Math.abs(b), 0);
    if (Math.abs(sumTxs) > 0.0001) {
      if (
        // eslint-disable-next-line
        window.confirm(
          `Wrong sum transactions: ${sumTxs}\nDo you want to reconcile them?`,
        )
      ) {
        txReconcileRequest(leftSelectedArray, rightSelectedArray);
        // eslint-disable-next-line
        alert('Status: ok');
        reUploadResults();
      }
    } else {
      txReconcileRequest(leftSelectedArray, rightSelectedArray);
      // eslint-disable-next-line
      alert('Status: ok');
      reUploadResults();
    }
  };

  const onSelectAll = (value) => {
    setLeftSelectAll(value);
    setRightSelectAll(value);
  };

  const onLeftSelectAll = () => {
    setLeftSelectAll(!leftSelectAll);
  };

  const onRightSelectAll = () => {
    setRightSelectAll(!rightSelectAll);
  };
  const setLeftSelected = (id, status, selectValue) => {
    const selected = {
      ...leftSelected,
      [id]: { status, value: selectValue },
    };
    let leftSums = 0;
    let rightSums = 0;

    /* eslint-disable */
    for (const [, value] of Object.entries(selected)) {
      if (value.status === true) {
        leftSums += value.value;
      }
    }

    for (const [, value] of Object.entries(rightSelected)) {
      if (value.status === true) {
        rightSums += value.value;
      }
    }
    /* eslint-enable */

    setSelectedValueDelta(leftSums - rightSums);
    updateLeftSelected((tempSelected) => ({
      ...tempSelected,
      [id]: { status, value: selectValue },
    }));
  };

  const setRightSelected = (id, status, value) => {
    const selected = {
      ...rightSelected,
      [id]: { status, value },
    };
    let leftSums = 0;
    let rightSums = 0;

    /* eslint-disable */
    for (const [, value] of Object.entries(selected)) {
      if (value.status === true) {
        rightSums += value.value;
      }
    }

    for (const [, value] of Object.entries(leftSelected)) {
      if (value.status === true) {
        leftSums += value.value;
      }
    }
    /* eslint-enable */

    setSelectedValueDelta(leftSums - rightSums);
    updateRightSelected((tempSelected) => ({
      ...tempSelected,
      [id]: { status, value },
    }));
  };

  const onRightSort = (event, sortKey) => {
    event.preventDefault();
    const newData = [...rightSortedRows];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevRightSortKey) {
      reverse = !reverseRightSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setRightPrevSortKey(sortKey);
    setRightSortedRows(newData);
    setRightReverseSort(reverse);

    document
      .getElementById(`column-${prevRightSortKey}`)
      .classList.remove('text-primary');
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');
  };

  const getLeftSide = () => {
    leftRows = leftSortedRows.map((entry) => (
      <CpTableRow
        entry={entry}
        selectAll={leftSelectAll}
        setSelectedRows={
          mode === 'bo-vs-bo' ? setRightSelected : setLeftSelected
        }
        reUploadResults={reUploadResults}
      />
    ));

    const onLeftSort = (event, sortKey) => {
      event.preventDefault();
      const newData = [...leftSortedRows];
      if (newData.length === 0) {
        return;
      }

      if (typeof newData[0][sortKey] === 'string') {
        newData.sort((a, b) => {
          const v1 = a[sortKey] !== null ? a[sortKey] : '';
          const v2 = b[sortKey] !== null ? b[sortKey] : '';
          return v1.localeCompare(v2);
        });
      } else {
        newData.sort((a, b) => a[sortKey] - b[sortKey]);
      }

      let reverse = false;
      if (sortKey === prevLeftSortKey) {
        reverse = !reverseLeftSort;
      }
      if (reverse) {
        newData.reverse();
      }

      setLeftPrevSortKey(sortKey);
      setLeftSortedRows(newData);
      setLeftReverseSort(reverse);

      document
        .getElementById(`column-${prevLeftSortKey}`)
        .classList.remove('text-primary');
      document
        .getElementById(`column-${sortKey}`)
        .classList.add('text-primary');
    };

    return (
      <div className="col">
        <h3>{leftRowsTitle}</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20px',
          }}
        >
          <button
            className="btn btn-primary"
            disabled={pageNumberLeft === 0}
            onClick={() => {
              if (pageNumberLeft > 0) {
                setPageNumberLeft(pageNumberLeft - 1);
              }
            }}
          >
            {' '}
            &lt;{' '}
          </button>
          {leftRows.length === 500 ? (
            <p
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '16px',
                margin: '0',
              }}
            >
              {pageNumberLeft + 1}
            </p>
          ) : undefined}
          <button
            className="btn btn-primary"
            disabled={leftRows.length !== 500}
            onClick={() => {
              setPageNumberLeft(pageNumberLeft + 1);
            }}
          >
            {' '}
            &gt;{' '}
          </button>
        </div>
        {pageNumberLeft * 500 + 1} - {pageNumberLeft * 500 + leftRows.length}
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onLeftSelectAll}
                    checked={leftSelectAll}
                  />
                </th>
                <th>report_date</th>
                <th
                  onClick={(e) => onLeftSort(e, 'cp')}
                  id={`
                column - sum`}
                  key="cp"
                >
                  cp
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onLeftSort(e, 'operationtype')}
                  id={`
                column - sum`}
                  key="operationtype"
                >
                  operationtype
                  <i className="fa fa-fw fa-sort" />
                </th>
                {window.location.pathname ===
                '/dividends-coupons-reconcile' ? undefined : (
                  <th
                    onClick={(e) => onLeftSort(e, 'symboltype')}
                    id={`
                column - sum`}
                    key="symboltype"
                  >
                    symboltype
                    <i className="fa fa-fw fa-sort" />
                  </th>
                )}
                <th
                  onClick={(e) => onLeftSort(e, 'ccy')}
                  id={`
                column - sum`}
                  key="ccy"
                >
                  ccy
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onLeftSort(e, 'amount')}
                  id={`
                column - sum`}
                  key="amount"
                >
                  amount
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onLeftSort(e, 'symbol')}
                  id={`
                column - sum`}
                  key="symbol"
                >
                  symbol
                  <i className="fa fa-fw fa-sort" />
                </th>
                {window.location.pathname === '/dividends-coupons-reconcile' ? (
                  <th
                    onClick={(e) => onLeftSort(e, 'isin')}
                    id={`
                column - sum`}
                    key="isin"
                  >
                    isin
                    <i className="fa fa-fw fa-sort" />
                  </th>
                ) : undefined}
                {window.location.pathname === '/dividends-coupons-reconcile' ? (
                  <th
                    onClick={(e) => onLeftSort(e, 'account')}
                    id={`
                column - sum`}
                    key="account"
                  >
                    account
                    <i className="fa fa-fw fa-sort" />
                  </th>
                ) : undefined}
              </tr>
            </thead>
            <tbody>{leftRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const getRightSide = () => {
    rightRows = rightSortedRows.map((entry) => (
      <BOTableRow
        entry={entry}
        selectAll={rightSelectAll}
        setSelectedRows={
          mode === 'cp-vs-cp' ? setLeftSelected : setRightSelected
        }
        reUploadResults={reUploadResults}
      />
    ));

    return (
      <div className="col">
        <h3>{rightRowsTitle}</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20px',
          }}
        >
          <button
            className="btn btn-primary"
            disabled={pageNumberRight === 0}
            onClick={() => {
              if (pageNumberRight > 0) {
                setPageNumberRight(pageNumberRight - 1);
              }
            }}
          >
            {' '}
            &lt;{' '}
          </button>
          {rightRows.length === 500 ? (
            <p
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '16px',
                margin: '0',
              }}
            >
              {pageNumberRight + 1}
            </p>
          ) : undefined}
          <button
            className="btn btn-primary"
            disabled={rightRows.length !== 500}
            onClick={() => {
              setPageNumberRight(pageNumberRight + 1);
            }}
          >
            {' '}
            &gt;{' '}
          </button>
        </div>
        {pageNumberRight * 500 + 1} - {pageNumberRight * 500 + rightRows.length}
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onRightSelectAll}
                    checked={rightSelectAll}
                  />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'report_date')}
                  id={`
                column - sum`}
                  key="report_date"
                >
                  report_date
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'cp')}
                  id={`
                column - sum`}
                  key="cp"
                >
                  cp
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'operationtype')}
                  id={`
                column - sum`}
                  key="operationtype"
                >
                  operationtype
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'symboltype')}
                  id={`
                column - sum`}
                  key="symboltype"
                >
                  symboltype
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'ccy')}
                  id={`
                column - sum`}
                  key="ccy"
                >
                  ccy
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'amount')}
                  id={`
                column - sum`}
                  key="amount"
                >
                  amount
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th
                  onClick={(e) => onRightSort(e, 'symbol')}
                  id={`
                column - sum`}
                  key="symbol"
                >
                  symbol
                  <i className="fa fa-fw fa-sort" />
                </th>
              </tr>
            </thead>
            <tbody>{rightRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const getReconciled = () => (
    <div className="col">
      <h3>Reconciled transactions</h3>
      <Table
        hasPagination
        showTableInfo
        showScrollbar
        columns={getReconciledTableColumns(handleUnGroup)}
        data={reconciledSortedRows.map((item) => ({
          report_date: item.report_date,
          cp_full_id: item.cp_full_id,
          bo_uid: item.bo_id,
          group_id: item.group_id,
          cp_symbol: item.cp_symbol,
          bo_symbol: item.bo_symbol,
          cp_ccy: item.cp_ccy,
          bo_ccy: item.bo_ccy,
          cp_amount: item.cp_amount,
          bo_amount: item.bo_amount,
          symbol_type: item.symbol_type,
          isin: item.isin,
        }))}
      />
    </div>
  );
  const onFilterByIsin = (e) => {
    setIsinFilter(e.target.value);
  };

  const onFilterSubmit = () => {
    const leftFiltered = [];
    const rightFiltered = [];
    results.results.left_side.forEach((entry) => {
      if (
        entry.isin === isinFilter ||
        isinFilter === undefined ||
        isinFilter === ''
      ) {
        leftFiltered.push(entry);
      }
    });
    results.results.right_side.forEach((entry) => {
      if (
        entry.isin === isinFilter ||
        isinFilter === undefined ||
        isinFilter === ''
      ) {
        rightFiltered.push(entry);
      }
    });
    setLeftSortedRows(leftFiltered);
    setRightSortedRows(rightFiltered);
  };
  let qty = 0;
  /* eslint-disable */
  let selectors;
  for (const [key, value] of Object.entries(leftSelected)) {
    if (value.status === true) {
      selectors = document.querySelectorAll(`[id="${key}"]`);
      for (let i = 0; i < selectors.length; i++) {
        if (selectors[i].getElementsByTagName('input')[0].checked) {
          qty += 1;
          if (qty === 2) {
            // eslint-next-line-disable
            alert(
              'The same row was selected. Please uncheck and select another row.',
            );
          }
        }
      }
      qty = 0;
    }
  }
  for (const [key, value] of Object.entries(rightSelected)) {
    if (value.status === true) {
      selectors = document.querySelectorAll(`[id="${key}"]`);
      for (let i = 0; i < selectors.length; i += 1) {
        if (selectors[i].getElementsByTagName('input')[0].checked) {
          qty += 1;
          if (qty === 2) {
            alert(
              'The same row was selected. Please uncheck and select another row.',
            );
          }
        }
      }
      qty = 0;
    }
  }
  /* eslint-enable */
  const deltaNotifier =
    selectedValueDelta !== 0 ? (
      <div className="alert alert-danger mt-2 mb-2" role="alert">
        Value delta between selected {mode} transactions is{' '}
        <b>{Math.abs(selectedValueDelta)}</b>
      </div>
    ) : undefined;

  const onReRunClick = () => {
    const reconRunnerApi = new ReconRunnerApiService();
    const payload = {
      scripts: 'SimpleScript.TransactionsRecon',
      force_run: true,
      use_bo_drive: false,
    };
    if (reportDateScript) {
      payload.date = reportDateScript;
    }
    reconRunnerApi
      .runScripts(payload, false)
      .then((json) => {
        setScriptStatus([json]);
      })
      .catch((e) => {
        setScriptStatus([
          {
            status: 'error',
            message: e.message,
            scripts: ['SimpleScript.TransactionsRecon'],
          },
        ]);
      });
  };

  const onSetScriptReportDate = (date) => {
    setReportDateScript(date);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-4">
        <div className="col-2">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">ISIN</div>
            </div>
            <input
              type="text"
              value={isinFilter || ''}
              onChange={onFilterByIsin}
              className="form-control"
              name="isin"
            />
            <button className="btn btn-primary" onClick={onFilterSubmit}>
              filter
            </button>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn btn-primary"
              onClick={() => onSelectAll(false)}
            >
              Clear selected
            </button>
            <button
              className="btn btn-danger"
              onClick={() => onSelectAll(true)}
            >
              Select all
            </button>
            <button className="btn btn-warning" onClick={onReconcile}>
              Reconcile
            </button>

            <div className="input-group-text ml-4">ScriptReportDate</div>
            <DayPickerInput
              onDayChange={(dateAsMoment) =>
                onSetScriptReportDate(dateAsMoment.toISOString().slice(0, 10))
              }
              inputProps={{ className: 'custom-select mr-sm-2' }}
            />
            <button
              className="btn btn-outline-success"
              onClick={() => onReRunClick()}
            >
              Re-Run Reconcile Script
            </button>
          </div>
        </div>
      </div>

      <ScriptRunNotifications runResults={scriptStatus} />
      {deltaNotifier}

      <div className="row">
        {getLeftSide()}
        {getRightSide()}
      </div>

      <div className="row mt-4">{getReconciled()}</div>
    </div>
  );
}
