import { FilterValue } from 'react-ui-kit-exante';

export enum SortType {
  DESC = 'desc',
  ASC = 'asc',
}

export interface ColumnsWithFilters {
  onFilter?(col: string, value: FilterValue): void;
  onRemove?(col: string): void;
}

export interface SortingTableObject {
  id: string;
  desc: boolean;
}
