import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

function MappedSymbolsTableRow({ entry, onMappingChanged }) {
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(undefined);

  useEffect(() => {
    setModified({ ...entry });
  }, [entry]);

  const onDeleteClick = () => {
    const apiService = new ReconTablesApiService();
    apiService
      .deleteSymbolsMapping(entry.id)
      .then(() => {
        onMappingChanged();
      })
      .catch((e) => {
        alert(e);
      });
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    const newVal = { ...modified };
    newVal[name] = e.target.value;
    setModified(newVal);
  };

  const getModifiedFields = () => {
    const modifiedFields = {};
    // eslint-disable-next-line
    for (const prop in entry) {
      if (entry[prop] !== modified[prop]) {
        modifiedFields[prop] = modified[prop];
      }
    }
    return modifiedFields;
  };

  const onChangeSubmit = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    const changedFields = getModifiedFields();
    const payload = { ...entry, ...changedFields };
    api
      .patchSymbolsMapping(entry.id, payload)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  const addTime = () => {
    let added = null;
    if (
      entry.timestamp !== undefined &&
      entry.timestamp !== '' &&
      entry.timestamp !== null
    ) {
      try {
        added = new Date(entry.timestamp);
        added = added.toLocaleDateString();
      } catch (e) {
        added = '';
      }
    }
    return added;
  };

  const fromTime = () => {
    let added = null;
    if (
      entry.start_date !== undefined &&
      entry.start_date !== '' &&
      entry.start_date !== null
    ) {
      try {
        added = new Date(entry.start_date);
        added = added.toLocaleDateString();
      } catch (e) {
        added = '';
      }
    }
    return added;
  };

  const buttons = () => {
    if (loading) {
      return <Spin />;
    }
    return (
      <>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={onDeleteClick}
        >
          Remove
        </button>
        <button
          className="btn btn-outline-warning btn-sm ml-2"
          onClick={onChangeSubmit}
        >
          Update
        </button>
      </>
    );
  };

  if (modified === undefined) {
    return null;
  }

  return (
    <tr key={entry.full_id}>
      <td>{entry.cp}</td>
      <td>{entry.cp_symbol}</td>
      <td>{entry.exante_symbol}</td>
      <td style={{ minWidth: '70px' }}>
        <input
          value={modified.value_multiplier}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="value_multiplier"
        />
      </td>
      <td style={{ minWidth: '70px' }}>
        <input
          value={modified.price_multiplier}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="price_multiplier"
        />
      </td>
      <td style={{ minWidth: '70px' }}>
        <input
          value={modified.qty_multiplier}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="qty_multiplier"
        />
      </td>
      <td style={{ minWidth: '70px' }}>
        <input
          value={modified.ccy}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="ccy"
        />
      </td>
      <td style={{ minWidth: '120px' }}>
        <input
          value={modified.isin}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="isin"
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        <input
          value={modified.cp_type}
          type="text"
          className="form-control"
          onChange={onInputChange}
          name="cp_type"
        />
      </td>
      <td>{addTime()}</td>
      <td>{fromTime()}</td>
      <td style={{ minWidth: '200px' }}> {buttons()} </td>
    </tr>
  );
}

export default MappedSymbolsTableRow;
