import { Component } from 'react';

import { FilterForm } from 'components/FilterForm/FilterForm';
import ReconRunnerApiService from 'services/ReconRunnerApiService';

import ScriptStatusTable from './status-table';

class ScriptsStatusesPage extends Component {
  // eslint-disable-next-line
  state = {
    scriptStatuses: [],
    selectedStatuses: [],
  };

  componentDidMount() {
    this.getStatuses();
  }

  getFilteredStatuses = (val) => {
    const tempVal = val;
    if (tempVal === undefined) {
      // eslint-disable-next-line
      this.setState({ selectedStatuses: this.state.scriptStatuses });
      return;
    }

    // eslint-disable-next-line
    const found = this.state.scriptStatuses.filter((script) =>
      script.name.toLowerCase().includes(val),
    );
    this.setState({ selectedStatuses: found });
  };

  getStatuses = () => {
    const apiService = new ReconRunnerApiService();
    apiService
      .getScriptsStatus()
      .then((json) => {
        this.setState({
          scriptStatuses: json,
          selectedStatuses: json,
        });
      })
      .catch((e) => {
        alert(e);
      });
  };

  render() {
    return (
      <>
        <FilterForm
          header="Script status"
          searchChanged={this.getFilteredStatuses}
        />
        <ScriptStatusTable scriptStatuses={this.state.selectedStatuses} />
      </>
    );
  }
}

export default ScriptsStatusesPage;
