import { useEffect, useState } from 'react';

import ReconTablesApiService from '../../services/ReconTablesApiService';

function dynamicSort(property) {
  let sortOrder = 1;
  let tempProperty = property;
  if (property[0] === '-') {
    sortOrder = -1;
    tempProperty = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = 0;
    if (a[tempProperty] < b[tempProperty]) {
      result = -1;
    } else if (a[tempProperty] > b[tempProperty]) {
      result = 1;
    }
    return result * sortOrder;
  };
}

function TableRow(prop) {
  const entry = prop.entry;
  const active = prop.value;

  const { conditions } = entry;
  let rowSpan = conditions.length;
  if (rowSpan === 0) {
    rowSpan = 1;
  }
  const extraRows = [];
  const onSelected = (event) => {
    const payload = {};
    const api = new ReconTablesApiService();

    payload.value_id = event.target.value;
    payload.active = event.target.checked;
    api
      .postValueMappingUpdate(payload)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  };

  if (conditions.length > 1) {
    for (let i = 1; i < conditions.length; i += 1) {
      extraRows.push(
        <tr key={`${entry.id}_${i}`}>
          <td>{`${conditions[i].condition_field} ${conditions[
            i
          ].comparison_type.toUpperCase()} ${
            conditions[i].condition_value
          }`}</td>
        </tr>,
      );
    }
  }
  if (entry.active === false && active === false) {
    return null;
  }
  if (entry.cp_id !== prop.entity && prop.entity !== 'All') {
    return null;
  }
  return (
    <>
      <tr key={entry.id}>
        <td rowSpan={rowSpan}>{entry.name}</td>
        <td rowSpan={rowSpan}>{entry.cp_id}</td>
        <td rowSpan={rowSpan}>{entry.report_name}</td>
        <td rowSpan={rowSpan}>{entry.order_index}</td>
        <td rowSpan={rowSpan}>{entry.cp_update_field}</td>
        <td rowSpan={rowSpan}>{entry.cp_source_field}</td>
        <td rowSpan={rowSpan}>{entry.replace_type.toUpperCase()}</td>
        <td rowSpan={rowSpan}>{entry.replace_value}</td>
        <td rowSpan={rowSpan}>{entry.standard_value}</td>
        <td rowSpan={rowSpan}>{entry.standard_value_type.toUpperCase()}</td>
        <td rowSpan={rowSpan}>{entry.start_date}</td>
        <td rowSpan={rowSpan}>{entry.end_date}</td>
        {conditions === undefined || conditions.length === 0 ? (
          <td rowSpan={rowSpan}>No conditions</td>
        ) : (
          <td>{`${
            conditions[0].condition_field
          } ${conditions[0].comparison_type.toUpperCase()} ${
            conditions[0].condition_value
          }`}</td>
        )}
        <td>
          <input
            type="checkbox"
            onChange={onSelected}
            value={entry.id}
            checked={entry.active}
          />
        </td>
      </tr>
      {extraRows}
    </>
  );
}

export function ResultsTable(prop) {
  const { entity, results, active } = prop;
  const [sortedData, setSortedData] = useState(results);
  const data = results;
  const l = results === undefined ? 0 : results.length;

  useEffect(() => {
    setSortedData(data);
  }, [l]);

  const onSort = (event, sortKey) => {
    const newData = [...sortedData];
    const temp = newData.sort(dynamicSort(sortKey));

    setSortedData(temp);
  };
  if (results === undefined) {
    return (
      <div className="container">
        <h2>Nothing found</h2>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th onClick={(e) => onSort(e, 'name')}>Name</th>
            <th onClick={(e) => onSort(e, 'cp_id')}>CP</th>
            <th onClick={(e) => onSort(e, 'report')}>Report</th>
            <th onClick={(e) => onSort(e, 'order_index')}>Order Index</th>
            <th onClick={(e) => onSort(e, 'cp_update_field')}>Update Field</th>
            <th onClick={(e) => onSort(e, 'cp_source_field')}>Source Field</th>
            <th onClick={(e) => onSort(e, 'replace_type')}>Replace Type</th>
            <th onClick={(e) => onSort(e, 'replace_value')}>Replace Value</th>
            <th onClick={(e) => onSort(e, 'standard_value')}>Standard Value</th>
            <th onClick={(e) => onSort(e, 'standard_value_type')}>
              Standard Type
            </th>
            <th onClick={(e) => onSort(e, 'start_date')}>Start Date</th>
            <th onClick={(e) => onSort(e, 'end_date')}>End Date</th>
            <th>Conditions</th>
            <th onClick={(e) => onSort(e, 'active')}>Active</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((entry) => (
            <TableRow
              key={entry.id}
              entry={entry}
              value={active}
              entity={entity}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
