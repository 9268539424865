import BaseApiService from './baseApiService';

interface Script {
  related_legal_entity?: string;
  name: string;
  compose_name: string;
}
interface ScriptRunStatuses {
  script_name: string;
  app: string;
}
interface RunScriptsPayload {
  date: string;
  scripts: string | string[];
  update_mapping: boolean;
  update_table: boolean;
  force_run: boolean;
  use_bo_drive: boolean;
}
interface RunScriptsResponse {
  scripts: string | string[];
  status: string;
  message: string;
}

class ReconRunnerApiService extends BaseApiService {
  _apiBase = this.getApiBase(8001, 'runner');

  async getScripts(group: string) {
    const url = group === undefined ? `scripts/` : `scripts/?group=${group}`;
    return this.getResource<Script[]>(url);
  }

  async getScriptsStatus() {
    return this.getResource<Script[]>('scripts/');
  }

  async getScriptOutput(scriptID: string, outputID: string) {
    return this.getResource<{ result: string }>(
      `script-output/?id=${scriptID}&output=${outputID}`,
    );
  }

  async getScriptsExecutionsInfo() {
    return this.getResource<ScriptRunStatuses[]>('scripts-run-statuses/');
  }

  async runScripts(payload: RunScriptsPayload, onlyPostScripts: boolean) {
    const endpoint = onlyPostScripts ? 'run-post-script/' : 'run-script/';
    return this.postResource<RunScriptsPayload, RunScriptsResponse>(
      endpoint,
      payload,
    );
  }

  async runByCPAndType(cp: any, scriptType: string, date: string) {
    const payload = {
      cp,
      type: scriptType,
      date,
    };

    return this.postResource('run-script-for-cp/', payload);
  }
}

export default ReconRunnerApiService;
