import { Component } from 'react';

const typeOfTrades = {
  trade: 'Trade',
  redemption: 'redemption',
};

export class BrokerSideTableRow extends Component {
  onChange = (e) => {
    if (e.target.checked) {
      this.props.addTrade(this.props.trade.full_id);
      return;
    }

    this.props.removeTrade(this.props.trade.full_id);
  };

  render() {
    const { trade, selected } = this.props;
    const date = new Date(trade.report_date);
    const dateF = date.toLocaleDateString();
    let cls = trade.type_of_trade === typeOfTrades.trade ? null : 'bg-warning';
    if (typeof cls === 'string') {
      cls =
        trade.type_of_trade?.toLowerCase() === typeOfTrades.redemption
          ? 'bg-secondary'
          : cls;
    }

    return (
      <tr key={trade.full_id} className={cls}>
        <td>
          <input type="checkbox" onChange={this.onChange} checked={selected} />
        </td>
        <td>{trade.symbol}</td>
        <td>{trade.qty}</td>
        <td>{trade.price}</td>
        <td>{trade.ccy}</td>
        <td>{trade.value}</td>
        <td>{trade.full_id}</td>
        <td>{dateF}</td>
        <td>{trade.broker_id}</td>
      </tr>
    );
  }
}
