import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function AutoMappingForm({ successCallback }) {
  const brokerIDs = useSelector((state) => state.counterParties);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [reportOptions, setReportOptions] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [sdbField, setSdbField] = useState([]);
  const [tableColumnsOptions, setTableColumnsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ruleConditions, setRuleConditions] = useState([]);
  const [ruleFields, setRuleFields] = useState([]);
  const [activeCP, setActiveCP] = useState(undefined);
  const [allReports, setAllReports] = useState([]);

  useEffect(() => {
    const api = new ReconTablesApiService();
    api.getListMappingReports({}).then((res) => {
      setAllReports(res.results);
    });

    api.getListComparisonType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setComparisonOptions(options);
    });

    api.getListInstrumentMappingField().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.field} value={r.field}>
          {r.field}
        </option>
      ));
      setFieldOptions(options);
    });
    api.getSdbFields().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.field} value={r.field}>
          {r.field}
        </option>
      ));
      setSdbField(options);
    });

    api.getListMappingCpTradesFields().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.field} value={r}>
          {r}
        </option>
      ));
      setTableColumnsOptions(options);
    });
  }, []);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }

    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);
  useEffect(() => {
    const cpReportOptions = [];
    allReports.forEach((r) => {
      if (r.cp_id === activeCP) {
        if (r.state === 'Testing' || r.state === 'Setup') {
          cpReportOptions.push(
            <option
              key={r.id}
              value={r.id}
            >{`${r.path}/${r.contains} (${r.type})`}</option>,
          );
        }
      }
    });
    setReportOptions(cpReportOptions);
  }, [activeCP]);

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = getFormPayload(event.target);
    // eslint-disable-next-line
    for (const key of Object.keys(payload)) {
      if (payload[key] === undefined && key !== 'end_date') {
        alert('All fields required');
        return;
      }
    }

    const convertedPayload = {
      name: payload.name,
      cp_id: payload.cp_id,
      report: payload.report,
      start_date: payload.start_date,
      end_date: payload.end_date,
      fields: [],
      conditions: [],
    };

    const maxConditionsNumber = ruleConditions.length;
    for (let i = 0; i < maxConditionsNumber; i += 1) {
      const condition = {
        condition_field: payload[`condition_field#${i}`],
        condition_value: payload[`condition_value#${i}`],
        comparison_type: payload[`comparison_type#${i}`],
      };
      convertedPayload.conditions.push(condition);
    }

    const maxFieldNumber = ruleFields.length;
    for (let i = 0; i < maxFieldNumber; i += 1) {
      const field = {
        instrument_mapping_field: payload[`instrument_mapping_field#${i}`],
        required: payload[`required#${i}`],
        sdb_field: payload[`sdb_field#${i}`],
      };
      convertedPayload.fields.push(field);
    }

    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .postAutoMappingRuleWithFields(convertedPayload)
      .then(() => {
        setLoading(false);
        successCallback();
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  const deleteCondition = (idxExclude) => {
    const newConditions = ruleConditions.filter((_, idx) => idx !== idxExclude);
    setRuleConditions(newConditions);
  };

  const addCondition = () => {
    const idx = ruleConditions.length;
    const ruleEl = (
      <div className="form-row mb-2">
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Condition Field</div>
            </div>
            <select
              className="custom-select mr-sm-2"
              name={`condition_field#${idx}`}
            >
              {tableColumnsOptions}
            </select>
          </div>
        </div>
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Condition Value</div>
            </div>
            <input
              type="text"
              className="form-control"
              name={`condition_value#${idx}`}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Comparison Type</div>
            </div>
            <select
              className="custom-select mr-sm-2"
              name={`comparison_type#${idx}`}
            >
              {comparisonOptions}
            </select>
          </div>
        </div>
        <div className="col-1">
          <div className="input-group">
            <button
              className="btn btn-danger"
              onClick={() => deleteCondition(idx)}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
    const newConditions = [...ruleConditions];
    newConditions.push(ruleEl);
    setRuleConditions(newConditions);
  };

  const deleteField = (idxExclude) => {
    const newFields = ruleFields.filter((_, idx) => idx !== idxExclude);
    setRuleFields(newFields);
  };

  const addField = () => {
    const idx = ruleFields.length;
    const fieldElement = (
      <div className="form-row mb-2">
        <div className="col-5">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Instrument Mapping Field</div>
            </div>
            <select
              className="custom-select mr-sm-2"
              name={`instrument_mapping_field#${idx}`}
            >
              {fieldOptions}
            </select>
          </div>
        </div>
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">SDB Field</div>
            </div>

            <select className="custom-select mr-sm-2" name={`sdb_field#${idx}`}>
              {sdbField}
            </select>
          </div>
        </div>
        <div className="col-2">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Required</div>
            </div>
            <select className="custom-select mr-sm-2" name={`required#${idx}`}>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="col-1">
          <div className="input-group">
            <button
              className="btn btn-danger"
              onClick={() => deleteField(idx)}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
    const newFields = [...ruleFields];
    newFields.push(fieldElement);
    setRuleFields(newFields);
  };

  const onCpChange = (e) => {
    const cp = e.target.value;
    setActiveCP(cp);
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Name</div>
              </div>
              <input type="text" className="form-control" name="name" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp_id"
                onChange={onCpChange}
              >
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Report</div>
              </div>
              <select className="custom-select mr-sm-2" name="report">
                {reportOptions}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Start Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'start_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">End Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'end_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
        </div>
        {ruleFields}
        {ruleConditions}
        <div className="form-row">
          <div className="mx-auto">
            {loading ? (
              <Spin />
            ) : (
              <div>
                <button className="btn btn-warning" type="submit">
                  Create
                </button>
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={addField}
                >
                  Add Field
                </button>
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={addCondition}
                >
                  Add Condition
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
