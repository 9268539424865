import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';
import { commonSlice } from 'store/reducers/commonReducer';

export function SearchForm({ search }) {
  const brokerIDs = useAppSelector((state) => state.counterParties);
  const entities = useAppSelector((state) => state.legalEntities);

  const [cpOptionsList, setCpOptionsList] = useState([]);
  const [entitiesOptionsList, setEntitiesOptionsList] = useState([]);
  const [transactionKindsList, setTransactionTypes] = useState([]);
  const dispatch = useDispatch();

  // TODO: refactor after BPA-2712
  useEffect(() => {
    const api = new ReconTablesApiService();
    const forPage = window.location.pathname.substring(1);
    fetch(
      // eslint-disable-next-line
      `${api._apiBase}tables/transaction-operation-types/?for_page=${forPage}`,
    )
      .then((response) => response.json())
      .then((data) => {
        setTransactionTypes(data.results);
      })
      .catch((e) => {
        alert(e);
      });
  }, []);

  useEffect(() => {
    const options = brokerIDs.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));
    setCpOptionsList(options);
  }, [brokerIDs]);

  useEffect(() => {
    const options = [];
    /* eslint-disable */
    for (const key of Object.keys(entities)) {
      options.push(
        <option key={key} value={key}>
          {entities[key]}
        </option>,
      );
    }
    /* eslint-enable */
    setEntitiesOptionsList(options);
  }, [entities]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    if (payload.from_date === undefined || payload.to_date === undefined) {
      // eslint-disable-next-line
      alert('From and To dates are required field for search');
      return;
    }
    if (payload.transaction_kind === undefined) {
      // eslint-disable-next-line
      alert('Transaction_kind are required field for search');
      return;
    }
    if (payload.legal_entity === undefined) {
      // eslint-disable-next-line
      alert('Legal_Entity are required field for search');
      return;
    }
    search(payload);
  };

  const handleChangeMode = (mode) =>
    dispatch(commonSlice.actions.setMode(mode));

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchPositionMapping">
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">From</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'from_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">To</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'to_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="legal_entity"
                placeholder="Entity"
              >
                <option key="any" value="">
                  Any
                </option>
                {entitiesOptionsList}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">CP</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp"
                placeholder="CP"
              >
                <option key="any" value="">
                  Any
                </option>
                {cpOptionsList}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <select className="custom-select mr-sm-2" name="transaction_kind">
                {transactionKindsList.map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-row">
            <div className="mx-auto">
              <button
                className="btn btn-warning ml-2"
                type="submit"
                onClick={() => handleChangeMode('cp-vs-bo')}
              >
                Load CP vs BO
              </button>
              <button
                className="btn btn-warning ml-2"
                type="submit"
                onClick={() => handleChangeMode('bo-vs-bo')}
              >
                Load BO vs BO
              </button>
              <button
                className="btn btn-warning ml-2"
                type="submit"
                onClick={() => handleChangeMode('cp-vs-cp')}
              >
                Load CP vs CP
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
