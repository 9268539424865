import DayPickerInput from 'react-day-picker/DayPickerInput';

import { getFormPayload } from '../../services/utils';

export function OrderStatusForm(props) {
  const { queryReport } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    queryReport(payload);
  };

  return (
    <div className="container-fluid mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchFormReconcile">
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">order_id</div>
              </div>
              <input type="text" className="form-control" name="order_id" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">exchange_id</div>
              </div>
              <input type="text" className="form-control" name="exchange_id" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">counterparty</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp_name"
                placeholder="counterparty"
              >
                <option key="" value="" />
                <option key="binance" value="binance">
                  BINANCE
                </option>
                <option key="bitfinex" value="bitfinex">
                  BITFINEX
                </option>
                <option key="bitstamp" value="bitstamp">
                  BITSTAMP
                </option>
                <option key="deribit" value="deribit">
                  DERIBIT
                </option>
                <option key="globitex" value="globitex">
                  GLOBITEX
                </option>
                <option key="hitbtc" value="hitbtc">
                  HITBTC
                </option>
                <option key="lmax" value="lmax">
                  LMAX
                </option>
                <option key="okcoin" value="okcoin">
                  OKCOIN
                </option>
              </select>
            </div>
          </div>
          <div className="col">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col">
            <div className="mx-auto">
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
      <hr />
    </div>
  );
}
