import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from '../../services/ReconTablesApiService';
import './style.css';

function PositionMappingPreview({ entry, mtyQty, onMappingAccepted }) {
  const { position_reconciliation: positionReconciliation, candidates } = entry;
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const acceptMapping = (candidate) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    const startDate = new Date(positionReconciliation.report_date);
    const payload = {
      exante_symbol: candidate.symbol,
      cp_symbol: positionReconciliation.cp_symbol,
      cp: positionReconciliation.cp,
      account: positionReconciliation.account,
      cp_type: positionReconciliation.type,
      ccy: positionReconciliation.ccy,
      isin: positionReconciliation.isin,
      mty: 1,
      cmty: 1,
      mty_qty: mtyQty,
      start_date: startDate.toISOString(),
    };
    api
      .postSymbolsMapping(payload)
      .then((res) => {
        setLoading(false);
        return api.updatePositionReconciliationFromMapping(res.id);
      })
      .then(() => {
        onMappingAccepted();
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    const newRows = [];
    if (candidates === undefined) {
      setRows([]);
      return;
    }
    // eslint-disable-next-line
    for (const c of candidates) {
      newRows.push(
        <tr key={c.isin + c.id}>
          <td>{c.symbol}</td>
          <td>{-c.diff}</td>
          <td>{c.ccy}</td>
          <td>{c.isin}</td>
          <td>{c.symbol_type}</td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => acceptMapping(c)}
            >
              Accept Mapping
            </button>
          </td>
        </tr>,
      );
    }
    setRows(newRows);
  }, [candidates]);

  if (loading) {
    return (
      <div className="container-fluid">
        <Spin />
      </div>
    );
  }
  if (candidates === undefined || candidates.length === 0) {
    if (
      positionReconciliation === undefined ||
      positionReconciliation === null
    ) {
      return null;
    }
    return (
      <div className="container-fluid">
        <h5>No Matches</h5>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <table className="table table-striped table-bordered table-sm">
                <thead className="thead-light">
                  <tr>
                    <th>Symbol</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Ccy</th>
                    <th>ISIN</th>
                    <th>Type</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{positionReconciliation.cp_symbol}</td>
                    <td>{positionReconciliation.price}</td>
                    <td>{positionReconciliation.qty}</td>
                    <td>{positionReconciliation.ccy}</td>
                    <td>{positionReconciliation.isin}</td>
                    <td>{positionReconciliation.type}</td>
                    <td>{positionReconciliation.value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped table-bordered table-sm">
                <thead className="thead-light">
                  <tr>
                    <th>Symbol</th>
                    <th>Qty Diff</th>
                    <th>Ccy</th>
                    <th>ISIN</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PositionMappingTableRow({ entry, updatePreview }) {
  const [loading, setLoading] = useState(false);
  const [mty, setMty] = useState(1);

  const findMapping = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .findMappingForPositionReconciliation(
        entry.id,
        mty,
        entry.qty,
        entry.cp,
        entry.report_date,
        entry.ccy,
      )
      .then((res) => {
        setLoading(false);
        updatePreview(res, mty);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onMtyChange = (e) => {
    e.preventDefault();
    setMty(e.target.value);
  };

  return (
    <tr key={entry.id}>
      <td>{entry.report_date}</td>
      <td>{entry.cp}</td>
      <td>{entry.cp_symbol}</td>
      <td>{entry.bo_symbol}</td>
      <td>{entry.isin}</td>
      <td>{entry.price}</td>
      <td>{entry.qty}</td>
      <td>{entry.ccy}</td>
      <td>{entry.value}</td>
      <td style={{ maxWidth: '200px' }}>
        {loading ? (
          <Spin />
        ) : (
          <div className="input-group" style={{ align: 'center' }}>
            <div className="input-group-prepend">
              <button
                className="btn btn-primary btn-sm"
                onClick={findMapping}
                style={{ maxWidth: '170px' }}
              >
                Find Mapping For Qty Mty
              </button>
            </div>
            <input
              style={{ maxWidth: '120px' }}
              value={mty}
              onChange={onMtyChange}
              type="number"
              className="form-control"
            />
          </div>
        )}
      </td>
    </tr>
  );
}

export function PositionMappingTable({ entries }) {
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [rows, setRows] = useState([]);
  const [previewEntry, setPreviewEntry] = useState([]);
  const [mty, setMty] = useState(1);

  const updateMappingPreview = (newMapping, _mty) => {
    setPreviewEntry(newMapping);
    setMty(_mty);
  };

  useEffect(() => {
    if (selectedEntries === undefined || selectedEntries.length === 0) {
      setRows([]);
      return;
    }
    const newRows = [];
    // eslint-disable-next-line
    for (const entry of selectedEntries) {
      newRows.push(
        <PositionMappingTableRow
          key={entry.id}
          entry={entry}
          updatePreview={updateMappingPreview}
        />,
      );
    }
    setRows(newRows);
  }, [selectedEntries]);

  useEffect(() => {
    setSelectedEntries(entries);
  }, [entries]);

  const onMappingAccepted = (selected, allEntries) => {
    const newEntries = [];

    // eslint-disable-next-line
    for (const s of allEntries) {
      if (s.id === selected.id) {
        continue;
      }
      newEntries.push(s);
    }
    setSelectedEntries(newEntries);
    setPreviewEntry(undefined);
  };

  if (rows.length === 0) {
    return <h2>Nothing found</h2>;
  }

  return (
    <div className="container-fluid">
      <div className="table-wrapper">
        <table className="table table-striped table-sm table-bordered table-hover table-sm">
          <thead className="thead-light">
            <tr>
              <th>Report Date</th>
              <th>CP</th>
              <th>CP Symbol</th>
              <th>BO Symbol</th>
              <th>ISIN</th>
              <th>Price</th>
              <th>Qty</th>
              <th>Ccy</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      <hr />
      {previewEntry === undefined ? null : (
        <div className="row xterm-bg-color-2">
          <PositionMappingPreview
            entry={previewEntry}
            mtyQty={mty}
            onMappingAccepted={() =>
              onMappingAccepted(
                previewEntry.position_reconciliation,
                selectedEntries,
              )
            }
          />
        </div>
      )}
    </div>
  );
}
