import { Link } from 'react-router-dom';
import { UseTableCellProps } from 'react-table';
import { IColumn } from 'react-ui-kit-exante';

import { initialUrl } from 'constants/app';
import { Report } from 'services/MifidApiService';
import { ColumnsWithFilters } from 'types';

export const getReportsTableColumns = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters): IColumn<Report>[] => [
  {
    Header: 'Date',
    accessor: 'date',
    type: 'dateRange',
    onFilter,
    onRemove,
  },
  {
    Header: 'Legal Entity',
    accessor: 'legal_entity_id',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Status',
    accessor: 'status_id',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'UID',
    accessor: 'uid',
    type: 'textInput',
    Cell: ({ row }: UseTableCellProps<Report>) => (
      <Link to={`${initialUrl}/mifid/reports/${row.values.uid}`}>
        {row.values.uid}
      </Link>
    ),
    onFilter,
    onRemove,
  },
];
