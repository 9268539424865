import { Component } from 'react';

import ReconRunnerApiService from 'services/ReconRunnerApiService';

import { ModalProps, ModalState } from './types';

export class Modal extends Component<ModalProps, ModalState> {
  // eslint-disable-next-line
  state: ModalState = {
    text: undefined,
  };

  componentDidMount() {
    const { text } = this.props;
    this.setState({ text });
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps: ModalProps, prevState: ModalState) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      this.setState({ text: this.props.text });
    }
  }

  onShowClick = () => {
    const { scriptID, outputID } = this.props;
    const { text } = this.state;
    if (text !== undefined) {
      return;
    }
    const api = new ReconRunnerApiService();
    api
      .getScriptOutput(scriptID, outputID)
      .then((resp) => {
        this.setState({ text: resp.result });
      })
      .catch((e) => {
        // eslint-disable-next-line no-alert
        alert(e);
      });
  };

  render() {
    const { btnClass, buttonText, name } = this.props;
    const { text } = this.state;
    const modalID = `modal${name}`;
    const target = `#${modalID}`;
    const btnCls = btnClass || 'btn btn-link';
    const btnText = buttonText || 'Show';

    return (
      <>
        <button
          type="button"
          className={btnCls}
          data-toggle="modal"
          data-target={target}
          onClick={this.onShowClick}
        >
          {btnText}
        </button>
        <div
          className="modal fade"
          id={modalID}
          tabIndex={-1}
          role="dialog"
          aria-labelledby={modalID}
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{text}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
