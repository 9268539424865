import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from '../../services/ReconTablesApiService';

export function BankTableRow(props) {
  const api = new ReconTablesApiService();
  const { entry, selectAll, setSelectedRows, reUploadResults } = props;
  const [isShownComment, setIsShownComment] = useState(false);
  const [isShownButtonName, setIsShownButtonName] = useState('show');
  const [selected, setSelected] = useState(false);
  const [accountId, setAccountId] = useState();
  const [operationType, setOperationType] = useState();
  const operationTypes = useSelector((state) => state.operationTypes);

  const onSelected = () => {
    setSelected(!selected);
    setSelectedRows(entry.bank_id, !selected);
  };

  useEffect(() => {
    setSelected(selectAll);
    setSelectedRows(entry.bank_id, selectAll);
  }, [selectAll]);

  useEffect(() => {
    setAccountId(entry.bank_account_id ? entry.bank_account_id : '');
    setOperationType(entry.bank_operation_type.toLowerCase());
  }, [entry]);

  const onChangeAccountId = (e) => {
    setAccountId(e.target.value);
  };

  const onChangeAccountIdSubmit = () => {
    api
      .bankChangeAccountId({ id: entry.bank_id, account_id: accountId })
      .then((results) => {
        alert(`Status: ${results.status}`);
        reUploadResults();
      })
      .catch((e) => {
        alert(`Failed to reconcile: ${e}`);
      });
  };

  const onChangeOperationType = (e) => {
    setOperationType(e.target.value);
  };

  const onChangeOperationTypSubmit = () => {
    api
      .bankChangeOperationType({
        id: entry.bank_id,
        operation_type: operationType,
        source: 'BANK',
      })
      .then((results) => {
        alert(`Status: ${results.status}`);
        reUploadResults();
      })
      .catch((e) => {
        alert(`Failed to reconcile: ${e}`);
      });
  };

  const operationTypesOptions = operationTypes.map((row) => (
    <option key={row.toLowerCase()} value={row.toLowerCase()}>
      {row.toLowerCase()}
    </option>
  ));

  const showComment = () => {
    setIsShownComment(!isShownComment);
    const name = !isShownComment === true ? 'hide' : 'show';
    setIsShownButtonName(name);
  };

  return (
    <tr key={entry.bank_id + entry.bank_tx_id}>
      <td>
        <input type="checkbox" onChange={onSelected} checked={selected} />
      </td>
      <td>{entry.bank_tx_id}</td>
      <td>{entry.bank_book_date}</td>
      <td>{entry.bank_ccy}</td>
      <td>{entry.bank_sum}</td>
      <td>
        <div className="input-group">
          <input
            value={accountId || ''}
            onChange={onChangeAccountId}
            type="text"
            className="form-control"
          />
          <div className="input-group-prepend">
            <button
              className="btn btn-sm btn-warning"
              type="submit"
              onClick={onChangeAccountIdSubmit}
            >
              Edit
            </button>
          </div>
        </div>
      </td>
      <td>
        <div className="input-group">
          <select
            className="custom-select mr-sm-2"
            name="operation_type"
            onChange={onChangeOperationType}
            value={operationType}
          >
            {operationTypesOptions}
          </select>
          <div className="input-group-prepend">
            <button
              className="btn btn-sm btn-warning"
              type="submit"
              onClick={onChangeOperationTypSubmit}
            >
              Edit
            </button>
          </div>
        </div>
      </td>
      <td>{entry.bank_details}</td>
      <td>
        {isShownComment && <div>{entry.comment}</div>}
        <div>
          <button
            className="btn btn-sm btn-info"
            type="submit"
            onClick={showComment}
          >
            {isShownButtonName}
          </button>
        </div>
      </td>
    </tr>
  );
}
