function TableRow({ entry }) {
  const { fields } = entry;
  let rowSpan = 1;
  if (fields.length > 0) {
    rowSpan = fields.length;
  }
  const extraRows = [];

  const formatRequired = (val) => {
    if (val) {
      return 'required';
    }
    return 'not required';
  };

  const formattedField = (field) => {
    const sdbField =
      field.sdb_field !== undefined ? `{${field.sdb_field})` : '';
    return `${field.instrument_mapping_field}${sdbField} ${formatRequired(
      fields.required,
    )}`;
  };

  if (fields.length > 1) {
    for (let i = 1; i < fields.length; i += 1) {
      extraRows.push(
        <tr key={`${entry.id}_${i}`}>
          <td>{formattedField(fields[i])}</td>
        </tr>,
      );
    }
  }

  const conditionsMerged = () => {
    let formattedCondition = '';
    if (entry.conditions === undefined) {
      return formattedCondition;
    }
    // eslint-disable-next-line
    for (const condition of entry.conditions) {
      if (formattedCondition !== '') {
        formattedCondition = formattedCondition.concat(', ');
      }
      formattedCondition += formattedCondition.concat(
        `${condition.condition_field} ${condition.comparison_type} ${condition.condition_value}`,
      );
    }
    return formattedCondition;
  };

  return (
    <>
      <tr key={entry.id}>
        <td rowSpan={rowSpan}>{entry.name}</td>
        <td rowSpan={rowSpan}>{entry.cp_id}</td>
        <td rowSpan={rowSpan}>{entry.report}</td>
        <td rowSpan={rowSpan}>{entry.start_date}</td>
        <td rowSpan={rowSpan}>{entry.end_date}</td>
        <td rowSpan={rowSpan}>{conditionsMerged()}</td>
        {fields === undefined || fields.length === 0 ? (
          <td rowSpan={rowSpan} />
        ) : (
          <td>{formattedField(fields[0])}</td>
        )}
      </tr>
      {extraRows}
    </>
  );
}

export function ResultsTable({ results }) {
  if (results === undefined) {
    return (
      <div className="container">
        <h2>Nothing found</h2>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>Name</th>
            <th>CP</th>
            <th>File Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Conditions</th>
            <th>Fields</th>
          </tr>
        </thead>
        <tbody>
          {results.map((entry) => (
            <TableRow entry={entry} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
