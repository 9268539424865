import { FC, useState } from 'react';
import { Loader, Button, Notification } from 'react-ui-kit-exante';
import { v4 as uid } from 'uuid';

import ReconTablesApiService, {
  UnreconSearchItem,
} from 'services/ReconTablesApiService';

interface Props {
  entry: UnreconSearchItem;
  rowClass: string;
}
const api = new ReconTablesApiService();

const ManualUnreconcileTableRow: FC<Props> = ({ entry, rowClass }) => {
  const [processing, setProcessing] = useState(false);
  const [unreconed, setUnreconed] = useState(false);

  const onUnReconClick = () => {
    setProcessing(true);
    api
      .unreconcileGroup(entry.group_id)
      .then(() => {
        setUnreconed(true);
        Notification.success('Success unrecon');
      })
      .catch((e) => {
        Notification.error(e);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const button = processing ? (
    <Loader />
  ) : (
    <Button
      className="btn btn-outline-danger"
      onClick={onUnReconClick}
      disabled={unreconed}
    >
      UN Reconcile
    </Button>
  );

  const getEmptyCells = (count: number) => {
    const cells = [];
    for (let step = 0; step < count; step += 1) {
      cells.push(<td />);
    }
    return cells;
  };

  const maxRows = Math.max(entry.ctrades.length, entry.cptrades.length);

  const rows = [];

  for (let i = 0; i < maxRows; i += 1) {
    const cpName = entry.ctrades.length > 0 ? entry.ctrades[0].cp : '';
    const groupColumns =
      i !== 0 ? null : (
        <>
          <td rowSpan={maxRows}>{entry.group_id}</td>
          <td rowSpan={maxRows}>{button}</td>
          <td rowSpan={maxRows}>{cpName}</td>
        </>
      );

    const cRow = entry.ctrades[i];
    const cColumns = !cRow ? (
      getEmptyCells(8)
    ) : (
      <>
        <td>{cRow.trade_number}</td>
        <td>{cRow.symbol}</td>
        <td>{cRow.price}</td>
        <td>{cRow.qty}</td>
        <td>{cRow.value}</td>
        <td>{cRow.timestamp}</td>
        <td>{cRow.exchange_order_id}</td>
        <td>{cRow.rec_status ? 'OK' : 'NOT OK'}</td>
      </>
    );

    const cpRow = entry.cptrades[i];
    const cpColumns = !cpRow ? null : (
      <>
        <td>{cpRow.symbol}</td>
        <td>{cpRow.price}</td>
        <td>{cpRow.qty}</td>
        <td>{cpRow.value}</td>
        <td>{cpRow.timestamp}</td>
        <td>{cpRow.exchange_order_id}</td>
      </>
    );

    const uniqId = uid();
    rows.push(
      <tr key={uniqId} className={rowClass}>
        {groupColumns}
        {cColumns}
        {cpColumns}
      </tr>,
    );
  }

  return <>{rows}</>;
};

export default ManualUnreconcileTableRow;
