import { FC, MouseEvent, useMemo, useState } from 'react';

import { ReportWithFields } from 'services/ReconTablesApiService';
import { dynamicSort } from 'utils';

import { ReportTableRow } from './table-row';

interface Props {
  reports: ReportWithFields[];
  onClone: (value: number) => void;
}

export const ReportListView: FC<Props> = ({ reports, onClone }) => {
  const [sortedKey, setSortedKey] = useState('');

  const onSort = (
    event: MouseEvent<HTMLTableHeaderCellElement>,
    sortKey: string,
  ) => {
    setSortedKey(sortKey);
  };

  const sortedReports = useMemo(
    () => (sortedKey ? [...reports].sort(dynamicSort(sortedKey)) : reports),
    [reports, sortedKey],
  );
  const rows = useMemo(
    () =>
      sortedReports.map((report) => (
        <ReportTableRow onClone={onClone} report={report} key={report.id} />
      )),
    [sortedReports],
  );
  return (
    <div className="mt-3 table-responsive">
      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th onClick={(e) => onSort(e, 'id')}>ID</th>
            <th onClick={(e) => onSort(e, 'cp_id')}>Counterparty</th>
            <th onClick={(e) => onSort(e, 'name')}> Name</th>
            <th onClick={(e) => onSort(e, 'type')}> Type</th>
            <th onClick={(e) => onSort(e, 'path')}>Path</th>
            <th onClick={(e) => onSort(e, 'contains')}> Contains</th>
            <th onClick={(e) => onSort(e, 'file_type')}>File Type</th>
            <th onClick={(e) => onSort(e, 'state')}>State</th>
            <th>Field Mappings</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};
