import { Component } from 'react';

import { TableRow } from './table-row';

export default class SymbolCOTTable extends Component {
  // eslint-disable-next-line
  state = {
    entries: [],
  };

  componentDidMount() {
    this.setState({ entries: this.props.entries });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      // eslint-disable-next-line
      this.setState({ entries: this.props.entries });
    }
  }

  fetchData = () => {
    this.props.fetchData();
  };

  render() {
    const { entries } = this.state;
    const rows =
      entries === undefined
        ? []
        : entries.map((entry) => (
            <TableRow entry={entry} onRowDeleted={this.fetchData} />
          ));
    return (
      <div className="container">
        <table className="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Counterparty</th>
              <th>Time</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}
