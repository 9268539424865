import { uniq } from 'ramda';
import { useMemo, useState } from 'react';
import { ISortBy, Notification } from 'react-ui-kit-exante';

import { ReconciledTransaction } from 'services/ReconTablesApiService';
import { getUrlFromPayload } from 'services/utils';
import { SortType } from 'types';

import { DatesIsValid, ModifyReconciledTransaction } from './types';

const filterPayloadStateDefault = {
  categories: '',
  from_date: undefined,
  to_date: undefined,
  legal_entity: '',
  cps: '',
  operation_types: [],
  ccy: [],
  symbols: '',
  symbol_types: '',
};

export const getBreakReportFilters = (value: string) => {
  const filters: Record<string, null | boolean | string> = {
    break_report_filter: '',
    break_report_category: '',
  };
  if (value === 'empty') {
    filters.break_report_filter = false;
  } else if (value === 'not_empty') {
    filters.break_report_filter = true;
  } else if (value) {
    filters.break_report_category = value;
  }
  return filters;
};

export function useFilters(setPage: (val: number) => void) {
  const [fromFilter, setFromFilter] = useState<undefined | string>(undefined);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [toFilter, setToFilter] = useState<undefined | string>(undefined);
  const [entityFilter, setEntityFilter] = useState('');
  const [cpFilter, setCpFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [ccyFilter, setCcyFilter] = useState<string[]>([]);
  const [symbolTypeFilter, setSymbolTypeFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState('');
  const [breakCategoryFilter, setBreakCategoryFilter] = useState('');
  const [filterPayloadState, setFilterPayloadState] = useState(
    filterPayloadStateDefault,
  );
  const [sortingParams, setSortingParams] = useState<ISortBy[]>([]);
  const [datesIsValid, setDatesIsValid] = useState<DatesIsValid>({
    from: true,
    to: true,
  });
  const checkPayload = () => {
    if (!fromFilter || !toFilter) {
      Notification.error('From and To dates are required field for search');
      return false;
    }
    if (!datesIsValid.from) {
      Notification.error('From date is not valid');
      return false;
    }
    if (!datesIsValid.to) {
      Notification.error('To date is not valid');
      return false;
    }
    if (!entityFilter) {
      Notification.error('Entity are required field for search');
      return false;
    }
    return true;
  };
  const handleSetFilters = (loadFirstPage: boolean) => {
    if (!checkPayload()) {
      return;
    }
    if (loadFirstPage) {
      setFilterPayloadState((prevState: any) => ({
        ...prevState,
        from_date: fromFilter,
        to_date: toFilter,
        cps: cpFilter,
        legal_entity: entityFilter,
        operation_types: typeFilter,
        symbols: symbolFilter,
        symbol_types: symbolTypeFilter,
        ccy: ccyFilter,
        categories: categoryFilter,
      }));
      setPage(0);
    }
  };

  const getUrlPayload = () => {
    const {
      operation_types: operationTypes,
      ccy,
      ...filterPayload
    } = filterPayloadState;
    let urlWithPayload = getUrlFromPayload('', {
      ...filterPayload,
      ...getBreakReportFilters(breakCategoryFilter),
    });
    urlWithPayload = `&${urlWithPayload}`;
    if (operationTypes.length) {
      urlWithPayload += `&operation_types=${operationTypes.join(
        '&operation_types=',
      )}`;
    }
    if (ccy.length) {
      urlWithPayload += `&ccys=${ccy.join('&ccys=')}`;
    }
    if (sortingParams.length) {
      const order = sortingParams
        .map((item) => `${item.id}_${item.desc ? SortType.DESC : SortType.ASC}`)
        .join(',');
      urlWithPayload += `&order=${order}`;
    }

    return urlWithPayload;
  };

  return {
    filterPayloadState,
    handleSetFilters,
    getUrlPayload,
    setDatesIsValid,
    datesIsValid,
    setCategoryFilter,
    categoryFilter,
    setFromFilter,
    fromFilter,
    setToFilter,
    toFilter,
    setEntityFilter,
    setCpFilter,
    setTypeFilter,
    setCcyFilter,
    setSymbolFilter,
    symbolFilter,
    setSymbolTypeFilter,
    symbolTypeFilter,
    setBreakCategoryFilter,
    setSortingParams,
    sortingParams,
  };
}

export function useReconciledTable() {
  const [reconciledTransactions, setReconciledTransactions] = useState<
    ReconciledTransaction[]
  >([]);

  const groupedReconciledTransactions = useMemo(
    () =>
      uniq(reconciledTransactions.map((i) => i.group_id))
        .reduce((acc: ModifyReconciledTransaction[], groupId) => {
          const filteredByGroupId = reconciledTransactions.filter(
            (i) => i.group_id === groupId,
          );

          const cpTransactions = filteredByGroupId.reduce(
            (items: ModifyReconciledTransaction[], i) =>
              i.cp_id
                ? [...items, { ...i, cp_report_date: i.report_date }]
                : items,
            [],
          );
          const boTransactions = filteredByGroupId.reduce(
            (items: ModifyReconciledTransaction[], i) =>
              i.bo_id
                ? [...items, { ...i, bo_report_date: i.report_date }]
                : items,
            [],
          );

          let moreTransactions: ModifyReconciledTransaction[] = [];
          let lessTransactions: ModifyReconciledTransaction[] = [];
          if (boTransactions.length >= cpTransactions.length) {
            moreTransactions = boTransactions;
            lessTransactions = cpTransactions;
          }
          if (cpTransactions.length > boTransactions.length) {
            moreTransactions = cpTransactions;
            lessTransactions = boTransactions;
          }
          const buf = moreTransactions.map((item, index) => {
            if (lessTransactions[index]) {
              return { ...item, ...lessTransactions[index] };
            }
            return item;
          });
          buf.forEach((item, index) => {
            if (index === 0) {
              acc.push(item);
            } else {
              acc.push({ ...item, group_id: null });
            }
          });
          return acc;
        }, [])
        .map((item, index) => ({
          ...item,
          id: index,
        })),
    [reconciledTransactions],
  );

  return {
    groupedReconciledTransactions,
    setReconciledTransactions,
  };
}
