import { Form, Input, InputNumber, Popconfirm, Table } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import './index.css';

const DISPLAY_COLS = [
  'report_date',
  'cp',
  'cp_symbol',
  'bo_symbol',
  'qty',
  'timestamp',
  'account',
  'price',
  'qty_long',
  'qty_short',
  'cp_qty',
  'value_date',
];
const EDITABLE_COLUMNS_ARR = [
  'bo_symbol',
  'qty',
  'qty_long',
  'qty_short',
  'cp_qty',
  'price',
];
const EDITABLE_COLUMNS_TYPES = {
  qty: 'number',
  qty_short: 'number',
  qty_long: 'number',
  cp_qty: 'number',
  price: 'number',
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableTable = ({ rawData }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [baseColumns, setBaseColumns] = useState([]);

  const extractColumns = (arr) => {
    const obj = arr[0];
    const extractedCols = [];

    // eslint-disable-next-line
    for (const k of Object.keys(obj)) {
      if (DISPLAY_COLS.indexOf(k) < 0) {
        // eslint-disable-next-line
        continue;
      }

      extractedCols.push({
        title: k,
        dataIndex: k,
        editable: EDITABLE_COLUMNS_ARR.indexOf(k) > -1,
      });
    }
    return extractedCols;
  };

  useEffect(() => {
    setData(rawData);
    setBaseColumns(extractColumns(rawData));
  }, rawData);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        const apiService = new ReconTablesApiService();
        apiService
          .patchPositionReconciliation(item.id)
          .then(() => {
            newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            setEditingKey('');
            alert('Saved');
          })
          .catch((e) => {
            alert(`Failed to update position reconciliation entry ${e}`);
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      alert('Validate Failed:', errInfo);
    }
  };

  const columns = [
    ...baseColumns,
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="#"
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          EDITABLE_COLUMNS_TYPES[col.dataIndex] === undefined
            ? 'text'
            : EDITABLE_COLUMNS_TYPES[col.dataIndex],
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: { cell: EditableCell },
        }}
        size="small"
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{ onChange: cancel }}
      />
    </Form>
  );
};
