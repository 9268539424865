import { FC, useEffect, useMemo, useState } from 'react';
import {
  Panel,
  Button,
  Autocomplete,
  Notification,
  Input,
  Tooltip,
} from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService, {
  CreateRawDataReportPayload,
  ParsingModuleParam,
} from 'services/ReconTablesApiService';

import { ModuleItem, ModuleValue } from '../ModuleItem/ModuleItem';

const api = new ReconTablesApiService();

export const NewRawData: FC = () => {
  const cpList = useAppSelector((state) => state.counterParties);
  const [parsingModuleItems, setParsingModuleItems] = useState<
    { name: string }[]
  >([]);

  const [name, setName] = useState('');
  const [cp, setCp] = useState('');
  const [module, setModule] = useState('');

  const [selectedModuleParams, setSelectedModuleParams] = useState<
    ParsingModuleParam[]
  >([]);
  const [selectedModuleValues, setSelectedModuleValues] = useState<
    ModuleValue[]
  >([]);

  const handleChangeValues = (value: ModuleValue) => {
    const editedValues = selectedModuleValues.find(
      (i) => i.param_name === value.param_name,
    );
    if (editedValues) {
      const withoutEdited = selectedModuleValues.filter(
        (i) => i.param_name !== value.param_name,
      );
      setSelectedModuleValues([...withoutEdited, value]);
    }
  };

  const cpOptions = useMemo(
    () =>
      cpList.map((i) => ({
        label: i,
        value: i,
      })),
    [cpList],
  );
  const parsingModuleOptions = useMemo(
    () =>
      parsingModuleItems.map((i) => ({
        label: i.name.toUpperCase(),
        value: i.name,
      })),
    [parsingModuleItems],
  );

  const paramValuesMap = (param: ParsingModuleParam) => {
    let defaultValue: string | boolean = '';
    if (param.param_type === 'bool') {
      defaultValue = Boolean(param.default_value);
    }
    if (
      param.default_value &&
      (param.param_type === 'string' || param.param_type === 'select')
    ) {
      defaultValue = param.default_value;
    }
    return {
      param_name: param.param_name,
      param_value: defaultValue,
    };
  };
  useEffect(() => {
    if (!module) {
      return;
    }
    api
      .getParsingModuleParams(module)
      .then((res) => {
        setSelectedModuleParams(res.results);
        setSelectedModuleValues(res.results.map(paramValuesMap));
      })
      .catch((error) => {
        Notification.error(`Fetch parsing module params error: ${error}`);
      });
  }, [module]);

  useEffect(() => {
    api
      .getParsingModuleOptions()
      .then((res) => {
        setParsingModuleItems(res.results);
      })
      .catch((error) => {
        Notification.error(`Fetch parsing module options error: ${error}`);
      });
  }, []);

  const handleCreateRaw = async () => {
    const payload: CreateRawDataReportPayload = {
      cp,
      name,
      module,
      module_configs: selectedModuleValues.map((item) => ({
        param_value: String(item.param_value),
        param_name: item.param_name,
      })),
    };
    await api.createRawDataReportConfig(payload);
    setName('');
    setModule('');
    setSelectedModuleValues([]);
  };

  const saveActions = (
    <div className="d-flex align-items-center">
      <Button className="mr-2" onClick={handleCreateRaw}>
        Create
      </Button>
    </div>
  );
  return (
    <Panel title="Add raw data" action={saveActions}>
      <div className="mt-4">
        <Input
          label="Name"
          className="mb-3"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Autocomplete
          placeholder="Cp"
          className="w-100 mb-3"
          options={cpOptions}
          onChange={(event, newValue: any) => {
            setCp(newValue?.value);
          }}
        />
        <Autocomplete
          placeholder="Module"
          className="w-100"
          options={parsingModuleOptions}
          onChange={(event, newValue: any) => {
            setModule(newValue?.value);
          }}
        />
        {selectedModuleParams.map((item) => (
          <div className="my-3" key={item.id}>
            {item.description ? (
              <Tooltip title={item.description}>
                <ModuleItem
                  item={item}
                  onChangeValue={handleChangeValues}
                  values={selectedModuleValues}
                />
              </Tooltip>
            ) : (
              <ModuleItem
                item={item}
                onChangeValue={handleChangeValues}
                values={selectedModuleValues}
              />
            )}
          </div>
        ))}
      </div>
    </Panel>
  );
};
