import { useEffect, useState } from 'react';

export function CpTableRow(props) {
  const { entry, selectAll, setSelectedRows } = props;
  const [selected, setSelected] = useState(false);

  const onSelected = () => {
    setSelected(!selected);
    setSelectedRows(entry.id, !selected, entry.amount);
  };

  useEffect(() => {
    setSelected(selectAll);
    setSelectedRows(entry.id, selectAll, entry.amount);
  }, [selectAll]);

  return (
    <tr key={entry.id} id={entry.id}>
      <td>
        <input type="checkbox" onChange={onSelected} checked={selected} />
      </td>
      <td>{entry.report_date}</td>
      <td>{entry.cp}</td>
      <td>{entry.operationtype}</td>
      <td>{entry.symboltype}</td>
      <td>{entry.ccy}</td>
      <td>{entry.amount}</td>
      <td>{entry.symbol}</td>
      {window.location.pathname === '/dividends-coupons-reconcile' ? (
        <td>entry.isin</td>
      ) : undefined}
    </tr>
  );
}
