import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import SeriesMappingFormAdd from './components/form-add';
import SeriesMappingFormSearch from './components/form-search';
import SeriesMappingTable from './components/table';

class SeriesMappingPage extends Component {
  apiService = new ReconTablesApiService();

  // eslint-disable-next-line
  state = {
    request: false,
    error: false,
    mappingEntries: [],
  };

  componentDidMount() {
    this.getSeriesMapping();
  }

  onMappingChanged = () => {
    this.getSeriesMapping();
  };

  updateSearchResults = (data) => {
    this.setState({ request: false, mappingEntries: data.results });
  };

  getSeriesMapping = (cp, cpSeries, exanteSeries) => {
    this.setState({ request: true, error: false });
    const filterPayload = {
      cp,
      cp_symbol: cpSeries,
      exante_symbol: exanteSeries,
    };
    this.apiService
      .getListShortMapping(filterPayload)
      .then((data) => {
        this.setState({ request: false, mappingEntries: data.results });
      })
      .catch((e) => {
        this.setState({ request: false, error: true });
        alert(e);
      });
  };

  render() {
    const spinner = this.state.request ? <Spinner /> : null;
    const error = this.state.error ? <h2>Error!</h2> : null;

    let table = this.state.request ? undefined : (
      <SeriesMappingTable
        data={this.state.mappingEntries}
        onMappingChanged={this.onMappingChanged}
      />
    );

    if (table && this.state.mappingEntries.length === 0) {
      table = <h5>Nothing found</h5>;
    }

    return (
      <>
        <SeriesMappingFormAdd onMappingChanged={this.onMappingChanged} />
        <br />
        <SeriesMappingFormSearch updateSearchResults={this.getSeriesMapping} />
        {spinner}
        {table}
        {error}
      </>
    );
  }
}

export default SeriesMappingPage;
