import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';

import ReconTablesApiService from '../../../services/ReconTablesApiService';

import AutoMappingTable from './auto-mapping-table';
import AutoMappingForm from './form';

class AutoMappingView extends Component {
  apiService = new ReconTablesApiService();

  constructor(props) {
    super(props);
    this.state = {
      mappings: [],
      loading: false,
      date: undefined,
    };
  }

  onFormSubmit = (date, counterparty) => {
    this.setState({
      loading: true,
      mappings: [],
      date,
    });

    this.apiService
      .searchOneToOneMapping(date, counterparty)
      .then((data) => {
        this.setState({
          mappings: data,
          loading: false,
        });
      })
      .catch((e) => {
        alert(`Failed to fetch One to One mapping: ${e}`);
        this.setState({ loading: false });
      });
  };

  render() {
    const spinner = this.state.loading ? <Spinner /> : undefined;
    return (
      <div className="container-fluid">
        <div className="mb-3">
          <AutoMappingForm onSubmit={this.onFormSubmit} />
        </div>
        {spinner}
        <div>
          <AutoMappingTable
            mappings={this.state.mappings}
            date={this.state.date}
            onMappingChanged={this.props.onMappingChanged}
          />
        </div>
      </div>
    );
  }
}

export default AutoMappingView;
