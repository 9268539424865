import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import { CPCutOffTimeTable } from './table';

export function CPCutOffTimePage() {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);

  const reload = (withIndicator = false) => {
    const api = new ReconTablesApiService();
    if (withIndicator) {
      setLoading(true);
    }

    api
      .getListCpCutOffTime()
      .then((resp) => {
        setLoading(false);
        setEntries(resp.results);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    reload(true);
  }, []);

  return (
    <>
      {loading ? (
        <div className="mt-2">
          <Spin />
        </div>
      ) : (
        <CPCutOffTimeTable reload={() => reload(false)} entries={entries} />
      )}
    </>
  );
}
