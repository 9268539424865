import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

import { TableRow } from './table-row';

import './style.css';

class Table extends Component {
  SORT_ICON = (<i className="fa fa-fw fa-sort" />);

  // eslint-disable-next-line
  state = {
    data: [], // fetched entries for table
    reverseSort: true, // how we sort entries
    prevSortKey: undefined, // previous sort key (double click on the same key will cause reverse order)
    allChecked: false, // all rows are checked
    checkedRows: [], // checked entries for update
    cssClassBulkUpdateForm: 'card-body bg-secondary', // css for bulk update form
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ data });
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      // eslint-disable-next-line
      this.setState({ data: this.props.data });
    }
  }

  onAllChecked = () => {
    // select all entries

    // eslint-disable-next-line
    const allChecked = !this.state.allChecked;
    // eslint-disable-next-line
    const checkedRows = allChecked ? [...this.state.data] : [];
    this.setState({ allChecked, checkedRows });
  };

  onRowChecked = (row_entry, checked) => {
    // row checked callback. passed to TableRow
    /* eslint-disable */
    for (const alreadyChecked of this.state.checkedRows) {
      if (alreadyChecked.trade_number !== row_entry.trade_number) {
        continue;
      }

      if (!checked) {
        this.setState({
          checkedRows: this.state.checkedRows.filter(
            (e) => e.trade_number !== row_entry.trade_number,
          ),
        });
        return;
      }
    }
    this.setState({ checkedRows: [...this.state.checkedRows, row_entry] });
    /* eslint-enable */
  };

  updateAllChecked = (event) => {
    // call API to make updates for all selected rows (entries)
    event.preventDefault();
    const payload = getFormPayload(event.target, true);

    const api = new ReconTablesApiService();

    this.state.checkedRows.forEach((entry) => {
      const patchPayload = {
        ...entry,
      };
      if (payload.report_date !== undefined) {
        patchPayload.cp_report_date = payload.report_date;
      }
      if (payload.cp_name !== undefined) {
        patchPayload.cp_name = payload.cp_name;
      }
      api
        .patchCTrade(entry.trade_number, patchPayload)
        .then(() => {
          this.setState((state) => {
            const cssClassBulkUpdateForm =
              state.cssClassBulkUpdateForm.concat(' bg-success');
            return { cssClassBulkUpdateForm };
          });
        })
        .catch((e) => {
          this.setState((state) => {
            const cssClassBulkUpdateForm =
              state.cssClassBulkUpdateForm.concat(' bg-danger');
            return { cssClassBulkUpdateForm };
          });
          alert(e);
        });
    });
  };

  onSort = (event, sortKey) => {
    event.preventDefault();
    /* eslint-disable */
    const data = this.state.data;
    if (typeof data[0][sortKey] === 'string') {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === this.state.prevSortKey) {
      reverse = !this.state.reverseSort;
    }
    if (reverse) {
      data.reverse();
    }
    try {
      document
        .getElementById(`column-${this.state.prevSortKey}`)
        .classList.remove('text-primary');
    } catch (e) {
      console.log(e);
    }
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');

    this.setState({
      data,
      reverseSort: reverse,
      prevSortKey: sortKey,
    });
    /* eslint-enable */
  };

  render() {
    const { cpNamesLEMap, counterParties, legalEntities } = this.props;
    const rows = this.state.data.map((entry) => {
      // get all possible cp names for given entry by trade legal entity.
      // probably need to be cached, stated somewhere for faster access
      let ops = new Set(cpNamesLEMap[entry.entity_legal_malta_id]);
      ops = Array.from(ops);
      ops.sort();

      // if entry in checkedRows array than mark it as checked
      let rowIsChecked = false;
      /* eslint-disable */
      for (const checked of this.state.checkedRows) {
        // trade_number is unique across ctrades, so we can use it
        if (entry.trade_number === checked.trade_number) {
          rowIsChecked = true;
          break;
        }
      }
      /* eslint-disable */
      return (
        <TableRow
          entry={entry}
          cpNameList={ops}
          checked={rowIsChecked}
          legalEntities={legalEntities}
          onCheck={this.onRowChecked}
        />
      );
    });

    const cpOptionsList = counterParties.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));

    // form to update all selected entries with given values
    const updateAllForm = (
      <div className="container-fluid">
        <div className="card">
          <div className={this.state.cssClassBulkUpdateForm}>
            <form onSubmit={this.updateAllChecked}>
              <div className="form-row">
                <div className="col">
                  <select className="custom-select mr-sm-2" name="cp_name">
                    <option value="">All CPs</option>
                    {cpOptionsList}
                  </select>
                </div>
                <div className="col">
                  <DayPickerInput
                    inputProps={{
                      className: 'custom-select mr-sm-2',
                      name: 'report_date',
                      autoComplete: 'off',
                    }}
                  />
                </div>
                <div className="col">
                  <button className="btn btn btn-dark">
                    Update All Selected
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {updateAllForm}
        <table className="table table-bordered table-striped table-hover table-responsive table-min-height container-fluid">
          <thead>
            <tr className="thead-light">
              <th>Rec Status</th>
              <th
                onClick={(e) => this.onSort(e, 'account')}
                id="column-account"
              >
                Account{this.SORT_ICON}
              </th>
              <th onClick={(e) => this.onSort(e, 'symbol')} id="column-symbol">
                Symbol {this.SORT_ICON}
              </th>
              <th>OrderID</th>
              <th>Order Pos</th>
              <th onClick={(e) => this.onSort(e, 'qty')} id="column-qty">
                Qty{this.SORT_ICON}
              </th>
              <th onClick={(e) => this.onSort(e, 'price')} id="column-price">
                Price {this.SORT_ICON}
              </th>
              <th
                onClick={(e) => this.onSort(e, 'entity_legal_malta_id')}
                id="column-entity_legal_malta_id"
              >
                Entity{this.SORT_ICON}
              </th>
              <th onClick={(e) => this.onSort(e, 'date')} id="column-date">
                Date{this.SORT_ICON}
              </th>
              <th>CP Report Date</th>
              <th
                onClick={(e) => this.onSort(e, 'cp_name')}
                id="column-cp_name"
              >
                CP Name{this.SORT_ICON}
              </th>
              <th>Action</th>
              <th>
                Select
                <input
                  name="checkAll"
                  type="checkbox"
                  checked={this.state.allChecked}
                  onChange={this.onAllChecked}
                />
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = ({ cpToLeMap, counterParties, legalEntities }) => ({
  cpNamesLEMap: cpToLeMap,
  counterParties,
  legalEntities,
});

export default connect(mapStateToProps)(Table);
