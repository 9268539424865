import { FC, useMemo, useContext, useCallback } from 'react';
import { Table, useTableData } from 'react-ui-kit-exante';

import { ApprovalsDataContext } from '../../pages/Approvals/ApprovalsProvider';

import './ApprovalsTableStyles.css';
import {
  getApprovalsTableColumns,
  calculateCountOfPages,
} from './helpersApprovalsTable';

const ApprovalsTable: FC = () => {
  const {
    approve,
    decline,
    dataTable,
    isLoading,
    fetchDataTable,
    activeCheckbox,
    setActiveCheckbox,
  } = useContext(ApprovalsDataContext);
  const total = dataTable.total || 0;

  const getTableData = useCallback(
    ({ params }) => fetchDataTable({ params }),
    [],
  );

  const tableDataArgs: any = useMemo(
    () => ({
      data: { onFetch: getTableData },
    }),
    [getTableData],
  );

  const {
    page,
    limit,
    filters,
    setPage,
    setLimit,
    setFilter,
    removeFilter,
    resetFilters,
  } = useTableData(tableDataArgs);

  const filterProps = useMemo(
    () => ({
      filters,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  const columns = useMemo(
    () =>
      getApprovalsTableColumns({
        approve,
        decline,
        activeCheckbox,
        setActiveCheckbox,
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [dataTable, activeCheckbox],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  return (
    <Table
      hasFilters
      isFlexLayout
      pageSize={20}
      showTableInfo
      hasPagination
      columns={columns}
      isLoading={isLoading}
      className="paddingCell"
      tableId="tableApprovals"
      serverPaginationProps={{
        total,
        setPage,
        pageCount,
        pageSize: limit,
        pageIndex: page,
        setPageSize: setLimit,
      }}
      data={dataTable.data || []}
      filteringProps={filterProps}
    />
  );
};

export default ApprovalsTable;
