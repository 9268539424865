import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from '../../services/utils';

export default function TradeDashboardForm({ searchSubmit }) {
  const [cpOptionsList, setCpOptionsList] = useState([]);
  const [entitiesOptionsList, setEntitiesOptionsList] = useState([]);
  const brokerIDs = useSelector((state) => state.counterParties);
  const entities = useSelector((state) => state.legalEntities);

  useEffect(() => {
    let options = brokerIDs.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));
    options = [
      <option key="any" value="">
        Any
      </option>,
      ...options,
    ];
    setCpOptionsList(options);
  }, [brokerIDs]);

  useEffect(() => {
    const options = [];
    options.push(
      <option key="any" value="">
        Any
      </option>,
    );
    /* eslint-disable */
    for (const key of Object.keys(entities)) {
      options.push(
        <option key={key} value={entities[key]}>
          {entities[key]}
        </option>,
      );
    }
    /* eslint-enable */
    setEntitiesOptionsList(options);
  }, [entities]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    searchSubmit(payload);
  };

  return (
    <div className="container-fluid mt-3">
      <form onSubmit={onSubmit} name="TradeDashboardForm">
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'report_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp">
                {cpOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select mr-sm-2" name="legal_entity">
                {entitiesOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Status</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="status_bo_status_cp"
              >
                <option key="any" value="">
                  Any
                </option>
                <option key="ok" value="Ok | Ok">
                  Ok|Ok
                </option>
                <option key="not_ok" value="Not ok | Not ok">
                  Not ok|Not ok
                </option>
                <option key="not_ok" value="Ok | Not ok">
                  Ok|Not ok
                </option>
                <option key="not_ok" value="Ok | N/A">
                  Ok|N/A
                </option>
                <option key="not_ok" value="Not ok | Ok">
                  Not ok|Ok
                </option>
                <option key="not_ok" value="Not ok | N/A">
                  Not ok|N/A
                </option>
              </select>
            </div>
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary">
              Load
            </button>
          </div>
        </div>
      </form>
      <hr />
    </div>
  );
}
