import { Component } from 'react';
import { connect } from 'react-redux';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

class AddSymbolCOTForm extends Component {
  // eslint-disable-next-line
  state = { processing: false };

  onSubmit = (event) => {
    this.setState({ processing: true });
    event.preventDefault();
    const payload = getFormPayload(event.target, false);
    try {
      // sanity check for format
      // eslint-disable-next-line
      const [hours, minutes, seconds] = payload.time.split(':');
    } catch (e) {
      alert('Wrong time format. Expected HH:MM:SS');
    }
    const api = new ReconTablesApiService();
    api
      .postSymbolCutOffTime(payload)
      .then(() => {
        this.setState({ processing: false });
        this.props.onAdded();
      })
      .catch((e) => {
        this.setState({ processing: false });
        alert(e);
      });
  };

  render() {
    const { counterParties } = this.props;
    const cpOptions = !counterParties
      ? []
      : counterParties.map((cp) => (
          <option key={cp} value={cp}>
            {cp.toUpperCase()}
          </option>
        ));

    return (
      <div className="container mb-3 mt-3">
        <form onSubmit={this.onSubmit} name="addSymbolCOTForm">
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Symbol</div>
                </div>
                <input type="text" className="form-control" name="symbol" />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Time</div>
                </div>
                <input type="text" className="form-control" name="time" />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Counterparty</div>
                </div>
                <select className="custom-select mr-sm-2" name="cp">
                  {cpOptions}
                </select>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="mx-auto">
              {this.state.processing ? (
                <Spinner />
              ) : (
                <button className="btn btn-outline-success" type="submit">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ counterParties }) => ({ counterParties });

export default connect(mapStateToProps)(AddSymbolCOTForm);
