import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CpMapping } from 'services/ReconTablesApiService';

interface CommonState {
  eodResults: string[];
  loading: boolean;
  counterParties: string[];
  cpNames: Record<string, string>;
  cpList: CpMapping[];
  cpToLeMap: Array<string[]>;
  transactionKinds: string[];
  currencies: string[];
  operationTypes: string[];
  legalEntities: Record<string | number, string>;
  cTradesTableTrades: string[];
  positionDiffResults: string[];
  loadingDiffResults: boolean;
  positionDiffResultsCP?: string;
  loadingMetaData: boolean;
  formPayload: any;
  mode: string;
}

const initialState: CommonState = {
  eodResults: [],
  loading: false,
  counterParties: [],
  cpNames: {},
  cpList: [],
  cpToLeMap: [],
  transactionKinds: [],
  currencies: [],
  operationTypes: [],
  legalEntities: {},
  cTradesTableTrades: [],
  positionDiffResults: [],
  loadingDiffResults: false,
  positionDiffResultsCP: undefined,
  loadingMetaData: false,
  formPayload: null,
  mode: '',
};

interface MetaDataPayload {
  cp_list: CpMapping[];
  le_cp_names_dict: Array<string[]>;
  cp_names_list: string[];
  cp_names_dict: Record<string, string>;
  le_names: Record<string | number, string>;
  ccy_list: string[];
  operation_type_list: string[];
  transaction_kinds: string[];
}

export const commonSlice = createSlice({
  name: 'commonState',
  initialState,
  reducers: {
    metaDataLoaded(state, action: PayloadAction<MetaDataPayload>) {
      state.counterParties = action.payload.cp_names_list.length
        ? action.payload.cp_names_list.sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase()),
          )
        : [];
      state.cpList = action.payload.cp_list;
      state.cpNames = action.payload.cp_names_dict;
      state.legalEntities = action.payload.le_names;
      state.cpToLeMap = action.payload.le_cp_names_dict;
      state.currencies = action.payload.ccy_list;
      state.operationTypes = action.payload.operation_type_list?.sort();
      state.transactionKinds = action.payload.transaction_kinds;
      state.loadingMetaData = false;
    },
    markMetaDataLoading(state) {
      state.loadingMetaData = true;
    },
    setPositionDiffResults(state, action: PayloadAction<string[]>) {
      state.positionDiffResults = action.payload;
      state.loadingDiffResults = false;
    },
    setPositionDiffResultsLoading(state) {
      state.positionDiffResults = [];
      state.loadingDiffResults = true;
    },
    setPositionDiffResultsCP(state, action: PayloadAction<string>) {
      state.positionDiffResultsCP = action.payload;
    },
    setSearchPayload(state, action: PayloadAction<any>) {
      state.formPayload = action.payload;
    },
    setMode(state, action: PayloadAction<string>) {
      state.mode = action.payload;
    },
  },
});

export default commonSlice.reducer;
