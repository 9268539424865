import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { Spinner } from 'components/Spinner/Spinner';
import ReconRunnerApiService from 'services/ReconRunnerApiService';
import { formatDate } from 'services/date-funcs';

import ScriptRunNotifications from './notifications';

class RunScriptPage extends Component {
  // eslint-disable-next-line
  state = {
    allScripts: [],
    selectedScripts: [],
    selectedDate: undefined,
    loading: false,
    error: false,
    runNotifications: [],
  };

  componentDidMount() {
    this.getScripts();
    if (this.props.isTradeRecon) {
      this.setState({ updateViewsCheckboxChecked: false });
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.group !== this.props.group) {
      this.getScripts();
    }
  }

  onDateSelected = (dateSelected) => {
    this.setState({ selectedDate: formatDate(dateSelected) });
  };

  getScripts = () => {
    const { group } = this.props;
    const apiService = new ReconRunnerApiService();

    this.setState({
      loading: true,
      error: false,
      selectedDate: undefined,
      selectedScripts: [],
      runNotifications: [],
    });

    apiService
      .getScripts(group)
      .then((json) => {
        this.setState({
          allScripts: json,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  makeScriptsTable = () => {
    const rows = [];

    const columnsInRow = 5;
    const onChange = this.scriptTicked;

    const byLE = {};
    const unknown = 'unknown';
    this.state.allScripts.forEach((s) => {
      const le = s.related_legal_entity ? s.related_legal_entity : unknown;
      let another = byLE[le];
      if (another === undefined) {
        another = [];
      }
      another.push(s);
      byLE[le] = another;
    });

    function toRowWrapper() {
      let columns = [];
      return function toRow(value, index, array) {
        const elID = `runner-${value.name}`;

        if (columns.length > columnsInRow) {
          rows.push(<tr>{columns}</tr>);
          columns = [];
        }

        let badge = null;

        if (value.current_state === 'failed') {
          badge = <span className="badge badge-danger ml-1">failed</span>;
        }
        if (value.current_state === 'running') {
          badge = <span className="badge badge-primary ml-1">running</span>;
        }
        if (value.current_state === 'success') {
          badge = <span className="badge badge-success ml-1">success</span>;
        }
        if (value.current_state === 'data not found') {
          badge = (
            <span className="badge badge-warning ml-1">data not found</span>
          );
        }

        columns.push(
          <td>
            <div className="container-fluid">
              <input
                type="checkbox"
                className="form-check-input"
                id={elID}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor={elID}>
                {value.name}
              </label>
              {badge}
            </div>
          </td>,
        );

        if (index === array.length - 1) {
          rows.push(<tr>{columns}</tr>);
        }
      };
    }

    Object.keys(byLE).forEach((k) => {
      if (byLE[k].length === 0) {
        return;
      }
      rows.push(
        <tr>
          <td colSpan={6}>
            <p>{k.toUpperCase()}</p>
          </td>
        </tr>,
      );

      const f = toRowWrapper();
      byLE[k].forEach(f);
    });

    return rows;
  };

  scriptTicked = (e) => {
    const checkbox = e.target;
    const scriptName = checkbox.id.slice('runner-'.length);
    let composeName = scriptName;
    /* eslint-disable */
    for (const sc of this.state.allScripts) {
      if (sc.name === scriptName) {
        composeName = sc.compose_name;
      }
    }
    /* eslint-enable */
    if (checkbox.checked) {
      this.addSelectedScript(composeName);
      return;
    }
    this.removeSelectedScript(composeName);
  };

  addSelectedScript = (name) => {
    // eslint-disable-next-line
    const alreadySelected = this.state.selectedScripts;
    alreadySelected.push(name);
    // eslint-disable-next-line
    this.setState({ selectedScripts: alreadySelected });
  };

  removeSelectedScript = (name) => {
    // eslint-disable-next-line
    const newScriptsList = this.state.selectedScripts.filter(
      (value) => value !== name,
    );
    this.setState({ selectedScripts: newScriptsList });
  };

  addRunNotification = (respJson) => {
    const rawScriptsNames = respJson.scripts;
    const formattedScriptNames = [];
    /* eslint-disable */
    for (const rn of rawScriptsNames) {
      let formattedName = rn;
      for (const script of this.state.allScripts) {
        if (script.name === rn) {
          formattedName = script.name;
          break;
        }
      }
      formattedScriptNames.push(formattedName);
    }
    /* eslint-enable */

    respJson.scripts = formattedScriptNames;
    // eslint-disable-next-line
    const notifications = [...this.state.runNotifications, respJson];
    this.setState({ runNotifications: notifications });
  };

  onCheckBoxChanged = (e) => {
    const checkBoxName = `${e.target.id.split('-')[1]}Checked`;
    // eslint-disable-next-line
    this.setState({ [checkBoxName]: !this.state[checkBoxName] });
  };

  shouldUpdateViews = () => {
    const updateViewsCheckBox = document.getElementById(
      'check-updateViewsCheckbox',
    );
    return updateViewsCheckBox === null
      ? undefined
      : updateViewsCheckBox.checked;
  };

  fireRunScripts = (useBoDrive, onlyPost) => {
    const payload = {
      scripts: this.state.selectedScripts,
      force_run: true,
      use_bo_drive: useBoDrive,
    };

    if (this.state.selectedDate) {
      payload.date = this.state.selectedDate;
    }

    if (this.shouldUpdateViews()) {
      payload.update_mapping = true;
      payload.update_table = true;
    }

    const apiService = new ReconRunnerApiService();
    apiService
      .runScripts(payload, onlyPost)
      .then((json) => {
        this.addRunNotification(json);
      })
      .catch((e) => {
        this.addRunNotification({
          status: 'error',
          message: e.message,
          scripts: [...this.state.selectedScripts],
        });
      });
  };

  onRunReconciliationClick = (e) => {
    e.preventDefault();
    this.fireRunScripts(false, true);
  };

  onRunParsingAndReconciliationClick = (e) => {
    e.preventDefault();
    this.fireRunScripts(false, false);
  };

  onRunParsingAndReconciliationFromBoDrive = (e) => {
    e.preventDefault();
    this.fireRunScripts(true, false);
  };

  onSimpleRunClick = (e) => {
    e.preventDefault();
    this.fireRunScripts(false, false);
  };

  getButtonGroup = () => {
    if (!this.props.isTradeRecon) {
      return (
        <button
          className="btn btn-primary"
          onClick={this.onSimpleRunClick}
          type="button"
        >
          Run
        </button>
      );
    }

    return (
      <>
        <div className="col-2">
          <button
            className="btn btn-primary"
            onClick={this.onRunParsingAndReconciliationClick}
            type="button"
          >
            Run Parsing & Recon
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary"
            onClick={this.onRunParsingAndReconciliationFromBoDrive}
            type="button"
          >
            Run Parsing & Recon (BO Drive)
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary"
            onClick={this.onRunReconciliationClick}
            type="button"
          >
            Run Recon
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            className="form-check-input"
            id="check-updateViewsCheckbox"
            checked={this.state.updateViewsCheckboxChecked}
            onClick={this.onCheckBoxChanged}
          />
          <label
            className="form-check-label"
            htmlFor="check-updateViewsCheckbox"
          >
            Update views
          </label>
        </div>
      </>
    );
  };

  render() {
    const tableRows = this.makeScriptsTable();
    const buttonGroup = this.getButtonGroup();

    let container = (
      <form className="container-fluid">
        <table className="table table-bordered table-striped text-left">
          <tbody>{tableRows}</tbody>
        </table>
        <div className="d-flex justify-content-center align-items-center">
          {buttonGroup}
        </div>
      </form>
    );

    const dateSelector = this.props.hasDate ? (
      <DayPickerInput
        onDayChange={this.onDateSelected}
        inputProps={{ className: 'custom-select mr-sm-2', autocomplete: 'off' }}
      />
    ) : undefined;

    if (this.state.loading) {
      container = <Spinner />;
    } else if (this.state.error) {
      container = <h2>Error!</h2>;
    } else if (this.state.allScripts.length === 0) {
      container = <h2>No scripts found</h2>;
    }

    return (
      <>
        <div className="container-fluid mt-3 mb-3">
          {this.props.hasDate ? (
            <h2>Run scripts for date</h2>
          ) : (
            <h2>Run scripts</h2>
          )}
          {dateSelector}
        </div>
        {container}
        <ScriptRunNotifications runResults={this.state.runNotifications} />
      </>
    );
  }
}

export default RunScriptPage;
