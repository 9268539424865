import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Notification,
  NotificationProvider,
  Select,
} from 'react-ui-kit-exante';

import {
  FilterValueType,
  ReconEmirApiService,
  ScriptItem,
} from 'services/ReconEmirApiService';

import { EmirScriptItem } from './script-param-item';

const api = new ReconEmirApiService();

export const RunScripts: FC = () => {
  const [scripts, setScripts] = useState<ScriptItem[]>([]);
  const [selectScriptName, setSelectScriptName] = useState<string>('');
  const [paramValues, setParamValues] = useState<
    Record<string, string | boolean>
  >({ valid: false });

  useEffect(() => {
    api
      .getScripts()
      .then((res) => {
        setScripts(res.scripts);
      })
      .catch(() => {
        Notification.error('Scripts no load');
      });
  }, []);

  useEffect(() => {
    setParamValues({});
  }, [selectScriptName]);

  const selectScriptParams = useMemo(() => {
    const foundScriptParams = scripts.find(
      (i) => i.name === selectScriptName,
    )?.params;
    return foundScriptParams
      ? Object.keys(foundScriptParams).map((i) => ({
          name: i,
          type: foundScriptParams[i],
        }))
      : [];
  }, [scripts, selectScriptName]);

  const handleRunScript = useCallback(async () => {
    const paramsObject: Record<string, string | boolean> =
      selectScriptParams.reduce(
        (acc, i) =>
          i.type === FilterValueType.BOOLEAN
            ? { ...acc, [i.name]: false }
            : { ...acc, [i.name]: '' },
        {},
      );

    try {
      await api.runScript({
        ...paramsObject,
        ...paramValues,
        script: selectScriptName,
      });
      Notification.success(`The script has been successfully launched`);
    } catch (e) {
      Notification.error(`Script run error: ${e}`);
    }
  }, [selectScriptName, selectScriptParams]);

  const options = useMemo(
    () => scripts.map((i) => ({ label: i.name, value: i.name })),
    [scripts],
  );

  return (
    <>
      <NotificationProvider />
      <Select
        id="selectScripts"
        className="w-100"
        value={selectScriptName}
        onChange={(event) => {
          setSelectScriptName(event.target.value);
        }}
        options={options}
        placeholder="Select script"
        sx={{
          width: 300,
        }}
      />
      {!!selectScriptParams.length &&
        selectScriptParams.map((item) => (
          <div key={item.name} className="text-left mt-4">
            <EmirScriptItem
              values={paramValues}
              setValues={setParamValues}
              type={item.type}
              fieldName={item.name}
            />
          </div>
        ))}
      {!!selectScriptName && (
        <Button fullWidth className="mt-4" onClick={handleRunScript}>
          run script
        </Button>
      )}
    </>
  );
};
