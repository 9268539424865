import { Component } from 'react';

import { BrokerSideTableRow } from './table-row';

import '../style.css';

export class ReconcileBrokersSide extends Component {
  // eslint-disable-next-line
  state = {
    trades: [],
    selected: [],
    reverseSort: true,
    prevSortKey: undefined,
  };

  componentDidMount() {
    this.setState({ trades: this.props.trades, selected: this.props.selected });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      this.setState({
        trades: this.props.trades,
        selected: this.props.selected,
      });
    }
  }

  onSelectAll = (e) => {
    const updFunc = e.target.checked
      ? this.props.onSelectAll
      : this.props.onClearAll;
    updFunc();
  };

  onSort = (event, sortKey) => {
    event.preventDefault();
    // eslint-disable-next-line
    const data = this.state.trades;
    if (typeof data[0][sortKey] === 'string') {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === this.state.prevSortKey) {
      reverse = !this.state.reverseSort;
    }
    if (reverse) {
      data.reverse();
    }

    this.setState({
      trades: data,
      reverseSort: reverse,
      prevSortKey: sortKey,
    });
  };

  render() {
    const rows =
      this.state.trades === undefined
        ? undefined
        : this.state.trades.map((trade) => {
            const rowChecked = this.state.selected.indexOf(trade.full_id) > -1;
            return (
              <BrokerSideTableRow
                trade={trade}
                selected={rowChecked}
                addTrade={this.props.onTradeAdded}
                removeTrade={this.props.onTradeRemoved}
              />
            );
          });
    const title = this.props.qtySelected ? (
      <h3>
        Broker Trades{' '}
        <span className="badge badge-primary">
          Selected QTY {this.props.qtySelected}
        </span>
      </h3>
    ) : (
      <h3>Broker Trades</h3>
    );

    return (
      <div className="col">
        {title}
        <div className="table-wrapper">
          <table className="table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <th>
                    <input type="checkbox" onChange={this.onSelectAll} />
                  </th>
                </th>
                <th onClick={(e) => this.onSort(e, 'symbol')}>
                  Symbol
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th onClick={(e) => this.onSort(e, 'qty')}>
                  Qty
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th onClick={(e) => this.onSort(e, 'price')}>
                  Price
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>CCY</th>
                <th onClick={(e) => this.onSort(e, 'value')}>
                  Value
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>FullID</th>
                <th>Date</th>
                <th>BrokerID</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
