import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { commonSlice } from 'store/reducers/commonReducer';

const MetaDataFetcher = ({ children }) => {
  const dispatch = useDispatch();
  const apiService = new ReconTablesApiService();

  useEffect(() => {
    dispatch(commonSlice.actions.markMetaDataLoading());

    const leCpNamesDict = {};
    const cpNamesList = new Set();
    const cpNamesDict = {};
    const ccyList = new Set();
    const operationTypeList = new Set();
    const transactionKinds = new Set();
    const leNames = {};

    apiService
      .getListCPMapping()
      .then((response) => {
        // eslint-disable-next-line
        for (const entry of response.results) {
          if (!entry.active) {
            continue;
          }
          cpNamesList.add(entry.cp);
          cpNamesDict[entry.cp] = entry.cp_type;

          if (leCpNamesDict[entry.malta_entity] === undefined) {
            leCpNamesDict[entry.malta_entity] = [entry.cp];
          } else {
            leCpNamesDict[entry.malta_entity].push(entry.cp);
          }
        }

        apiService
          .getListBOEntityLegalName()
          .then((res) => {
            // eslint-disable-next-line
            for (const entry of res.results) {
              leNames[entry.id_in_ctrades] = entry.name;
            }
            // eslint-disable-next-line
            for (const k of Object.keys(leCpNamesDict)) {
              leCpNamesDict[k].sort();
            }

            apiService
              .getListCurrency()
              .then((tempRes) => {
                // eslint-disable-next-line
                for (const entry of tempRes.results) {
                  ccyList.add(entry.name);
                }

                apiService
                  .getListOperationType()
                  .then((tempSecondRes) => {
                    // eslint-disable-next-line
                    for (const entry of tempSecondRes.results) {
                      operationTypeList.add(entry.name);
                    }
                    apiService
                      .getListTransactionOperationType()
                      .then((tempThreeRes) => {
                        // eslint-disable-next-line
                        for (const entry of tempThreeRes.results) {
                          transactionKinds.add(entry.name);
                        }

                        const metaData = {
                          cp_list: response.results,
                          le_cp_names_dict: leCpNamesDict,
                          cp_names_list: Array.from(cpNamesList).sort((a, b) =>
                            a.toLowerCase().localeCompare(b.toLowerCase()),
                          ),
                          cp_names_dict: cpNamesDict,
                          le_names: leNames,
                          ccy_list: Array.from(ccyList),
                          operation_type_list: Array.from(operationTypeList),
                          transaction_kinds: Array.from(transactionKinds),
                        };
                        dispatch(commonSlice.actions.metaDataLoaded(metaData));
                      })
                      .catch((e) => {
                        alert(
                          `Failed to fetch metadata for Transaction Operation Type: ${e}. Please reload`,
                        );
                      });
                  })
                  .catch((e) => {
                    alert(
                      `Failed to fetch metadata for Operation Type: ${e}. Please reload.`,
                    );
                  });
              })
              .catch((e) => {
                alert(
                  `Failed to fetch metadata for Currency: ${e}. Please reload.`,
                );
              });
          })
          .catch((e) => {
            alert(`Failed to fetch metadata: ${e}. Please reload.`);
          });
      })
      .catch((e) => {
        alert(`Failed to fetch metadata: ${e}. Please reload.`);
      });
  }, []);

  return children;
};

export default MetaDataFetcher;
