import { useEffect, useState } from 'react';

import ScriptRunNotifications from '../run-script-page/notifications';

import { BankTableRow } from './bank-table-row';
import { BOTableRow } from './bo-table-row';
import { ReconciledRow } from './reconciled-row';
import './bank_style.css';

export function BankReconcileTable(props) {
  const {
    results,
    bankReconcileRequest,
    reUploadResults,
    changeCommentRequest,
  } = props;
  const [leftSelected, updateLeftSelected] = useState({});
  const [rightSelected, updateRightSelected] = useState({});
  const [data, setData] = useState(results);
  const [comment, setComment] = useState();
  const [leftSelectAll, setLeftSelectAll] = useState(false);
  const [rightSelectAll, setRightSelectAll] = useState(false);
  // eslint-disable-next-line
  const [scriptStatus, _] = useState();

  const [leftSortedRows, setLeftSortedRows] = useState([]);
  const [prevLeftSortKey, setLeftPrevSortKey] = useState();
  const [reverseLeftSort, setLeftReverseSort] = useState(false);

  const [rightSortedRows, setRightSortedRows] = useState([]);
  const [prevRightSortKey, setRightPrevSortKey] = useState();
  const [reverseRightSort, setRightReverseSort] = useState(false);

  useEffect(() => {
    setData(results);
    if (results !== undefined) {
      setLeftSortedRows(results.results.left_side);
      setRightSortedRows(results.results.right_side);
    }
  }, [results]);

  if (data === undefined || data.length === 0) {
    return <h3>No data</h3>;
  }

  const onReconcile = () => {
    const leftSelectedArray = [];
    const rightSelectedArray = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(leftSelected)) {
      if (value === true) {
        leftSelectedArray.push(key);
      }
    }
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(rightSelected)) {
      if (value === true) {
        rightSelectedArray.push(key);
      }
    }
    const arraySum = [];
    leftSortedRows.forEach((entry) => {
      if (leftSelectedArray.includes(entry.bank_id.toString())) {
        arraySum.push(entry.bank_sum);
      }
    });
    rightSortedRows.forEach((entry) => {
      if (rightSelectedArray.includes(entry.bo_id.toString())) {
        arraySum.push(entry.bo_sum);
      }
    });
    const sumTxs = arraySum.reduce((a, b) => Math.abs(a) - Math.abs(b), 0);
    if (Math.abs(sumTxs) > 0.2) {
      if (
        window.confirm(
          `Wrong sum transactions: ${sumTxs}\nDo you want to reconcile them?`,
        )
      ) {
        bankReconcileRequest(leftSelectedArray, rightSelectedArray);
        alert('Status: ok');
        reUploadResults();
      }
    } else {
      bankReconcileRequest(leftSelectedArray, rightSelectedArray);
      alert('Status: ok');
      reUploadResults();
    }
  };

  const onSelectAll = (value) => {
    setLeftSelectAll(value);
    setRightSelectAll(value);
  };

  const onLeftSelectAll = () => {
    setLeftSelectAll(!leftSelectAll);
  };

  const onRightSelectAll = () => {
    setRightSelectAll(!rightSelectAll);
  };

  const setLeftSelected = (id, status) => {
    updateLeftSelected((tempSelected) => ({ ...tempSelected, [id]: status }));
  };

  const setRightSelected = (id, status) => {
    updateRightSelected((tempSelected) => ({
      ...tempSelected,
      [id]: status,
    }));
  };

  const onLeftSort = (event, sortKey) => {
    event.preventDefault();
    const newData = [...leftSortedRows];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevLeftSortKey) {
      reverse = !reverseLeftSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setLeftPrevSortKey(sortKey);
    setLeftSortedRows(newData);
    setLeftReverseSort(reverse);

    document
      .getElementById(`column-${prevLeftSortKey}`)
      .classList.remove('text-primary');
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');
  };

  const getLeftSide = () => {
    const bankRows = leftSortedRows.map((entry) => (
      <BankTableRow
        entry={entry}
        selectAll={leftSelectAll}
        setSelectedRows={setLeftSelected}
        reUploadResults={reUploadResults}
      />
    ));

    return (
      <div className="col-7">
        <h3>CP Transactions</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onLeftSelectAll}
                    checked={leftSelectAll}
                  />
                </th>
                <th>tx_id</th>
                <th>book_date</th>
                <th>ccy</th>
                <th
                  onClick={(e) => onLeftSort(e, 'bank_sum')}
                  id="column-sum"
                  key="bank_sum"
                >
                  sum
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>account_id</th>
                <th>operation_type</th>
                <th>details</th>
                <th>comment</th>
              </tr>
            </thead>
            <tbody>{bankRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const onRightSort = (event, sortKey) => {
    event.preventDefault();
    const newData = [...rightSortedRows];

    if (typeof newData[0][sortKey] === 'string') {
      newData.sort((a, b) => {
        const v1 = a[sortKey] !== null ? a[sortKey] : '';
        const v2 = b[sortKey] !== null ? b[sortKey] : '';
        return v1.localeCompare(v2);
      });
    } else {
      newData.sort((a, b) => a[sortKey] - b[sortKey]);
    }

    let reverse = false;
    if (sortKey === prevRightSortKey) {
      reverse = !reverseRightSort;
    }
    if (reverse) {
      newData.reverse();
    }

    setRightPrevSortKey(sortKey);
    setRightSortedRows(newData);
    setRightReverseSort(reverse);

    document
      .getElementById(`column-${prevRightSortKey}`)
      .classList.remove('text-primary');
    document.getElementById(`column-${sortKey}`).classList.add('text-primary');
  };

  const getRightSide = () => {
    const boRows = rightSortedRows.map((entry) => (
      <BOTableRow
        entry={entry}
        selectAll={rightSelectAll}
        setSelectedRows={setRightSelected}
        reUploadResults={reUploadResults}
      />
    ));

    return (
      <div className="col-5">
        <h3>BO Transactions</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>
                  <input
                    type="checkbox"
                    onChange={onRightSelectAll}
                    checked={rightSelectAll}
                  />
                </th>
                <th>bo_id</th>
                <th>botimestamp</th>
                <th
                  onClick={(e) => onRightSort(e, 'bo_sum')}
                  id="column-sum"
                  key="bo_sum"
                >
                  sum
                  <i className="fa fa-fw fa-sort" />
                </th>
                <th>ccy</th>
                <th>account_id</th>
                <th>operation_type</th>
                <th>comment</th>
              </tr>
            </thead>
            <tbody>{boRows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const getReconciled = () => {
    const rows = data.results.reconciled.map((entry) => (
      <ReconciledRow entry={entry} reUploadResults={reUploadResults} />
    ));

    return (
      <div className="col">
        <h3>Reconciled operations</h3>
        <div className="table-wrapper">
          <table className="bank_table table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr className="thead-dark">
                <th>bo_id</th>
                <th>bo_sum</th>
                <th>bo_ccy</th>
                <th>bo_operation_type</th>
                <th>bo_account_id</th>
                <th>bo_internal_comment</th>
                <th>recon_comment</th>
                <th>group_id</th>
                <th>recon_type</th>
                <th>bank_tx_id</th>
                <th>bank_book_date</th>
                <th>bank_ccy</th>
                <th>bank_sum</th>
                <th>bank</th>
                <th>bank_account_id</th>
                <th>bank_operation_type</th>
                <th>bank_details</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const onChangeCommentSubmit = () => {
    const leftSelectedArray = [];
    const rightSelectedArray = [];

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(leftSelected)) {
      if (value === true) {
        leftSelectedArray.push(key);
      }
    }
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(rightSelected)) {
      if (value === true) {
        rightSelectedArray.push(key);
      }
    }
    const sumArrays = [...leftSelectedArray, ...rightSelectedArray];
    const newComment = comment === undefined ? null : comment;
    changeCommentRequest(sumArrays, newComment);
    alert('Status: ok');
    reUploadResults();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn btn-primary"
              onClick={() => onSelectAll(false)}
            >
              Clear selected
            </button>
            <button
              className="btn btn-danger"
              onClick={() => onSelectAll(true)}
            >
              Select all
            </button>
            <button className="btn btn-warning" onClick={onReconcile}>
              Reconcile
            </button>
            <div className="input-group mx-4">
              <span className="input-group-text">Comment: </span>
              <input
                onChange={onChangeComment}
                type="text"
                className="form-control"
              />
              <div className="input-group-prepend">
                <button
                  className="btn btn-sm btn-warning"
                  type="submit"
                  onClick={onChangeCommentSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScriptRunNotifications runResults={scriptStatus} />
      <div className="row">
        {getLeftSide()}
        {getRightSide()}
      </div>
      <div className="row mt-4">{getReconciled()}</div>
    </div>
  );
}
