import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Table,
  Button,
  NotificationProvider,
  Notification,
  ConfirmationModal,
  ISortBy,
} from 'react-ui-kit-exante';

import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService, {
  TablesWithBreakReport,
  SIDE,
  CpAndCTrades,
  CategoryComment,
} from 'services/ReconTablesApiService';
import { SortType } from 'types';
import { getSumFromArray } from 'utils/array';

import { BreakCategoryForm } from './BreakCategoryForm/BreakCategoryForm';
import {
  getTradesTableColumns,
  getReconciledTableColumns,
} from './ManualReconcileUnionUtils';
import { SearchForm } from './SearchForm/SearchForm';
import { useFilters } from './use-filters';
import { useReconciledTable } from './use-reconciled-table';

import './ManualReconcileUnion.css';

export const UnionManualReconcilePage = () => {
  const mountedPageAndCount = useRef(false);
  const mountedSortingParams = useRef(false);
  const dataHasBeenUploaded = useRef(false);

  const cpWithInfoList = useAppSelector((state) => state.cpList);
  const [confirmModalIsOpened, setConfirmModalIsOpened] = useState(false);

  const [isLoadingTrades, setIsLoadingTrades] = useState(false);
  const [cpAndCTrades, setCpAndCTrades] = useState<CpAndCTrades[]>([]);

  const [breakCategories, setBreakCategories] = useState<CategoryComment[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const {
    mountedFilterPayload,
    filterPayloadState,
    handleSetFilters,
    breakReportFilters,
    sortingParams,
    setSortingParams,
    ...allFilters
  } = useFilters(setPage);
  const {
    isLoadingReconciledTrades,
    setIsLoadingReconciledTrades,
    setReconciledTrades,
    groupedReconciledTrades,
  } = useReconciledTable();

  const [breakCategory, setBreakCategory] = useState(0);
  const [breakComment, setBreakComment] = useState('');

  const [selectedTrades, setSelectedTrades] = useState<CpAndCTrades[]>([]);

  const api = new ReconTablesApiService();

  const selectedTheirTrades = useMemo(
    () => selectedTrades.filter((i) => i.side === SIDE.THEIR),
    [selectedTrades],
  );
  const selectedOurTrades = useMemo(
    () => selectedTrades.filter((i) => i.side === SIDE.OUR),
    [selectedTrades],
  );
  const selectedTradesSum = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTrades, 'value') -
            getSumFromArray(selectedTheirTrades, 'value'),
        ).toFixed(6),
      ),
    [selectedTrades, cpAndCTrades],
  );

  const selectedTradesQty = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTrades, 'qty') -
            getSumFromArray(selectedTheirTrades, 'qty'),
        ).toFixed(6),
      ),
    [selectedTrades, cpAndCTrades],
  );

  const { selectedTradesCurrencyIsSame, reconcileEnabled } = useMemo(() => {
    const everyIsOneCcy = selectedTrades.every(
      (i) => i.ccy === selectedTrades[0].ccy,
    );
    const allCpsIgnoreCcy = selectedTrades.reduce((acc: any, item) => {
      const cpFromTransaction = cpWithInfoList.find(
        (i) => item.cp?.toUpperCase() === i.cp?.toUpperCase(),
      );
      return acc === null
        ? cpFromTransaction?.ignore_ccy
        : cpFromTransaction?.ignore_ccy && acc;
    }, null);
    return {
      selectedTradesCurrencyIsSame: everyIsOneCcy,
      reconcileEnabled:
        (selectedTrades.length >= 2 && everyIsOneCcy) ||
        (selectedTrades.length >= 2 && allCpsIgnoreCcy),
    };
  }, [selectedTrades, cpAndCTrades, cpWithInfoList]);

  const loadTrades = async (loadFirstPage = false) => {
    setIsLoadingTrades(true);
    const pageNumber = loadFirstPage ? 0 : page;
    const payload = {
      ...filterPayloadState,
      ...breakReportFilters,
      order: sortingParams
        .map((item) => `${item.id}_${item.desc ? SortType.DESC : SortType.ASC}`)
        .join(','),
    };

    try {
      const cpAndCTradesResponse = await api.getCpAndCTrades(
        payload,
        pageNumber,
        pageSize,
      );
      dataHasBeenUploaded.current = true;
      setCount(Math.ceil(cpAndCTradesResponse.total / pageSize));
      setCpAndCTrades(cpAndCTradesResponse.results);
      setTotal(cpAndCTradesResponse.total);
      setIsLoadingTrades(false);
    } catch (e) {
      setIsLoadingTrades(false);
      Notification.error('Trades no loaded');
    }
    if (loadFirstPage) {
      setSelectedTrades([]);
      try {
        setIsLoadingReconciledTrades(true);
        const response = await api.getReconciledTrades(filterPayloadState);
        setReconciledTrades(response.results);
        setIsLoadingReconciledTrades(false);
      } catch (e) {
        setIsLoadingReconciledTrades(false);
        Notification.error('Reconciled trades no loaded');
      }
    }
  };

  useEffect(() => {
    api
      .getCommentCategories({})
      .then((response) => {
        const results = response.results;
        if (results) {
          setBreakCategories(results);
          if (results[0]) {
            setBreakCategory(results[0].id);
          }
        }
      })
      .catch((e) => {
        Notification.error(e);
      });
  }, []);

  useEffect(() => {
    if (mountedFilterPayload.current) {
      loadTrades(true);
    } else {
      mountedFilterPayload.current = true;
    }
  }, [filterPayloadState]);
  useEffect(() => {
    if (mountedSortingParams.current) {
      loadTrades(true);
    } else {
      mountedSortingParams.current = true;
    }
  }, [sortingParams]);
  useEffect(() => {
    if (mountedPageAndCount.current) {
      loadTrades();
    } else {
      mountedPageAndCount.current = true;
    }
  }, [page, pageSize]);

  const handleCheckTrade = (trade: CpAndCTrades) => {
    if (selectedTrades.find((i) => i.id === trade.id)) {
      setSelectedTrades([...selectedTrades.filter((i) => i.id !== trade.id)]);
    } else {
      setSelectedTrades([...selectedTrades, trade]);
    }
  };
  const handleCheckAllOnPage = () => {
    const notSelectedOnPage = cpAndCTrades.filter(
      (i) => !selectedTrades.find((j) => j.id === i.id),
    );
    setSelectedTrades([...selectedTrades, ...notSelectedOnPage]);
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTransactionsOnPage = selectedTrades.filter((item) => {
      const foundTransaction = cpAndCTrades.find((i) => i.id === item.id);
      return !foundTransaction;
    });
    setSelectedTrades(withoutTransactionsOnPage);
  };

  const handleReconcile = async () => {
    setConfirmModalIsOpened(false);
    try {
      await api.reconcileTrades({
        ctrade_ids: selectedOurTrades.map((i) => i.id),
        cptrade_ids: selectedTheirTrades.map((i) => i.id),
      });
      Notification.success('Trades reconciled');
      await loadTrades(true);
      setSelectedTrades([]);
    } catch (e) {
      Notification.error('Reconcile error');
    }
  };

  const handleOpenReconcileConfirm = async () => {
    if (Number(selectedTradesSum) !== 0) {
      setConfirmModalIsOpened(true);
      return;
    }
    await handleReconcile();
    setConfirmModalIsOpened(false);
  };

  const handleCreateBreakCategoryComment = async () => {
    if (!breakComment) {
      Notification.error('Fill in the comment field to save');
      return;
    }
    await api.createBreakReportComment({
      category: Number(breakCategory),
      comment: breakComment,
      record_types_mapping: {
        [TablesWithBreakReport.CPTRADES]: selectedTheirTrades.map((i) => i.id),
        [TablesWithBreakReport.CTRADES]: selectedOurTrades.map((i) => i.id),
      },
    });
    setSelectedTrades([]);
    await loadTrades();
  };

  const handleUpdateBreakCategoryById = async (
    record_types_mapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => {
    await api.createBreakReportComment({
      category: categoryId,
      comment,
      record_types_mapping,
    });
    await loadTrades();
  };

  const cpBoMixedTableColumns = useMemo(
    () =>
      getTradesTableColumns(
        cpAndCTrades,
        selectedTrades.map((i) => i.id),
        handleCheckTrade,
        handleCheckAllOnPage,
        handleUnCheckAllOnPage,
        breakCategories,
        handleUpdateBreakCategoryById,
      ),
    [cpAndCTrades, breakCategories, selectedTrades],
  );
  const serverPaginationProps = {
    pageIndex: page,
    pageCount: count,
    pageSize,
    setPage,
    setPageSize,
    total,
  };

  const handleSorting = (sortingPayload: ISortBy[]) => {
    setSortingParams(sortingPayload);
  };

  const tradesTable = (
    <div className="row">
      <div className="col-12">
        <Table
          tableId="tradesTable"
          className="tradesTable"
          showTableInfo
          hasPagination
          manualSortBy
          isFlexLayout
          pageSizes={pageSizes}
          serverPaginationProps={serverPaginationProps}
          columns={cpBoMixedTableColumns}
          isLoading={isLoadingTrades}
          data={cpAndCTrades}
          onSort={handleSorting}
        />
      </div>
    </div>
  );

  const handleUnReconcile = async (group_id: number) => {
    try {
      await api.unReconcileTrades({
        group_id,
      });
      Notification.success('Unreconciled');
      await loadTrades(true);
    } catch (e) {
      Notification.error(`Failed to unreconcile: ${e}`);
    }
  };
  const reconciledTableColumns = getReconciledTableColumns(handleUnReconcile);
  const reconciledTable = (
    <>
      <div className="row">
        <div className="col-12">
          <Table
            showTableInfo
            hasPagination
            disableSortBy
            className="autoReconcileTable"
            tableId="reconciledTrades"
            pageSize={5}
            pageSizes={pageSizes}
            isLoading={isLoadingReconciledTrades}
            columns={reconciledTableColumns}
            data={groupedReconciledTrades}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <NotificationProvider />
      <div className="container TradesPage">
        <h2 className="m-0">Trades Reconciliation (union view)</h2>
        <SearchForm
          {...allFilters}
          breakCategories={breakCategories}
          handleSubmit={handleSetFilters}
        />
        <div className="my-2">
          <p className="m-0 my-2 text-left">Set break category on selected: </p>
          <BreakCategoryForm
            comment={breakComment}
            breakCategories={breakCategories}
            setComment={setBreakComment}
            setCategory={setBreakCategory}
            onCreateBreakCategoryComment={handleCreateBreakCategoryComment}
          />
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <div className="actionsBlock d-flex justify-content-left">
              <Button
                disabled={!reconcileEnabled}
                onClick={handleOpenReconcileConfirm}
              >
                Reconcile
              </Button>
            </div>
          </div>
        </div>
        {selectedTrades.length ? (
          <div className="row">
            <div className="col-12">
              <div
                className={classNames({
                  'my-2': true,
                  alert: true,
                  'w-100': true,
                  'alert-danger': Number(selectedTradesSum) !== 0,
                  'alert-primary': Number(selectedTradesSum) === 0,
                })}
              >
                Value difference <b>{selectedTradesSum}</b>
              </div>
              <div className="mb-2 w-100 alert alert-primary">
                Quantity difference <b>{selectedTradesQty}</b>
              </div>
              {!selectedTradesCurrencyIsSame ? (
                <div className="alert alert-danger my-2 w-100">
                  CCY is different!
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <div className="container-fluid TradesPage">
        {dataHasBeenUploaded.current ? tradesTable : null}
        {dataHasBeenUploaded.current ? reconciledTable : null}
      </div>
      <ConfirmationModal
        open={confirmModalIsOpened}
        text={`Wrong sum trades: ${selectedTradesSum}\nDo you want to reconcile them?`}
        handleCancel={() => setConfirmModalIsOpened(false)}
        handleSubmit={handleReconcile}
      />
    </>
  );
};
