import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { BankReconcileForm } from './form';
import { BankReconcileTable } from './table';

export function BankReconcilePage() {
  const api = new ReconTablesApiService();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [formPayload, setFormPayload] = useState();

  const loadSearchResults = (form_payload) => {
    setLoading(true);
    setFormPayload(form_payload);
    api
      .searchBankTransactions(form_payload)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const reUploadResults = () => {
    setLoading(true);
    api
      .searchBankTransactions(formPayload)
      .then((res) => {
        setLoading(false);
        setResults(res);
      })
      .catch((e) => {
        alert(`Failed to search: ${e}`);
        setLoading(false);
      });
  };

  const bankReconcileRequest = async (left_ids, right_ids) => {
    try {
      await api.bankReconcile({ left_ids, right_ids });
    } catch (e) {
      alert(`Failed to reconcile: ${e}`);
    }
  };

  const changeCommentRequest = async (ids, comment) => {
    try {
      await api.changeBankTXComment({ ids, comment });
    } catch (e) {
      alert(`Failed change comment to bank transactions: ${e}`);
    }
  };

  return (
    <>
      <BankReconcileForm onSearchSubmit={loadSearchResults} />
      {loading ? (
        <Spinner />
      ) : (
        <BankReconcileTable
          results={results}
          bankReconcileRequest={bankReconcileRequest}
          reUploadResults={reUploadResults}
          changeCommentRequest={changeCommentRequest}
        />
      )}
    </>
  );
}
