import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ReconTablesApiService from 'services/ReconTablesApiService';

function DetailsView({ report }) {
  const [states, setStates] = useState([]);
  const onSelected = (e) => {
    const payload = {};
    const api = new ReconTablesApiService();

    payload.report_id = report.id;
    payload.state = e.target.value;
    api
      .postMappingUpdateReport(payload)
      .then(() => {})
      .catch(() => {});
  };

  const onDeleteClick = (cp) => {
    const api = new ReconTablesApiService();
    api
      .postDeleteExcelStopString(cp)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(e);
      });
  };
  useEffect(() => {
    const api = new ReconTablesApiService();
    api.getListReportState().then((res) => {
      const options = res.results.map((r) => {
        if (report.state === r.state) {
          return (
            <option selected key={r.state} value={r.state}>
              {r.state}
            </option>
          );
        }

        return (
          <option key={r.state} value={r.state}>
            {r.state}
          </option>
        );
      });
      setStates(options);
    });
  }, []);
  const onAddClick = (cp_id) => {
    const api = new ReconTablesApiService();
    const payload = {};
    payload.table_id = cp_id;
    payload.data = document.getElementById(cp_id).value;

    api
      .postMappingExcelStopString(payload)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(e);
      });
  };
  const reportDetails = () => {
    /* eslint-disable */
    const details = [];
    for (const d of report.fields) {
      details.push(
        <tr>
          <td>
            <b>Start String</b>
          </td>
          <td>{d.start_string}</td>
          <td>
            <b>Fields miss</b>
          </td>
          <td>{d.fields_miss}</td>
          <td>
            <b>Start miss</b>
          </td>
          <td>{d.start_miss}</td>
        </tr>,
      );

      details.push(
        <tr>
          <td>
            <b>Book</b>
          </td>
          <td>{d.book}</td>
          <td>
            <b>File Postfix</b>
          </td>
          <td>{d.file_postfix}</td>
          <td>
            <b>Row miss</b>
          </td>
          <td>{d.row_miss}</td>
        </tr>,
      );

      for (const st of d.stop_strings) {
        details.push(
          <tr>
            <td>
              <b>Stop string</b>
            </td>
            <td>{st.data}</td>

            <td>
              <button
                type="button"
                className="btn btn-sm btn-outline-success"
                onClick={() => onDeleteClick(st.id)}
              >
                Delete
              </button>
            </td>
          </tr>,
        );
      }
      details.push(
        <tr>
          <input
            id={d.id}
            type="text"
            className="form-control"
            name={`stop_string#${d.id}`}
          />
          <button
            type="button"
            className="btn btn-sm btn-outline-success"
            onClick={() => onAddClick(d.id)}
          >
            Add stop sting
          </button>
        </tr>,
      );
    }

    if (details.length === 0) {
      return null;
    }
    return (
      <>
        <tr>
          <td colSpan="7">
            <h4>Tables</h4>
          </td>
        </tr>
        {details}
      </>
    );
    /* eslint-enable */
  };

  return (
    <div className="container-fluid mt-4">
      <h2>Report {report.id} details</h2>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>
                <b>Counterparty</b>
              </td>
              <td>{report.cp_id}</td>
              <td>
                <b>Type</b>
              </td>
              <td>{report.type}</td>
              <td>
                <b>State</b>
              </td>
              <td>
                <select
                  onChange={onSelected}
                  className="custom-select mr-sm-2"
                  name="states"
                >
                  {states}
                </select>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <b>Path</b>
              </td>
              <td>{report.path}</td>
              <td>
                <b>Contains</b>
              </td>
              <td>{report.contains}</td>
              <td>
                <b>File Type</b>
              </td>
              <td>{report.file_type}</td>
              <td />
            </tr>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>{report.name}</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            {reportDetails()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function ReportExcelDetailsPage() {
  const { reportId } = useParams();
  const [report, setReport] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getExcelMappingReportWithFields(reportId)
      .then((r) => {
        setReport(r);
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line
        alert(e);
        setReport(undefined);
        setLoading(false);
      });
  }, [reportId]);

  return (
    <>
      {loading ?? (
        <div className="mt-4">
          <Spin />
        </div>
      )}
      {report === undefined ? null : <DetailsView report={report} />}
    </>
  );
}
