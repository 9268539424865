import { Component } from 'react';

import ManualUnreconcileTableRow from './table-row';

export default class ManualUnreconcileTable extends Component {
  // eslint-disable-next-line
  state = {
    entries: [],
  };

  componentDidMount() {
    this.setState({
      entries: this.props.entries,
    });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      this.setState({ entries: this.props.entries });
    }
  }

  render() {
    const rows = this.state.entries.map((entry, index) => {
      const lastRowCls = index % 2 === 0 ? 'table-primary' : 'table-info';
      return <ManualUnreconcileTableRow entry={entry} rowClass={lastRowCls} />;
    });
    return (
      <div className="table-responsive mt-3">
        <table className="table table-bordered table-sm">
          <thead>
            <tr className="thead-light">
              <th>GroupID</th>
              <th>Action</th>
              <th>CP</th>
              <th>Trade Number</th>
              <th>Exante Symbol</th>
              <th>Price</th>
              <th>Qty</th>
              <th>Value</th>
              <th>Timestamp</th>
              <th>Exchange Order ID</th>
              <th>RecStatus</th>
              <th>Cp Symbol</th>
              <th>Price</th>
              <th>Qty</th>
              <th>Value</th>
              <th>Timestamp</th>
              <th>CP Exchange Order ID</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}
