import { Component } from 'react';

import ScriptStatusTableRow from './table-row';

class ScriptStatusTable extends Component {
  // eslint-disable-next-line
  state = { scriptStatuses: [] };

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.scriptStatuses !== this.props.scriptStatuses) {
      // eslint-disable-next-line
      this.setState({ scriptStatuses: this.props.scriptStatuses });
    }
  }

  render() {
    let counter = 0;
    const rows = this.state.scriptStatuses.map((entry) => {
      counter += 1;
      return <ScriptStatusTableRow entry={entry} number={counter} />;
    });

    return (
      <table
        className="table table-hover table-bordered table-sm"
        id="table-script-status"
      >
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Group</th>
            <th>Status</th>
            <th>Args</th>
            <th>Last Run</th>
            <th>Last Success Run</th>
            <th>Std out</th>
            <th>Std err</th>
            <th>Py exception</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

export default ScriptStatusTable;
