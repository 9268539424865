import { Component } from 'react';

import { Modal } from 'components/Modal/Modal';

// eslint-disable-next-line
class ScriptRunInfoTableRow extends Component {
  render() {
    const { entry, number } = this.props;
    let rowClass = 'table-warning';

    if (entry.state === 'success') {
      rowClass = 'table-success';
    }
    if (entry.state === 'failed') {
      rowClass = 'table-danger';
    }

    if (entry.state === 'running') {
      rowClass = 'table-primary';
    }

    const errName = `err${number}`;
    const stdName = `std${number}`;
    const excName = `exc${number}`;

    return (
      <tr key={entry.app} className={rowClass}>
        <td>{entry.name}</td>
        <td>{entry.state}</td>
        <td>{entry.for_date}</td>
        <td>{entry.run_time}</td>
        <td>
          <Modal
            scriptID={`${entry.script_model}.${entry.name}`}
            name={stdName}
            outputID="stdout"
          />
        </td>
        <td>
          <Modal
            scriptID={`${entry.script_model}.${entry.name}`}
            name={errName}
            outputID="stderr"
          />
        </td>
        <td>
          <Modal
            scriptID={`${entry.script_model}.${entry.name}`}
            name={excName}
            outputID="exception"
          />
        </td>
      </tr>
    );
  }
}

export default ScriptRunInfoTableRow;
