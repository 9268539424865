import { Component } from 'react';

import SeriesMappingTableRow from './table-row';

class SeriesMappingTable extends Component {
  // eslint-disable-next-line
  state = {
    data: [],
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ data });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      // eslint-disable-next-line
      this.setState({ data: this.props.data });
    }
  }

  render() {
    const rows = this.state.data.map((entry) => (
      <SeriesMappingTableRow
        key={entry.id}
        entry={entry}
        onMappingChanged={this.props.onMappingChanged}
      />
    ));

    return (
      <table className="table table-bordered table-striped table-hover table-responsive table-sm w-100 d-block d-md-table">
        <thead>
          <tr className="thead-light">
            <th>CP</th>
            <th>CP Series</th>
            <th>Exante Series</th>
            <th>Plus month</th>
            <th>Strike Mty</th>
            <th>Mty Qty</th>
            <th>Mty Price</th>
            <th>Mty Value</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

export default SeriesMappingTable;
