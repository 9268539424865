import { Component } from 'react';

import MappingTableRow from './table-row';

import '../style.css';

class AutoMappingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappings: [],
    };
  }

  componentDidMount() {
    const { mappings } = this.props;
    this.setState({
      mappings: mappings || [],
    });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.mappings !== this.props.mappings) {
      // eslint-disable-next-line
      this.setState({ mappings: this.props.mappings });
    }
  }

  render() {
    if (this.state.mappings.length === 0) {
      return null;
    }

    const rows = this.state.mappings.map((entry) => (
      <MappingTableRow
        entry={entry}
        date={this.props.date}
        onMappingChanged={this.props.onMappingChanged}
      />
    ));

    return (
      <div className="table-wrapper-scroll-y my-custom-scrollbar2 table-responsive">
        <table className="table table-sm table-bordered table-striped table-hover table-sm w-150 d-block d-md-table ">
          <thead>
            <tr className="thead-light">
              <th>CP</th>
              <th>CP Symbol</th>
              <th>Exante Symbol</th>
              <th style={{ minWidth: '90px' }}>Mty</th>
              <th>CpMty</th>
              <th>MtyQty</th>
              <th>Exact?</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default AutoMappingTable;
