import { useRef, useState } from 'react';
import { ISortBy } from 'react-ui-kit-exante';

import { getBreakReportFilters } from '../UnionTransactionsReconcile/hooks';
import { DatesIsValid } from '../UnionTransactionsReconcile/types';

import { checkPayload } from './ManualReconcileUnionUtils';
import { filterPayloadStateDefault } from './constants';

export function useFilters(setPage: (val: number) => void) {
  const mountedFilterPayload = useRef(false);

  const [fromFilter, setFromFilter] = useState<undefined | string>(undefined);
  const [toFilter, setToFilter] = useState<undefined | string>(undefined);
  const [cpFilter, setCpFilter] = useState('');
  const [entityFilter, setEntityFilter] = useState('');
  const [brokerSymbolFilter, setBrokerSymbolFilter] = useState('');
  const [ourSymbolFilter, setOurSymbolFilter] = useState('');
  const [breakCategoryFilter, setBreakCategoryFilter] = useState('');
  const [filterPayloadState, setFilterPayloadState] = useState(
    filterPayloadStateDefault,
  );
  const [sortingParams, setSortingParams] = useState<ISortBy[]>([]);
  const [datesIsValid, setDatesIsValid] = useState<DatesIsValid>({
    from: true,
    to: true,
  });

  const handleSetFilters = (loadFirstPage: boolean) => {
    if (
      !checkPayload({
        fromFilter,
        toFilter,
        entityFilter,
        cpFilter,
        datesIsValid,
      })
    ) {
      return;
    }
    if (loadFirstPage) {
      setFilterPayloadState((prevState: any) => ({
        ...prevState,
        from_date: fromFilter,
        to_date: toFilter,
        cp: cpFilter,
        legal_entity: entityFilter,
        our_symbol: ourSymbolFilter,
        broker_symbol: brokerSymbolFilter,
      }));
      setPage(0);
    }
  };

  return {
    mountedFilterPayload,
    filterPayloadState,
    breakReportFilters: getBreakReportFilters(breakCategoryFilter),
    handleSetFilters,
    datesIsValid,
    setDatesIsValid,
    setFromFilter,
    fromFilter,
    setToFilter,
    toFilter,
    setCpFilter,
    setEntityFilter,
    setOurSymbolFilter,
    ourSymbolFilter,
    setBrokerSymbolFilter,
    brokerSymbolFilter,
    setBreakCategoryFilter,
    sortingParams,
    setSortingParams,
  };
}
