import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';

import { getFormPayload } from '../../services/utils';

const formID = 'searchFormReconcile';
export const BOvsBO = 'BOvsBO';
export const BOvsCP = 'BOvsCP';
export const CPvsCP = 'CPvsCP';

class ManualReconcileSearchForm extends Component {
  onSubmit = (submitType) => {
    const form = document.getElementById(formID);
    const payload = getFormPayload(form, true);

    if (
      payload.from_report_date === undefined ||
      payload.to_report_date === undefined
    ) {
      alert('From and To dates are required field for search');
      return;
    }
    if (payload.cp === undefined && payload.legal_entity_malta === undefined) {
      alert('One of [Counterparty, LegalEntity] fields is required for search');
      return;
    }
    this.props.onSearchSubmit(payload, submitType);
  };

  render() {
    const cpOptionsList = [
      <option key="any" value="">
        Any
      </option>,
    ];
    this.props.cpNamesList.forEach((id) => {
      cpOptionsList.push(
        <option key={id} value={id}>
          {id.toUpperCase()}
        </option>,
      );
    });

    const leOptionsList = [
      <option key="any" value="">
        Any
      </option>,
    ];

    Object.keys(this.props.legalEntities).forEach((k) => {
      leOptionsList.push(
        <option key={k} value={k}>
          {this.props.legalEntities[k]}
        </option>,
      );
    });

    return (
      <div className="container mb-3 mt-3">
        <form onSubmit={this.onSubmit} name="searchFormReconcile" id={formID}>
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">From</div>
                </div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'from_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">To</div>
                </div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'to_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Counterparty</div>
                </div>
                <select
                  className="custom-select mr-sm-2"
                  name="cp"
                  placeholder="Counterparty"
                >
                  {cpOptionsList}
                </select>
              </div>
            </div>
          </div>
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Legal Entity</div>
                </div>
                <select
                  className="custom-select mr-sm-2"
                  name="legal_entity_malta"
                  placeholder="Legal Entity"
                >
                  {leOptionsList}
                </select>
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Our Symbol</div>
                </div>
                <input type="text" className="form-control" name="symbol" />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Broker Symbol</div>
                </div>
                <input type="text" className="form-control" name="cp_symbol" />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="mx-auto">
              <button
                className="btn btn-success"
                type="button"
                onClick={() => this.onSubmit(BOvsCP)}
              >
                Load CP vs BO
              </button>
              <button
                className="btn btn-warning ml-2"
                type="button"
                onClick={() => this.onSubmit(BOvsBO)}
              >
                Load BO vs BO
              </button>
              <button
                className="btn btn-warning ml-2"
                type="button"
                onClick={() => this.onSubmit(CPvsCP)}
              >
                Load CP vs CP
              </button>
            </div>
          </div>
        </form>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = ({ counterParties, legalEntities }) => ({
  legalEntities,
  cpNamesList: counterParties,
});

export default connect(mapStateToProps)(ManualReconcileSearchForm);
