export enum DateFormats {
  DDMMYYYY = 'DD.MM.YYYY',
}

export const workflowOptions = [
  {
    value: 'manual_trade',
    label: 'Manual trade',
  },
  {
    value: 'manual_transaction',
    label: 'Manual transaction',
  },
];

export const BASE_URL = 'https://workflow-engine.stage.zorg.sh/api/v1/core'; // вынести в env
export const POLLING_DELAY = 30000;
