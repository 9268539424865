import { FC } from 'react';
import './Spinner.css';

// TODO: refactor this component and get rid of divs
export const Spinner: FC = () => (
  <div className="d-flex justify-content-center">
    <div className="lds-css ng-scope">
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    <br />
  </div>
);
