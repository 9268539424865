import { Component } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import './style.css';

import AutoMappingView from './auto-mapping-view';
import ManualMappingForm from './manual-mapping-form';
import MappedSymbolsTable from './mapped-symbols-table';

class MappingPage extends Component {
  apiService = new ReconTablesApiService();

  constructor(props) {
    super(props);
    this.state = {
      mappedSymbols: [],
      filterPayload: {},
    };
  }

  componentDidMount() {
    this.updateMappingTable();
  }

  onMappingChanged = () => {
    this.updateMappingTable();
  };

  updateMappingTable = () => {
    this.getMappedSymbols(this.state.filterPayload);
  };

  getMappedSymbols = (filter) => {
    this.apiService
      .getListSymbolsMapping(filter)
      .then((data) => {
        this.setState({
          mappedSymbols: data.results,
        });
      })
      .catch((e) => {
        alert(`Failed to fetch ${e}`);
      });
  };

  onFilterSubmit = (filterPayload) => {
    this.setState({ filterPayload });
    this.getMappedSymbols(filterPayload);
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-sm-6 col-12 border border-dark py-2 d-flex flex-column"
            id="left"
          >
            <div className="row mb-2">
              <ManualMappingForm onMappingChanged={this.onMappingChanged} />
            </div>
            <hr />
            <div className="row">
              <AutoMappingView onMappingChanged={this.onMappingChanged} />
            </div>
          </div>
          <div className="col-sm-6 col-12 offset-0 offset-sm-6 py-2" id="right">
            <MappedSymbolsTable
              mappedSymbols={this.state.mappedSymbols}
              onMappingChanged={this.onMappingChanged}
              onFilterSubmit={this.onFilterSubmit}
            />
            ;
          </div>
        </div>
      </div>
    );
  }
}

export default MappingPage;
