import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

const types = [
  'Total Cash',
  'Total Equity',
  'Used Margin',
  'Net Free Equity',
  'Other payables and liabilities',
  'Other assets from other sources',
  'Commissions Receivable Amount',
  'Cash Reconciliation Adjustment',
  'Money in segregated accounts',
  'Accounts receivable from group companies',
  'Paid-up share capital',
  'Retained profits',
];
const categoryValues = ['', 'CORPORATE'];

function AddForm({ onSuccess, ccyList, entityList }) {
  const counterParties = useAppSelector((state) => state.counterParties);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState([]);
  const [valueValid, setValueValid] = useState(false);

  const onValueChange = (e) => {
    setValueValid(Boolean(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target);
    payload.comment = 'added via interface';
    payload.source_file = 'recon-ui';
    payload.category = payload.category === '' ? null : payload.category;

    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .postRowBalance(payload)
      .then((resp) => {
        const newCreated = [...created];
        newCreated.push(resp.full_id);
        setCreated(newCreated);
        setLoading(false);
        onSuccess({});
      })
      .catch(() => {
        setLoading(false);
        alert('Error when adding');
      });
  };

  const categoryList = categoryValues.map((id) => (
    <option key={id} value={id}>
      {id}
    </option>
  ));
  const cpOptionsList = counterParties.map((id) => (
    <option key={id} value={id}>
      {id.toUpperCase()}
    </option>
  ));
  const ccyOptionsList = ccyList.map((ccy) => (
    <option
      key={ccy.name}
      value={ccy.name}
      selected={ccy.name.toUpperCase() === 'USD'}
    >
      {ccy.name.toUpperCase()}
    </option>
  ));

  const typeOptionsList = types.map((id) => (
    <option key={id} value={id}>
      {id.toUpperCase()}
    </option>
  ));

  const createdArr = () => {
    if (created.length === 0) {
      return null;
    }
    return created.map((c) => (
      <div className="alert alert-success" role="alert">
        {`Created row balance with full id = ${c}`}
      </div>
    ));
  };

  if (loading) {
    return (
      <div className="container mt-2">
        <Spin />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="card bg-secondary mt-2">
        <div className="card-body">
          <h4 className="card-title">Add Row Balance Entry</h4>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="cp">
                    {cpOptionsList}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Report Date</div>
                  </div>
                  <DayPickerInput
                    inputProps={{
                      className: 'custom-select mr-sm-2',
                      name: 'report_date',
                      autoComplete: 'off',
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Type</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="type">
                    {typeOptionsList}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CCY</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="ccy">
                    {ccyOptionsList}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Account</div>
                  </div>
                  <input type="text" className="form-control" name="account" />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Value</div>
                  </div>
                  <input
                    type="number"
                    className={
                      valueValid ? 'form-control' : 'form-control is-invalid'
                    }
                    name="value"
                    step="0.0000001"
                    onChange={onValueChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Malta Entity</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="legal_entity">
                    {entityList}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Category</div>
                  </div>
                  <select className="custom-select mr-sm-2" name="category">
                    {categoryList}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="mx-auto">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!valueValid}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="mt-2">{createdArr()}</div>
    </div>
  );
}

function FilterForm({ onFilter }) {
  const counterParties = useAppSelector((state) => state.counterParties);
  const cpOptionsList = [
    <option key="any" value="">
      Any
    </option>,
  ];

  counterParties.forEach((id) => {
    cpOptionsList.push(
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>,
    );
  });

  const typeOptionsList = [
    <option key="any" value="">
      Any
    </option>,
  ];
  types.forEach((id) => {
    typeOptionsList.push(
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>,
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    onFilter(payload);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">CP</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp">
                {cpOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Report Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'report_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <select className="custom-select mr-sm-2" name="type">
                {typeOptionsList}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Account</div>
              </div>
              <input type="text" className="form-control" name="account" />
            </div>
          </div>
          <div className="col-1">
            <button className="btn btn-primary" type="submit">
              Filter
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function TableRow({ entry, ccyList, onRowDeleted }) {
  const [modified, setModified] = useState(entry);
  const [initial, setInitial] = useState(entry);
  const [valueValid, setValueValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const onUpdate = () => {
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .patchRowBalance(modified.full_id, modified)
      .then(() => {
        setInitial(modified);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .deleteRowBalance(modified.full_id)
      .then(() => {
        setLoading(false);
        onRowDeleted();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onValueChange = (e) => {
    const newM = { ...modified };
    newM[e.target.name] = e.target.value;
    setModified(newM);
    if (e.target.name === 'value') {
      const isValid = /^\d*\.?\d+$/.test(e.target.value);
      setValueValid(isValid);
    }
  };

  const dateChangeHandler = (date) => {
    const strDate = date.toISOString().slice(0, 10);
    const newM = { ...modified };
    newM.report_date = strDate;
    setModified(newM);
  };

  const counterParties = useAppSelector((state) => state.counterParties);
  const cpOptionsList = counterParties.map((id) => (
    <option key={id} value={id} selected={id === entry.cp}>
      {id.toUpperCase()}
    </option>
  ));
  const typeOptionsList = types.map((id) => (
    <option key={id} value={id} selected={id === entry.type}>
      {id.toUpperCase()}
    </option>
  ));
  const ccyOptionsList = ccyList.map((ccy) => (
    <option key={ccy.name} value={ccy.name} selected={entry.ccy === ccy.name}>
      {ccy.name.toUpperCase()}
    </option>
  ));

  const isModified = () => {
    // eslint-disable-next-line
    for (const p of Object.keys(entry)) {
      if (modified[p] !== initial[p]) {
        return true;
      }
    }
    return false;
  };

  const button = valueValid ? (
    <button className="btn btn-primary" onClick={onUpdate}>
      Update
    </button>
  ) : null;

  return (
    <tr
      className={isModified() ? 'bg-success' : ''}
      key={`${entry.report_date}__${entry.account}__${entry.value}`}
    >
      <td>
        <select
          className="custom-select mr-sm-2"
          name="cp"
          onChange={onValueChange}
        >
          {cpOptionsList}
        </select>
      </td>
      <td>
        <DayPickerInput
          inputProps={{
            className: 'custom-select mr-sm-2',
            name: 'report_date',
            autoComplete: 'off',
          }}
          value={entry.report_date}
          onDayChange={dateChangeHandler}
        />
      </td>
      <td>
        <select
          className="custom-select mr-sm-2"
          name="type"
          onChange={onValueChange}
        >
          {typeOptionsList}
        </select>
      </td>
      <td>
        <select
          className="custom-select mr-sm-2"
          name="ccy"
          onChange={onValueChange}
        >
          {ccyOptionsList}
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          name="account"
          defaultValue={entry.account}
          onChange={onValueChange}
        />
      </td>
      <td>
        <input
          type="number"
          defaultValue={entry.value}
          className={valueValid ? 'form-control' : 'form-control is-invalid'}
          name="value"
          step="0.0000001"
          onChange={onValueChange}
        />
      </td>
      <td>{loading ? <Spin /> : button}</td>
      <td>
        {loading ? (
          <Spin />
        ) : (
          <button className="btn btn-danger" onClick={onDelete}>
            Delete
          </button>
        )}
      </td>
    </tr>
  );
}

function Table({ data, ccyList, refresh }) {
  if (data === undefined) {
    return null;
  }
  const rows = data.map((d) => (
    <TableRow
      key={`${d.report_date}__${d.account}__${d.value}`}
      entry={d}
      ccyList={ccyList}
      onRowDeleted={refresh}
    />
  ));

  return (
    <div className="container-fluid">
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>CP</th>
            <th>Report Date</th>
            <th>Type</th>
            <th>CCY</th>
            <th>Account</th>
            <th>Value</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default function RowBalancePage() {
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [ccyList, setCcyList] = useState([]);
  const [entityList, setEntityList] = useState([]);

  const loadResults = (filterPayload) => {
    filterPayload.source_file = 'recon-ui';
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListRowBalance(filterPayload)
      .then((resp) => {
        setLoading(false);
        setFilteredResults(resp.results);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onUpdate = () => {
    loadResults(activeFilter);
  };

  useEffect(() => {
    const api = new ReconTablesApiService();

    api.getListCurrency().then((res) => {
      setCcyList(res.results);
    });
    api.getListBOEntityLegalName().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.name} value={r.id_in_ctrades}>
          {r.name}
        </option>
      ));
      setEntityList(options);
    });
    onUpdate();
  }, []);

  const onFilter = (filterPayload) => {
    setActiveFilter(filterPayload);
    loadResults(filterPayload);
  };

  const onAddSuccess = (payload) => {
    setActiveFilter(payload);
    loadResults(payload);
  };

  return (
    <>
      <AddForm
        onSuccess={onAddSuccess}
        ccyList={ccyList}
        entityList={entityList}
      />
      <hr />
      <FilterForm onFilter={onFilter} />
      {loading ? (
        <Spin />
      ) : (
        <Table data={filteredResults} ccyList={ccyList} refresh={onUpdate} />
      )}
    </>
  );
}
