import { Spin } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export default function SeriesMappingFormAdd({ onMappingChanged }) {
  const counterParties = useSelector((state) => state.counterParties);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target);
    const { cp, cp_symbol: cpSymbol, exante_symbol: exanteSymbol } = payload;

    if (cp === undefined || cp === '') {
      alert('Counterparty is not specified');
      return;
    }

    if (cpSymbol === undefined || cpSymbol === '') {
      alert('Counterparty series is not specified');
      return;
    }

    if (exanteSymbol === undefined || exanteSymbol === '') {
      alert('Exante series is not specified');
      return;
    }
    const splited = exanteSymbol.split('.');
    if (splited.length < 2) {
      alert('Looks like Exante series is in wrong format');
      return;
    }

    const apiService = new ReconTablesApiService();
    setLoading(true);
    apiService
      .postShortMapping(payload)
      .then((resp) => {
        alert(JSON.stringify(resp));
        setLoading(false);
        onMappingChanged();
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  };

  const cpOptionsList = counterParties.map((id) => (
    <option key={id} value={id}>
      {id.toUpperCase()}
    </option>
  ));

  return (
    <div className="container-fluid">
      <div className="card bg-secondary mt-2">
        <div className="card-body">
          <h4 className="card-title">Add series mapping</h4>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-2">
              <div className="col">
                <select
                  className="custom-select mr-sm-2"
                  name="cp"
                  placeholder="Counterparty"
                >
                  <option key="emptySelect" value="" selected />
                  {cpOptionsList}
                </select>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP Series</div>
                  </div>
                  <input
                    type="text"
                    className="form-control text-capitalize"
                    name="cp_symbol"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Exante Series</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="exante_symbol"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Plus Month</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="plus_month"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Strike Mty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="strike_mty"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Qty Mty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="mty_qty"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Price Mty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="mty_price"
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Value Mty</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="mty_value"
                  />
                </div>
              </div>
              {loading ? (
                <Spin />
              ) : (
                <button className="btn btn-info">Add</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
