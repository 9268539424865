import { UseTableCellProps } from 'react-table';
import { Notification, TableProps } from 'react-ui-kit-exante';

import { minColumnWidth } from 'constants/tables';
import {
  CategoryComment,
  CpAndCTrades,
  CpAndCTradesWithGroupId,
  SIDE,
  TablesWithBreakReport,
} from 'services/ReconTablesApiService';

import { DatesIsValid } from '../UnionTransactionsReconcile/types';

import {
  getBreakCommentCell,
  getBreakReportCategoryCell,
  getCellCheckbox,
  getGeneralCheckbox,
  getUngroupCell,
  getColumnHeader,
} from './ColumnComponents/ColumnComponents';

const minWidths = {
  id: 60,
  side: 70,
  qty: 80,
  price: 75,
  legal_entity: 115,
  date: 115,
  value: 90,
  value_date: 115,
  type: 35,
  cp: 80,
  symbol: 120,
  ccy: 85,
  isin: 45,
  account: 110,
  break_report_category: 135,
  break_comment: 120,
};
const generalColumns: TableProps<CpAndCTrades>['columns'] = [
  {
    Header: 'Qty',
    accessor: 'qty',
    minWidth: minWidths.qty,
    width: minWidths.qty,
  },
  {
    Header: 'Price',
    accessor: 'price',
    minWidth: minWidths.price,
    width: minWidths.price,
  },
  {
    Header: 'Legal entity',
    accessor: 'legal_entity',
    minWidth: minWidths.legal_entity,
    width: minWidths.legal_entity,
  },

  {
    Header: 'Date',
    accessor: 'date',
    minWidth: minWidths.date,
    width: minWidths.date,
  },

  {
    Header: 'Value',
    accessor: 'value',
    minWidth: minWidths.value,
    width: minWidths.value,
  },
  {
    Header: 'Value date',
    accessor: 'value_date',
    minWidth: minWidths.value_date,
    width: minWidths.value_date,
  },

  {
    Header: 'Type',
    accessor: 'type',
    minWidth: minWidths.type,
    width: minWidths.type,
  },

  {
    Header: 'CP',
    accessor: 'cp',
    minWidth: minWidths.cp,
    width: minWidths.cp,
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: ({ row }: UseTableCellProps<CpAndCTrades>) => (
      <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.symbol}</span>
    ),
    minWidth: minWidths.symbol,
    width: minWidths.symbol,
  },
  {
    Header: 'CCY',
    accessor: 'ccy',
    minWidth: minWidths.ccy,
    width: minWidths.ccy,
  },
  {
    Header: 'ISIN',
    accessor: 'isin',
    minWidth: minWidths.isin,
    width: minWidths.isin,
  },
  {
    Header: 'Account',
    accessor: 'account',
    minWidth: minWidths.account,
    width: minWidths.account,
  },
];
export function getTradesTableColumns(
  trades: CpAndCTrades[],
  selectedTransactionsIds: number[],
  onCheckTransaction: (item: CpAndCTrades) => void,
  onCheckAllTransactionOnPage: () => void,
  onUnCheckAllTransactionOnPage: () => void,
  breakCategories: CategoryComment[],
  onUpdateBreakCategoryById: (
    record_types_mapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => void,
) {
  const columns: TableProps<CpAndCTrades>['columns'] = [
    {
      accessor: 'id',
      // @ts-ignore
      disableSortBy: true,
      minWidth: minWidths.id,
      width: minWidths.id,
      Header: () =>
        getGeneralCheckbox<CpAndCTrades>(
          trades,
          selectedTransactionsIds,
          onCheckAllTransactionOnPage,
          onUnCheckAllTransactionOnPage,
        ),
      Cell: ({ row }: UseTableCellProps<CpAndCTrades>) =>
        getCellCheckbox<CpAndCTrades>(
          row.values.id,
          trades,
          selectedTransactionsIds,
          onCheckTransaction,
        ),
    },
    {
      Header: 'Side',
      accessor: 'side',
      minWidth: minWidths.side,
      width: minWidths.side,
    },
    ...generalColumns.map((item) => ({
      ...item,
      Header: getColumnHeader(String(item.Header)) as any,
    })),
    {
      Header: 'Break category',
      accessor: 'break_report_category',
      minWidth: minWidths.break_report_category,
      width: minWidths.break_report_category,
      Cell: ({ row }: UseTableCellProps<CpAndCTrades>) =>
        getBreakReportCategoryCell(
          row.values.id,
          row.values.break_report_category,
          row.values.side,
          row.values.break_comment,
          breakCategories,
          onUpdateBreakCategoryById,
          TablesWithBreakReport.CPTRADES,
          TablesWithBreakReport.CTRADES,
        ),
    },
    {
      Header: 'Comment',
      accessor: 'break_comment',
      minWidth: minWidths.break_comment,
      width: minWidths.break_comment,
      Cell: ({ row }: UseTableCellProps<CpAndCTrades>) =>
        getBreakCommentCell(
          row.values.id,
          row.values.break_report_category,
          row.values.side,
          row.values.break_comment,
          breakCategories,
          onUpdateBreakCategoryById,
          TablesWithBreakReport.CPTRADES,
          TablesWithBreakReport.CTRADES,
        ),
    },
  ];
  return columns;
}

export function getReconciledTableColumns(onUngroup: (item: number) => void) {
  const columns: TableProps<Record<string, any>>['columns'] = [
    {
      Header: '',
      accessor: 'id',
      width: 0,
    },
    {
      Header: 'Group id',
      accessor: 'group_id',
      minWidth: minColumnWidth,
      Cell: ({ row }) => getUngroupCell(row.values.group_id, onUngroup),
    },
    ...generalColumns.map((i) => ({
      Header: `${i.Header} | cp`,
      accessor: `${i.accessor}_cp`,
      minWidth: i.minWidth,
      width: i.width,
    })),
    ...generalColumns.map((i) => ({
      Header: `${i.Header} | bo`,
      accessor: `${i.accessor}_bo`,
      minWidth: minColumnWidth,
    })),
  ];

  return columns;
}

interface CheckValue {
  fromFilter?: string;
  toFilter?: string;
  entityFilter: string;
  cpFilter: string;
  datesIsValid: DatesIsValid;
}
export const checkPayload = (value: CheckValue) => {
  if (!value.fromFilter || !value.toFilter) {
    Notification.error('From and To dates are required field for search');
    return false;
  }
  if (!value.datesIsValid.from) {
    Notification.error('From date is not valid');
    return false;
  }
  if (!value.datesIsValid.to) {
    Notification.error('To date is not valid');
    return false;
  }
  if (!value.entityFilter && !value.cpFilter) {
    Notification.error('Entity or Cp are required field for search');
    return false;
  }
  return true;
};

export const getTrades = (
  side: SIDE,
  keyPrefix: string,
  trades: CpAndCTradesWithGroupId[],
) =>
  trades.reduce((accum: any[], item: any) => {
    if (item.side === side) {
      const obj: Record<string, string> = {};
      Object.keys(item).forEach((key) => {
        obj[`${key}_${keyPrefix}`] = item[key];
      });
      return [...accum, { ...item, ...obj }];
    }
    return accum;
  }, []);
