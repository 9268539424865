import { Component } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

class MappingTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonCssClass: 'btn btn-outline-danger',
      added: false,
      mty: 1,
      cmty: 1,
      mty_qty: 1,
      ccy: undefined,
      cp_type: undefined,
    };
  }

  componentDidMount() {
    const { mty, cmty, mtyQty, ccy, cp_type: cpType } = this.props.entry;

    this.setState({ mty, cmty, mty_qty: mtyQty, ccy, cp_type: cpType });
  }

  onAcceptClick = (event) => {
    event.preventDefault();
    if (this.state.added) {
      return;
    }
    const apiService = new ReconTablesApiService();
    const {
      cp,
      cp_symbol: cpSymbol,
      exante_symbol: exanteSymbol,
    } = this.props.entry;
    const { date } = this.props;
    const { mty, cmty, mty_qty: mtyQty, ccy, cp_type: cpType } = this.state;
    const dateF = new Date(date);
    const payload = {
      cp,
      cp_symbol: cpSymbol,
      exante_symbol: exanteSymbol,
      mty,
      cmty,
      mty_qty: mtyQty,
      isin: '',
      account: cp, // TODO look likes issue on backend side
      ccy,
      cp_type: cpType,
      start_date: dateF.toISOString(),
    };

    apiService
      .postSymbolsMapping(payload)
      .then(() => {
        this.setState({
          buttonCssClass: 'btn btn-outline-success disabled',
          added: true,
        });
        this.props.onMappingChanged();
      })
      .catch((e) => {
        alert(`failed to add automapping: ${e}`);
      });
  };

  onInputChanged = (e) => {
    const fieldName = e.target.attributes.getNamedItem('kind').value;
    const value = e.target.value;
    this.setState({
      [fieldName]: value,
    });
  };

  render() {
    const { entry } = this.props;
    const cls = entry.exact ? undefined : 'table-warning';
    const exact = entry.exact ? 'YES' : 'NO';
    const step = this.state.mty < 1 ? 0.001 : 1;
    return (
      <tr className={cls}>
        <td>{entry.cp}</td>
        <td>{entry.cp_symbol}</td>
        <td>{entry.exante_symbol}</td>
        <td>
          <input
            type="number"
            className="form-control"
            value={this.state.mty}
            kind="mty"
            min="0"
            onChange={this.onInputChanged}
            step={step}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            value={this.state.cpMty}
            kind="cmty"
            min="0"
            onChange={this.onInputChanged}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            value={this.state.mtyQty}
            kind="mty_qty"
            min="0"
            onChange={this.onInputChanged}
          />
        </td>
        <td>{exact}</td>
        <td>
          <button
            type="button"
            className={this.state.buttonCssClass}
            onClick={this.onAcceptClick}
          >
            Accept
          </button>
        </td>
      </tr>
    );
  }
}

export default MappingTableRow;
