import { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import { getFormPayload } from '../../services/utils';

export function BankReconcileForm(props) {
  const { onSearchSubmit } = props;
  const legalEntities = useSelector((state) => state.legalEntities);
  const cpNames = useSelector((state) => state.cpNames);
  const currencies = useSelector((state) => state.currencies);
  const operationTypes = useSelector((state) => state.operationTypes);
  const [selectedCP, setSelectedCP] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);

    if (
      payload.from_report_date === undefined ||
      payload.to_report_date === undefined
    ) {
      alert('From and To dates are required field for search');
      return;
    }
    payload.cp = selectedCP;
    onSearchSubmit(payload);
  };

  const cpOptions = Object.entries(cpNames)
    .sort()
    .map(([key, value]) => {
      if (value === 'bank') {
        return (
          <option key={key} value={key}>
            {key.toUpperCase()}
          </option>
        );
      }
      return null;
    });

  const leOptions = Object.entries(legalEntities).map(([key, value]) => (
    <option key={key} value={value}>
      {value.toUpperCase()}
    </option>
  ));

  const operationTypesOptions = operationTypes.map((entry) => (
    <option key={entry} value={entry}>
      {entry}
    </option>
  ));

  const currenciesOptions = currencies.map((entry) => (
    <option key={entry} value={entry}>
      {entry}
    </option>
  ));

  const onChangeSelectedCP = (e) => {
    const values = Array.from(
      e.target.selectedOptions,
      (option) => option.value,
    );
    setSelectedCP(values);
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit} name="searchFormBankReconcile">
        <div className="form-row mb-2 col-12">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">From</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'from_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">To</div>
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    name: 'to_report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">CP</div>
              </div>
              <select
                multiple
                size="2"
                className="custom-select mr-sm-2"
                name="cp"
                onChange={onChangeSelectedCP}
              >
                <option key="all" value="all">
                  ALL
                </option>
                {cpOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select mr-sm-2" name="legal_entity">
                {leOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Currency</div>
              </div>
              <select className="custom-select mr-sm-2" name="ccy">
                <option key="all" value="all">
                  ALL
                </option>
                {currenciesOptions}
              </select>
            </div>
          </div>
        </div>

        <div className="form-row mb-2 col-12">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Bank Operation</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="bank_operation_type"
              >
                <option key="all" value="all">
                  ALL
                </option>
                {operationTypesOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Bank Account ID</div>
              </div>
              <input
                type="text"
                className="form-control"
                name="bank_account_id"
              />
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">BO Operation</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="bo_operation_type"
              >
                <option key="all" value="all">
                  ALL
                </option>
                {operationTypesOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">BO Account ID</div>
              </div>
              <input
                type="text"
                className="form-control"
                name="bo_account_id"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="mx-auto">
            <button className="btn btn-success" type="submit">
              Load Data
            </button>
          </div>
        </div>
      </form>
      <hr />
    </div>
  );
}
