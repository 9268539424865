import cn from 'classnames';
import { FC, useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService, {
  CpTrade,
  CtradeExtended,
} from 'services/ReconTablesApiService';

import './style.css';

const COLUMNS = [
  'report_date',
  'broker_id',
  'account',
  'symbol',
  'qty',
  'price',
  'legal_entity',
  'ccy',
  'isin',
];

interface Trade extends CpTrade {
  hasMapping: string;
}
interface Props {
  trades: Trade[];
  tradesIsLoading: boolean;
  setMatchResults: (res: CtradeExtended[], trade: Trade) => void;
}

const api = new ReconTablesApiService();
export const SearchResultTable: FC<Props> = ({
  trades,
  tradesIsLoading,
  setMatchResults,
}) => {
  const leNames = useAppSelector((state) => state.legalEntities);

  const [selectedTradesUid, setSelectedTradesUid] = useState<number[]>([]);
  const [pairIsLoading, setPairIsLoading] = useState(false);

  const findMatched = (trade: Trade) => {
    setPairIsLoading(true);
    setSelectedTradesUid([...selectedTradesUid, trade.full_id]);
    api
      .suggestCTradesForCpTrade(trade.full_id)
      .then((res) => {
        setMatchResults(res, trade);
      })
      .catch((e) => {
        // eslint-disable-next-line no-alert
        alert(e);
      })
      .finally(() => {
        setPairIsLoading(false);
      });
  };

  const tHead = (
    <thead>
      <tr className="thead-light">
        {COLUMNS.map((item) => (
          <th>{item}</th>
        ))}
        <th>Action</th>
      </tr>
    </thead>
  );

  const tBodyRows = trades.map((trade) => {
    const isLastSelectedElement =
      selectedTradesUid[selectedTradesUid.length - 1] === trade.full_id;
    const className = cn(
      { 'font-weight-bold': !!trade.hasMapping },
      {
        'table-primary': isLastSelectedElement,
      },
      {
        'table-active':
          !isLastSelectedElement && selectedTradesUid.includes(trade.full_id),
      },
    );
    return (
      <tr className={className}>
        {COLUMNS.map((column) => (
          <td>
            {
              // @ts-ignore
              column === 'legal_entity' ? leNames[trade[column]] : trade[column]
            }
          </td>
        ))}
        <td>
          {selectedTradesUid.includes(trade.full_id) &&
          pairIsLoading &&
          isLastSelectedElement ? (
            <Spinner />
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => findMatched(trade)}
            >
              Find pair
            </button>
          )}
        </td>
      </tr>
    );
  });
  if (tradesIsLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <p>* Bold text means CP symbol already has mapping</p>
      <div className="table-responsive mt-3">
        {!trades.length ? (
          <h2 className="text-center">No data</h2>
        ) : (
          <div className="table-wrapper-results">
            <table className="table table-bordered table-hover table-sm">
              {tHead}
              <tbody>{tBodyRows}</tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
