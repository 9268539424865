import cn from 'classnames';
import { useEffect, useMemo, useState, useRef } from 'react';
import {
  Table,
  Button,
  NotificationProvider,
  Notification,
  ConfirmationModal,
  ISortBy,
} from 'react-ui-kit-exante';

import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService, {
  TablesWithBreakReport,
  SIDE,
  Transaction,
  TransactionOperationType,
  CategoryComment,
} from 'services/ReconTablesApiService';
import { getSumFromArray } from 'utils/array';

import { BreakCategoryForm } from './BreakCategoryForm/BreakCategoryForm';
import { SearchForm } from './SearchForm/SearchForm';
import {
  getCpBoMixedTableColumns,
  getReconciledTableColumns,
  editTableValueMapping,
} from './UnionTransactionsReconcileUtils';
import { useFilters, useReconciledTable } from './hooks';

import './UnionTransactionsReconcile.css';

export const UnionTransactionsReconcilePage = () => {
  const mountedPageAndCount = useRef(false);
  const mountedFilterPayload = useRef(false);
  const mountedSortingParams = useRef(false);
  const dataHasBeenUploaded = useRef(false);

  const cpList = useAppSelector((state) => state.counterParties);
  const [transactionTypes, setTransactionTypes] = useState<
    TransactionOperationType[]
  >([]);

  const [confirmModalIsOpened, setConfirmModalIsOpened] = useState(false);
  const [isEditTable, setIsEditTable] = useState(false);
  const [isLoadingCpBo, setIsLoadingCpBo] = useState(false);
  const [cpBoTransactions, setCpBoTransactions] = useState<Transaction[]>([]);
  const [breakCategories, setBreakCategories] = useState<CategoryComment[]>([]);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const {
    filterPayloadState,
    handleSetFilters,
    getUrlPayload,
    setSortingParams,
    sortingParams,
    ...allFilters
  } = useFilters(setPage);

  const [breakCategory, setBreakCategory] = useState(0);
  const [breakComment, setBreakComment] = useState('');

  const [isLoadingReconciled, setIsLoadingReconciled] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([]);

  const { setReconciledTransactions, groupedReconciledTransactions } =
    useReconciledTable();

  const api = new ReconTablesApiService();

  const selectedTheirTransactions = useMemo(
    () => selectedTransactions.filter((i) => i.side === SIDE.THEIR),
    [selectedTransactions],
  );
  const selectedOurTransactions = useMemo(
    () => selectedTransactions.filter((i) => i.side === SIDE.OUR),
    [selectedTransactions],
  );
  const selectedElementsSum = useMemo(
    () =>
      parseFloat(
        Number(
          getSumFromArray(selectedOurTransactions, 'amount') -
            getSumFromArray(selectedTheirTransactions, 'amount'),
        ).toFixed(6),
      ),
    [selectedTransactions, cpBoTransactions],
  );
  const { selectedElementsCurrencyIsSame, reconcileDisabled } = useMemo(() => {
    const everyIsOneCcy = selectedTransactions.every(
      (i) => i.ccy === selectedTransactions[0].ccy,
    );
    return {
      selectedElementsCurrencyIsSame: everyIsOneCcy,
      reconcileDisabled: !(selectedTransactions.length >= 2) || !everyIsOneCcy,
    };
  }, [selectedTransactions, cpBoTransactions]);

  const loadTransactions = async (loadFirstPage = false) => {
    setIsLoadingCpBo(true);
    const pageNumber = loadFirstPage ? 0 : page;
    const urlWithPayload = getUrlPayload();
    try {
      const cpBoResponse = await api.getCpBoTransactions(
        urlWithPayload,
        pageNumber,
        pageSize,
      );
      dataHasBeenUploaded.current = true;
      setCount(Math.ceil(cpBoResponse.total / pageSize));
      setCpBoTransactions(cpBoResponse.results);
      setTotal(cpBoResponse.total);
      setIsLoadingCpBo(false);
    } catch (e) {
      setIsLoadingCpBo(false);
      setCpBoTransactions([]);
      Notification.error('Сp Bo Transactions no loaded');
    }
    if (!loadFirstPage) {
      return;
    }
    setSelectedTransactions([]);
    setIsLoadingReconciled(true);
    try {
      const reconciledResponse = await api.getReconciledTransactions(
        urlWithPayload,
      );
      setReconciledTransactions(reconciledResponse.results);
      setIsLoadingReconciled(false);
    } catch (e) {
      setIsLoadingReconciled(false);
      setReconciledTransactions([]);
      Notification.error('Reconciled Transactions no loaded');
    }
  };

  const updateLocalTableData = (
    id: number,
    filedName: string,
    value: string,
  ) => {
    const foundTransaction = cpBoTransactions.find((i) => i.id === id);
    const otherTransactions = cpBoTransactions.filter((i) => i.id !== id);
    const updatedObject: any = {
      ...foundTransaction,
      [editTableValueMapping[filedName] ?? filedName]: value,
    };
    setCpBoTransactions([...otherTransactions, updatedObject]);
  };

  const handleUpdateTheirTransaction = async (
    id: number,
    filedName: string,
    value: string,
  ) => {
    try {
      await api.patchCpTransaction(id, { [filedName]: value });
      updateLocalTableData(id, filedName, value);
    } catch (_) {
      Notification.error('Update their transaction error');
    }
  };

  const handleUpdateOurTransaction = async (
    id: number,
    filedName: string,
    value: string,
  ) => {
    try {
      await api.patchBoFinancialTransaction(id, { [filedName]: value });
      updateLocalTableData(id, filedName, value);
    } catch (_) {
      Notification.error('Update our transaction error');
    }
  };

  useEffect(() => {
    api
      .getCommentCategories({})
      .then((response) => {
        const results = response.results;
        if (results) {
          setBreakCategories(results);
          if (results[0]) {
            setBreakCategory(results[0].id);
          }
        }
      })
      .catch((e) => {
        Notification.error(`comment no loaded: ${e}`);
      });

    api
      .getTransactionOperationTypeListForPage('transactions-reconcile')
      .then((response) => {
        if (response.results) {
          setTransactionTypes(response.results);
        }
      })
      .catch(() => {
        Notification.error('Fetch TransactionTypes error');
      });
  }, []);

  useEffect(() => {
    if (mountedFilterPayload.current) {
      loadTransactions(true);
    } else {
      mountedFilterPayload.current = true;
    }
  }, [filterPayloadState]);
  useEffect(() => {
    if (mountedSortingParams.current) {
      loadTransactions();
    } else {
      mountedSortingParams.current = true;
    }
  }, [sortingParams]);
  useEffect(() => {
    if (mountedPageAndCount.current) {
      loadTransactions();
    } else {
      mountedPageAndCount.current = true;
    }
  }, [page, pageSize]);

  const handleCheckTransaction = (transaction: Transaction) => {
    if (selectedTransactions.find((i) => i.id === transaction.id)) {
      setSelectedTransactions([
        ...selectedTransactions.filter((i) => i.id !== transaction.id),
      ]);
    } else {
      setSelectedTransactions([...selectedTransactions, transaction]);
    }
  };
  const handleCheckAllOnPage = () => {
    setSelectedTransactions([...selectedTransactions, ...cpBoTransactions]);
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTransactionsOnPage = selectedTransactions.filter((item) => {
      const foundTransaction = cpBoTransactions.find((i) => i.id === item.id);
      return !foundTransaction;
    });
    setSelectedTransactions(withoutTransactionsOnPage);
  };
  const handleReconcile = async () => {
    setConfirmModalIsOpened(false);
    try {
      const cpSelectedIds = selectedTheirTransactions.map((i) => i.id);
      const boSelectedIds = selectedOurTransactions.map((i) => i.id);
      await api.postReconcileTransactions({
        cp_ids: cpSelectedIds,
        bo_ids: boSelectedIds,
      });
      Notification.success('Transactions reconciled');
      await loadTransactions(true);
      setSelectedTransactions([]);
    } catch (e) {
      Notification.error('Reconcile error');
    }
  };
  const handleOpenReconcileConfirm = async () => {
    if (Number(selectedElementsSum) !== 0) {
      setConfirmModalIsOpened(true);
      return;
    }
    await handleReconcile();
    setConfirmModalIsOpened(false);
  };

  const handleUnGroup = async (group_id: number) => {
    try {
      await api.deleteReconcileTransactions({
        group_id,
      });
      Notification.success(`Success ungroup`);
      await loadTransactions(true);
    } catch (e) {
      Notification.error(`Failed to reconcile: ${e}`);
    }
  };

  const handleCreateBreakCategoryComment = async () => {
    if (!breakComment) {
      Notification.error('Fill in the comment field to save');
      return;
    }
    const cpTransactions = selectedTheirTransactions.map((i) => i.id);
    const boTransactions = selectedOurTransactions.map((i) => i.id);
    await api.createBreakReportComment({
      category: Number(breakCategory),
      comment: breakComment,
      record_types_mapping: {
        [TablesWithBreakReport.CPTRANSACTION]: cpTransactions,
        [TablesWithBreakReport.FTBO]: boTransactions,
      },
    });
    setSelectedTransactions([]);
    await loadTransactions();
  };

  const handleUpdateBreakCategoryById = async (
    record_types_mapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => {
    await api.createBreakReportComment({
      category: categoryId,
      comment,
      record_types_mapping,
    });
    await loadTransactions();
  };

  const commentsLength = useMemo(() => {
    const widthCoefficient = 8;
    const defaultCommentWidth = 200;
    if (cpBoTransactions) {
      return (
        Math.max(
          ...cpBoTransactions
            .filter((i) => i.comment)
            .map((i) => i.comment.length),
        ) * widthCoefficient
      );
    }
    return defaultCommentWidth;
  }, [cpBoTransactions]);

  const cpBoMixedTableColumns = useMemo(() => {
    const columns = getCpBoMixedTableColumns(
      cpBoTransactions,
      selectedTransactions.map((i) => i.id),
      handleCheckTransaction,
      commentsLength,
      handleCheckAllOnPage,
      handleUnCheckAllOnPage,
      breakCategories,
      handleUpdateBreakCategoryById,
      transactionTypes,
      cpList,
      handleUpdateTheirTransaction,
      handleUpdateOurTransaction,
    );
    return columns.map((item) => {
      const { Cell, ...column } = item as any;
      if (item.accessor === 'id') {
        return item;
      }
      if (!isEditTable) {
        return column;
      }
      return item;
    });
  }, [cpBoTransactions, breakCategories, selectedTransactions, isEditTable]);
  const serverPaginationProps = {
    pageIndex: page,
    pageCount: count,
    pageSize,
    setPage,
    setPageSize,
    total,
  };

  const handleSorting = (sortingPayload: ISortBy[]) => {
    setSortingParams(sortingPayload);
  };

  const cpBoMixedTable = (
    <div className="row my-2">
      <div className="col-12">
        <Table
          tableId="MixedTransactions"
          className="autoReconcileTable"
          showTableInfo
          hasPagination
          manualSortBy
          isFlexLayout
          pageSizes={pageSizes}
          serverPaginationProps={serverPaginationProps}
          columns={cpBoMixedTableColumns}
          isLoading={isLoadingCpBo}
          data={cpBoTransactions}
          onSort={handleSorting}
        />
      </div>
    </div>
  );

  const reconciledTableColumns = getReconciledTableColumns(handleUnGroup);

  const reconciledTable = (
    <>
      <div className="row">
        <div className="col-12">
          <Table
            className="autoReconcileTable"
            tableId="ReconciledTransactions"
            showTableInfo
            disableSortBy
            hasPagination
            pageSize={5}
            pageSizes={pageSizes}
            isLoading={isLoadingReconciled}
            columns={reconciledTableColumns}
            data={groupedReconciledTransactions}
          />
        </div>
      </div>
    </>
  );

  const actionsBlock = (
    <div>
      <div className="my-2">
        <BreakCategoryForm
          breakCategories={breakCategories}
          setComment={setBreakComment}
          setCategory={setBreakCategory}
          comment={breakComment}
          onCreateBreakCategoryComment={handleCreateBreakCategoryComment}
        />
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <div className="actionsBlock d-flex justify-content-left">
            <Button
              disabled={reconcileDisabled}
              onClick={handleOpenReconcileConfirm}
              className="mr-2"
            >
              Reconcile
            </Button>
            <Button onClick={() => setIsEditTable(!isEditTable)}>
              {isEditTable ? 'View' : 'Edit'}
            </Button>
          </div>
        </div>
      </div>
      {selectedTransactions.length ? (
        <div className="row">
          <div className="col-12">
            <div
              className={cn({
                'my-2': true,
                alert: true,
                'w-100': true,
                'alert-danger': Number(selectedElementsSum) !== 0,
                'alert-primary': Number(selectedElementsSum) === 0,
              })}
            >
              Value difference between selected transactions is{' '}
              <b>{selectedElementsSum}</b>
            </div>
            {!selectedElementsCurrencyIsSame ? (
              <div className="alert alert-danger my-2 w-100">
                CCY is different!
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <NotificationProvider />
      <div className="container autoReconcilePage">
        <h2 className="m-0">Transactions Reconciliation (union view)</h2>
        <SearchForm
          {...allFilters}
          breakCategories={breakCategories}
          handleSubmit={handleSetFilters}
          transactionTypes={transactionTypes}
        />
        <p className="m-0 mt-2 text-left">Set break category on selected: </p>
        {actionsBlock}
      </div>
      <div className="container-fluid">
        {dataHasBeenUploaded.current ? cpBoMixedTable : null}
        <h2 className="mb-2">Reconciled transactions</h2>
        {dataHasBeenUploaded.current ? reconciledTable : null}
      </div>
      <ConfirmationModal
        open={confirmModalIsOpened}
        text={`Wrong sum transactions: ${selectedElementsSum}\nDo you want to reconcile them?`}
        handleCancel={() => setConfirmModalIsOpened(false)}
        handleSubmit={handleReconcile}
      />
    </>
  );
};
