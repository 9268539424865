import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function NewReportForm({ onNewReportCreated }) {
  const [loading, setLoading] = useState(false);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const brokerIDs = useSelector((state) => state.counterParties);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);
  const [selectedReport, setSelectedReport] = useState('trades');

  useEffect(() => {
    const apiService = new ReconTablesApiService();

    apiService.getListMappingReportType().then((res) => {
      const options = res.results.map((r) => (
        <option value={r.type} key={r.type}>
          {r.type}
        </option>
      ));
      setReportTypeOptions(options);
    });

    apiService.getListComparisonType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setComparisonOptions(options);
    });
  }, []);

  useEffect(() => {
    const apiService = new ReconTablesApiService();
    if (selectedReport === 'trades') {
      apiService.getListMappingCpTradesFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (selectedReport === 'positions') {
      apiService.getListMappingPositionReconciliationFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (selectedReport === 'cash') {
      apiService.getListMappingRowBalanceFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (selectedReport === 'coupon') {
      apiService.getCouponFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (selectedReport === 'dividend') {
      apiService.getDividendFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }
    if (selectedReport === 'transactions') {
      apiService.getListCpTransactionFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }
  }, [selectedReport]);

  const onReportChange = (e) => {
    const selectedID = e.target.value;

    setSelectedReport(selectedID);
  };

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }

    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);

  const onAddFieldMapping = () => {
    const newFieldMapping = {
      conditions: [],
    };
    const newFieldMappings = [...fieldMappings];
    newFieldMappings.push(newFieldMapping);
    setFieldMappings(newFieldMappings);
  };

  const addCondition = (fieldMappingIdx) => {
    const newFieldMappings = [...fieldMappings];
    const fmToModify = newFieldMappings[fieldMappingIdx];
    fmToModify.conditions.push(1);
    setFieldMappings(newFieldMappings);
  };

  const deleteCondition = (fieldMappingIdx, condIdx) => {
    const newFieldMappings = [...fieldMappings];
    const fmToModify = newFieldMappings[fieldMappingIdx];
    fmToModify.conditions = fmToModify.conditions.filter(
      (_, idx) => idx !== condIdx,
    );
    newFieldMappings[fieldMappingIdx] = fmToModify;
    setFieldMappings(newFieldMappings);
  };

  const deleteFieldMapping = (fieldMappingIdx) => {
    const newFieldMappings = fieldMappings.filter(
      (_, idx) => idx !== fieldMappingIdx,
    );
    setFieldMappings(newFieldMappings);
  };

  const fieldMappingEl = () => {
    const elements = [];
    let idx = -1;
    // eslint-disable-next-line
    for (const fm of fieldMappings) {
      idx += 1;
      const tempIdx = idx;
      const conditionsElements = [];
      let idxCond = -1;
      // eslint-disable-next-line
      for (const cond of fm.conditions) {
        idxCond += 1;
        const tempIdxCond = idxCond;
        conditionsElements.push(
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Field</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_field#${tempIdxCond}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Value</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_value#${tempIdxCond}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Comparison Type</div>
                </div>
                <select
                  className="custom-select mr-sm-2"
                  name={`conditions#${idx}:comparison_type#${tempIdxCond}`}
                >
                  {comparisonOptions}
                </select>
              </div>
            </div>
            <div className="col-1">
              <div className="input-group">
                <button
                  className="btn btn-danger"
                  onClick={() => deleteCondition(tempIdx, tempIdxCond)}
                  type="button"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>,
        );
      }
      const el = (
        <div className="card bg-light mt-3">
          <div className="card-body">
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP Field</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`cp_field#${idx}`}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mapping Field</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`mapping_field#${idx}`}
                  >
                    {fieldOptions}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mapping Type</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`mapping_type#${idx}`}
                  >
                    <option value="index">Index</option>
                    <option value="name">Name</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Action</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`action#${idx}`}
                  >
                    <option key="true" value="true">
                      Yes
                    </option>
                    <option key="false" value="false">
                      No
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {conditionsElements}
            <div className="form-row mt-2">
              <div className="mx-auto">
                {loading ? (
                  <Spin />
                ) : (
                  <div>
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => addCondition(tempIdx)}
                    >
                      Add Condition
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      onClick={() => deleteFieldMapping(tempIdx)}
                      type="button"
                    >
                      Delete FM
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      elements.push(el);
    }
    return elements;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const rawPayload = getFormPayload(e.target);
    const payload = {};
    /* eslint-disable */
    for (const key of Object.keys(rawPayload)) {
      if (key.indexOf('#') > -1) {
        continue;
      }
      payload[key] = rawPayload[key];
    }
    /* eslint-enable */

    if (payload.is_heading === 'true') {
      payload.is_heading = true;
    } else {
      payload.is_heading = false;
    }

    const fm = [];
    for (let i = 0; i < fieldMappings.length; i += 1) {
      const fmRaw = fieldMappings[i];
      const conditions = [];
      if (fmRaw.conditions.length > 0) {
        for (let j = 0; j < fmRaw.conditions.length; j += 1) {
          conditions.push({
            condition_field: rawPayload[`conditions#${i}:condition_field#${j}`],
            condition_value: rawPayload[`conditions#${i}:condition_value#${j}`],
            comparison_type: rawPayload[`conditions#${i}:comparison_type#${j}`],
          });
        }
      }
      fm.push({
        cp_field: rawPayload[`cp_field#${i}`],
        mapping_field: rawPayload[`mapping_field#${i}`],
        name: rawPayload[`name#${i}`],
        mapping_type: rawPayload[`mapping_type#${i}`],
        action: rawPayload[`action#${i}`],
        conditions,
      });
    }
    payload.field_mappings = fm;
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .postMappingReportWithFields(payload)
      .then((res) => {
        setLoading(false);
        onNewReportCreated(res.report_id);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_id">
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Type</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="type"
                onChange={onReportChange}
              >
                {reportTypeOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Name</div>
              </div>
              <input type="text" className="form-control" name="name" />
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path</div>
              </div>
              <input type="text" className="form-control" name="path" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Contains</div>
              </div>
              <input type="text" className="form-control" name="contains" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">File Type</div>
              </div>
              <input type="text" className="form-control" name="file_type" />
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Delimitter</div>
              </div>
              <input type="text" className="form-control" name="delimitter" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Quotechar</div>
              </div>
              <input type="text" className="form-control" name="quotechar" />
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Heading</div>
              </div>
              <select className="custom-select mr-sm-2" name="is_heading">
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counts</div>
              </div>
              <input type="text" className="form-control" name="counts" />
            </div>
          </div>
        </div>

        {fieldMappingEl()}
        <div className="form-row mt-3">
          <div className="mx-auto">
            {loading ? (
              <Spin />
            ) : (
              <div>
                <button className="btn btn-warning" type="submit">
                  Create
                </button>
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={onAddFieldMapping}
                >
                  Add Field Mapping
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
