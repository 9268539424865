import { TableProps } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { STATUSES, Task } from 'services/ReconEmirApiService';
import { ColumnsWithFilters } from 'types';

export const getTasksTableHeader = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters) => {
  const columns: TableProps<Task>['columns'] = [
    {
      Header: 'Id',
      accessor: 'id',
      minWidth: 70,
      width: 70,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 110,
      width: 110,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Start time',
      accessor: 'start_time',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'End time',
      accessor: 'end_time',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Report date',
      accessor: 'report_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Output',
      accessor: 'output',
      minWidth,
      maxWidth: 3000,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 120,
      width: 120,
      type: 'select',
      filterOptions: [
        { label: STATUSES.CREATED, value: STATUSES.CREATED },
        { label: STATUSES.FAILED, value: STATUSES.FAILED },
        { label: STATUSES.RUNNING, value: STATUSES.RUNNING },
        { label: STATUSES.FINISHED, value: STATUSES.FINISHED },
      ],
      onFilter,
      onRemove,
    },
  ];
  return columns;
};
