import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function NewReportForm({ onNewReportCreated }) {
  const brokerIDs = useSelector((state) => state.counterParties);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const selectedReport = 'trades';

  useEffect(() => {
    const apiService = new ReconTablesApiService();

    apiService.getListMappingReportType().then(() => {});

    apiService.getListComparisonType().then(() => {});
  }, []);

  useEffect(() => {
    const apiService = new ReconTablesApiService();
    if (selectedReport === 'trades') {
      apiService.getListMappingCpTradesFields().then(() => {});
    }

    if (selectedReport === 'positions') {
      apiService.getListMappingCpTradesFields().then(() => {});
    }

    if (selectedReport === 'cash') {
      apiService.getListMappingRowBalanceFields().then(() => {});
    }

    if (selectedReport === 'coupon') {
      apiService.getCouponFields().then(() => {});
    }

    if (selectedReport === 'dividend') {
      apiService.getDividendFields().then(() => {});
    }
  }, [selectedReport]);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }

    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
    setBrokerOptions(options);
  }, [brokerIDs]);

  const onSubmit = (event) => {
    event.preventDefault();
    const rawPayload = getFormPayload(event.target);
    const payload = {};
    // eslint-disable-next-line
    for (const key of Object.keys(rawPayload)) {
      if (key.indexOf('#') > -1) {
        continue;
      }
      payload[key] = rawPayload[key];
    }

    payload.field_mappings = [];
    payload.type = 'unpivot';

    const api = new ReconTablesApiService();
    api
      .postMappingReportWithFields(payload)
      .then((res) => {
        onNewReportCreated(res.report_id);
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_id">
                {brokerOptions}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Name</div>
              </div>
              <input type="text" className="form-control" name="name" />
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Path</div>
              </div>
              <input type="text" className="form-control" name="path" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Contains</div>
              </div>
              <input type="text" className="form-control" name="contains" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">File Type</div>
              </div>
              <input type="text" className="form-control" name="file_type" />
            </div>
          </div>
        </div>
        <div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Qutput File</div>
              </div>
              <input type="text" className="form-control" name="output" />
            </div>
          </div>

          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Fields List</div>
              </div>
              <input type="text" className="form-control" name="fields" />
            </div>
          </div>
        </div>
        <div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Delimitter</div>
              </div>
              <input type="text" className="form-control" name="delimitter" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Quotechar</div>
              </div>
              <input type="text" className="form-control" name="quotechar" />
            </div>
          </div>
        </div>
        <button className="btn btn-warning" type="submit">
          Create
        </button>
      </form>
    </div>
  );
}
