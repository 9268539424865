import { Component } from 'react';

import PositionDiffForm from './form';
import LinkedTables from './linked-tables';

// eslint-disable-next-line
class PositionDiffPage extends Component {
  render() {
    return (
      <>
        <PositionDiffForm />
        <LinkedTables />
      </>
    );
  }
}

export default PositionDiffPage;
