import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  Panel,
  useTableData,
  IOnFetchArguments,
  ISortBy,
  Button,
  CloseButton,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import {
  ReconEmirApiService,
  Task,
  TasksTable,
} from 'services/ReconEmirApiService';
import { calculateCountOfPages } from 'utils';

import { Metrics } from './metrics';
import { RunScripts } from './run-scripts';
import { getTasksTableHeader } from './utils';

const api = new ReconEmirApiService();

export const EmirScriptsPage: FC = () => {
  const [runIsShow, setRunIsShow] = useState(false);
  const [reportDate, setReportDate] = useState<string[]>([]);

  const getTasks = useCallback(
    (props: IOnFetchArguments) => api.getTasks(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getTasksTableHeader({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Task)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );
  const tableData = useMemo(
    () => ({
      data: { onFetch: getTasks },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getTasks],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<TasksTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () => getTasksTableHeader({ onFilter: setFilter, onRemove: removeFilter }),
    [data?.tasks],
  );

  useEffect(() => {
    if (filters.report_date) {
      setReportDate(filters.report_date as string[]);
    }
  }, [filters]);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const panelAction = useMemo(
    () => (
      <CloseButton
        className="mb-2"
        onClick={() => setRunIsShow(false)}
        size="l"
      />
    ),
    [setRunIsShow],
  );

  return (
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-12">
          <Panel
            title="Management console"
            action={
              <Button onClick={() => setRunIsShow(!runIsShow)}>Run</Button>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className={runIsShow ? 'col-8' : 'col-12'}>
          <Table
            tableId="emirTasksTable"
            data={data?.tasks || []}
            columns={columns}
            filteringProps={filteringProps}
            isLoading={isLoading}
            defaultSortBy={defaultOrdering}
            hasFilters
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            isFlexLayout
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
        <div className={runIsShow ? 'col-4' : 'd-none'}>
          <Panel title="Run scripts" action={panelAction}>
            <RunScripts />
          </Panel>
        </div>
      </div>
      <Metrics reportDate={reportDate} />
    </div>
  );
};
