import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { AutoMappingForm } from './auto-mapping-form';
import { ResultsTable } from './table';

export default function AutoMappingRulesPage() {
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);

  const loadRules = () => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListAutoMappingRuleWithFields({})
      .then((res) => {
        setLoading(false);
        setRules(res);
      })
      .catch((e) => {
        setRules([]);
        alert(e);
      });
  };

  useEffect(() => {
    loadRules();
  }, []);

  const onAddSubmit = () => {
    // eslint-disable-next-line
    console.log('On Add');
    loadRules();
  };

  return (
    <>
      <AutoMappingForm successCallback={onAddSubmit} />
      {loading ? <Spin /> : <ResultsTable results={rules} />}
    </>
  );
}
