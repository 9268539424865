import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { XleTotalForm } from './form';
import { XleReportTable } from './table';

export function XleTotalReportPage() {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);

  const queryReport = (payload) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    if (payload.type === 'hkrecon') {
      api
        .getListXleReportHK(payload)
        .then((e) => {
          setResults(e);
          setLoading(false);
        })
        .catch((e) => {
          setResults([]);
          setLoading(false);
          alert(e);
        });
    }
    if (payload.type === 'ium_recon') {
      api
        .getListXleReportEXT(payload)
        .then((e) => {
          setResults(e);
          setLoading(false);
        })
        .catch((e) => {
          setResults([]);
          setLoading(false);
          alert(e);
        });
    }
    if (payload.type === 'xntrecon') {
      api
        .getListXleReportXNT(payload)
        .then((e) => {
          setResults(e);
          setLoading(false);
        })
        .catch((e) => {
          setResults([]);
          setLoading(false);
          alert(e);
        });
    }
  };

  return (
    <>
      <XleTotalForm queryReport={queryReport} />
      {loading ? <Spinner /> : <XleReportTable data={results} />}
    </>
  );
}
