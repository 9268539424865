import { Component } from 'react';

function roundNumber(value) {
  if (typeof value === 'number') {
    return value.toFixed(3);
  }
  return value;
}

export class PosDiffTableRow extends Component {
  onClick = () => {
    this.props.onClick(this.props.entry);
  };

  render() {
    const { entry } = this.props;
    return (
      <tr onClick={this.onClick} className="pointer">
        <td>{entry.account}</td>
        <td>{entry.symbol}</td>
        <td>{entry.cp_symbol}</td>
        <td>{entry.isin}</td>
        <td>{roundNumber(entry.current)}</td>
        <td>{roundNumber(entry.reported)}</td>
        <td>{roundNumber(entry.previously_reported)}</td>
        <td>{roundNumber(entry.difference)}</td>
        <td>{roundNumber(entry.diffs_sum)}</td>
        <td>{roundNumber(entry.correction_sum)}</td>
        <td>{roundNumber(entry.transactions_sum)}</td>
      </tr>
    );
  }
}
