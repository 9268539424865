import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import ReconRunnerApiService from 'services/ReconRunnerApiService';
import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

function FilterInputs({ filterOptions, counter }) {
  const options = filterOptions.map((o) => (
    <option key={o} value={o}>
      {o}
    </option>
  ));
  return (
    <>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Filter Field</div>
          </div>
          <select className="custom-select mr-sm-2" name={`field-${counter}`}>
            {options}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Filter Condition</div>
          </div>
          <select
            className="custom-select mr-sm-2"
            name={`condition-${counter}`}
          >
            <option key="eq" value="eq">
              =
            </option>
            <option key="not_eq" value="ne">
              !=
            </option>
            <option key="gt" value="gt">
              &#62;
            </option>
            <option key="lt" value="lt">
              &#60;
            </option>
            <option key="like" value="contains">
              contains(string only)
            </option>
          </select>
        </div>
      </div>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Value</div>
          </div>
          <input
            type="text"
            className="form-control"
            name={`value-${counter}`}
            placeholder=""
          />
        </div>
      </div>
    </>
  );
}

export function XleTotalForm(props) {
  const formID = '';
  const { queryReport } = props;
  const [filterFields, setFilterFields] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedTable, setSelectedTable] = useState('hkrecon');
  const [addedInputs, setAddedInputs] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const api = new ReconTablesApiService();
    api
      .getXleFiltersByLE()
      .then((resp) => {
        setFilterFields(resp);
        setActiveFilters(resp.hkrecon);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  }, []);

  const onTableChange = (e) => {
    if (selectedTable !== e.target.value) {
      setAddedInputs([]);
    }
    setSelectedTable(e.target.value);
    setActiveFilters(filterFields[e.target.value]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    queryReport(payload);
  };

  const onRebuildClick = () => {
    const payload = {
      scripts: ['SQLScript.xle_reports'],
      force_run: true,
      update_mapping: false,
      update_table: false,
    };
    const runnerAPI = new ReconRunnerApiService();
    runnerAPI
      .runScripts(payload, false)
      .then(() => {
        alert(
          'Processing DB updates. It can take around 1 minute... Please search for reports later',
        );
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  };

  const removeFilterInput = (cnt) => {
    const newInputs = [...addedInputs];
    newInputs.filter((e) => e.count !== cnt);
    setAddedInputs(newInputs);
  };

  const addFilterInput = () => {
    const newCounter = counter + 1;
    const newInputs = [...addedInputs];
    const input = (
      <div className="form-row mb-2" key={newCounter}>
        <FilterInputs
          filterOptions={activeFilters}
          counter={newCounter}
          key={newCounter}
        />
        <div className="col">
          <div className="mx-auto">
            <button
              onClick={() => removeFilterInput(newCounter)}
              className="btn btn-danger"
              type="button"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );

    newInputs.push({
      count: newCounter,
      input,
    });
    setCounter(newCounter);
    setAddedInputs(newInputs);
  };

  const resetFilters = () => {
    setAddedInputs([]);
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit} id={formID}>
        <div className="form-row mb-2">
          <div className="col-3" />
          <div className="col-3">
            <DayPickerInput
              inputProps={{
                className: 'custom-select mr-sm-2',
                name: 'report_date',
                autoComplete: 'off',
              }}
            />
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">View</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="type"
                onChange={onTableChange}
              >
                <option key="hk" value="hkrecon">
                  hkrecon
                </option>
                <option key="ium" value="ium_recon">
                  iumrecon
                </option>
                <option key="xnt" value="xntrecon">
                  xntrecon
                </option>
              </select>
            </div>
          </div>
          <div className="col-3" />
        </div>
        {addedInputs.map((i) => i.input)}
        <button
          className="btn btn-warning ml-2"
          type="reset"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
        <button
          className="btn btn-primary ml-2"
          type="button"
          onClick={addFilterInput}
        >
          Add Filter
        </button>
        <button className="btn btn-success ml-2" type="submit">
          Load
        </button>
      </form>
      <hr />
      <button className="btn btn-warning" onClick={onRebuildClick}>
        Update MatView(rebuild report)
      </button>
    </div>
  );
}
