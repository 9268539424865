import { useState } from 'react';
import { Link } from 'react-router-dom';

import { NewCpForm } from './form';

export default function NewCpPage() {
  const [addedReports, setAddedReports] = useState([]);
  const onCpCreated = (reportId) => {
    const newReports = [...addedReports];
    newReports.push(reportId);
    setAddedReports(newReports);
  };

  const addedReportsList = () => {
    if (addedReports.length === 0) {
      return null;
    }
    return addedReports.map((r) => (
      <div className="alert alert-success" role="alert">
        CP added
        <Link to={`/mapping/reports/${r}/`}>{r}</Link>
      </div>
    ));
  };

  return (
    <>
      <NewCpForm onNewReportCreated={onCpCreated} />
      {addedReportsList()}
    </>
  );
}
