import { FC, ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import {
  NotificationProvider,
  Notification,
  Loader,
} from 'react-ui-kit-exante';

import ReconTablesApiService, {
  ReportWithFields,
} from 'services/ReconTablesApiService';

import { ReportListView } from './list';

const api = new ReconTablesApiService();
const reportStatuses = ['Closed', 'Setup', 'Stopped'];

const allOptionValue = 'All';
export const ReportsListPage: FC = () => {
  const [reports, setReports] = useState<ReportWithFields[]>([]);
  const [filteredReports, setFilteredReports] = useState<ReportWithFields[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState(allOptionValue);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);

  const loadData = () => {
    setLoading(true);
    api
      .getListMappingReportWithFields()
      .then((response) => {
        const filterOptionsValue = response.reduce(
          (acc: string[], i) =>
            acc.includes(i.cp_id) ? acc : [...acc, i.cp_id],
          [],
        );
        setFilterOptions(filterOptionsValue);
        const filtered = response.filter((i) => i.type !== 'unpivot');
        setReports(filtered);
        setFilteredReports(filtered);
      })
      .catch((e) => {
        Notification.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onClone = (cpId: number) => {
    api
      .postCloneReportWithFields(cpId)
      .then(() => {
        Notification.success('Cloned success');
        loadData();
      })
      .catch((e) => {
        Notification.error(e);
      });
  };

  const onCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;
    setActive(checked);
  };

  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const { value } = e.target;
    setFilter(value);
    if (value === allOptionValue) {
      setFilteredReports(reports);
    } else {
      setFilteredReports(reports.filter((i) => i.cp_id === value));
    }
  };

  const items = useMemo(
    () =>
      filteredReports.filter(
        (i) => !(reportStatuses.includes(i.state) && !active),
      ),
    [active, filteredReports],
  );

  return (
    <>
      <NotificationProvider />
      <div className="container">
        <h2>Reports List</h2>
        Show not active elements
        <input type="checkbox" onChange={onCheck} checked={active} />
        <div>
          Filters
          <select
            className="custom-select mr-sm-2"
            name="filters"
            onChange={onChange}
          >
            <option
              key={allOptionValue}
              value={allOptionValue}
              selected={filter === allOptionValue}
            >
              All
            </option>
            {filterOptions.map((i) => (
              <option key={i} value={i} selected={filter === i}>
                {i}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="container-fluid">
        {loading ? (
          <Loader />
        ) : (
          <ReportListView onClone={onClone} reports={items} />
        )}
      </div>
    </>
  );
};
