import { useEffect, useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import './style.css';

function formatNumber(val) {
  if (val === undefined) {
    return '-';
  }
  if (typeof val === 'string') {
    if (Number.isNaN(Number(val))) {
      return val;
    }
    return Number(val).toFixed(2);
  }
  return typeof val === 'number' ? val.toFixed(2) : 'N/A';
}

export function EODTableRow(props) {
  const { entry, setEodModalDetailsData } = props;
  const [userComment, setUserComment] = useState();
  const [updatingComment, setUpdatingComment] = useState(false);

  useEffect(() => {
    setUserComment(entry.comment);
  }, [entry]);

  const onInputChange = (e) => {
    setUserComment(e.target.value);
  };

  const onDetailsClick = () => {
    const api = new ReconTablesApiService();
    const filterPayload = {
      symbol: entry.bo_symbol,
      cp_symbol: entry.cp_symbol,
      cp: entry.cp,
      report_date: entry.report_date,
    };
    api
      .getReconBySymbolTradesDetails(filterPayload)
      .then((response) => {
        setEodModalDetailsData(response);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const onCommentChangeClick = (event) => {
    event.preventDefault();
    setUpdatingComment(true);
    const payload = {
      id: entry.id,
      comment: document.getElementById(`commentEdit${entry.id}`).value,
    };

    const api = new ReconTablesApiService();
    api
      .upsertReconBySymbolComment(payload)
      .then(() => {
        setUpdatingComment(false);
      })
      .catch((e) => {
        setUpdatingComment(false);
        alert(e);
      });
  };

  const comment = updatingComment ? (
    <Spinner />
  ) : (
    <form onSubmit={onCommentChangeClick}>
      <textarea
        className="form-control text-small"
        id={`commentEdit${entry.id}`}
        onChange={onInputChange}
        rows="2"
        defaultValue={entry.comment}
        value={userComment}
      />
      <button type="submit" className="btn btn-sm btn-link">
        Update
      </button>
    </form>
  );

  return (
    <tr key={entry.id}>
      <td>{entry.legal_entity}</td>
      <td>{entry.report_date}</td>
      <td>{entry.cp}</td>
      <td>
        <b>{entry.bo_symbol}</b>
      </td>
      <td>
        <b>{entry.cp_symbol}</b>
      </td>
      <td>{formatNumber(entry.bo_value)}</td>
      <td>{formatNumber(entry.cp_value)}</td>
      <td>{entry.bo_ccy}</td>
      <td>{entry.cp_ccy}</td>
      <td>{formatNumber(entry.bo_qty)}</td>
      <td>{formatNumber(entry.cp_qty)}</td>
      <td>{formatNumber(entry.diff_value)}</td>
      <td>
        <b>{entry.recon_status}</b>
      </td>
      <td>
        <button
          type="button"
          className="btn btn-link"
          data-toggle="modal"
          data-target="#EodModalDetails"
          onClick={() => onDetailsClick()}
        >
          Show
        </button>
      </td>
      <td>{comment}</td>
    </tr>
  );
}
