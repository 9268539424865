import { useState } from 'react';
import { Link } from 'react-router-dom';

import { NewReportForm } from './form';

export default function NewReportPage() {
  const [addedReports, setAddedReports] = useState([]);
  const onNewReportCreated = (reportId) => {
    const newReports = [...addedReports];
    newReports.push(reportId);
    setAddedReports(newReports);
  };

  const addedReportsList = () => {
    if (addedReports.length === 0) {
      return null;
    }
    return addedReports.map((r) => (
      <div className="alert alert-success" role="alert">
        Report added
        <Link to={`/mapping/excel-reports/${r}/`}>{r}</Link>
      </div>
    ));
  };

  return (
    <>
      <NewReportForm onNewReportCreated={onNewReportCreated} />
      {addedReportsList()}
    </>
  );
}
