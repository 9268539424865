import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function NewFieldForm(prop) {
  const type = prop.type;
  const reportId = prop.report_id;
  const state = prop.state;
  const [loading, setLoading] = useState(false);
  const [fieldOptions, setFieldOptions] = useState([]);
  const brokerIDs = useSelector((reduxState) => reduxState.counterParties);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);

  useEffect(async () => {
    const apiService = new ReconTablesApiService();

    await apiService.getListMappingReportType();

    await apiService.getListComparisonType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setComparisonOptions(options);
    });

    apiService.getListMappingCpTradesFields().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.field} value={r}>
          {r}
        </option>
      ));
      setFieldOptions(options);
    });
  }, []);

  useEffect(() => {
    const apiService = new ReconTablesApiService();
    if (type === 'trades') {
      apiService.getListMappingCpTradesFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (type === 'positions') {
      apiService.getListMappingCpTradesFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (type === 'cash') {
      apiService.getListMappingRowBalanceFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (type === 'coupon') {
      apiService.getCouponFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }

    if (type === 'dividend') {
      apiService.getDividendFields().then((res) => {
        const options = res.results.map((r) => (
          <option key={r.field} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
      });
    }
  }, [type]);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }

    const options = [];
    // eslint-disable-next-line
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    }
  }, [brokerIDs]);

  const addCondition = (fieldMappingIdx) => {
    const newFieldMappings = [...fieldMappings];
    const fmToModify = newFieldMappings[fieldMappingIdx];
    fmToModify.conditions.push(1);
    setFieldMappings(newFieldMappings);
  };

  const deleteCondition = (fieldMappingIdx, condIdx) => {
    const newFieldMappings = [...fieldMappings];
    const fmToModify = newFieldMappings[fieldMappingIdx];
    fmToModify.conditions = fmToModify.conditions.filter(
      (_, idx) => idx !== condIdx,
    );
    newFieldMappings[fieldMappingIdx] = fmToModify;
    setFieldMappings(newFieldMappings);
  };

  const deleteFieldMapping = (fieldMappingIdx) => {
    const newFieldMappings = fieldMappings.filter(
      (_, idx) => idx !== fieldMappingIdx,
    );
    setFieldMappings(newFieldMappings);
  };

  const fieldMappingEl = () => {
    const elements = [];
    let idx = -1;
    // eslint-disable-next-line
    for (const fm of fieldMappings) {
      idx += 1;
      const tempIdx = idx;
      const conditionsElements = [];
      let idxCond = -1;
      // eslint-disable-next-line
      for (const _ of fm.conditions) {
        idxCond += 1;
        const idxCondTemp = idxCond;
        conditionsElements.push(
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Field</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_field#${idxCondTemp}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Condition Value</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name={`conditions#${idx}:condition_value#${idxCondTemp}`}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Comparison Type</div>
                </div>
                <select
                  className="custom-select mr-sm-2"
                  name={`conditions#${idx}:comparison_type#${idxCondTemp}`}
                >
                  {comparisonOptions}
                </select>
              </div>
            </div>
            <div className="col-1">
              <div className="input-group">
                <button
                  className="btn btn-danger"
                  onClick={() => deleteCondition(tempIdx, idxCondTemp)}
                  type="button"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>,
        );
      }
      const el = (
        <div className="card bg-light mt-3">
          <div className="card-body">
            <div className="form-row mb-2">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">CP Field</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`cp_field#${idx}`}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mapping Field</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`mapping_field#${idx}`}
                  >
                    {fieldOptions}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mapping Type</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`mapping_type#${idx}`}
                  >
                    <option value="index">Index</option>
                    <option value="name">Name</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Action</div>
                  </div>
                  <select
                    className="custom-select mr-sm-2"
                    name={`action#${idx}`}
                  >
                    <option key="true" value="true">
                      Yes
                    </option>
                    <option key="false" value="false">
                      No
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {conditionsElements}
            <div className="form-row mt-2">
              <div className="mx-auto">
                {loading ? (
                  <Spin />
                ) : (
                  <div>
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => addCondition(tempIdx)}
                    >
                      Add Condition
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      onClick={() => deleteFieldMapping(tempIdx)}
                      type="button"
                    >
                      Delete FM
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      elements.push(el);
    }
    return elements;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const rawPayload = getFormPayload(e.target);
    const payload = {};
    payload.report_id = reportId;
    const fm = [];
    for (let i = 0; i < fieldMappings.length; i += 1) {
      const fmRaw = fieldMappings[i];
      const conditions = [];
      if (fmRaw.conditions.length > 0) {
        for (let j = 0; j < fmRaw.conditions.length; j += 1) {
          conditions.push({
            condition_field: rawPayload[`conditions#${i}:condition_field#${j}`],
            condition_value: rawPayload[`conditions#${i}:condition_value#${j}`],
            comparison_type: rawPayload[`conditions#${i}:comparison_type#${j}`],
          });
        }
      }
      fm.push({
        cp_field: rawPayload[`cp_field#${i}`],
        mapping_field: rawPayload[`mapping_field#${i}`],
        mapping_type: rawPayload[`mapping_type#${i}`],
        action: rawPayload[`action#${i}`],
        conditions,
      });
    }
    payload.field_mappings = fm;
    const api = new ReconTablesApiService();
    setLoading(true);
    api
      .postMappingFields(payload)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (state === 'Testing' || state === 'Setup') {
    return (
      <div className="container mb-3 mt-3">
        <form onSubmit={onSubmit}>
          <div className="form-row mb-2">
            {fieldMappingEl()}
            <div className="form-row mt-3">
              <div className="mx-auto">{loading ? <Spin /> : <div />}</div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          {fieldMappingEl()}
          <div className="form-row mt-3">
            <div className="mx-auto">{loading ? <Spin /> : <div />}</div>
          </div>
        </div>
      </form>
    </div>
  );
}
