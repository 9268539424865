import { Spin } from 'antd';
import { useState } from 'react';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import TradeDashboardForm from './form';
import TradeReconDashboardTable from './table';

export default function TradeReconDashboardPage() {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  const search = (payload) => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getListReconStatus(payload)
      .then((resp) => {
        setLoading(false);
        setEntries(resp.results);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <TradeDashboardForm searchSubmit={search} />
      {loading ? <Spin /> : <TradeReconDashboardTable entries={entries} />}
    </>
  );
}
