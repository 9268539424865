import JSONTree from 'react-json-tree';

export function OrderStatusTable(props) {
  const { data } = props;
  if (data === undefined || data.length === 0 || data.results === undefined) {
    return <h3>No data</h3>;
  }
  const orderDbStatus = data.results.order_db_status;
  const cryptoStatsStatus = data.results.crypto_stats_status;
  return (
    <table className="table table-bordered table-striped table-hover table-responsive table-sm w-100 d-block d-md-table">
      <thead>
        <tr className="jsonView">
          <th>crypto_stats_status</th>
          <th>order_db_status</th>
        </tr>
      </thead>
      <tbody>
        <tr className="jsonView">
          <td>
            <JSONTree data={cryptoStatsStatus} />
          </td>
          <td>
            <JSONTree data={orderDbStatus} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
