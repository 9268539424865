import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

function TableRow({ entry, onUpdateHandler }) {
  const [time, setTime] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTime(entry.time);
  }, [entry]);

  const onTimeChange = (e) => {
    e.preventDefault();
    setTime(e.target.value);
  };

  const update = () => {
    try {
      // sanity check for format
      // eslint-disable-next-line
      const [hours, minutes, seconds] = time.split(':');
    } catch (e) {
      alert('Wrong time format. Expected HH:MM:SS');
    }
    setLoading(true);
    const api = new ReconTablesApiService();
    const payload = {
      ...entry,
      time,
    };
    api
      .patchCpCutOffTime(entry.id, payload)
      .then(() => {
        setLoading(false);
        onUpdateHandler();
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <tr key={entry.field_id}>
      <td>{entry.cp.toUpperCase()}</td>
      <td style={{ maxWidth: '80px' }}>
        {loading ? (
          <Spin />
        ) : (
          <div className="input-group">
            <div className="input-group-prepend">
              <button className="btn btn-sm btn-primary" onClick={update}>
                Update
              </button>
            </div>
            <input
              value={time}
              onChange={onTimeChange}
              type="text"
              className="form-control"
            />
          </div>
        )}
      </td>
    </tr>
  );
}

export function CPCutOffTimeTable({ reload, entries }) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const newRows = entries.map((e) => (
      <TableRow entry={e} onUpdateHandler={reload} />
    ));
    setRows(newRows);
  }, [entries]);
  return (
    <div className="container mt-3">
      <h2>COT by Counterparty</h2>
      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-light">
          <tr>
            <th>Counterparty</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
