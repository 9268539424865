import ReactDOM from 'react-dom';

import { addBaseTag } from 'utils';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

addBaseTag();

window.RECON_UI = {
  ...window.RECON_UI,
  render: (node) => {
    // eslint-disable-next-line
    console.log('changes');
    ReactDOM.render(<App />, node);
  },
};

const reconDiv = document.getElementById('root-recon');
if (reconDiv) {
  ReactDOM.render(<App />, reconDiv);
}

serviceWorker.unregister();
