import { Spin } from 'antd';
import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { FTPPathAddForm } from './form';
import { FTPPathTable } from './table';

export default function FTPPathPage() {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [isFTPBO, setIsFTPBO] = useState(false);

  const loadEntries = (isBO, withIndicator = true) => {
    if (withIndicator) {
      setLoading(true);
    }

    setEntries([]);
    const api = new ReconTablesApiService();
    if (isBO) {
      api
        .getListFtpPathBoDrive()
        .then((resp) => {
          setLoading(false);
          setEntries(resp.results);
        })
        .catch((e) => {
          alert(e);
        });
      return;
    }

    api
      .getListFtpPath()
      .then((resp) => {
        setLoading(false);
        setEntries(resp.results);
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    loadEntries(isFTPBO);
  }, [isFTPBO]);

  const changeFTPType = () => {
    setIsFTPBO(!isFTPBO);
  };

  return (
    <>
      <div className="container mt-3">
        <button className="btn btn-outline-primary" onClick={changeFTPType}>
          {isFTPBO ? 'Switch to FTP' : 'switch to BO FTP'}
        </button>
      </div>
      <hr />
      {loading ? (
        <div className="mt-2">
          <Spin />
        </div>
      ) : (
        <div>
          <h2>{isFTPBO ? 'BO Drive FTP paths' : 'FTP Paths'}</h2>
          <FTPPathAddForm isBO={isFTPBO} updateTable={loadEntries} />
          <hr />
          <FTPPathTable entries={entries} isBO={isFTPBO} reload={loadEntries} />
        </div>
      )}
    </>
  );
}
