import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import { OrderStatusForm } from './form';
import { OrderStatusTable } from './table';

export function OrderStatusPage() {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);

  const queryReport = (payload) => {
    setLoading(true);
    const service = new ReconTablesApiService();
    service
      .getOrderStatus(payload)
      .then((e) => {
        setResults(e);
        setLoading(false);
      })
      .catch((e) => {
        setResults([]);
        setLoading(false);
        alert(e);
      });
  };

  return (
    <>
      <OrderStatusForm queryReport={queryReport} />
      {loading ? <Spinner /> : <OrderStatusTable data={results} />}
    </>
  );
}
