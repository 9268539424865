import { FC, ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Loader,
  Table,
  Panel,
  Tabs,
  Tab,
  Notification,
  IColumn,
} from 'react-ui-kit-exante';

import {
  ReconEmirApiService,
  DetailInfoItem,
} from 'services/ReconEmirApiService';

export const DetailPage: FC = () => {
  const { tradeId, positionId } =
    useParams<{ tradeId?: string; positionId?: string }>();
  const [detailData, setDetailData] = useState<Record<
    string,
    DetailInfoItem[]
  > | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const api = new ReconEmirApiService();

  useEffect(() => {
    setIsLoading(true);
    if (tradeId) {
      api
        .getCurrentTrade(tradeId)
        .then((res) => {
          setDetailData(res);
        })
        .catch(() => {
          Notification.error('Trade loading error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (positionId) {
      api
        .getCurrentPosition(positionId)
        .then((res) => {
          setDetailData(res);
        })
        .catch(() => {
          Notification.error('Position loading error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const tableInfo = useMemo(() => {
    if (detailData) {
      const columns = Object.keys(detailData);
      const valueList = columns.reduce((acc: Record<string, string>[], key) => {
        const values = detailData[key];
        if (values) {
          const buffValues: Record<string, string>[] = [];
          values.forEach((tabValue) => {
            Object.keys(tabValue.items).forEach((itemKey) => {
              buffValues.push({
                tabName: tabValue.name,
                columnName: key,
                valueName: itemKey,
                value: tabValue.items[itemKey],
              });
            });
          });
          return [...acc, ...buffValues];
        }
        return acc;
      }, []);

      const dataForTable = valueList.reduce(
        (acc: Record<string, Record<string, Record<string, string>>>, item) => {
          let distributedByTabsResult = {
            ...acc,
          };
          const value =
            typeof item.value === 'boolean' ? String(item.value) : item.value;
          if (Object.keys(acc).includes(item.tabName)) {
            if (Object.keys(acc[item.tabName]).includes(item.valueName)) {
              const obj = distributedByTabsResult[item.tabName][item.valueName];
              distributedByTabsResult[item.tabName][item.valueName] = {
                ...obj,
                [item.columnName]: value,
              };
            } else {
              const obj = distributedByTabsResult[item.tabName];
              distributedByTabsResult[item.tabName] = {
                ...obj,
                [item.valueName]: {
                  [item.columnName]: value,
                },
              };
            }
          } else {
            distributedByTabsResult = {
              ...distributedByTabsResult,
              [item.tabName]: {
                [item.valueName]: {
                  [item.columnName]: value,
                },
              },
            };
          }
          return distributedByTabsResult;
        },
        {},
      );

      return { columns, tabs: Object.keys(dataForTable), dataForTable };
    }
    return null;
  }, [detailData]);

  const tableDataByTab = useMemo(() => {
    const tab = tableInfo?.tabs[currentTab];
    return tab && tableInfo?.dataForTable[tab]
      ? Object.keys(tableInfo?.dataForTable[tab]).map((key) => ({
          value: key,
          ...tableInfo.dataForTable[tab][key],
        }))
      : [];
  }, [currentTab, tableInfo]);

  const tableColumns = useMemo(() => {
    const columns: IColumn<Record<string, string>>[] = tableInfo?.columns.length
      ? tableInfo?.columns.map((i) => ({
          Header: i,
          accessor: i,
          width: 200,
          disableSortBy: true,
          maxWidth: 700,
        }))
      : [];
    return [
      { Header: 'Value', accessor: 'value', width: 260, disableSortBy: true },
      ...columns,
    ];
  }, [tableInfo]);

  useEffect(() => {
    if (tableInfo?.tabs.length && !currentTab) {
      setCurrentTab(0);
    }
  }, [tableInfo]);
  const title = useMemo(
    () =>
      tradeId ? `Trade detail: ${tradeId}` : `Position detail: ${positionId}`,
    [tradeId],
  );

  const handleChangeCurrentTab = (event: ChangeEvent<{}>, newVal: number) => {
    setCurrentTab(newVal);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Panel title={title} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Tabs value={currentTab} onChange={handleChangeCurrentTab}>
            {tableInfo?.tabs.map((i) => (
              <Tab key={i} label={i} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <Table
              tableId={`columns-detail${tradeId ? 'trade' : 'position'}`}
              isLoading={isLoading}
              columns={tableColumns}
              data={tableDataByTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};
