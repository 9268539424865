import { useEffect, useState } from 'react';

function TableRow({ entry }) {
  return (
    <tr
      className={
        entry.status_bo_status_cp.includes('Not') ? 'bg-danger' : 'bg-success'
      }
      key={entry.id}
    >
      <td>{entry.report_date}</td>
      <td>{entry.cp}</td>
      <td>{entry.legal_entity}</td>
      <td>{entry.total_bo_trades}</td>
      <td>{entry.bo_matched}</td>
      <td>{entry.bo_not_matched}</td>
      <td>{entry.total_cp_trades}</td>
      <td>{entry.cp_matched}</td>
      <td>{entry.cp_not_matched}</td>
      <td>{entry.is_manual}</td>
      <td>
        <b>{entry.status_bo_status_cp}</b>
      </td>
    </tr>
  );
}

export default function TradeReconDashboardTable({ entries }) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const newRows = entries.map((e) => <TableRow entry={e} />);
    setRows(newRows);
  }, [entries]);

  if (entries.length === 0) {
    return <h2>Nothing found</h2>;
  }

  return (
    <div className="container-fluid">
      <table className="table table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th>Report Date</th>
            <th>CP</th>
            <th>Entity</th>
            <th># BO Trades</th>
            <th># BO Matched</th>
            <th># BO Not Matched</th>
            <th># CP Trades</th>
            <th># CP Matched</th>
            <th># CP Not Matched</th>
            <th>Is Manual</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
