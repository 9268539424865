function formatDate(d) {
  if (d === undefined) {
    return d;
  }

  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = `${d.getFullYear()}`;

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

function getDayBack(daysBack) {
  const d = new Date();
  d.setDate(d.getDate() - daysBack);
  return d;
}

export { getDayBack, formatDate };
