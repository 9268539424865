/* eslint-disable react-hooks/rules-of-hooks */
import dayjs from 'dayjs';
import { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Row } from 'react-table';

import { DATE_FORMAT } from 'constants/date';
import { SIDE, Transaction } from 'services/ReconTablesApiService';

export type IUpdateAction = (
  id: number,
  filedName: string,
  value: string,
) => void;

export const getCpEditCell = (
  row: Row<Transaction>,
  cpList: string[],
  onChangeAction: IUpdateAction,
) => {
  const currentCp = row.values.cp;
  if (row.values.side === SIDE.THEIR) {
    return currentCp;
  }
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text">CP</div>
      </div>
      <select
        className="custom-select"
        name="cp"
        placeholder="CP"
        onChange={(event) =>
          onChangeAction(row.values.id, 'cp', event.target.value)
        }
      >
        {currentCp ? (
          <option key={currentCp} value={currentCp}>
            {currentCp}
          </option>
        ) : (
          <option key="any" value="">
            Any
          </option>
        )}
        {cpList.map((id) => (
          <option key={id} value={id}>
            {id.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export const getReportDateEditCell = (
  row: Row<Transaction>,
  onChangeAction: IUpdateAction,
) => {
  const currentReportDate = row.values.reportdate;

  const handleChangeDate = (date: Date) =>
    onChangeAction(
      row.values.id,
      'report_date',
      dayjs(date).format(DATE_FORMAT),
    );
  return (
    <div className="dayPickInTable">
      <DayPickerInput
        value={currentReportDate}
        onDayChange={handleChangeDate}
        inputProps={{
          className: 'custom-select',
          name: 'reportDate',
          autoComplete: 'off',
        }}
      />
    </div>
  );
};

export const getOperationTypeEditCell = (
  row: Row<Transaction>,
  typeList: { id: number; name: string }[],
  onChangeAction: IUpdateAction,
) => {
  const currentType = row.values.operationtype;
  return (
    <div className="input-group">
      <select
        className="custom-select"
        name={row.values.id}
        placeholder="Type"
        onChange={(event) =>
          onChangeAction(row.values.id, 'operation_type', event.target.value)
        }
      >
        {currentType ? (
          <option key={currentType} value={currentType}>
            {currentType}
          </option>
        ) : (
          <option key="any" value="">
            Any
          </option>
        )}
        {typeList.map(({ name }) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

interface InputEditCellProp {
  row: Row<Transaction>;
  fieldName: string;
  editFieldName: string;
  onChangeAction: IUpdateAction;
}

export function getInputEditCell({
  row,
  fieldName,
  editFieldName,
  onChangeAction,
}: InputEditCellProp) {
  const [currentValue, setCurrentValue] = useState(row.values[fieldName]);
  const [value, setValue] = useState(currentValue);
  const [enabled, setEnabled] = useState(false);
  const handleSave = () => {
    if (value === currentValue) {
      return;
    }
    if (!value) {
      // eslint-disable-next-line no-alert
      alert(`Fill in the ${fieldName} field to save`);
      setValue(currentValue);
      setEnabled(false);
      return;
    }
    setCurrentValue(value);
    onChangeAction(row.values.id, editFieldName, value);
    setEnabled(false);
  };
  const handleChangeEditable = () => {
    if (!enabled) {
      setEnabled(true);
    }
  };
  const handleResetValue = () => {
    setValue(currentValue);
    setEnabled(false);
  };
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        {!enabled ? (
          <div className="input-group-text" onClick={handleChangeEditable}>
            <i className="fa fa-pencil-square-o" aria-hidden="true" />
          </div>
        ) : (
          <div className="input-group-text" onClick={handleSave}>
            <i className="fa fa-check" aria-hidden="true" />
          </div>
        )}
      </div>
      <input
        type="text"
        className="form-control"
        name={row.values.id}
        disabled={!enabled}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {enabled && (
        <div className="input-group-append">
          <div className="input-group-text" onClick={handleResetValue}>
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </div>
      )}
    </div>
  );
}
