export function getFormPayload(form: any) {
  const payload: Record<string, boolean> = {};

  for (let i = 0; i < form.elements.length; i += 1) {
    const fieldName = form.elements[i].name;
    const fieldType = form.elements[i].type;
    if (fieldType && fieldType === 'checkbox') {
      if (form.elements[i].checked) {
        payload[fieldName] = true;
      }
    }
    const fieldValue = form.elements[i].value;
    if (fieldValue) {
      payload[fieldName] = fieldValue;
    }
  }
  return payload;
}

export function getUrlFromPayload(url: string, payload: Record<string, any>) {
  return Object.keys(payload).reduce((acc, key) => {
    let value = payload[key];
    if (!value) {
      return acc;
    }
    if (!Number(value) && !Array.isArray(value)) {
      value = value.replace('&', '%26');
    }
    return `${acc}${key}=${value}&`;
  }, url);
}
