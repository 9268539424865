import { Spin } from 'antd';
import { Component } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';

export class TableRow extends Component {
  // eslint-disable-next-line
  state = {
    processing: false,
    time: undefined,
  };

  componentDidMount() {
    this.setState({ time: this.props.entry.time });
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.entry.time !== prevProps.entry.time) {
      // eslint-disable-next-line
      this.setState({ time: this.props.entry.time });
    }
  }

  onTimeChanged = (e) => {
    e.preventDefault();
    e.preventDefault();
    this.setState({ time: e.target.value });
  };

  onDelete = () => {
    this.setState({ processing: true });

    const api = new ReconTablesApiService();

    api
      .deleteSymbolCutOffTime(this.props.entry.id)
      .then(() => {
        this.setState({ processing: false });
        this.props.onRowDeleted();
      })
      .catch((e) => {
        this.setState({ processing: false });
        alert(e);
      });
  };

  updateTime = () => {
    this.setState({ processing: true });
    const { entry } = this.props;

    const api = new ReconTablesApiService();
    const payload = {
      ...entry,
      time: this.state.time,
    };
    api
      .patchSymbolCutOffTime(entry.id, payload)
      .then(() => {
        this.setState({ processing: false });
      })
      .catch((e) => {
        alert(e);
        this.setState({ processing: false });
      });
  };

  render() {
    const { entry } = this.props;
    return (
      <tr key={entry.id}>
        <td>{entry.symbol}</td>
        <td>{entry.cp}</td>
        <td style={{ maxWidth: '80px' }}>
          {this.state.processing ? (
            <Spin />
          ) : (
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.updateTime}
                >
                  Update
                </button>
              </div>
              <input
                value={this.state.time}
                onChange={this.onTimeChanged}
                type="text"
                className="form-control"
              />
            </div>
          )}
        </td>
        <td>
          {this.state.processing ? (
            <Spin />
          ) : (
            <button className="btn btn-danger" onClick={this.onDelete}>
              Delete
            </button>
          )}
        </td>
      </tr>
    );
  }
}
