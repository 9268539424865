import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';

import { formatDate } from 'services/date-funcs';

class AutoMappingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCP: undefined,
      selectedDate: undefined,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state.selectedDate, this.state.selectedCP);
  };

  handleDayChange = (day) => {
    this.setState({
      selectedDate: formatDate(day),
    });
  };

  cpChangeHandler = (e) => {
    this.setState({
      selectedCP: e.target.value,
    });
  };

  render() {
    const CounterpartyOptions = this.props.cpNamesList.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));

    const CounterpartySelector = (
      <select className="custom-select mr-sm-2" onChange={this.cpChangeHandler}>
        {CounterpartyOptions}
      </select>
    );

    return (
      <div className="card bg-secondary">
        <div className="card-body">
          <h4 className="card-title">Auto Mapping</h4>
          <form onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="col">
                <DayPickerInput
                  onDayChange={this.handleDayChange}
                  inputProps={{
                    className: 'custom-select mr-sm-2',
                    autoComplete: 'off',
                  }}
                />
              </div>
              <div className="col">{CounterpartySelector}</div>
              <div className="col">
                <button className="btn btn btn-info">Find matches</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ counterParties }) => ({
  cpNamesList: counterParties,
});

export default connect(mapStateToProps)(AutoMappingForm);
