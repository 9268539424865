import { Component } from 'react';
import { connect } from 'react-redux';
import 'react-day-picker/lib/style.css';

import { getFormPayload } from 'services/utils';

import DayRangeInput from './day-range-input';

class Form extends Component {
  FORMAT = 'dd/MM/yyyy';

  constructor(props) {
    super(props);
    this.state = {
      orderId: undefined,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const formPayload = getFormPayload(e.target);
    if (formPayload.order_id) {
      onSubmit(`order_id=${formPayload.order_id}`);
      return;
    }
    let query = '';
    /* eslint-disable */
    for (const [key, value] of Object.entries(formPayload)) {
      query += `${key}=${value}&`;
    }
    /* eslint-disable */
    onSubmit(query);
  };

  orderIdChangeHandler = (e) => {
    this.setState({ orderId: e.target.value });
  };

  render() {
    const { orderId } = this.state;
    const showOthers = orderId === '' || orderId === undefined;
    const { cpIdList, entityMap } = this.props;

    const cpOptionsList = cpIdList.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));

    const entityOptionsList = [];
    /* eslint-disable */
    for (const key of Object.keys(entityMap)) {
      entityOptionsList.push(
        <option key={key} value={key}>
          {entityMap[key]}
        </option>,
      );
    }
    /* eslint-disable */

    const cpSelector = showOthers ? (
      <div className="col">
        <select
          className="custom-select mr-sm-2"
          name="cp_name"
          placeholder="Counter party"
        >
          <option value="">All CPs</option>
          {cpOptionsList}
        </select>
      </div>
    ) : undefined;

    const entitySelector = showOthers ? (
      <div className="col">
        <select
          className="custom-select mr-sm-2"
          name="entity_legal_malta_id"
          placeholder="Legal Entity"
        >
          <option value="">All Entities</option>
          {entityOptionsList}
        </select>
      </div>
    ) : undefined;

    const symbolInput = showOthers ? (
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">symbol</div>
          </div>
          <input type="text" className="form-control" name="symbol" />
        </div>
      </div>
    ) : undefined;

    const priceInput = showOthers ? (
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">price</div>
          </div>
          <input type="text" className="form-control" name="price" />
        </div>
      </div>
    ) : undefined;

    const recStatusInput = showOthers ? (
      <div className="col">
        <select
          className="custom-select mr-sm-2"
          id="inlineFormCustomSelect"
          name="rec_status"
        >
          <option value="">Any RecStatus</option>
          <option value="True">Reconciled</option>
          <option value="False">Unreconciled</option>
        </select>
      </div>
    ) : undefined;

    const accountInput = showOthers ? (
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">account</div>
          </div>
          <input type="text" className="form-control" name="account" />
        </div>
      </div>
    ) : undefined;

    const dayRangeInput = showOthers ? (
      <DayRangeInput
        fromDate="from_cp_report_date"
        toDate="to_cp_report_date"
      />
    ) : undefined;

    const orderIDInput = (
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">order id</div>
          </div>
          <input
            type="text"
            className="form-control"
            name="order_id"
            onChange={this.orderIdChangeHandler}
          />
        </div>
      </div>
    );
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="card-body bg-secondary">
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                {cpSelector}
                {entitySelector}
                {recStatusInput}
                {orderIDInput}
                {symbolInput}
                {priceInput}
                {accountInput}
                {dayRangeInput}
                <div className="col">
                  <button className="btn btn btn-dark">Get trades</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cpIdList: state.counterParties,
  entityMap: state.legalEntities,
});

export default connect(mapStateToProps)(Form);
