import { useState } from 'react';

import { getFormPayload } from 'services/utils';

function FilterInputs({ filterOptions, counter }) {
  return (
    <>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Filter Field</div>
          </div>
          <select className="custom-select mr-sm-2" name={`field-${counter}`}>
            {filterOptions}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Filter Condition</div>
          </div>
          <select
            className="custom-select mr-sm-2"
            name={`condition-${counter}`}
          >
            <option key="eq" value="=">
              =
            </option>
            <option key="not_eq" value="!=">
              !=
            </option>
            <option key="gt" value=">">
              &#62;
            </option>
            <option key="lt" value="<">
              &#60;
            </option>
            <option key="like" value="like">
              contains(string only)
            </option>
          </select>
        </div>
      </div>
      <div className="col">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">Value</div>
          </div>
          <input
            type="text"
            className="form-control"
            name={`value-${counter}`}
            placeholder=""
          />
        </div>
      </div>
    </>
  );
}

export function FilterForm(props) {
  const { filterOptions, onFilter, onFilterReset } = props;

  const [additionalInputs, setAdditionalInputs] = useState([]);
  const [counter, setCounter] = useState(0);

  const onRemove = (cnt) => {
    const newInputs = [...additionalInputs];
    newInputs.filter((e) => e.count !== cnt);
    setAdditionalInputs(newInputs);
  };

  const onAddAnother = () => {
    const newCounter = counter + 1;
    const newInputs = [...additionalInputs];
    const input = (
      <div className="form-row mb-2" key={newCounter}>
        <FilterInputs
          filterOptions={filterOptions}
          counter={newCounter}
          key={newCounter}
        />
        <div className="col">
          <div className="mx-auto">
            <button
              onClick={() => onRemove(newCounter)}
              className="btn btn-danger"
              type="button"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );

    newInputs.push({
      count: newCounter,
      input,
    });

    setAdditionalInputs(newInputs);
    setCounter(newCounter);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const rawPayload = getFormPayload(e.target);
    const filterConditions = [];

    Object.keys(rawPayload).forEach((k) => {
      const kArr = k.split('-');
      filterConditions.push({
        field: rawPayload[`field-${kArr[1]}`],
        condition: rawPayload[`condition-${kArr[1]}`],
        value: rawPayload[`value-${kArr[1]}`],
      });
    });

    onFilter(filterConditions);
  };

  const moreInputs = additionalInputs.map((e) => e.input);
  return (
    <div className="container">
      <form id="iumFilterForm" onSubmit={onSubmit}>
        {moreInputs}
        <div className="form-row mb-2">
          <FilterInputs filterOptions={filterOptions} counter={0} key={0} />
          <div className="col">
            <div className="mx-auto">
              <button className="btn btn-success" type="submit">
                Filter
              </button>
              <button
                className="btn btn-warning ml-2"
                type="reset"
                onClick={onFilterReset}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                type="button"
                onClick={onAddAnother}
              >
                Add Another
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
