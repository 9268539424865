import { Component } from 'react';

import '../style.css';

import MappedSymbolsFilter from './mapped-symbols-filter';
import MappedSymbolsTableRow from './table-row';

class MappedSymbolsTable extends Component {
  // eslint-disable-next-line
  state = {
    mappedSymbols: [],
  };
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.mappedSymbols !== this.props.mappedSymbols) {
      // eslint-disable-next-line
      this.setState({
        mappedSymbols: this.props.mappedSymbols,
      });
    }
  }

  render() {
    const rows = (this.state?.mappedSymbols ?? []).map((entry) => (
      <MappedSymbolsTableRow
        key={entry.full_id}
        entry={entry}
        onMappingChanged={this.props.onMappingChanged}
      />
    ));
    return (
      <div className="table-wrapper-scroll-y my-custom-scrollbar">
        <MappedSymbolsFilter onSubmit={this.props.onFilterSubmit} />
        <table className="table table-bordered table-striped table-hover table-responsive table-sm table-fixed-width d-block d-md-table">
          <thead>
            <tr className="thead-light">
              <th>Counterparty</th>
              <th>CP Symbol</th>
              <th>Exante Symbol</th>
              <th>Mty</th>
              <th>MtyPrice</th>
              <th>Mty Qty</th>
              <th>CCY</th>
              <th>ISIN</th>
              <th>CP Type</th>
              <th>Added</th>
              <th>From Date</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default MappedSymbolsTable;
