const jwt = require('jsonwebtoken');

const METABASE_SITE_URL = 'http://recon-metabase.exante.eu';
const METABASE_SECRET_KEY =
  '33bb1c99a681ada1e9d69417abacd20a40a3636be52702bf17b1fa0db6ba11e1';
const payload = {
  resource: { dashboard: 418 },
  params: {},
  exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
};
const token = jwt.sign(payload, METABASE_SECRET_KEY);
const iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;

export default function LiquidityMonitorPage() {
  return (
    <div>
      <iframe
        title="LiquidityMonitorPage"
        src={iframeUrl}
        frameBorder="0"
        style={{
          overflow: 'hidden',
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: '60px',
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </div>
  );
}
