import { UseTableCellProps } from 'react-table';
import { TableProps, Button } from 'react-ui-kit-exante';

import { RawDataReportSetup } from 'services/ReconTablesApiService';
import { ColumnsWithFilters } from 'types';

type Columns = ColumnsWithFilters & {
  showedIds: number[];
  hideRow: (val: number) => void;
  showRow: (val: number) => void;
};
export const getRawDataTableColumns = ({
  onFilter,
  onRemove,
  showedIds,
  hideRow,
  showRow,
}: Columns): TableProps<RawDataReportSetup>['columns'] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    width: 70,
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Module',
    accessor: 'module',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Cp',
    accessor: 'cp',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    id: 'module_configs',
    Header: 'Module configs',
    disableFilters: true,
    Cell: ({ row }: UseTableCellProps<RawDataReportSetup>) => {
      const id = Number(row.original.id);
      const isShow = !!showedIds.find((i) => i === id);
      const handleClick = () => (isShow ? hideRow(id) : showRow(id));
      return (
        <div className="text-left">
          <Button onClick={handleClick}>
            {isShow ? 'hide' : 'show'} configs
          </Button>
          {isShow && (
            <ul className="list-group">
              {row.original.module_configs?.map((item) => (
                <li>
                  <span className="font-weight-bold">{item.param_name}</span>:{' '}
                  {item.param_value}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    },
  },
];
