import dayjs from 'dayjs';
import {
  FC,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { DatePicker, Autocomplete, Input, Button } from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import ReconTablesApiService, {
  CategoryComment,
  TransactionOperationType,
} from 'services/ReconTablesApiService';

import { DatesIsValid } from '../types';

interface Props {
  datesIsValid: DatesIsValid;
  categoryFilter?: string;
  setCategoryFilter: Dispatch<SetStateAction<string>>;
  setDatesIsValid: (value: DatesIsValid) => void;
  setFromFilter: Dispatch<SetStateAction<string | undefined>>;
  fromFilter?: string;
  setToFilter: Dispatch<SetStateAction<string | undefined>>;
  toFilter?: string;
  setEntityFilter: Dispatch<SetStateAction<string>>;
  setCpFilter: Dispatch<SetStateAction<string>>;
  setTypeFilter: Dispatch<SetStateAction<string[]>>;
  setCcyFilter: Dispatch<SetStateAction<string[]>>;
  setSymbolFilter: Dispatch<SetStateAction<string>>;
  symbolFilter: string;
  setSymbolTypeFilter: Dispatch<SetStateAction<string>>;
  symbolTypeFilter: string;
  setBreakCategoryFilter: Dispatch<SetStateAction<string>>;
  breakCategories: CategoryComment[];
  transactionTypes: TransactionOperationType[];
  handleSubmit: (val: boolean) => void;
}
export const SearchForm: FC<Props> = (props) => {
  const {
    setDatesIsValid,
    categoryFilter,
    setCategoryFilter,
    datesIsValid,
    setFromFilter,
    fromFilter,
    setToFilter,
    toFilter,
    setEntityFilter,
    setCpFilter,
    setTypeFilter,
    setCcyFilter,
    setSymbolFilter,
    symbolFilter,
    setSymbolTypeFilter,
    symbolTypeFilter,
    setBreakCategoryFilter,
    breakCategories,
    transactionTypes,
    handleSubmit,
  } = props;

  const [ccyList, setCcyList] = useState<string[]>([]);

  const cpList = useAppSelector((state) => state.counterParties);
  const entities = useAppSelector((state) => state.legalEntities);

  const api = new ReconTablesApiService();
  useEffect(() => {
    api
      .getCcyList()
      .then((response) => {
        if (response.results) {
          setCcyList(response.results);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Fetch Ccy List error');
      });
  }, []);

  const typeOptions = useMemo(
    () =>
      transactionTypes.reduce((acc: string[], value) => {
        const foundElement = acc.find((i) => i === value.name);
        if (!foundElement) {
          return [...acc, value.name];
        }
        return acc;
      }, []),
    [transactionTypes],
  );

  const handleSetDate = (date: unknown, type: 'from' | 'to') => {
    if (!date) {
      setDatesIsValid({ ...datesIsValid, [type]: false });
      return;
    }
    setDatesIsValid({ ...datesIsValid, [type]: true });
    const formattedDate = dayjs(date as string).format(DATE_FORMAT);
    if (type === 'from') {
      setFromFilter(formattedDate);
    }
    if (type === 'to') {
      setToFilter(formattedDate);
    }
  };

  const entityOptions = useMemo(
    () =>
      Object.keys(entities).map((i) => ({
        label: entities[i],
        value: i,
      })),
    [entities],
  );
  const cpOptions = useMemo(
    () =>
      cpList.map((i) => ({
        label: i.toUpperCase(),
        value: i,
      })),
    [cpList],
  );
  const breakCategoryOptions = useMemo(
    () => [
      { label: 'empty', value: 'empty' },
      { label: 'not_empty', value: 'not_empty' },
      ...breakCategories.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ],
    [breakCategories],
  );

  return (
    <div className="row search-form">
      <div className="col-lg-3 col-md-4 mt-2">
        <DatePicker
          label="From"
          controlProps={{ fullWidth: true }}
          value={fromFilter}
          onChange={(date) => handleSetDate(date, 'from')}
        />
      </div>
      <div className="col-lg-3 col-md-4 mt-2">
        <DatePicker
          label="To"
          controlProps={{ fullWidth: true }}
          value={toFilter}
          onChange={(date) => handleSetDate(date, 'to')}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Entity"
          className="w-100"
          options={entityOptions}
          onChange={(event, newValue: any) => {
            setEntityFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Cp"
          className="w-100"
          options={cpOptions}
          onChange={(event, newValue: any) => {
            setCpFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Type"
          className="w-100"
          isMultiple
          options={typeOptions}
          onChange={(event, newValue) => {
            setTypeFilter(newValue);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Ccy"
          className="w-100"
          isMultiple
          fullWidth
          options={ccyList}
          onChange={(event, newValue) => {
            setCcyFilter(newValue);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Input
          label="Symbol"
          value={symbolFilter}
          fullWidth
          onChange={(event) => setSymbolFilter(event.target.value)}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Input
          label="Symbol Type"
          value={symbolTypeFilter}
          fullWidth
          onChange={(event) => setSymbolTypeFilter(event.target.value)}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Input
          label="Category"
          value={categoryFilter}
          fullWidth
          onChange={(event) => setCategoryFilter(event.target.value)}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Autocomplete
          placeholder="Break Category"
          className="w-100"
          options={breakCategoryOptions}
          onChange={(event, newValue: any) => {
            setBreakCategoryFilter(newValue?.value);
          }}
        />
      </div>

      <div className="col-lg-3 col-md-4 mt-2">
        <Button fullWidth onClick={() => handleSubmit(true)}>
          Load Data
        </Button>
      </div>
    </div>
  );
};
