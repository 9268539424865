import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function ValueMappingAddForm({ successCallback }) {
  const brokerIDs = useSelector((state) => state.counterParties);
  const [standardValueTypeOptions, setStandardValueTypeOptions] = useState([]);
  const [replaceOptions, setReplaceOptions] = useState([]);
  const [comparisonOptions, setComparisonOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState(brokerIDs);
  const [reportOptions, setReportOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [activeCP, setActiveCP] = useState(undefined);
  const [allReports, setAllReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(undefined);

  useEffect(() => {
    const api = new ReconTablesApiService();
    api.getListMappingReports({}).then((res) => {
      setAllReports(res.results);
    });

    api.getListMappingReplaceType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setReplaceOptions(options);
    });

    api.getListStandardValueType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setStandardValueTypeOptions(options);
    });

    api.getListComparisonType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.type} value={r.type}>
          {r.type.toUpperCase()}
        </option>
      ));
      setComparisonOptions(options);
    });
  }, []);

  useEffect(() => {
    if (!brokerIDs.length) {
      return;
    }
    const options = [];
    /* eslint-disable */
    for (const key of Object.keys(brokerIDs)) {
      options.push(
        <option key={key} value={brokerIDs[key]}>
          {brokerIDs[key].toUpperCase()}
        </option>,
      );
    } /* eslint-enable */
    setBrokerOptions(options);
  }, [brokerIDs]);

  useEffect(() => {
    const cpReportOptions = [];
    let i = 0;
    allReports.forEach((r) => {
      if (r.cp_id === activeCP) {
        if (i === 0) {
          setSelectedReport(r);
        }
        i += 1;
        if (r.state === 'Testing' || r.state === 'Setup') {
          cpReportOptions.push(
            <option key={r.id} value={r.id}>{`${r.name}`}</option>,
          );
        }
      }
    });
    setReportOptions(cpReportOptions);
  }, [activeCP]);

  useEffect(() => {
    if (selectedReport === undefined) {
      return;
    }
    const api = new ReconTablesApiService();
    if (selectedReport.type === 'trades') {
      api.getListMappingCpTradesFields().then((res) => {
        const options = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);

        const fields = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setModelOptions(fields);
      });
      return;
    }
    if (selectedReport.type === 'transactions') {
      api.getListCpTransactionFields().then((res) => {
        const options = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);

        const fields = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setModelOptions(fields);
      });
      return;
    }

    if (selectedReport.type === 'positions') {
      api.getListMappingPositionReconciliationFields().then((res) => {
        const options = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
        const fields = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setModelOptions(fields);
      });
      return;
    }

    if (selectedReport.type === 'cash') {
      api.getListMappingRowBalanceFields().then((res) => {
        const options = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setFieldOptions(options);
        const fields = res.fields.map((r) => (
          <option key={r.fields} value={r}>
            {r}
          </option>
        ));
        setModelOptions(fields);
      });
    }
  }, [selectedReport]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target);
    /* eslint-disable */
    for (const key of Object.keys(payload)) {
      if (payload[key] === undefined && key !== 'end_date') {
        return;
      }
    }
    /* eslint-disable */

    const maxFieldNumber = rules.length;
    const convertedPayload = {
      name: payload.name,
      cp_id: payload.cp_id,
      report: payload.report,
      cp_update_field: payload.cp_update_field,
      cp_source_field: payload.cp_source_field,
      replace_type: payload.replace_type,
      replace_value: payload.replace_value,
      standard_value: payload.standard_value,
      standard_value_type: payload.standard_value_type,
      order_index: payload.order_index,
      active: true,
      start_date: payload.start_date,
      end_date: payload.end_date,
      conditions: [],
    };

    for (let i = 0; i < maxFieldNumber; i += 1) {
      const condition = {
        condition: payload[`condition_field:${i}`],
        condition_value: payload[`condition_value:${i}`],
        comparison_type: payload[`comparison_type:${i}`],
      };
      convertedPayload.conditions.push(condition);
    }

    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .postValueMappingRuleWithConditions(convertedPayload)
      .then(() => {
        setLoading(false);
        successCallback();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDeleteRule = (idxExclude) => {
    const newRules = rules.filter((_, idx) => idx !== idxExclude);
    setRules(newRules);
  };

  const onAddRule = () => {
    const idx = rules.length;

    const ruleEl = (
      <div className="form-row mb-2">
        <div className="col-3">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Condition Field</div>
            </div>
            <select
              className="custom-select mr-sm-2"
              name={`condition_field:${idx}`}
            >
              {fieldOptions}
            </select>
          </div>
        </div>
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Condition Value</div>
            </div>
            <input
              type="text"
              className="form-control"
              name={`condition_value:${idx}`}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Comparison Type</div>
            </div>
            <select
              className="custom-select mr-sm-2"
              name={`comparison_type:${idx}`}
            >
              {comparisonOptions}
            </select>
          </div>
        </div>
        <div className="col-1">
          <div className="input-group">
            <button
              className="btn btn-danger"
              onClick={() => onDeleteRule(idx)}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
    const newRules = [...rules];
    newRules.push(ruleEl);
    setRules(newRules);
  };

  const onCpChange = (e) => {
    const cp = e.target.value;
    setActiveCP(cp);
  };

  const onReportChange = (e) => {
    const selectedID = e.target.value;
    /* eslint-disable */
    for (const r of allReports) {
      if (r.id === Number(selectedID)) {
        setSelectedReport(r);
        return;
      }
    } /* eslint-enable */
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Name</div>
              </div>
              <input type="text" className="form-control" name="name" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="cp_id"
                onChange={onCpChange}
              >
                {brokerOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Report</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="report"
                onChange={onReportChange}
              >
                {reportOptions}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Update Field</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_update_field">
                {modelOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Source Field</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_source_field">
                {fieldOptions}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Replace Type</div>
              </div>
              <select className="custom-select mr-sm-2" name="replace_type">
                {replaceOptions}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Replace Value</div>
              </div>
              <input
                type="text"
                className="form-control"
                name="replace_value"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Standard Value</div>
              </div>
              <input
                type="text"
                className="form-control"
                name="standard_value"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Standard Value Type</div>
              </div>
              <select
                className="custom-select mr-sm-2"
                name="standard_value_type"
              >
                {standardValueTypeOptions}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Order Index</div>
              </div>
              <input
                type="number"
                className="form-control"
                name="order_index"
                min="-1"
                step="1"
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Start Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'start_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">End Date</div>
              </div>
              <DayPickerInput
                inputProps={{
                  className: 'custom-select mr-sm-2',
                  name: 'end_date',
                  autoComplete: 'off',
                }}
              />
            </div>
          </div>
        </div>
        {rules}
        <div className="form-row">
          <div className="mx-auto">
            {loading ? (
              <Spin />
            ) : (
              <div>
                <button className="btn btn-warning" type="submit">
                  Create
                </button>
                <button
                  className="btn btn-success ml-2"
                  type="button"
                  onClick={onAddRule}
                >
                  Add Condition
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
