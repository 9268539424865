import { Empty } from 'antd';
import { useState } from 'react';

import { Spinner } from 'components/Spinner/Spinner';

import ReconTablesApiService from '../../services/ReconTablesApiService';

import { PositionRecAddForm } from './add-form';
import { PositionRecSearchForm } from './search-form';
import { EditableTable } from './table';

export function PositionrecPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const search = (payload) => {
    setLoading(true);
    setData([]);
    const api = new ReconTablesApiService();
    api
      .getListPositionReconciliation(payload)
      .then((res) => {
        setLoading(false);
        const results = res.results === undefined ? res : res.results;
        if (results === undefined) {
          setData([]);
          return;
        }
        // eslint-disable-next-line
        for (const r of results) {
          r.key = r.uid;
        }
        setData(results);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  const note = loading ? (
    <Spinner />
  ) : (
    <div className="mt-2">
      <Empty />
    </div>
  );
  return (
    <div className="container-fluid">
      <PositionRecSearchForm searchFunc={search} />
      <PositionRecAddForm />
      {data.length > 0 ? <EditableTable rawData={data} /> : note}
    </div>
  );
}
