import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';

class ManualUnreconcileForm extends Component {
  formID = 'searchFormUnreconcile';

  priorityFieldsNames = [
    'trade_number',
    'order_id',
    'exchange_order_id',
    'full_id',
  ];

  constructor(props) {
    super(props);
    this.state = {
      showOthers: true,
    };
  }

  priorityFieldChanged = (e) => {
    // If one of priority fields changed than clean other all fields (including other priority fields)
    const form = document.getElementById(this.formID);
    let showOthers = true;
    this.priorityFieldsNames.forEach((name) => {
      showOthers = showOthers && form.elements[name].value === '';
      if (e.target.name !== name) {
        form.elements[name].value = '';
      }
    });

    this.setState({
      showOthers,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const payload = {};
    let hasPriorityFieldValue = false;
    // if we have priority fields than don't use any other values from form in payload

    // eslint-disable-next-line
    for (let i = 0; i < e.target.elements.length; i += 1) {
      const fieldName = e.target.elements[i].name;
      const fieldValue = e.target.elements[i].value;
      if (fieldValue !== undefined && fieldValue !== '') {
        if (this.priorityFieldsNames.indexOf(fieldName) > -1) {
          hasPriorityFieldValue = true;
        }
        payload[fieldName] = fieldValue;
      }
    }

    if (!hasPriorityFieldValue) {
      this.props.onSubmit(payload);
      return;
    }

    const newPayload = {};
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(payload)) {
      if (this.priorityFieldsNames.indexOf(key) === -1) {
        continue;
      }
      newPayload[key] = value;
    }

    this.props.onSubmit(newPayload);
  };

  render() {
    const { showOthers } = this.state;
    const cpOptionsList = this.props.cpIdList.map((id) => (
      <option key={id} value={id}>
        {id.toUpperCase()}
      </option>
    ));
    return (
      <div className="container-fluid mt-3">
        <form onSubmit={this.onSubmit} id={this.formID}>
          <div className="form-row mb-2" hidden={!showOthers}>
            <div className="col">
              <div className="input-group">
                <DayPickerInput
                  inputProps={{
                    className: 'custom-select',
                    name: 'report_date',
                    autoComplete: 'off',
                  }}
                />
              </div>
            </div>
            <div className="col">
              <select className="custom-select mr-sm-2" name="cp">
                <option value="">All CPs</option>
                {cpOptionsList}
              </select>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Exante Symbol</div>
                </div>
                <input type="text" className="form-control" name="symbol" />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP Symbol</div>
                </div>
                <input type="text" className="form-control" name="cp_symbol" />
              </div>
            </div>
          </div>
          <div className="form-row mb-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">order id</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.priorityFieldChanged}
                  name="order_id"
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Exch order id</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.priorityFieldChanged}
                  name="exchange_order_id"
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Bo trade #</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.priorityFieldChanged}
                  name="trade_number"
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP Full ID</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.priorityFieldChanged}
                  name="full_id"
                />
              </div>
            </div>
          </div>
          <div className="form-row mb-2">
            <div className="mx-auto">
              <button className="btn btn-outline-success" type="submit">
                Search pairs
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ counterParties }) => ({ cpIdList: counterParties });

export default connect(mapStateToProps)(ManualUnreconcileForm);
