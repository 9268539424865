import { Component } from 'react';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import ManualUnreconcileTable from '../manual-unreconcile/table';

import { ReconcileBrokersSide } from './brokers-side/side';
import { BOvsBO, BOvsCP, CPvsCP } from './form';
import { ReconcileOursSide } from './ours-side/side';

const CTradesIDField = 'trade_number';
const CPTradesIDField = 'full_id';

const CTradesCpField = 'cp_name';
const CPTradesCpField = 'broker_id';

export class ReconcileSideBySideView extends Component {
  // eslint-disable-next-line
  state = {
    kind: undefined,

    cpWithInfoList: [],

    allLeftTrades: [],
    allRightTrades: [],

    selectedLeftTrades: [],
    selectedRightTrades: [],

    reconciliationInProgress: false,
    reconciledGroups: [],

    selectedValueDelta: undefined,

    selectedLeftQty: undefined,
    selectedRightQty: undefined,
  };

  api = new ReconTablesApiService();

  componentDidMount() {
    this.updateStateFromProps();
    // TODO: refactor after BPA-2712
    this.api
      .getListCPMapping()
      .then((res) => {
        this.setState({
          cpWithInfoList: res.results,
        });
      })
      .catch(() => {
        alert('No loaded cps');
      });
  }
  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === this.props) {
      return;
    }
    this.updateStateFromProps();
  }

  updateStateFromProps = () => {
    const { left_trades: leftTrades, right_trades: rightTrades } =
      this.props.searchResults;
    const { kind } = this.props;
    this.setState({
      allLeftTrades: leftTrades,
      allRightTrades: rightTrades,
      selectedLeftTrades: [],
      selectedRightTrades: [],
      reconciledGroups: [],
      kind,
    });
  };

  leftTradesIDFieldName = () => {
    const { kind } = this.state;
    if (kind === BOvsCP || kind === BOvsBO) {
      return CTradesIDField;
    }
    return CPTradesIDField;
  };

  rightTradesIDFieldName = () => {
    const { kind } = this.state;
    if (kind === BOvsCP || kind === CPvsCP) {
      return CPTradesIDField;
    }
    return CTradesIDField;
  };

  onReconcileSubmit = () => {
    this.setState({ reconciliationInProgress: true });

    const payload = {
      left_trades: this.state.selectedLeftTrades,
      right_trades: this.state.selectedRightTrades,
      source: 'manual-recon',
    };

    const api = new ReconTablesApiService();

    if (this.state.kind === BOvsBO) {
      api
        .reconcileTradesBoVsBo(payload)
        .then((result) => {
          this.addReconciliationResults(
            this.state.selectedLeftTrades,
            this.state.selectedRightTrades,
            result.group_id,
          );
          this.removeReconciledFromLists();
        })
        .catch((e) => {
          this.setState({ reconciliationInProgress: false });
          alert(`Failed to reconcile ${e.message}`);
        });
    }
    if (this.state.kind === BOvsCP) {
      payload.add_mapping = true;
      api
        .reconcileTradesBoVsCp(payload)
        .then((result) => {
          this.addReconciliationResults(
            this.state.selectedLeftTrades,
            this.state.selectedRightTrades,
            result.group_id,
          );
          this.removeReconciledFromLists();
        })
        .catch((e) => {
          this.setState({ reconciliationInProgress: false });
          alert(`Failed to reconcile ${e.message}`);
        });
    }
    if (this.state.kind === CPvsCP) {
      api
        .reconcileTradesCpVsCp(payload)
        .then((result) => {
          this.addReconciliationResults(
            this.state.selectedLeftTrades,
            this.state.selectedRightTrades,
            result.group_id,
          );
          this.removeReconciledFromLists();
        })
        .catch((e) => {
          this.setState({ reconciliationInProgress: false });
          alert(`Failed to reconcile ${e.message}`);
        });
    }
  };

  addReconciliationResults = (leftTradesIDs, rightTradesIDs, groupid) => {
    const leftIDName = this.leftTradesIDFieldName();

    const leftTrades = leftTradesIDs.map((id) =>
      this.state.allLeftTrades.find((obj) => obj[leftIDName] === id),
    );

    const rightIDName = this.rightTradesIDFieldName();
    const rightTrades = rightTradesIDs.map((id) =>
      this.state.allRightTrades.find((obj) => obj[rightIDName] === id),
    );

    const newReconciledGroups = [
      // eslint-disable-next-line
      ...this.state.reconciledGroups,
      {
        group_id: groupid,
        ctrades: leftTrades,
        cptrades: rightTrades,
      },
    ];

    this.setState({ reconciledGroups: newReconciledGroups });
  };

  removeReconciledFromLists = () => {
    const oldLeftAll = [...this.state.allLeftTrades];
    const oldRightAll = [...this.state.allRightTrades];

    const oldLeftSelected = [...this.state.selectedLeftTrades];
    const oldRightSelected = [...this.state.selectedRightTrades];

    const leftFieldIDName = this.leftTradesIDFieldName();
    const rightFieldIDName = this.rightTradesIDFieldName();
    const { kind } = this.state;

    const newLeftAll = oldLeftAll.filter((value) => {
      if (kind === BOvsCP) {
        return oldLeftSelected.indexOf(value[leftFieldIDName]) === -1;
      }
      // in case of CPvsCP and BOvsBO we need to exclude selected trade from both views because
      // it's usually duplicated in every side
      return (
        oldLeftSelected.indexOf(value[leftFieldIDName]) === -1 &&
        oldRightSelected.indexOf(value[leftFieldIDName]) === -1
      );
    });

    const newRightAll = oldRightAll.filter((value) => {
      if (kind === BOvsCP) {
        return oldRightSelected.indexOf(value[rightFieldIDName]) === -1;
      }

      return (
        oldRightSelected.indexOf(value[rightFieldIDName]) === -1 &&
        oldLeftSelected.indexOf(value[rightFieldIDName]) === -1
      );
    });

    this.setState({
      allLeftTrades: newLeftAll,
      allRightTrades: newRightAll,
      selectedLeftTrades: [],
      selectedRightTrades: [],
      reconciliationInProgress: false,
    });
  };

  updateTotalValues = (selectedLeft, selectedRight) => {
    let leftValue = 0;
    let rightValue = 0;
    let leftQty = 0;
    let rightQty = 0;

    const leftID = this.leftTradesIDFieldName();
    const rightID = this.rightTradesIDFieldName();

    selectedLeft.forEach((id) => {
      const trade = this.state.allLeftTrades.find((obj) => obj[leftID] === id);

      leftValue += trade ? trade.value : 0;
      leftQty += trade.qty;
    });

    selectedRight.forEach((id) => {
      const trade = this.state.allRightTrades.find(
        (obj) => obj[rightID] === id,
      );
      rightValue += trade ? trade.value : 0;
      rightQty += trade.qty;
    });

    // For our trades recon we should have 2 opposite trades
    const delta =
      this.state.kind === BOvsBO || this.state.kind === CPvsCP
        ? leftValue + rightValue
        : leftValue - rightValue;
    this.setState({
      selectedValueDelta: delta === 0 ? undefined : delta,
      selectedLeftQty: leftQty,
      selectedRightQty: rightQty,
    });
  };

  addLeftTrade = (trade_id) => {
    // eslint-disable-next-line
    const selected = [...this.state.selectedLeftTrades];
    selected.push(trade_id);
    this.setState({ selectedLeftTrades: selected });
    this.updateTotalValues(selected, this.state.selectedRightTrades);
  };

  selectAllLeft = () => {
    const selected = [];
    const idField = this.leftTradesIDFieldName();
    // eslint-disable-next-line
    for (const trade of this.state.allLeftTrades) {
      selected.push(trade[idField]);
    }
    this.setState({ selectedLeftTrades: selected });
    this.updateTotalValues(selected, this.state.selectedRightTrades);
  };

  clearAllLeft = () => {
    this.setState({ selectedLeftTrades: [] });
    this.updateTotalValues([], this.state.selectedRightTrades);
  };

  addRightTrade = (trade_id) => {
    // eslint-disable-next-line
    const selected = [...this.state.selectedRightTrades];
    selected.push(trade_id);
    this.setState({ selectedRightTrades: selected });
    this.updateTotalValues(this.state.selectedLeftTrades, selected);
  };

  selectAllRight = () => {
    const selected = [];
    const idField = this.rightTradesIDFieldName();
    // eslint-disable-next-line
    for (const trade of this.state.allRightTrades) {
      selected.push(trade[idField]);
    }
    this.setState({ selectedRightTrades: selected });
    this.updateTotalValues(this.state.selectedLeftTrades, selected);
  };

  clearAllRight = () => {
    this.setState({ selectedRightTrades: [] });
    this.updateTotalValues(this.state.selectedLeftTrades, []);
  };

  removeLeftTrade = (trade_id) => {
    const selected = [...this.state.selectedLeftTrades];
    const newSelected = selected.filter((value) => value !== trade_id);
    this.setState({ selectedLeftTrades: newSelected });
    this.updateTotalValues(newSelected, this.state.selectedRightTrades);
  };

  removeRightTrade = (trade_id) => {
    const selected = [...this.state.selectedRightTrades];
    const newSelected = selected.filter((value) => value !== trade_id);
    this.setState({ selectedRightTrades: newSelected });
    this.updateTotalValues(this.state.selectedLeftTrades, newSelected);
  };

  clearAllSelected = () => {
    this.setState({ selectedLeftTrades: [], selectedRightTrades: [] });
    this.updateTotalValues([], []);
  };

  selectAll = () => {
    const leftSelected = [];
    const rightSelected = [];

    const leftID = this.leftTradesIDFieldName();
    const rightID = this.rightTradesIDFieldName();

    this.state.allLeftTrades.forEach((e) => {
      leftSelected.push(e[leftID]);
    });

    this.state.allRightTrades.forEach((e) => {
      rightSelected.push(e[rightID]);
    });

    this.setState({
      selectedLeftTrades: leftSelected,
      selectedRightTrades: rightSelected,
    });
    this.updateTotalValues(leftSelected, rightSelected);
  };

  getLeftSide = () => {
    const { kind } = this.state;
    if (kind === undefined) {
      return null;
    }

    // Case when left side is EXANTE's trades
    if (kind === BOvsCP || kind === BOvsBO) {
      return (
        <ReconcileOursSide
          trades={this.state.allLeftTrades}
          qtySelected={this.state.selectedLeftQty}
          selected={this.state.selectedLeftTrades}
          onTradeAdded={this.addLeftTrade}
          onTradeRemoved={this.removeLeftTrade}
          onSelectAll={this.selectAllLeft}
          onClearAll={this.clearAllLeft}
        />
      );
    }
    // Case when left side is BROKER trades
    return (
      <ReconcileBrokersSide
        trades={this.state.allLeftTrades}
        qtySelected={this.state.selectedLeftQty}
        selected={this.state.selectedLeftTrades}
        onTradeRemoved={this.removeLeftTrade}
        onTradeAdded={this.addLeftTrade}
        onSelectAll={this.selectAllLeft}
        onClearAll={this.clearAllLeft}
      />
    );
  };

  getRightSide = () => {
    const { kind } = this.state;
    if (kind === undefined) {
      return null;
    }

    if (kind === BOvsCP || kind === CPvsCP) {
      return (
        <ReconcileBrokersSide
          trades={this.state.allRightTrades}
          qtySelected={this.state.selectedRightQty}
          selected={this.state.selectedRightTrades}
          onTradeRemoved={this.removeRightTrade}
          onTradeAdded={this.addRightTrade}
          onSelectAll={this.selectAllRight}
          onClearAll={this.clearAllRight}
        />
      );
    }

    return (
      <ReconcileOursSide
        trades={this.state.allRightTrades}
        qtySelected={this.state.selectedRightQty}
        selected={this.state.selectedRightTrades}
        onTradeRemoved={this.removeRightTrade}
        onTradeAdded={this.addRightTrade}
        onSelectAll={this.selectAllRight}
        onClearAll={this.clearAllRight}
      />
    );
  };

  calcReconcileInfo = () => {
    const {
      selectedRightTrades,
      selectedLeftTrades,
      allLeftTrades,
      allRightTrades,
      cpWithInfoList,
    } = this.state;
    const allTrades = [...allLeftTrades, ...allRightTrades];
    const allSelectedTradesIds = [
      ...selectedRightTrades,
      ...selectedLeftTrades,
    ];
    const allSelectedTradesWithInfo = allSelectedTradesIds.map((id) => {
      const cpTrade = allTrades.find((i) => i[CTradesIDField] === id);
      const cptTrade = allTrades.find((i) => i[CPTradesIDField] === id);
      return cpTrade || cptTrade;
    });
    const everyIsOneCcy = allSelectedTradesWithInfo.every(
      (i) => i.ccy === allSelectedTradesWithInfo[0].ccy,
    );
    const allCpsIgnoreCcy = allSelectedTradesWithInfo.reduce((acc, item) => {
      const cpFromTrade = cpWithInfoList.find(
        (i) =>
          (item[CTradesCpField] || item[CPTradesCpField])?.toUpperCase() ===
          i.cp?.toUpperCase(),
      );
      return acc === null
        ? cpFromTrade?.ignore_ccy
        : cpFromTrade?.ignore_ccy && acc;
    }, null);

    const moreOneTradeSelected =
      selectedRightTrades.length > 0 && selectedLeftTrades.length > 0;
    const buttonEnabled =
      (moreOneTradeSelected && everyIsOneCcy) ||
      (moreOneTradeSelected && allCpsIgnoreCcy);

    return {
      buttonEnabled,
      everyIsOneCcy,
    };
  };

  render() {
    const { buttonEnabled, everyIsOneCcy } = this.calcReconcileInfo();

    const reconcileButton = this.state.reconciliationInProgress ? (
      <Spinner />
    ) : (
      <button
        className="btn btn-warning"
        onClick={this.onReconcileSubmit}
        disabled={!buttonEnabled}
      >
        Reconcile
      </button>
    );

    const deltaNotifier = this.state.selectedValueDelta ? (
      <div className="alert alert-danger my-2" role="alert">
        Value delta between selected left and right trades is{' '}
        <b>{this.state.selectedValueDelta}</b>
      </div>
    ) : null;

    const ccyIsDiffAlert = !everyIsOneCcy ? (
      <div className="alert alert-danger" role="alert">
        CCY is different!
      </div>
    ) : null;

    const reconciledTradesTable =
      this.state.reconciledGroups.length === 0 ? undefined : (
        <div className="row mt-2">
          <ManualUnreconcileTable entries={this.state.reconciledGroups} />
        </div>
      );

    return (
      <div className="container-fluid">
        <p>* Expirations and Redemptions are highlighted</p>
        {reconcileButton}
        <div className="row mt-2">
          <div className="col">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                className="btn btn-primary"
                onClick={this.clearAllSelected}
              >
                Clear selected
              </button>
              <button className="btn btn-danger" onClick={this.selectAll}>
                Select all
              </button>
            </div>
          </div>
        </div>
        {deltaNotifier}
        {ccyIsDiffAlert}
        <div className="row">
          {this.getLeftSide()}
          {this.getRightSide()}
        </div>
        {reconciledTradesTable}
      </div>
    );
  }
}
