import { useState, ChangeEventHandler } from 'react';
import { Button, Tooltip } from 'react-ui-kit-exante';

import { SIDE, TablesWithBreakReport } from 'services/ReconTablesApiService';

export function getGeneralCheckbox<T extends { id: number }>(
  transactions: T[],
  selectedTransactionsIds: number[],
  onCheckAllTransactionOnPage: () => void,
  onUnCheckAllTransactionOnPage: () => void,
) {
  const selectedTransactionsOnPageCount = transactions.reduce((acc, item) => {
    const foundElement = selectedTransactionsIds.find((id) => item.id === id);
    return foundElement ? acc + 1 : acc;
  }, 0);
  const checked = transactions.length === selectedTransactionsOnPageCount;
  return (
    <input
      type="checkbox"
      onChange={
        !checked ? onCheckAllTransactionOnPage : onUnCheckAllTransactionOnPage
      }
      checked={checked}
    />
  );
}

export function getCellCheckbox<T extends { id: number }>(
  id: number,
  transactions: T[],
  selectedTransactionsIds: number[],
  onCheckTransaction: (item: T) => void,
) {
  const checked = selectedTransactionsIds.find((item) => item === id);
  const transaction = transactions.find((item) => item.id === id);
  if (!transaction) {
    return null;
  }
  return (
    <div>
      <input
        type="checkbox"
        onChange={() => onCheckTransaction(transaction)}
        checked={!!checked}
      />
    </div>
  );
}

export function getBreakReportCategoryCell<
  B extends { id: number; name: string },
>(
  id: number,
  break_report_category: string,
  side: string,
  break_comment: string,
  breakCategories: B[],
  onUpdateBreakCategoryById: (
    recordTypesMapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => void,
  theirTableName: TablesWithBreakReport,
  ourTableName: TablesWithBreakReport,
) {
  const items = breakCategories.filter((i) => i.name !== break_report_category);
  const currentCategory = breakCategories.find(
    (i) => i.name === break_report_category,
  );
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const recordTypesMapping = {
      [theirTableName]: side === SIDE.THEIR ? [id] : [],
      [ourTableName]: side === SIDE.OUR ? [id] : [],
    };
    onUpdateBreakCategoryById(
      recordTypesMapping,
      Number(event.target.value),
      break_comment,
    );
  };
  return (
    <div className="input-group">
      {break_report_category ? (
        <select className="custom-select w-100" onChange={handleChange}>
          {currentCategory ? (
            <option value={currentCategory.id} key={currentCategory.id}>
              {currentCategory.name}
            </option>
          ) : null}
          {items.map((i) => (
            <option value={i.id} key={i.id}>
              {i.name}
            </option>
          ))}
        </select>
      ) : null}
    </div>
  );
}

export function getBreakCommentCell<B extends { id: number; name: string }>(
  id: number,
  break_report_category: string,
  side: string,
  break_comment: string,
  breakCategories: B[],
  onUpdateBreakCategoryById: (
    recordTypesMapping: Record<string, number[]>,
    categoryId: number,
    comment: string,
  ) => void,
  theirTableName: TablesWithBreakReport,
  ourTableName: TablesWithBreakReport,
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [comment, setComment] = useState(break_comment);
  const handleBlur: ChangeEventHandler<HTMLInputElement> = () => {
    if (!comment) {
      // eslint-disable-next-line no-alert
      alert('Fill in the comment field to save');
      setComment(break_comment);
      return;
    }
    const currentCategory = breakCategories.find(
      (i) => i.name === break_report_category,
    );
    const recordTypesMapping = {
      [theirTableName]: side === SIDE.THEIR ? [id] : [],
      [ourTableName]: side === SIDE.OUR ? [id] : [],
    };
    onUpdateBreakCategoryById(
      recordTypesMapping,
      currentCategory?.id ?? 1,
      comment,
    );
  };
  return (
    <div>
      {break_comment ? (
        <input
          type="text"
          className="form-control"
          style={{ width: '100%' }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              // @ts-ignore
              e.target.blur();
            }
          }}
          onBlur={handleBlur}
        />
      ) : null}
    </div>
  );
}

export function getUngroupCell(
  group_id: number,
  onUngroup: (item: number) => void,
) {
  return group_id ? (
    <div>
      <div>
        <span>{group_id}</span>
      </div>
      <Button color="red" onClick={() => onUngroup(group_id)}>
        Unrecon
      </Button>
    </div>
  ) : null;
}

export const getColumnHeader = (
  name: string,
  title = "Hold down the 'shift' key to multi sort",
) => (
  <Tooltip title={title} placement="top">
    <span>{name}</span>
  </Tooltip>
);
