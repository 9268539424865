import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ReportWithFields } from 'services/ReconTablesApiService';

interface Props {
  report: ReportWithFields;
  onClone: (value: number) => void;
}

export const ReportTableRow: FC<Props> = ({ report, onClone }) => {
  const { fields } = report;
  const rowSpan = !fields.length ? 1 : fields.length;
  const extraRows = useMemo(() => {
    if (fields.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [first, ...allFields] = fields;
      return allFields.map((item, index) => ({
        ...item,
        key: `${report.id}_${index}`,
      }));
    }
    return [];
  }, [report]);

  return (
    <>
      <tr key={report.id}>
        <td rowSpan={rowSpan}>
          <Link to={`/mapping/reports/${report.id}/`}>{report.id}</Link>
        </td>
        <td rowSpan={rowSpan}>{report.cp_id}</td>
        <td rowSpan={rowSpan}>{report.name}</td>
        <td rowSpan={rowSpan}>{report.type}</td>
        <td rowSpan={rowSpan}>{report.path}</td>
        <td rowSpan={rowSpan}>{report.contains}</td>
        <td rowSpan={rowSpan}>{report.file_type}</td>
        <td rowSpan={rowSpan}>{report.state.toString()}</td>
        {!fields || fields.length === 0 ? (
          <td rowSpan={rowSpan}>No conditions</td>
        ) : (
          <td>{`${fields[0].cp_field} to ${fields[0].mapping_field} by ${fields[0].mapping_type}`}</td>
        )}

        <td rowSpan={rowSpan}>
          <button
            type="button"
            className="btn btn-sm btn-outline-success"
            onClick={() => onClone(report.id)}
          >
            Clone
          </button>
        </td>
      </tr>
      {extraRows.map((i) => (
        <tr key={i.key}>
          <td>{`${i.cp_field} to ${i.mapping_field} by ${i.mapping_type}`}</td>
        </tr>
      ))}
    </>
  );
};
