import { FC, useCallback, useMemo } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import {
  Position,
  PositionsTable,
  ReconEmirApiService,
} from 'services/ReconEmirApiService';
import { calculateCountOfPages } from 'utils';

import { getPositionsTableColumns, parseNumberValue } from './utils';

const api = new ReconEmirApiService();

export const EmirPositionsPage: FC = () => {
  const getPositions = useCallback(
    (props: IOnFetchArguments) => api.getPositions(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getPositionsTableColumns({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Position)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getPositions },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getPositions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<PositionsTable>(tableData);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const positionItems = useMemo(
    () =>
      !data
        ? []
        : data.positions.map((item) => ({
            ...item,
            qty: parseNumberValue(item.qty),
            price: parseNumberValue(item.price),
            value: parseNumberValue(item.value),
            pnl: parseNumberValue(item.pnl),
          })),
    [data],
  );

  const columns = useMemo(
    () =>
      getPositionsTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [positionItems],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            tableId="emirPositions"
            data={positionItems}
            columns={columns}
            filteringProps={filteringProps}
            isLoading={isLoading}
            defaultSortBy={defaultOrdering}
            hasFilters
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            isFlexLayout
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
      </div>
    </div>
  );
};
