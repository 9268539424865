import dayjs from 'dayjs';
import { UseTableCellProps } from 'react-table';
import { Button, Checkbox, TableProps } from 'react-ui-kit-exante';

import { DateFormats, workflowOptions } from '../../constants';
import {
  ApprovalsDataProps,
  ColumnsApprovalsProps,
} from '../../pages/Approvals/types';

export const getApprovalsTableColumns = ({
  approve,
  decline,
  onRemove,
  onFilter,
  activeCheckbox,
  setActiveCheckbox,
}: ColumnsApprovalsProps): TableProps<ApprovalsDataProps>['columns'] => [
  {
    Header: 'Selected',
    accessor: 'checkbox',
    disableFilters: true,
    width: 80,
    disableSortBy: true,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => (
      <Checkbox
        checked={row.values?.execution_id === activeCheckbox}
        onClick={() => setActiveCheckbox(row.values?.execution_id)}
      />
    ),
  },
  {
    Header: 'Execution id',
    accessor: 'execution_id',
    disableFilters: true,
    width: 300,
    disableSortBy: true,
  },
  {
    Header: 'Workflow',
    accessor: 'workflow',
    width: 160,
    type: 'select',
    onFilter,
    onRemove,
    filterOptions: workflowOptions,
  },
  {
    Header: 'Create time',
    accessor: 'create_time',
    width: 115,
    type: 'dateRange',
    onFilter,
    onRemove,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => (
      <span>
        {dayjs(row.values?.create_time as string).format(DateFormats.DDMMYYYY)}
      </span>
    ),
  },
  {
    Header: 'Update time',
    accessor: 'update_time',
    type: 'dateRange',
    width: 115,
    disableFilters: true,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => (
      <span>
        {dayjs(row.values?.create_time as string).format(DateFormats.DDMMYYYY)}
      </span>
    ),
  },
  {
    Header: 'Author',
    accessor: 'author',
    width: 130,
    onFilter,
    onRemove,
  },
  {
    Header: 'Approvers',
    accessor: 'approvers',
    width: 280,
    onFilter,
    onRemove,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => (
      <span style={{ whiteSpace: 'pre-wrap' }}>
        {row.values?.approvers.join(', ')}
      </span>
    ),
  },
  {
    Header: 'Info',
    accessor: 'current_state',
    disableFilters: true,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => {
      const currentState = row.values?.current_state;
      const transaction =
        JSON.stringify(currentState?.info) ||
        JSON.stringify(currentState?.trade) ||
        JSON.stringify(currentState?.transaction) ||
        '';

      return (
        <span style={{ whiteSpace: 'pre-wrap' }} title={transaction}>
          {transaction}
        </span>
      );
    },
  },
  {
    Header: 'Approve',
    accessor: 'approve',
    disableFilters: true,
    width: 120,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => {
      const executionId = row.values?.execution_id;
      return (
        <Button
          size="small"
          onClick={() => approve(executionId)}
          disabled={activeCheckbox !== executionId}
        >
          Approve
        </Button>
      );
    },
  },
  {
    Header: 'Decline',
    accessor: 'decline',
    disableFilters: true,
    width: 120,
    Cell: ({ row }: UseTableCellProps<ApprovalsDataProps>) => {
      const executionId = row.values?.execution_id;
      return (
        <Button
          color="red"
          size="small"
          onClick={() => decline(executionId)}
          disabled={activeCheckbox !== executionId}
        >
          Decline
        </Button>
      );
    },
  },
];

export const calculateCountOfPages = (total: number, limit: number) =>
  Math.ceil(total / limit);
