import { Component } from 'react';

import { getFormPayload } from '../../../services/utils';

export default class MappedSymbolsFilter extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFormPayload(e.target, true);
    this.props.onSubmit(payload);
  };

  render() {
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit} name="mappedSymbolsFilter">
          <div className="form-row mb-2 mt-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP</div>
                </div>
                <input type="text" className="form-control" name="cp" />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Exante Symbol</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="exante_symbol"
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP Symbol</div>
                </div>
                <input type="text" className="form-control" name="cp_symbol" />
              </div>
            </div>
          </div>
          <div className="form-row mb-2 mt-2">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CCY</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="ccy"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">ISIN</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="isin"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">CP Type</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="cp_type"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-2">
              <button className="btn btn-outline-success" type="submit">
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
