import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ReconTablesApiService from 'services/ReconTablesApiService';

import { NewFieldForm } from './form';

function DetailsView({ report }) {
  const [states, setStates] = useState([]);

  const onDeleteClick = (cp) => {
    const api = new ReconTablesApiService();
    api.postDeleteMappingField(cp).then(() => {
      window.location.reload();
    });
  };

  const [cp, setCp] = useState(report.cp_id);
  const [name, setName] = useState(report.name);
  const [path, setPath] = useState(report.path);
  const [contains, setContains] = useState(report.contains);
  const [fileType, setFileType] = useState(report.file_type);
  const [delimiter, setDelimiter] = useState(report.delimitter);
  const [quotechar, setQuotechar] = useState(report.quotechar);
  const [isHeading, setIsHeading] = useState(report.is_heading);
  const [counts, setCounts] = useState(report.counts);

  const onSelected = (e) => {
    const payload = {};
    const api = new ReconTablesApiService();

    payload.report_id = report.id;
    payload.state = e.target.value;
    api.postMappingUpdateReport(payload).then(() => {
      window.location.reload();
    });
  };
  useEffect(() => {
    const api = new ReconTablesApiService();
    api.getListReportState().then((res) => {
      const options = res.results.map((r) => {
        if (report.state === r.state) {
          return (
            <option selected key={r.state} value={r.state}>
              {r.state}
            </option>
          );
        }

        if (report.state === 'Testing' && r.state === 'Stopped') {
          return null;
        }

        if (report.state === 'Setup' && r.state === 'Stopped') {
          return null;
        }

        if (
          report.state === 'Active' &&
          (r.state === 'Setup' || r.state === 'Testing')
        ) {
          return null;
        }

        if (
          report.state === 'Stopped' &&
          (r.state === 'Testing' || r.state === 'Setup')
        ) {
          return null;
        }

        if (report.state === 'Closed') {
          return null;
        }

        return (
          <option key={r.state} value={r.state}>
            {r.state}
          </option>
        );
      });
      setStates(options);
    });
  }, []);

  const reportDetails = () => {
    const details = [];

    // eslint-disable-next-line
    for (const d of report.fields) {
      if (report.state === 'Testing' || report.state === 'Setup') {
        details.push(
          <tr>
            <td>
              <b>CP Field</b>
            </td>
            <td>{d.cp_field}</td>
            <td>
              <b>Mapping Field</b>
            </td>
            <td>{d.mapping_field}</td>
            <td>
              <b>Mapping Type</b>
            </td>
            <td>{d.mapping_type}</td>
            <td>
              <button
                type="button"
                className="btn btn-sm btn-outline-success"
                onClick={() => onDeleteClick(d.id)}
              >
                Delete
              </button>
            </td>
          </tr>,
        );
        if (d.conditions.length > 0) {
          // eslint-disable-next-line
          for (const cond of d.conditions) {
            details.push(
              <tr>
                <td>
                  <b>Comparison_type</b>
                </td>
                <td>{cond.comparison_type}</td>
                <td>
                  <b>Condition Field</b>
                </td>
                <td>{cond.condition_field}</td>
                <td>
                  <b>Condition_value</b>
                </td>
                <td>{cond.condition_value}</td>
                <td />
              </tr>,
            );
          }
        }
        details.push(<tr />);
      } else {
        details.push(
          <tr>
            <td>
              <b>CP Field</b>
            </td>
            <td>{d.cp_field}</td>
            <td>
              <b>Mapping Field</b>
            </td>
            <td>{d.mapping_field}</td>
            <td>
              <b>Mapping Type</b>
            </td>
            <td>{d.mapping_type}</td>
            <td />
          </tr>,
        );
        if (d.conditions.length > 0) {
          // eslint-disable-next-line
          for (const cond of d.conditions) {
            details.push(
              <tr>
                <td>
                  <b>Comparison_type</b>
                </td>
                <td>{cond.comparison_type}</td>
                <td>
                  <b>Condition Field</b>
                </td>
                <td>{cond.condition_field}</td>
                <td>
                  <b>Condition_value</b>
                </td>
                <td>{cond.condition_value}</td>
                <td />
              </tr>,
            );
          }
        }
        details.push(<tr />);
      }
    }
    if (details.length === 0) {
      return null;
    }
    return (
      <>
        <tr>
          <td colSpan="7">
            <h4>Field Mapping Rules</h4>
          </td>
        </tr>
        {details}
      </>
    );
  };
  function handleBlur(param) {
    const api = new ReconTablesApiService();
    api.patchReport(report.id, param);
  }

  if (report.state === 'Setup' || report.state === 'Testing') {
    return (
      <div className="container-fluid mt-4">
        <h2>Report {report.id} details</h2>

        {
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>
                    <b>Counterparty</b>
                  </td>
                  <input
                    value={cp}
                    className="form-control"
                    // eslint-disable-next-line
                    onChange={(event) => setCp(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ cp_id: cp })}
                  />
                  <td>
                    <b>Type</b>
                  </td>
                  <td>{report.type}</td>
                  <td>
                    <b>State</b>
                  </td>
                  <td>
                    <select
                      onChange={onSelected}
                      className="custom-select mr-sm-2"
                      name="states"
                    >
                      {states}
                    </select>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    <b>Name</b>
                  </td>
                  <input
                    value={name}
                    className="form-control"
                    onChange={(event) => setName(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ name: name })}
                  />
                  <td>
                    <b>Path</b>
                  </td>
                  <input
                    value={path}
                    className="form-control"
                    onChange={(event) => setPath(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ path: path })}
                  />
                  <td>
                    <b>Contains</b>
                  </td>
                  <input
                    value={contains}
                    className="form-control"
                    onChange={(event) => setContains(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ contains: contains })}
                  />

                  <td />
                </tr>
                <tr>
                  <td>
                    <b>File Type</b>
                  </td>
                  <input
                    value={fileType}
                    className="form-control"
                    onChange={(event) => setFileType(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ file_type: fileType })}
                  />
                  <td>
                    <b>Delimitter</b>
                  </td>
                  <input
                    value={delimiter}
                    className="form-control"
                    onChange={(event) => setDelimiter(event.target.value)}
                    onBlur={() => handleBlur({ delimitter: delimiter })}
                  />
                  <td>
                    <b>Quotechar</b>
                  </td>
                  <input
                    value={quotechar}
                    className="form-control"
                    onChange={(event) => setQuotechar(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ quotechar: quotechar })}
                  />
                </tr>
                <tr>
                  <td>
                    <b>Heading</b>
                  </td>
                  <input
                    value={isHeading}
                    className="form-control"
                    onChange={(event) => setIsHeading(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() =>
                      handleBlur({ is_heading: setIsHeading(isHeading) })
                    }
                  />
                  <td>
                    <b>Count</b>
                  </td>
                  <input
                    value={counts}
                    className="form-control"
                    onChange={(event) => setCounts(event.target.value)}
                    // eslint-disable-next-line
                    onBlur={() => handleBlur({ counts: counts })}
                  />
                </tr>
                {reportDetails()}
              </tbody>
            </table>
          </div>
        }
      </div>
    );
  }
  return (
    <div className="container-fluid mt-4">
      <h2>Report {report.id} details</h2>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>
                <b>Counterparty</b>
              </td>
              <td>{report.cp_id}</td>
              <td>
                <b>Type</b>
              </td>
              <td>{report.type}</td>
              <td>
                <b>State</b>
              </td>
              <td>
                <select
                  onChange={onSelected}
                  className="custom-select mr-sm-2"
                  name="states"
                >
                  {states}
                </select>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>{report.name}</td>
              <td>
                <b>Path</b>
              </td>
              <td>{report.path}</td>
              <td>
                <b>Contains</b>
              </td>
              <td>{report.contains}</td>

              <td />
            </tr>
            <tr>
              <td>
                <b>File Type</b>
              </td>
              <td>{report.file_type}</td>
              <td>
                <b>Delimitter</b>
              </td>
              <td>{report.delimitter}</td>
              <td>
                <b>Quotechar</b>
              </td>
              <td>{report.quotechar}</td>
            </tr>
            <tr>
              <td>
                <b>Heading</b>
              </td>
              <td>{report.is_heading.toString()}</td>
              <td>
                <b>Count</b>
              </td>
              <td>{report.counts}</td>
            </tr>
            {reportDetails()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function ReportDetailsPage() {
  const { reportId } = useParams();
  const [report, setReport] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [addedReports, setAddedReports] = useState([]);
  const [type, setType] = useState('trades');
  const [state, setState] = useState('');
  const onNewReportCreated = (id) => {
    const newReports = [...addedReports];
    newReports.push(id);
    setAddedReports(newReports);
  };

  useEffect(() => {
    setLoading(true);
    const api = new ReconTablesApiService();
    api
      .getMappingReportWithFields(reportId)
      .then((r) => {
        setReport(r);
        setType(r.type);
        setState(r.state);
        setLoading(false);
      })
      .catch((e) => {
        alert(e);
        setReport(undefined);
        setLoading(false);
      });
  }, [reportId]);

  return (
    <>
      {loading ?? (
        <div className="mt-4">
          <Spin />
        </div>
      )}
      {!loading && report ? <DetailsView report={report} /> : null}
      <NewFieldForm
        onNewReportCreated={onNewReportCreated}
        report_id={reportId}
        type={type}
        state={state}
      />
    </>
  );
}
