import { FC, ReactNode, createContext, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { POLLING_DELAY } from '../../constants';
import { useInterval } from '../../hooks';
import { getRequest, postRequest } from '../../utils';

import {
  ContextProps,
  LocalParamsProps,
  FetchDataTableProps,
  ApprovalsTableProps,
} from './types';

export const ApprovalsDataContext = createContext<ContextProps>({
  dataTable: {
    data: [],
    total: 0,
  },
  isLoading: false,
  activeCheckbox: '',
  fetchDataTable: () => {},
  approve: (id: string) => id,
  decline: (id: string) => id,
  setActiveCheckbox: (id: string) => id,
});

export const ApprovalsDataProvider: FC<ReactNode> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeCheckbox, setActiveCheckbox] = useState('');
  const [skipLocal, setSkipLocal] = useState(0);
  const [limitLocal, setLimitLocal] = useState(20);
  const [authorLocal, setAuthorLocal] = useState('');
  const [workflowLocal, setWorkflowLocal] = useState('');
  const [approversLocal, setApproversLocal] = useState('');
  const [createTimeEndLocal, setCreateTimeEndLocal] = useState('');
  const [createTimeStartLocal, setCreateTimeStartLocal] = useState('');
  const [dataTable, setDataTable] = useState<ApprovalsTableProps>({
    data: [],
    total: 0,
  });

  const setLocalParams = ({
    skipParam,
    limitParam,
    authorParam,
    workflowParam,
    approversParam,
    createTimeEndParam,
    createTimeStartParam,
  }: LocalParamsProps) => {
    setSkipLocal(skipParam ?? 0);
    setLimitLocal(limitParam ?? 20);
    setAuthorLocal(authorParam ?? '');
    setWorkflowLocal(workflowParam ?? '');
    setApproversLocal(approversParam ?? '');
    setCreateTimeEndLocal(createTimeEndParam ?? '');
    setCreateTimeStartLocal(createTimeStartParam ?? '');
  };
  const fetchDataTable = ({
    params,
    isAction,
    isPolling,
  }: FetchDataTableProps) => {
    const skipParam = params?.skip;
    const limitParam = params?.limit;
    const authorParam = params?.author;
    const workflowParam = params?.workflow;
    const createTime = params?.create_time;
    const approversParam = params?.approvers;
    const createTimeEndParam =
      createTime && createTime[1] && `${createTime[1]}T23:59:00.000Z`;
    const createTimeStartParam =
      createTime && createTime[0] && `${createTime[0]}T00:00:00.000Z`;
    const skip = skipParam || skipLocal;
    const limit = limitParam || limitLocal;
    const author = authorParam || authorLocal;
    const workflow = workflowParam || workflowLocal;
    const approvers = approversParam || approversLocal;
    const createTimeEnd = createTimeEndParam || createTimeEndLocal;
    const createTimeStart = createTimeStartParam || createTimeStartLocal;
    const url = `/workflow-execution/?status=suspended&wf_type=${workflow}&author=${author}&create_time_gte=${createTimeStart}&create_time_lte=${createTimeEnd}&approver=${approvers}&limit=${limit}&offset=${skip}`;

    if (!isAction && !isPolling) {
      setLocalParams({
        skipParam,
        limitParam,
        authorParam,
        workflowParam,
        approversParam,
        createTimeEndParam,
        createTimeStartParam,
      });
    }

    if (!isPolling) {
      setIsLoading(true);
    }

    getRequest(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status code: ${response.status}`);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setDataTable({ data: data?.results?.reverse(), total: data?.count });
        Notification.success(
          isPolling
            ? 'Data updated successfully'
            : 'Data uploaded successfully',
        );
      })
      .catch((error) => Notification.error('Oops!', error.message))
      .finally(() => setIsLoading(false));
  };

  const approve = (id: string) => {
    const payload = {
      workflow_execution_id: id,
    };

    setIsLoading(true);

    postRequest({ url: '/approve/', payload })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} status code`);
        }
      })
      .then(() => {
        fetchDataTable({ isAction: true });
        Notification.success('Approved successfully');
      })
      .catch((error) => {
        setIsLoading(false);
        Notification.error('Oops!', error.message);
      });
  };

  const decline = (id: string) => {
    const payload = {
      workflow_execution_id: id,
    };
    setIsLoading(true);
    postRequest({ url: '/decline/', payload })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`status code: ${response.status}`);
        } else {
          fetchDataTable({ isAction: true });
          Notification.success('Declined successfully');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Notification.error('Oops!', error.message);
      });
  };

  useInterval(() => fetchDataTable({ isPolling: true }), POLLING_DELAY);

  return (
    <ApprovalsDataContext.Provider
      value={{
        approve,
        decline,
        dataTable,
        isLoading,
        fetchDataTable,
        activeCheckbox,
        setActiveCheckbox,
      }}
    >
      {children}
    </ApprovalsDataContext.Provider>
  );
};
