import { useEffect, useState } from 'react';

export function EodModalDetails(props) {
  const { data } = props;
  const [dataTable, setDataTable] = useState();

  const getTableFromData = () => {
    if (data === undefined || data.lenght === 0) {
      return <span>Loading data...</span>;
    }

    const cTradesRows = data.ctrades.map((trade) => (
      <tr key={trade.trade_number}>
        <td>{trade.trade_number}</td>
        <td>{trade.symbol}</td>
        <td>{trade.qty}</td>
        <td>{trade.price}</td>
        <td>{trade.value}</td>
        <td>{trade.timestamp}</td>
      </tr>
    ));

    const cpTradesRows = data.cptrades.map((trade) => (
      <tr key={trade.full_id}>
        <td>{trade.full_id}</td>
        <td>{trade.symbol}</td>
        <td>{trade.qty}</td>
        <td>{trade.price}</td>
        <td>{trade.value}</td>
        <td>{trade.timestamp}</td>
      </tr>
    ));

    return (
      <table className="table table-sm table-hover table-striped">
        <thead className="thead-light">
          <tr>
            <th>TradeNumber/FullID</th>
            <th>Symbol</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Value</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6">
              <h3>BO Trades</h3>
            </td>
          </tr>
          {cTradesRows}
          <tr>
            <td colSpan="6">
              <h3>CP Trades</h3>
            </td>
          </tr>
          {cpTradesRows}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    setDataTable(getTableFromData());
  }, [data]);

  return (
    <div
      className="modal fade"
      id="EodModalDetails"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="EodModalDetails"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{dataTable}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
