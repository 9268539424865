import { Link } from 'react-router-dom';
import { UseTableCellProps } from 'react-table';
import { TableProps } from 'react-ui-kit-exante';

import { initialUrl } from 'constants/app';
import { minColumnWidth as minWidth } from 'constants/tables';
import { Position, Trade } from 'services/ReconEmirApiService';
import { ColumnsWithFilters } from 'types';

const columnWidth = 300;
const successStatuses = ['entry_created', 'entry created'];
export function getTradesTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: TableProps<Trade>['columns'] = [
    {
      Header: 'Link',
      accessor: 'uid',
      minWidth: 40,
      Cell: ({ row }: UseTableCellProps<Trade>) => {
        const internalStatus = row.original.status ?? '';
        return successStatuses.includes(internalStatus) ? (
          <Link
            to={`${initialUrl}/emir/trade/${row.values.uid}`}
            target="_blank"
          >
            Link
          </Link>
        ) : null;
      },
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Position id',
      accessor: 'position_id',
      minWidth: 40,
      Cell: ({ row }: UseTableCellProps<Trade>) => {
        const internalStatus = row.original.status ?? '';
        return successStatuses.includes(internalStatus) ? (
          <Link
            to={`${initialUrl}/emir/position/${row.values.position_id}`}
            target="_blank"
          >
            {row.values.position_id}
          </Link>
        ) : null;
      },
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade date',
      accessor: 'trade_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade time',
      accessor: 'trade_time',
      width: columnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Account ID',
      accessor: 'account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal status',
      accessor: 'internal_status',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Side',
      accessor: 'side',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol ID',
      accessor: 'symbol',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol Type',
      accessor: 'symbol_type',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Quantity',
      accessor: 'qty',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Traded volume',
      accessor: 'value',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Currency',
      accessor: 'ccy',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Counterparty account ID',
      accessor: 'cp_account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Execution counterparty',
      accessor: 'execution_cp',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlement_cp',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'entity',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order ID',
      accessor: 'order_id',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status date',
      accessor: 'status_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}

export function getPositionsTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: TableProps<Position>['columns'] = [
    {
      Header: 'Link',
      accessor: 'id',
      minWidth,
      Cell: ({ row }: UseTableCellProps<Trade>) => {
        const internalStatus = row.original.status ?? '';
        return successStatuses.includes(internalStatus) ? (
          <Link
            to={`${initialUrl}/emir/position/${row.values.id}`}
            target="_blank"
          >
            Link
          </Link>
        ) : null;
      },
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Ccy',
      accessor: 'ccy',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Cp',
      accessor: 'cp',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Created date',
      accessor: 'created_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Exchange',
      accessor: 'exchange',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Expiration date',
      accessor: 'expiration_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal status',
      accessor: 'internal_status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Isin',
      accessor: 'isin',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Operation type',
      accessor: 'operation_type',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Pnl',
      accessor: 'pnl',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Realized pnl',
      accessor: 'realized_pnl',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Sent date',
      accessor: 'sent_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status date',
      accessor: 'status_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value',
      accessor: 'value',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}

export const parseNumberValue = (value: string) =>
  Number.parseFloat(value).toString();
