import { useEffect, useState } from 'react';

import ReconTablesApiService from 'services/ReconTablesApiService';
import { getFormPayload } from 'services/utils';

export function NewCpForm({ onNewReportCreated }) {
  const [maltaEntity, setMaltaEntity] = useState([]);
  const [cpType, setCpType] = useState([]);
  const selectedReport = undefined;

  useEffect(() => {
    const apiService = new ReconTablesApiService();

    apiService.getListMappingReportType().then(() => {});

    apiService.getListComparisonType().then(() => {});
  }, []);

  useEffect(() => {
    const apiService = new ReconTablesApiService();
    if (selectedReport === 'trades') {
      apiService.getListMappingCpTradesFields().then(() => {});
    }

    if (selectedReport === 'positions') {
      apiService.getListMappingCpTradesFields().then(() => {});
    }

    if (selectedReport === 'cash') {
      apiService.getListMappingRowBalanceFields().then(() => {});
    }

    if (selectedReport === 'coupon') {
      apiService.getCouponFields().then(() => {});
    }

    if (selectedReport === 'dividend') {
      apiService.getDividendFields().then(() => {});
    }
  }, [selectedReport]);

  useEffect(() => {
    const apiService = new ReconTablesApiService();
    apiService.getListCpType().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.data} value={r.data}>
          {r.data}
        </option>
      ));
      setCpType(options);
    });

    apiService.getListBOEntityLegalName().then((res) => {
      const options = res.results.map((r) => (
        <option key={r.name} value={r.id_in_ctrades}>
          {r.name}
        </option>
      ));
      setMaltaEntity(options);
    });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    const rawPayload = getFormPayload(event.target);
    const payload = {};

    payload.data = {
      cp: rawPayload.cp,
      malta_entity: parseInt(rawPayload.type, 10),
      cp_type: rawPayload.cp_type,
      active: rawPayload.active === 'true',
      bo_settlement_cp: rawPayload.bo_settlement_cp,
      settlement_execution_ccp: rawPayload.settlement_execution_ccp,
    };
    const api = new ReconTablesApiService();
    api
      .postCpMapping(payload)
      .then((res) => {
        onNewReportCreated(res.report_id);
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <div className="container mb-3 mt-3">
      <form onSubmit={onSubmit}>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Counterparty</div>
              </div>
              <input type="text" className="form-control" name="cp" />
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Entity</div>
              </div>
              <select className="custom-select mr-sm-2" name="type">
                {maltaEntity}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Cp type</div>
              </div>
              <select className="custom-select mr-sm-2" name="cp_type">
                {cpType}
              </select>
            </div>
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Settlement cp</div>
            </div>
            <input
              type="text"
              className="form-control"
              name="bo_settlement_cp"
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Execution cp</div>
            </div>
            <input
              type="text"
              className="form-control"
              name="settlement_execution_ccp"
            />
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Active</div>
              </div>
              <select className="custom-select mr-sm-2" name="active">
                <option key="true" value="true">
                  Yes
                </option>
                <option key="false" value="false">
                  No
                </option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <button className="btn btn-warning" type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
