import { Component } from 'react';
import { connect } from 'react-redux';

import { Spinner } from 'components/Spinner/Spinner';
import ReconTablesApiService from 'services/ReconTablesApiService';

import Form from './form';
import Table from './table';

class CTradesTablePage extends Component {
  api = new ReconTablesApiService();

  constructor(props) {
    super(props);
    this.state = {
      trades: [],
      request: false,
      error: false,
      query: undefined,
    };
  }

  onFormSubmit = (query) => {
    this.setState({ request: true, error: false, query });
    this.api
      .getListCTradesRawQueryParams(query)
      .then((data) => {
        this.setState({ trades: data.results, request: false });
      })
      .catch((e) => {
        alert(e);
        this.setState({ trades: [], request: false, error: true });
      });
  };

  render() {
    const spinner = this.state.request ? <Spinner /> : null;
    const error = this.state.error ? <h2>Error!</h2> : null;

    let table = <Table data={this.state.trades} />;

    table = this.state.request ? undefined : table;

    if (
      table !== undefined &&
      (this.state.trades === undefined || this.state.trades.length === 0)
    ) {
      table = <h5>Nothing found</h5>;
    }

    const exportButton =
      this.state.trades === undefined ||
      this.state.trades.length === 0 ? null : (
        <a
          className="btn btn-link mb-2"
          href={this.api.exportCtradesURL(this.state.query)}
          target="_blank"
          rel="noreferrer"
        >
          Export to csv
        </a>
      );

    return (
      <>
        <br />
        <Form onSubmit={this.onFormSubmit} />
        <br />
        {spinner}
        {exportButton}
        {table}
        {error}
      </>
    );
  }
}

export default connect()(CTradesTablePage);
