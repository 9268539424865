import { FC, useCallback, useMemo } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import {
  ReconEmirApiService,
  Trade,
  TradesTable,
} from 'services/ReconEmirApiService';
import { calculateCountOfPages } from 'utils';

import { getTradesTableColumns, parseNumberValue } from './utils';

const api = new ReconEmirApiService();

export const EmirTradesPage: FC = () => {
  const getTrades = useCallback(
    (props: IOnFetchArguments) => api.getTrades(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getTradesTableColumns({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Trade)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTrades },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<TradesTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const tradeItems = useMemo(
    () =>
      !data
        ? []
        : data.trades.map((item) => ({
            ...item,
            qty: parseNumberValue(item.qty),
            price: parseNumberValue(item.price),
            value: parseNumberValue(item.value),
          })),
    [data],
  );
  const columns = useMemo(
    () =>
      getTradesTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [tradeItems],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            tableId="emirTable"
            data={tradeItems}
            columns={columns}
            filteringProps={filteringProps}
            defaultSortBy={defaultOrdering}
            isLoading={isLoading}
            hasFilters
            hasPagination
            showTableInfo
            manualSortBy
            onSort={handleSorting}
            isFlexLayout
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
      </div>
    </div>
  );
};
